import React from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  Card,
  CardContent,
  Box,
  Theme,
  Stack,
  Chip,
  Typography,
  CardProps,
  Button,
  SvgIconProps,
  SxProps,
} from '@mui/material';
// ICONS
import CheckIcon from '@mui/icons-material/Check';
// CONSTANTS
import { IFlexiblePlan, SubjectColors } from '../../../constants';

interface PlanCardProps {
  plan: IFlexiblePlan;
  cardStyles?: CardProps['sx'];
}

// STYLES
const defaltHeaderContainerStyles: SxProps<Theme> = {
  borderRadius: '7px',
  color: '#fff',
  padding: '10px 15px',
};

const defaultChipStyles: React.CSSProperties = {
  borderRadius: '4px',
  fontWeight: 500,
};

const cardHeaderTitleBottomLineStyles: React.CSSProperties = {
  backgroundColor: '#fff',
  borderRadius: '4px',
  minHeight: '3px',
  minWidth: '50px',
};

const rateViewerContainerStyles: React.CSSProperties = {
  textAlign: 'center',
  marginTop: '40px',
};

const badgeContainerStyles: SxProps<Theme> = {
  textAlign: 'right',
  marginBottom: '-65px',
  marginTop: '-10px',
  marginRight: '-10px',
};

const defaultCardHeaderContainerBackground =
  "url('/images/shapes/billing/half-circle-dock-top-right.png') no-repeat top right/150px, url('/images/shapes/billing/half-circle-dock-bottom-left.png') no-repeat bottom left/100px";

// DATA
const getCardColor = (planKey: IFlexiblePlan['key']) => {
  let color = SubjectColors[0];

  switch (planKey) {
    case 'regular_plan':
      // eslint-disable-next-line prefer-destructuring
      color = SubjectColors[1];
      break;
    case 'premium_plan':
      // eslint-disable-next-line prefer-destructuring
      color = SubjectColors[4];
      break;
    default:
      // eslint-disable-next-line prefer-destructuring
      color = SubjectColors[0];
      break;
  }

  return color;
};

const getChipLabelColor = (planKey: IFlexiblePlan['key']) => {
  let chipColor: React.CSSProperties = {
    backgroundColor: '#f3c43f',
    color: '#000',
  };

  switch (planKey) {
    case 'premium_plan':
      chipColor = {
        backgroundColor: '#000',
        color: '#fff',
      };
      break;
    default:
      chipColor = {
        backgroundColor: '#f3c43f',
        color: '#000',
      };
      break;
  }

  return chipColor;
};

const getCardBadgeUrl = (planKey: IFlexiblePlan['key']) => {
  let badge = '/images/shapes/billing/planCard/excel-plan-badge.png';

  switch (planKey) {
    case 'regular_plan':
      badge = '/images/shapes/billing/improve-plan-badge.png';
      break;
    case 'platinum_plan':
      badge = '/images/shapes/billing/succeed-plan-badge.png';
      break;
    default:
      badge = '/images/shapes/billing/excel-plan-badge.png';
      break;
  }

  return badge;
};

const checkFeatureHighlight = (
  planKey: IFlexiblePlan['key'],
  index: number
) => {
  let isHighLighted = false;

  const improvePlanHighLightFeatureIndexes = [] as number[];
  const succeedPlanHighLightFeatureIndexes = [] as number[];
  const excelPlanHighLightFeatureIndexes = [] as number[];

  switch (planKey) {
    case 'regular_plan':
      isHighLighted = improvePlanHighLightFeatureIndexes.includes(index);
      break;
    case 'premium_plan':
      isHighLighted = succeedPlanHighLightFeatureIndexes.includes(index);
      break;
    case 'platinum_plan':
      isHighLighted = excelPlanHighLightFeatureIndexes.includes(index);
      break;
    default:
      isHighLighted = false;
      break;
  }

  return isHighLighted;
};

const PlanCard: React.FC<PlanCardProps> = ({ plan, cardStyles }) => {
  // HOOKS
  const navigate = useNavigate();
  const params = useParams();
  const location = useLocation();

  const _subjectColor = getCardColor(plan.key);
  const _chipColor = getChipLabelColor(plan.key);
  const _badgeUrl = getCardBadgeUrl(plan.key);

  const urlParams = new URLSearchParams(location.search);
  let isInstalment = false;

  // STYLES
  const _headerContainerStyes: SxProps<Theme> = {
    ...defaltHeaderContainerStyles,
    background: `${defaultCardHeaderContainerBackground}, ${_subjectColor}`,
  };
  const _chipStyles: React.CSSProperties = {
    ...defaultChipStyles,
    ..._chipColor,
  };

  const _cardStyles: CardProps['sx'] = {
    borderRadius: '7px',
    height: '100%',
    minHeight: '750px',
    ...(cardStyles || {}),
  };

  if (urlParams.get('payment') === 'instalment') {
    isInstalment = true;
  }
  return (
    <Card elevation={3} sx={_cardStyles}>
      <CardContent style={{ padding: '8px' }}>
        {/* CARD HEADER */}
        <Box sx={_headerContainerStyes}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <div>
              <div style={{ marginBottom: '8px' }}>
                <Typography fontWeight={500}>{plan.title}</Typography>
              </div>
              <div style={cardHeaderTitleBottomLineStyles} />
            </div>
            {plan.labelText && (
              <div>
                <Chip label={plan.labelText} size="small" style={_chipStyles} />
              </div>
            )}
          </Stack>
          <div style={rateViewerContainerStyles}>
            <div>
              <Typography className="ellipsis-text">
                <span style={{ fontWeight: 500, fontSize: '20px' }}>
                  {plan.currency}
                </span>
                &nbsp;&nbsp;
                <span style={{ fontWeight: 600, fontSize: '40px' }}>
                  {plan.rate || '0'}
                </span>
                &nbsp;
                <span style={{ fontWeight: 400, fontSize: '14px' }}>
                  / per hour
                </span>
              </Typography>
            </div>
            <div>
              <Typography fontWeight={500} fontSize="18px">
                {plan.hours} Hours
              </Typography>
            </div>
          </div>
          <Box sx={badgeContainerStyles}>
            <img src={_badgeUrl} alt={plan.key} style={{ width: '100px' }} />
          </Box>
        </Box>

        {/* CARD CONTENT */}
        <div style={{ padding: '60px 15px 50px 15px' }}>
          <Stack direction="row" justifyContent="center">
            <Button
              fullWidth
              size="large"
              variant="contained"
              color="primary"
              onClick={() =>
                isInstalment
                  ? navigate(
                      `/payment/instalment/${plan.key}/${params.userId}${location.search}`
                    )
                  : navigate(
                      `/payment/session/${plan.key}/${params.userId}${location.search}`
                    )
              }
              disabled={!plan.price}
            >
              Buy Now For {plan.currency} {plan.price}
            </Button>
          </Stack>
        </div>
        <div style={{ padding: '0 15px' }}>
          {plan.features.map((feature, index) => {
            const isHighlighted = checkFeatureHighlight(plan.key, index);
            // eslint-disable-next-line no-undef-init
            let _color: SvgIconProps['color'] = undefined;
            if (!isHighlighted) _color = 'secondary';

            return (
              <Stack
                key={index}
                direction="row"
                alignItems="center"
                style={{ marginBottom: '15px' }}
              >
                <CheckIcon color={_color} style={{ marginRight: '10px' }} />
                <Typography
                  color={_color}
                  fontWeight={isHighlighted ? 600 : 500}
                >
                  {feature}
                </Typography>
              </Stack>
            );
          })}
        </div>
      </CardContent>
    </Card>
  );
};

export default PlanCard;
