export interface ICountry {
  name: string;
  value: string;
  countryCode?: string;
}

export const Countries: Array<ICountry> = [
  {
    countryCode: 'AN',
    name: 'Andorra - Europe/Andorra',
    value: 'Europe/Andorra',
  },
  {
    countryCode: 'AE',
    name: 'United Arab Emirates - Asia/Dubai',
    value: 'Asia/Dubai',
  },
  {
    countryCode: 'AF',
    name: 'Afghanistan - Asia/Kabul',
    value: 'Asia/Kabul',
  },
  {
    countryCode: 'AG',
    name: 'Antigua and Barbuda - America/Antigua',
    value: 'America/Antigua',
  },
  {
    countryCode: 'AI',
    name: 'Anguilla - America/Anguilla',
    value: 'America/Anguilla',
  },
  {
    countryCode: 'AL',
    name: 'Albania - Europe/Tirane',
    value: 'Europe/Tirane',
  },
  {
    countryCode: 'AM',
    name: 'Armenia - Asia/Yerevan',
    value: 'Asia/Yerevan',
  },
  {
    countryCode: 'AO',
    name: 'Angola - Africa/Luanda',
    value: 'Africa/Luanda',
  },
  {
    countryCode: 'AQ',
    name: 'Antarctica - Antarctica/Casey',
    value: 'Antarctica/Casey',
  },
  {
    countryCode: 'AQ',
    name: 'Antarctica - Antarctica/Davis',
    value: 'Antarctica/Davis',
  },
  {
    countryCode: 'AQ',
    name: 'Antarctica - Antarctica/DumontDUrville',
    value: 'Antarctica/DumontDUrville',
  },
  {
    countryCode: 'AQ',
    name: 'Antarctica - Antarctica/Mawson',
    value: 'Antarctica/Mawson',
  },
  {
    countryCode: 'AQ',
    name: 'Antarctica - Antarctica/McMurdo',
    value: 'Antarctica/McMurdo',
  },
  {
    countryCode: 'AQ',
    name: 'Antarctica - Antarctica/Palmer',
    value: 'Antarctica/Palmer',
  },
  {
    countryCode: 'AQ',
    name: 'Antarctica - Antarctica/Rothera',
    value: 'Antarctica/Rothera',
  },
  {
    countryCode: 'AQ',
    name: 'Antarctica - Antarctica/Syowa',
    value: 'Antarctica/Syowa',
  },
  {
    countryCode: 'AQ',
    name: 'Antarctica - Antarctica/Troll',
    value: 'Antarctica/Troll',
  },
  {
    name: 'Antarctica - Antarctica/Vostok',
    value: 'Antarctica/Vostok',
  },
  {
    countryCode: 'AR',
    name: 'Argentina - America/Argentina/Buenos_Aires',
    value: 'America/Argentina/Buenos_Aires',
  },
  {
    countryCode: 'AR',
    name: 'Argentina - America/Argentina/Catamarca',
    value: 'America/Argentina/Catamarca',
  },
  {
    countryCode: 'AR',
    name: 'Argentina - America/Argentina/Cordoba',
    value: 'America/Argentina/Cordoba',
  },
  {
    countryCode: 'AR',
    name: 'Argentina - America/Argentina/Jujuy',
    value: 'America/Argentina/Jujuy',
  },
  {
    countryCode: 'AR',
    name: 'Argentina - America/Argentina/La_Rioja',
    value: 'America/Argentina/La_Rioja',
  },
  {
    countryCode: 'AR',
    name: 'Argentina - America/Argentina/Mendoza',
    value: 'America/Argentina/Mendoza',
  },
  {
    countryCode: 'AR',
    name: 'Argentina - America/Argentina/Rio_Gallegos',
    value: 'America/Argentina/Rio_Gallegos',
  },
  {
    countryCode: 'AR',
    name: 'Argentina - America/Argentina/Salta',
    value: 'America/Argentina/Salta',
  },
  {
    countryCode: 'AR',
    name: 'Argentina - America/Argentina/San_Juan',
    value: 'America/Argentina/San_Juan',
  },
  {
    countryCode: 'AR',
    name: 'Argentina - America/Argentina/San_Luis',
    value: 'America/Argentina/San_Luis',
  },
  {
    countryCode: 'AR',
    name: 'Argentina - America/Argentina/Tucuman',
    value: 'America/Argentina/Tucuman',
  },
  {
    countryCode: 'AR',
    name: 'Argentina - America/Argentina/Ushuaia',
    value: 'America/Argentina/Ushuaia',
  },
  {
    name: 'American Samoa - Pacific/Pago_Pago',
    value: 'Pacific/Pago_Pago',
  },
  {
    countryCode: 'AT',
    name: 'Austria - Europe/Vienna',
    value: 'Europe/Vienna',
  },
  {
    countryCode: 'AU',
    name: 'Australia - Antarctica/Macquarie',
    value: 'Antarctica/Macquarie',
  },
  {
    countryCode: 'AU',
    name: 'Australia - Australia/Adelaide',
    value: 'Australia/Adelaide',
  },
  {
    countryCode: 'AU',
    name: 'Australia - Australia/Brisbane',
    value: 'Australia/Brisbane',
  },
  {
    countryCode: 'AU',
    name: 'Australia - Australia/Broken_Hill',
    value: 'Australia/Broken_Hill',
  },
  {
    countryCode: 'AU',
    name: 'Australia - Australia/Currie',
    value: 'Australia/Currie',
  },
  {
    countryCode: 'AU',
    name: 'Australia - Australia/Darwin',
    value: 'Australia/Darwin',
  },
  {
    countryCode: 'AU',
    name: 'Australia - Australia/Eucla',
    value: 'Australia/Eucla',
  },
  {
    countryCode: 'AU',
    name: 'Australia - Australia/Hobart',
    value: 'Australia/Hobart',
  },
  {
    countryCode: 'AU',
    name: 'Australia - Australia/Lindeman',
    value: 'Australia/Lindeman',
  },
  {
    countryCode: 'AU',
    name: 'Australia - Australia/Lord_Howe',
    value: 'Australia/Lord_Howe',
  },
  {
    countryCode: 'AU',
    name: 'Australia - Australia/Melbourne',
    value: 'Australia/Melbourne',
  },
  {
    countryCode: 'AU',
    name: 'Australia - Australia/Perth',
    value: 'Australia/Perth',
  },
  {
    countryCode: 'AU',
    name: 'Australia - Australia/Sydney',
    value: 'Australia/Sydney',
  },
  {
    name: 'Aruba - America/Aruba',
    value: 'America/Aruba',
  },
  {
    name: 'Aland Islands - Europe/Mariehamn',
    value: 'Europe/Mariehamn',
  },
  {
    countryCode: 'AZ',
    name: 'Azerbaijan - Asia/Baku',
    value: 'Asia/Baku',
  },
  {
    countryCode: 'BA',
    name: 'Bosnia and Herzegovina - Europe/Sarajevo',
    value: 'Europe/Sarajevo',
  },
  {
    name: 'Barbados - America/Barbados',
    value: 'America/Barbados',
  },
  {
    countryCode: 'BD',
    name: 'Bangladesh - Asia/Dhaka',
    value: 'Asia/Dhaka',
  },
  {
    countryCode: 'BE',
    name: 'Belgium - Europe/Brussels',
    value: 'Europe/Brussels',
  },
  {
    name: 'Burkina Faso - Africa/Ouagadougou',
    value: 'Africa/Ouagadougou',
  },
  {
    countryCode: 'BG',
    name: 'Bulgaria - Europe/Sofia',
    value: 'Europe/Sofia',
  },
  {
    countryCode: 'BH',
    name: 'Bahrain - Asia/Bahrain',
    value: 'Asia/Bahrain',
  },
  {
    name: 'Burundi - Africa/Bujumbura',
    value: 'Africa/Bujumbura',
  },
  {
    name: 'Benin - Africa/Porto-Novo',
    value: 'Africa/Porto-Novo',
  },
  {
    name: 'Saint Barthélemy - America/St_Barthelemy',
    value: 'America/St_Barthelemy',
  },
  {
    name: 'Bermuda - Atlantic/Bermuda',
    value: 'Atlantic/Bermuda',
  },
  {
    countryCode: 'BN',
    name: 'Brunei - Asia/Brunei',
    value: 'Asia/Brunei',
  },
  {
    countryCode: 'BO',
    name: 'Bolivia - America/La_Paz',
    value: 'America/La_Paz',
  },
  {
    name: 'Bonaire, Saint Eustatius and Saba  - America/Kralendijk',
    value: 'America/Kralendijk',
  },
  {
    countryCode: 'BR',
    name: 'Brazil - America/Araguaina',
    value: 'America/Araguaina',
  },
  {
    countryCode: 'BR',
    name: 'Brazil - America/Bahia',
    value: 'America/Bahia',
  },
  {
    countryCode: 'BR',
    name: 'Brazil - America/Belem',
    value: 'America/Belem',
  },
  {
    countryCode: 'BR',
    name: 'Brazil - America/Boa_Vista',
    value: 'America/Boa_Vista',
  },
  {
    countryCode: 'BR',
    name: 'Brazil - America/Campo_Grande',
    value: 'America/Campo_Grande',
  },
  {
    countryCode: 'BR',
    name: 'Brazil - America/Cuiaba',
    value: 'America/Cuiaba',
  },
  {
    countryCode: 'BR',
    name: 'Brazil - America/Eirunepe',
    value: 'America/Eirunepe',
  },
  {
    countryCode: 'BR',
    name: 'Brazil - America/Fortaleza',
    value: 'America/Fortaleza',
  },
  {
    countryCode: 'BR',
    name: 'Brazil - America/Maceio',
    value: 'America/Maceio',
  },
  {
    countryCode: 'BR',
    name: 'Brazil - America/Manaus',
    value: 'America/Manaus',
  },
  {
    countryCode: 'BR',
    name: 'Brazil - America/Noronha',
    value: 'America/Noronha',
  },
  {
    countryCode: 'BR',
    name: 'Brazil - America/Porto_Velho',
    value: 'America/Porto_Velho',
  },
  {
    countryCode: 'BR',
    name: 'Brazil - America/Recife',
    value: 'America/Recife',
  },
  {
    countryCode: 'BR',
    name: 'Brazil - America/Rio_Branco',
    value: 'America/Rio_Branco',
  },
  {
    countryCode: 'BR',
    name: 'Brazil - America/Santarem',
    value: 'America/Santarem',
  },
  {
    countryCode: 'BR',
    name: 'Brazil - America/Sao_Paulo',
    value: 'America/Sao_Paulo',
  },
  {
    name: 'Bahamas - America/Nassau',
    value: 'America/Nassau',
  },
  {
    countryCode: 'BT',
    name: 'Bhutan - Asia/Thimphu',
    value: 'Asia/Thimphu',
  },
  {
    countryCode: 'BW',
    name: 'Botswana - Africa/Gaborone',
    value: 'Africa/Gaborone',
  },
  {
    countryCode: 'BY',
    name: 'Belarus - Europe/Minsk',
    value: 'Europe/Minsk',
  },
  {
    countryCode: 'BZ',
    name: 'Belize - America/Belize',
    value: 'America/Belize',
  },
  {
    countryCode: 'CA',
    name: 'Canada - America/Atikokan',
    value: 'America/Atikokan',
  },
  {
    countryCode: 'CA',
    name: 'Canada - America/Blanc-Sablon',
    value: 'America/Blanc-Sablon',
  },
  {
    countryCode: 'CA',
    name: 'Canada - America/Cambridge_Bay',
    value: 'America/Cambridge_Bay',
  },
  {
    countryCode: 'CA',
    name: 'Canada - America/Creston',
    value: 'America/Creston',
  },
  {
    countryCode: 'CA',
    name: 'Canada - America/Dawson',
    value: 'America/Dawson',
  },
  {
    countryCode: 'CA',
    name: 'Canada - America/Dawson_Creek',
    value: 'America/Dawson_Creek',
  },
  {
    countryCode: 'CA',
    name: 'Canada - America/Edmonton',
    value: 'America/Edmonton',
  },
  {
    countryCode: 'CA',
    name: 'Canada - America/Fort_Nelson',
    value: 'America/Fort_Nelson',
  },
  {
    countryCode: 'CA',
    name: 'Canada - America/Glace_Bay',
    value: 'America/Glace_Bay',
  },
  {
    countryCode: 'CA',
    name: 'Canada - America/Goose_Bay',
    value: 'America/Goose_Bay',
  },
  {
    countryCode: 'CA',
    name: 'Canada - America/Halifax',
    value: 'America/Halifax',
  },
  {
    countryCode: 'CA',
    name: 'Canada - America/Inuvik',
    value: 'America/Inuvik',
  },
  {
    countryCode: 'CA',
    name: 'Canada - America/Iqaluit',
    value: 'America/Iqaluit',
  },
  {
    countryCode: 'CA',
    name: 'Canada - America/Moncton',
    value: 'America/Moncton',
  },
  {
    countryCode: 'CA',
    name: 'Canada - America/Nipigon',
    value: 'America/Nipigon',
  },
  {
    countryCode: 'CA',
    name: 'Canada - America/Pangnirtung',
    value: 'America/Pangnirtung',
  },
  {
    countryCode: 'CA',
    name: 'Canada - America/Rainy_River',
    value: 'America/Rainy_River',
  },
  {
    countryCode: 'CA',
    name: 'Canada - America/Rankin_Inlet',
    value: 'America/Rankin_Inlet',
  },
  {
    countryCode: 'CA',
    name: 'Canada - America/Regina',
    value: 'America/Regina',
  },
  {
    countryCode: 'CA',
    name: 'Canada - America/Resolute',
    value: 'America/Resolute',
  },
  {
    countryCode: 'CA',
    name: 'Canada - America/St_Johns',
    value: 'America/St_Johns',
  },
  {
    countryCode: 'CA',
    name: 'Canada - America/Swift_Current',
    value: 'America/Swift_Current',
  },
  {
    countryCode: 'CA',
    name: 'Canada - America/Thunder_Bay',
    value: 'America/Thunder_Bay',
  },
  {
    countryCode: 'CA',
    name: 'Canada - America/Toronto',
    value: 'America/Toronto',
  },
  {
    countryCode: 'CA',
    name: 'Canada - America/Vancouver',
    value: 'America/Vancouver',
  },
  {
    countryCode: 'CA',
    name: 'Canada - America/Whitehorse',
    value: 'America/Whitehorse',
  },
  {
    countryCode: 'CA',
    name: 'Canada - America/Winnipeg',
    value: 'America/Winnipeg',
  },
  {
    countryCode: 'CA',
    name: 'Canada - America/Yellowknife',
    value: 'America/Yellowknife',
  },
  {
    name: 'Cocos Islands - Indian/Cocos',
    value: 'Indian/Cocos',
  },
  {
    countryCode: 'CD',
    name: 'Democratic Republic of the Congo - Africa/Kinshasa',
    value: 'Africa/Kinshasa',
  },
  {
    countryCode: 'CD',
    name: 'Democratic Republic of the Congo - Africa/Lubumbashi',
    value: 'Africa/Lubumbashi',
  },
  {
    name: 'Central African Republic - Africa/Bangui',
    value: 'Africa/Bangui',
  },
  {
    name: 'Republic of the Congo - Africa/Brazzaville',
    value: 'Africa/Brazzaville',
  },
  {
    countryCode: 'CH',
    name: 'Switzerland - Europe/Zurich',
    value: 'Europe/Zurich',
  },
  {
    countryCode: 'CI',
    name: 'Ivory Coast - Africa/Abidjan',
    value: 'Africa/Abidjan',
  },
  {
    name: 'Cook Islands - Pacific/Rarotonga',
    value: 'Pacific/Rarotonga',
  },
  {
    name: 'Chile - America/Punta_Arenas',
    value: 'America/Punta_Arenas',
  },
  {
    countryCode: 'CL',
    name: 'Chile - America/Santiago',
    value: 'America/Santiago',
  },
  {
    countryCode: 'CL',
    name: 'Chile - Pacific/Easter',
    value: 'Pacific/Easter',
  },
  {
    countryCode: 'CM',
    name: 'Cameroon - Africa/Douala',
    value: 'Africa/Douala',
  },
  {
    countryCode: 'CN',
    name: 'China - Asia/Shanghai',
    value: 'Asia/Shanghai',
  },
  {
    countryCode: 'CN',
    name: 'China - Asia/Urumqi',
    value: 'Asia/Urumqi',
  },
  {
    countryCode: 'CO',
    name: 'Colombia - America/Bogota',
    value: 'America/Bogota',
  },
  {
    countryCode: 'CR',
    name: 'Costa Rica - America/Costa_Rica',
    value: 'America/Costa_Rica',
  },
  {
    countryCode: 'CU',
    name: 'Cuba - America/Havana',
    value: 'America/Havana',
  },
  {
    name: 'Cape Verde - Atlantic/Cape_Verde',
    value: 'Atlantic/Cape_Verde',
  },
  {
    name: 'Curaçao - America/Curacao',
    value: 'America/Curacao',
  },
  {
    name: 'Christmas Island - Indian/Christmas',
    value: 'Indian/Christmas',
  },
  {
    name: 'Cyprus - Asia/Famagusta',
    value: 'Asia/Famagusta',
  },
  {
    name: 'Cyprus - Asia/Nicosia',
    value: 'Asia/Nicosia',
  },
  {
    countryCode: 'CZ',
    name: 'Czech Republic - Europe/Prague',
    value: 'Europe/Prague',
  },
  {
    countryCode: 'DE',
    name: 'Germany - Europe/Berlin',
    value: 'Europe/Berlin',
  },
  {
    countryCode: 'DE',
    name: 'Germany - Europe/Busingen',
    value: 'Europe/Busingen',
  },
  {
    countryCode: 'DJ',
    name: 'Djibouti - Africa/Djibouti',
    value: 'Africa/Djibouti',
  },
  {
    countryCode: 'DK',
    name: 'Denmark - Europe/Copenhagen',
    value: 'Europe/Copenhagen',
  },
  {
    name: 'Dominica - America/Dominica',
    value: 'America/Dominica',
  },
  {
    countryCode: 'DO',
    name: 'Dominican Republic - America/Santo_Domingo',
    value: 'America/Santo_Domingo',
  },
  {
    countryCode: 'DZ',
    name: 'Algeria - Africa/Algiers',
    value: 'Africa/Algiers',
  },
  {
    countryCode: 'EC',
    name: 'Ecuador - America/Guayaquil',
    value: 'America/Guayaquil',
  },
  {
    countryCode: 'EC',
    name: 'Ecuador - Pacific/Galapagos',
    value: 'Pacific/Galapagos',
  },
  {
    countryCode: 'EE',
    name: 'Estonia - Europe/Tallinn',
    value: 'Europe/Tallinn',
  },
  {
    countryCode: 'EG',
    name: 'Egypt - Africa/Cairo',
    value: 'Africa/Cairo',
  },
  {
    name: 'Western Sahara - Africa/El_Aaiun',
    value: 'Africa/El_Aaiun',
  },
  {
    countryCode: 'ER',
    name: 'Eritrea - Africa/Asmara',
    value: 'Africa/Asmara',
  },
  {
    countryCode: 'ES',
    name: 'Spain - Africa/Ceuta',
    value: 'Africa/Ceuta',
  },
  {
    countryCode: 'ES',
    name: 'Spain - Atlantic/Canary',
    value: 'Atlantic/Canary',
  },
  {
    countryCode: 'ES',
    name: 'Spain - Europe/Madrid',
    value: 'Europe/Madrid',
  },
  {
    countryCode: 'ET',
    name: 'Ethiopia - Africa/Addis_Ababa',
    value: 'Africa/Addis_Ababa',
  },
  {
    countryCode: 'FI',
    name: 'Finland - Europe/Helsinki',
    value: 'Europe/Helsinki',
  },
  {
    name: 'Fiji - Pacific/Fiji',
    value: 'Pacific/Fiji',
  },
  {
    name: 'Falkland Islands - Atlantic/Stanley',
    value: 'Atlantic/Stanley',
  },
  {
    name: 'Micronesia - Pacific/Chuuk',
    value: 'Pacific/Chuuk',
  },
  {
    name: 'Micronesia - Pacific/Kosrae',
    value: 'Pacific/Kosrae',
  },
  {
    name: 'Micronesia - Pacific/Pohnpei',
    value: 'Pacific/Pohnpei',
  },
  {
    countryCode: 'FO',
    name: 'Faroe Islands - Atlantic/Faroe',
    value: 'Atlantic/Faroe',
  },
  {
    countryCode: 'FR',
    name: 'France - Europe/Paris',
    value: 'Europe/Paris',
  },
  {
    name: 'Gabon - Africa/Libreville',
    value: 'Africa/Libreville',
  },
  {
    countryCode: 'GB',
    name: 'United Kingdom - Europe/London',
    value: 'Europe/London',
  },
  {
    name: 'Grenada - America/Grenada',
    value: 'America/Grenada',
  },
  {
    countryCode: 'GE',
    name: 'Georgia - Asia/Tbilisi',
    value: 'Asia/Tbilisi',
  },
  {
    name: 'French Guiana - America/Cayenne',
    value: 'America/Cayenne',
  },
  {
    name: 'Guernsey - Europe/Guernsey',
    value: 'Europe/Guernsey',
  },
  {
    name: 'Ghana - Africa/Accra',
    value: 'Africa/Accra',
  },
  {
    name: 'Gibraltar - Europe/Gibraltar',
    value: 'Europe/Gibraltar',
  },
  {
    countryCode: 'GL',
    name: 'Greenland - America/Danmarkshavn',
    value: 'America/Danmarkshavn',
  },
  {
    countryCode: 'GL',
    name: 'Greenland - America/Godthab',
    value: 'America/Godthab',
  },
  {
    countryCode: 'GL',
    name: 'Greenland - America/Scoresbysund',
    value: 'America/Scoresbysund',
  },
  {
    countryCode: 'GL',
    name: 'Greenland - America/Thule',
    value: 'America/Thule',
  },
  {
    name: 'Gambia - Africa/Banjul',
    value: 'Africa/Banjul',
  },
  {
    name: 'Guinea - Africa/Conakry',
    value: 'Africa/Conakry',
  },
  {
    name: 'Guadeloupe - America/Guadeloupe',
    value: 'America/Guadeloupe',
  },
  {
    name: 'Equatorial Guinea - Africa/Malabo',
    value: 'Africa/Malabo',
  },
  {
    countryCode: 'GR',
    name: 'Greece - Europe/Athens',
    value: 'Europe/Athens',
  },
  {
    name:
      'South Georgia and the South Sandwich Islands - Atlantic/South_Georgia',
    value: 'Atlantic/South_Georgia',
  },
  {
    countryCode: 'GT',
    name: 'Guatemala - America/Guatemala',
    value: 'America/Guatemala',
  },
  {
    name: 'Guam - Pacific/Guam',
    value: 'Pacific/Guam',
  },
  {
    name: 'Guinea-Bissau - Africa/Bissau',
    value: 'Africa/Bissau',
  },
  {
    name: 'Guyana - America/Guyana',
    value: 'America/Guyana',
  },
  {
    countryCode: 'HK',
    name: 'Hong Kong - Asia/Hong_Kong',
    value: 'Asia/Hong_Kong',
  },
  {
    countryCode: 'HN',
    name: 'Honduras - America/Tegucigalpa',
    value: 'America/Tegucigalpa',
  },
  {
    countryCode: 'HR',
    name: 'Croatia - Europe/Zagreb',
    value: 'Europe/Zagreb',
  },
  {
    countryCode: 'HT',
    name: 'Haiti - America/Port-au-Prince',
    value: 'America/Port-au-Prince',
  },
  {
    countryCode: 'HU',
    name: 'Hungary - Europe/Budapest',
    value: 'Europe/Budapest',
  },
  {
    countryCode: 'ID',
    name: 'Indonesia - Asia/Jakarta',
    value: 'Asia/Jakarta',
  },
  {
    countryCode: 'ID',
    name: 'Indonesia - Asia/Jayapura',
    value: 'Asia/Jayapura',
  },
  {
    countryCode: 'ID',
    name: 'Indonesia - Asia/Makassar',
    value: 'Asia/Makassar',
  },
  {
    countryCode: 'ID',
    name: 'Indonesia - Asia/Pontianak',
    value: 'Asia/Pontianak',
  },
  {
    countryCode: 'IE',
    name: 'Ireland - Europe/Dublin',
    value: 'Europe/Dublin',
  },
  {
    countryCode: 'IL',
    name: 'Israel - Asia/Jerusalem',
    value: 'Asia/Jerusalem',
  },
  {
    name: 'Isle of Man - Europe/Isle_of_Man',
    value: 'Europe/Isle_of_Man',
  },
  {
    countryCode: 'IN',
    name: 'India - Asia/Kolkata',
    value: 'Asia/Kolkata',
  },
  {
    countryCode: 'IN',
    name: 'India - Asia/Calcutta',
    value: 'Asia/Calcutta',
  },
  {
    name: 'British Indian Ocean Territory - Indian/Chagos',
    value: 'Indian/Chagos',
  },
  {
    countryCode: 'IQ',
    name: 'Iraq - Asia/Baghdad',
    value: 'Asia/Baghdad',
  },
  {
    countryCode: 'IR',
    name: 'Iran - Asia/Tehran',
    value: 'Asia/Tehran',
  },
  {
    countryCode: 'IS',
    name: 'Iceland - Atlantic/Reykjavik',
    value: 'Atlantic/Reykjavik',
  },
  {
    countryCode: 'IT',
    name: 'Italy - Europe/Rome',
    value: 'Europe/Rome',
  },
  {
    name: 'Jersey - Europe/Jersey',
    value: 'Europe/Jersey',
  },
  {
    countryCode: 'JM',
    name: 'Jamaica - America/Jamaica',
    value: 'America/Jamaica',
  },
  {
    countryCode: 'JO',
    name: 'Jordan - Asia/Amman',
    value: 'Asia/Amman',
  },
  {
    countryCode: 'JP',
    name: 'Japan - Asia/Tokyo',
    value: 'Asia/Tokyo',
  },
  {
    countryCode: 'KE',
    name: 'Kenya - Africa/Nairobi',
    value: 'Africa/Nairobi',
  },
  {
    countryCode: 'KG',
    name: 'Kyrgyzstan - Asia/Bishkek',
    value: 'Asia/Bishkek',
  },
  {
    countryCode: 'KH',
    name: 'Cambodia - Asia/Phnom_Penh',
    value: 'Asia/Phnom_Penh',
  },
  {
    name: 'Kiribati - Pacific/Enderbury',
    value: 'Pacific/Enderbury',
  },
  {
    name: 'Kiribati - Pacific/Kiritimati',
    value: 'Pacific/Kiritimati',
  },
  {
    name: 'Kiribati - Pacific/Tarawa',
    value: 'Pacific/Tarawa',
  },
  {
    name: 'Comoros - Indian/Comoro',
    value: 'Indian/Comoro',
  },
  {
    name: 'Saint Kitts and Nevis - America/St_Kitts',
    value: 'America/St_Kitts',
  },
  {
    name: 'North Korea - Asia/Pyongyang',
    value: 'Asia/Pyongyang',
  },
  {
    countryCode: 'KR',
    name: 'South Korea - Asia/Seoul',
    value: 'Asia/Seoul',
  },
  {
    countryCode: 'KW',
    name: 'Kuwait - Asia/Kuwait',
    value: 'Asia/Kuwait',
  },
  {
    name: 'Cayman Islands - America/Cayman',
    value: 'America/Cayman',
  },
  {
    countryCode: 'KZ',
    name: 'Kazakhstan - Asia/Almaty',
    value: 'Asia/Almaty',
  },
  {
    countryCode: 'KZ',
    name: 'Kazakhstan - Asia/Aqtau',
    value: 'Asia/Aqtau',
  },
  {
    countryCode: 'KZ',
    name: 'Kazakhstan - Asia/Aqtobe',
    value: 'Asia/Aqtobe',
  },
  {
    name: 'Kazakhstan - Asia/Atyrau',
    value: 'Asia/Atyrau',
  },
  {
    countryCode: 'KZ',
    name: 'Kazakhstan - Asia/Oral',
    value: 'Asia/Oral',
  },
  {
    name: 'Kazakhstan - Asia/Qostanay',
    value: 'Asia/Qostanay',
  },
  {
    countryCode: 'KZ',
    name: 'Kazakhstan - Asia/Qyzylorda',
    value: 'Asia/Qyzylorda',
  },
  {
    countryCode: 'LA',
    name: 'Laos - Asia/Vientiane',
    value: 'Asia/Vientiane',
  },
  {
    countryCode: 'LB',
    name: 'Lebanon - Asia/Beirut',
    value: 'Asia/Beirut',
  },
  {
    name: 'Saint Lucia - America/St_Lucia',
    value: 'America/St_Lucia',
  },
  {
    countryCode: 'LI',
    name: 'Liechtenstein - Europe/Vaduz',
    value: 'Europe/Vaduz',
  },
  {
    countryCode: 'LK',
    name: 'Sri Lanka - Asia/Colombo',
    value: 'Asia/Colombo',
  },
  {
    name: 'Liberia - Africa/Monrovia',
    value: 'Africa/Monrovia',
  },
  {
    name: 'Lesotho - Africa/Maseru',
    value: 'Africa/Maseru',
  },
  {
    countryCode: 'LT',
    name: 'Lithuania - Europe/Vilnius',
    value: 'Europe/Vilnius',
  },
  {
    countryCode: 'LU',
    name: 'Luxembourg - Europe/Luxembourg',
    value: 'Europe/Luxembourg',
  },
  {
    countryCode: 'LV',
    name: 'Latvia - Europe/Riga',
    value: 'Europe/Riga',
  },
  {
    countryCode: 'LY',
    name: 'Libya - Africa/Tripoli',
    value: 'Africa/Tripoli',
  },
  {
    countryCode: 'MA',
    name: 'Morocco - Africa/Casablanca',
    value: 'Africa/Casablanca',
  },
  {
    countryCode: 'MC',
    name: 'Monaco - Europe/Monaco',
    value: 'Europe/Monaco',
  },
  {
    countryCode: 'MD',
    name: 'Moldova - Europe/Chisinau',
    value: 'Europe/Chisinau',
  },
  {
    countryCode: 'ME',
    name: 'Montenegro - Europe/Podgorica',
    value: 'Europe/Podgorica',
  },
  {
    name: 'Saint Martin - America/Marigot',
    value: 'America/Marigot',
  },
  {
    name: 'Madagascar - Indian/Antananarivo',
    value: 'Indian/Antananarivo',
  },
  {
    name: 'Marshall Islands - Pacific/Kwajalein',
    value: 'Pacific/Kwajalein',
  },
  {
    name: 'Marshall Islands - Pacific/Majuro',
    value: 'Pacific/Majuro',
  },
  {
    countryCode: 'MK',
    name: 'Macedonia - Europe/Skopje',
    value: 'Europe/Skopje',
  },
  {
    countryCode: 'ML',
    name: 'Mali - Africa/Bamako',
    value: 'Africa/Bamako',
  },
  {
    name: 'Myanmar - Asia/Yangon',
    value: 'Asia/Yangon',
  },
  {
    countryCode: 'MN',
    name: 'Mongolia - Asia/Choibalsan',
    value: 'Asia/Choibalsan',
  },
  {
    countryCode: 'MN',
    name: 'Mongolia - Asia/Hovd',
    value: 'Asia/Hovd',
  },
  {
    countryCode: 'MN',
    name: 'Mongolia - Asia/Ulaanbaatar',
    value: 'Asia/Ulaanbaatar',
  },
  {
    countryCode: 'MO',
    name: 'Macao - Asia/Macau',
    value: 'Asia/Macau',
  },
  {
    name: 'Northern Mariana Islands - Pacific/Saipan',
    value: 'Pacific/Saipan',
  },
  {
    name: 'Martinique - America/Martinique',
    value: 'America/Martinique',
  },
  {
    name: 'Mauritania - Africa/Nouakchott',
    value: 'Africa/Nouakchott',
  },
  {
    name: 'Montserrat - America/Montserrat',
    value: 'America/Montserrat',
  },
  {
    countryCode: 'MT',
    name: 'Malta - Europe/Malta',
    value: 'Europe/Malta',
  },
  {
    name: 'Mauritius - Indian/Mauritius',
    value: 'Indian/Mauritius',
  },
  {
    countryCode: 'MV',
    name: 'Maldives - Indian/Maldives',
    value: 'Indian/Maldives',
  },
  {
    name: 'Malawi - Africa/Blantyre',
    value: 'Africa/Blantyre',
  },
  {
    countryCode: 'MX',
    name: 'Mexico - America/Bahia_Banderas',
    value: 'America/Bahia_Banderas',
  },
  {
    countryCode: 'MX',
    name: 'Mexico - America/Cancun',
    value: 'America/Cancun',
  },
  {
    countryCode: 'MX',
    name: 'Mexico - America/Chihuahua',
    value: 'America/Chihuahua',
  },
  {
    countryCode: 'MX',
    name: 'Mexico - America/Hermosillo',
    value: 'America/Hermosillo',
  },
  {
    countryCode: 'MX',
    name: 'Mexico - America/Matamoros',
    value: 'America/Matamoros',
  },
  {
    countryCode: 'MX',
    name: 'Mexico - America/Mazatlan',
    value: 'America/Mazatlan',
  },
  {
    countryCode: 'MX',
    name: 'Mexico - America/Merida',
    value: 'America/Merida',
  },
  {
    countryCode: 'MX',
    name: 'Mexico - America/Mexico_City',
    value: 'America/Mexico_City',
  },
  {
    countryCode: 'MX',
    name: 'Mexico - America/Monterrey',
    value: 'America/Monterrey',
  },
  {
    countryCode: 'MX',
    name: 'Mexico - America/Ojinaga',
    value: 'America/Ojinaga',
  },
  {
    countryCode: 'MX',
    name: 'Mexico - America/Tijuana',
    value: 'America/Tijuana',
  },
  {
    countryCode: 'MY',
    name: 'Malaysia - Asia/Kuala_Lumpur',
    value: 'Asia/Kuala_Lumpur',
  },
  {
    countryCode: 'MY',
    name: 'Malaysia - Asia/Kuching',
    value: 'Asia/Kuching',
  },
  {
    name: 'Mozambique - Africa/Maputo',
    value: 'Africa/Maputo',
  },
  {
    name: 'Namibia - Africa/Windhoek',
    value: 'Africa/Windhoek',
  },
  {
    name: 'New Caledonia - Pacific/Noumea',
    value: 'Pacific/Noumea',
  },
  {
    name: 'Niger - Africa/Niamey',
    value: 'Africa/Niamey',
  },
  {
    name: 'Norfolk Island - Pacific/Norfolk',
    value: 'Pacific/Norfolk',
  },
  {
    countryCode: 'NG',
    name: 'Nigeria - Africa/Lagos',
    value: 'Africa/Lagos',
  },
  {
    countryCode: 'NI',
    name: 'Nicaragua - America/Managua',
    value: 'America/Managua',
  },
  {
    countryCode: 'NL',
    name: 'Netherlands - Europe/Amsterdam',
    value: 'Europe/Amsterdam',
  },
  {
    countryCode: 'NO',
    name: 'Norway - Europe/Oslo',
    value: 'Europe/Oslo',
  },
  {
    countryCode: 'NP',
    name: 'Nepal - Asia/Kathmandu',
    value: 'Asia/Kathmandu',
  },
  {
    name: 'Nauru - Pacific/Nauru',
    value: 'Pacific/Nauru',
  },
  {
    name: 'Niue - Pacific/Niue',
    value: 'Pacific/Niue',
  },
  {
    countryCode: 'NZ',
    name: 'New Zealand - Pacific/Auckland',
    value: 'Pacific/Auckland',
  },
  {
    countryCode: 'NZ',
    name: 'New Zealand - Pacific/Chatham',
    value: 'Pacific/Chatham',
  },
  {
    countryCode: 'OM',
    name: 'Oman - Asia/Muscat',
    value: 'Asia/Muscat',
  },
  {
    countryCode: 'PA',
    name: 'Panama - America/Panama',
    value: 'America/Panama',
  },
  {
    countryCode: 'PE',
    name: 'Peru - America/Lima',
    value: 'America/Lima',
  },
  {
    name: 'French Polynesia - Pacific/Gambier',
    value: 'Pacific/Gambier',
  },
  {
    name: 'French Polynesia - Pacific/Marquesas',
    value: 'Pacific/Marquesas',
  },
  {
    name: 'French Polynesia - Pacific/Tahiti',
    value: 'Pacific/Tahiti',
  },
  {
    name: 'Papua New Guinea - Pacific/Bougainville',
    value: 'Pacific/Bougainville',
  },
  {
    name: 'Papua New Guinea - Pacific/Port_Moresby',
    value: 'Pacific/Port_Moresby',
  },
  {
    countryCode: 'PH',
    name: 'Philippines - Asia/Manila',
    value: 'Asia/Manila',
  },
  {
    countryCode: 'PK',
    name: 'Pakistan - Asia/Karachi',
    value: 'Asia/Karachi',
  },
  {
    countryCode: 'PL',
    name: 'Poland - Europe/Warsaw',
    value: 'Europe/Warsaw',
  },
  {
    name: 'Saint Pierre and Miquelon - America/Miquelon',
    value: 'America/Miquelon',
  },
  {
    name: 'Pitcairn - Pacific/Pitcairn',
    value: 'Pacific/Pitcairn',
  },
  {
    countryCode: 'PR',
    name: 'Puerto Rico - America/Puerto_Rico',
    value: 'America/Puerto_Rico',
  },
  {
    name: 'Palestinian Territory - Asia/Gaza',
    value: 'Asia/Gaza',
  },
  {
    name: 'Palestinian Territory - Asia/Hebron',
    value: 'Asia/Hebron',
  },
  {
    countryCode: 'PT',
    name: 'Portugal - Atlantic/Azores',
    value: 'Atlantic/Azores',
  },
  {
    countryCode: 'PT',
    name: 'Portugal - Atlantic/Madeira',
    value: 'Atlantic/Madeira',
  },
  {
    countryCode: 'PT',
    name: 'Portugal - Europe/Lisbon',
    value: 'Europe/Lisbon',
  },
  {
    name: 'Palau - Pacific/Palau',
    value: 'Pacific/Palau',
  },
  {
    countryCode: 'PY',
    name: 'Paraguay - America/Asuncion',
    value: 'America/Asuncion',
  },
  {
    countryCode: 'QA',
    name: 'Qatar - Asia/Qatar',
    value: 'Asia/Qatar',
  },
  {
    countryCode: 'RE',
    name: 'Reunion - Indian/Reunion',
    value: 'Indian/Reunion',
  },
  {
    countryCode: 'RO',
    name: 'Romania - Europe/Bucharest',
    value: 'Europe/Bucharest',
  },
  {
    countryCode: 'RS',
    name: 'Serbia - Europe/Belgrade',
    value: 'Europe/Belgrade',
  },
  {
    countryCode: 'RU',
    name: 'Russia - Asia/Anadyr',
    value: 'Asia/Anadyr',
  },
  {
    countryCode: 'RU',
    name: 'Russia - Asia/Barnaul',
    value: 'Asia/Barnaul',
  },
  {
    countryCode: 'RU',
    name: 'Russia - Asia/Chita',
    value: 'Asia/Chita',
  },
  {
    countryCode: 'RU',
    name: 'Russia - Asia/Irkutsk',
    value: 'Asia/Irkutsk',
  },
  {
    countryCode: 'RU',
    name: 'Russia - Asia/Kamchatka',
    value: 'Asia/Kamchatka',
  },
  {
    countryCode: 'RU',
    name: 'Russia - Asia/Khandyga',
    value: 'Asia/Khandyga',
  },
  {
    countryCode: 'RU',
    name: 'Russia - Asia/Krasnoyarsk',
    value: 'Asia/Krasnoyarsk',
  },
  {
    countryCode: 'RU',
    name: 'Russia - Asia/Magadan',
    value: 'Asia/Magadan',
  },
  {
    countryCode: 'RU',
    name: 'Russia - Asia/Novokuznetsk',
    value: 'Asia/Novokuznetsk',
  },
  {
    countryCode: 'RU',
    name: 'Russia - Asia/Novosibirsk',
    value: 'Asia/Novosibirsk',
  },
  {
    countryCode: 'RU',
    name: 'Russia - Asia/Omsk',
    value: 'Asia/Omsk',
  },
  {
    countryCode: 'RU',
    name: 'Russia - Asia/Sakhalin',
    value: 'Asia/Sakhalin',
  },
  {
    countryCode: 'RU',
    name: 'Russia - Asia/Srednekolymsk',
    value: 'Asia/Srednekolymsk',
  },
  {
    name: 'Russia - Asia/Tomsk',
    value: 'Asia/Tomsk',
  },
  {
    countryCode: 'RU',
    name: 'Russia - Asia/Ust-Nera',
    value: 'Asia/Ust-Nera',
  },
  {
    countryCode: 'RU',
    name: 'Russia - Asia/Vladivostok',
    value: 'Asia/Vladivostok',
  },
  {
    countryCode: 'RU',
    name: 'Russia - Asia/Yakutsk',
    value: 'Asia/Yakutsk',
  },
  {
    countryCode: 'RU',
    name: 'Russia - Asia/Yekaterinburg',
    value: 'Asia/Yekaterinburg',
  },
  {
    countryCode: 'RU',
    name: 'Russia - Europe/Astrakhan',
    value: 'Europe/Astrakhan',
  },
  {
    countryCode: 'RU',
    name: 'Russia - Europe/Kaliningrad',
    value: 'Europe/Kaliningrad',
  },
  {
    name: 'Russia - Europe/Kirov',
    value: 'Europe/Kirov',
  },
  {
    countryCode: 'RU',
    name: 'Russia - Europe/Moscow',
    value: 'Europe/Moscow',
  },
  {
    countryCode: 'RU',
    name: 'Russia - Europe/Samara',
    value: 'Europe/Samara',
  },
  {
    name: 'Russia - Europe/Saratov',
    value: 'Europe/Saratov',
  },
  {
    countryCode: 'RU',
    name: 'Russia - Europe/Ulyanovsk',
    value: 'Europe/Ulyanovsk',
  },
  {
    countryCode: 'RU',
    name: 'Russia - Europe/Volgograd',
    value: 'Europe/Volgograd',
  },
  {
    countryCode: 'RW',
    name: 'Rwanda - Africa/Kigali',
    value: 'Africa/Kigali',
  },
  {
    countryCode: 'SA',
    name: 'Saudi Arabia - Asia/Riyadh',
    value: 'Asia/Riyadh',
  },
  {
    name: 'Solomon Islands - Pacific/Guadalcanal',
    value: 'Pacific/Guadalcanal',
  },
  {
    name: 'Seychelles - Indian/Mahe',
    value: 'Indian/Mahe',
  },
  {
    name: 'Sudan - Africa/Khartoum',
    value: 'Africa/Khartoum',
  },
  {
    countryCode: 'SE',
    name: 'Sweden - Europe/Stockholm',
    value: 'Europe/Stockholm',
  },
  {
    countryCode: 'SG',
    name: 'Singapore - Asia/Singapore',
    value: 'Asia/Singapore',
  },
  {
    name: 'Saint Helena - Atlantic/St_Helena',
    value: 'Atlantic/St_Helena',
  },
  {
    countryCode: 'SI',
    name: 'Slovenia - Europe/Ljubljana',
    value: 'Europe/Ljubljana',
  },
  {
    name: 'Svalbard and Jan Mayen - Arctic/Longyearbyen',
    value: 'Arctic/Longyearbyen',
  },
  {
    countryCode: 'SK',
    name: 'Slovakia - Europe/Bratislava',
    value: 'Europe/Bratislava',
  },
  {
    name: 'Sierra Leone - Africa/Freetown',
    value: 'Africa/Freetown',
  },
  {
    name: 'San Marino - Europe/San_Marino',
    value: 'Europe/San_Marino',
  },
  {
    countryCode: 'SN',
    name: 'Senegal - Africa/Dakar',
    value: 'Africa/Dakar',
  },
  {
    countryCode: 'SO',
    name: 'Somalia - Africa/Mogadishu',
    value: 'Africa/Mogadishu',
  },
  {
    name: 'Suriname - America/Paramaribo',
    value: 'America/Paramaribo',
  },
  {
    name: 'South Sudan - Africa/Juba',
    value: 'Africa/Juba',
  },
  {
    name: 'Sao Tome and Principe - Africa/Sao_Tome',
    value: 'Africa/Sao_Tome',
  },
  {
    countryCode: 'SV',
    name: 'El Salvador - America/El_Salvador',
    value: 'America/El_Salvador',
  },
  {
    name: 'Sint Maarten - America/Lower_Princes',
    value: 'America/Lower_Princes',
  },
  {
    countryCode: 'SY',
    name: 'Syria - Asia/Damascus',
    value: 'Asia/Damascus',
  },
  {
    name: 'Swaziland - Africa/Mbabane',
    value: 'Africa/Mbabane',
  },
  {
    name: 'Turks and Caicos Islands - America/Grand_Turk',
    value: 'America/Grand_Turk',
  },
  {
    name: 'Chad - Africa/Ndjamena',
    value: 'Africa/Ndjamena',
  },
  {
    name: 'French Southern Territories - Indian/Kerguelen',
    value: 'Indian/Kerguelen',
  },
  {
    name: 'Togo - Africa/Lome',
    value: 'Africa/Lome',
  },
  {
    countryCode: 'TH',
    name: 'Thailand - Asia/Bangkok',
    value: 'Asia/Bangkok',
  },
  {
    countryCode: 'TJ',
    name: 'Tajikistan - Asia/Dushanbe',
    value: 'Asia/Dushanbe',
  },
  {
    name: 'Tokelau - Pacific/Fakaofo',
    value: 'Pacific/Fakaofo',
  },
  {
    name: 'East Timor - Asia/Dili',
    value: 'Asia/Dili',
  },
  {
    countryCode: 'TM',
    name: 'Turkmenistan - Asia/Ashgabat',
    value: 'Asia/Ashgabat',
  },
  {
    countryCode: 'TN',
    name: 'Tunisia - Africa/Tunis',
    value: 'Africa/Tunis',
  },
  {
    name: 'Tonga - Pacific/Tongatapu',
    value: 'Pacific/Tongatapu',
  },
  {
    countryCode: 'TR',
    name: 'Turkey - Europe/Istanbul',
    value: 'Europe/Istanbul',
  },
  {
    countryCode: 'TT',
    name: 'Trinidad and Tobago - America/Port_of_Spain',
    value: 'America/Port_of_Spain',
  },
  {
    name: 'Tuvalu - Pacific/Funafuti',
    value: 'Pacific/Funafuti',
  },
  {
    countryCode: 'TW',
    name: 'Taiwan - Asia/Taipei',
    value: 'Asia/Taipei',
  },
  {
    name: 'Tanzania - Africa/Dar_es_Salaam',
    value: 'Africa/Dar_es_Salaam',
  },
  {
    countryCode: 'UA',
    name: 'Ukraine - Europe/Kiev',
    value: 'Europe/Kiev',
  },
  {
    countryCode: 'RU',
    name: 'Ukraine - Europe/Simferopol',
    value: 'Europe/Simferopol',
  },
  {
    countryCode: 'UA',
    name: 'Ukraine - Europe/Uzhgorod',
    value: 'Europe/Uzhgorod',
  },
  {
    countryCode: 'UA',
    name: 'Ukraine - Europe/Zaporozhye',
    value: 'Europe/Zaporozhye',
  },
  {
    name: 'Uganda - Africa/Kampala',
    value: 'Africa/Kampala',
  },
  {
    name: 'United States Minor Outlying Islands - Pacific/Midway',
    value: 'Pacific/Midway',
  },
  {
    name: 'United States Minor Outlying Islands - Pacific/Wake',
    value: 'Pacific/Wake',
  },
  {
    countryCode: 'US',
    name: 'United States - America/Adak',
    value: 'America/Adak',
  },
  {
    countryCode: 'US',
    name: 'United States - America/Anchorage',
    value: 'America/Anchorage',
  },
  {
    countryCode: 'US',
    name: 'United States - America/Boise',
    value: 'America/Boise',
  },
  {
    countryCode: 'US',
    name: 'United States - America/Chicago',
    value: 'America/Chicago',
  },
  {
    countryCode: 'US',
    name: 'United States - America/Denver',
    value: 'America/Denver',
  },
  {
    countryCode: 'US',
    name: 'United States - America/Detroit',
    value: 'America/Detroit',
  },
  {
    countryCode: 'US',
    name: 'United States - America/Indiana/Indianapolis',
    value: 'America/Indiana/Indianapolis',
  },
  {
    countryCode: 'US',
    name: 'United States - America/Indiana/Knox',
    value: 'America/Indiana/Knox',
  },
  {
    countryCode: 'US',
    name: 'United States - America/Indiana/Marengo',
    value: 'America/Indiana/Marengo',
  },
  {
    countryCode: 'US',
    name: 'United States - America/Indiana/Petersburg',
    value: 'America/Indiana/Petersburg',
  },
  {
    countryCode: 'US',
    name: 'United States - America/Indiana/Tell_City',
    value: 'America/Indiana/Tell_City',
  },
  {
    countryCode: 'US',
    name: 'United States - America/Indiana/Vevay',
    value: 'America/Indiana/Vevay',
  },
  {
    countryCode: 'US',
    name: 'United States - America/Indiana/Vincennes',
    value: 'America/Indiana/Vincennes',
  },
  {
    countryCode: 'US',
    name: 'United States - America/Indiana/Winamac',
    value: 'America/Indiana/Winamac',
  },
  {
    countryCode: 'US',
    name: 'United States - America/Juneau',
    value: 'America/Juneau',
  },
  {
    countryCode: 'US',
    name: 'United States - America/Kentucky/Louisville',
    value: 'America/Kentucky/Louisville',
  },
  {
    countryCode: 'US',
    name: 'United States - America/Kentucky/Monticello',
    value: 'America/Kentucky/Monticello',
  },
  {
    countryCode: 'US',
    name: 'United States - America/Los_Angeles',
    value: 'America/Los_Angeles',
  },
  {
    countryCode: 'US',
    name: 'United States - America/Menominee',
    value: 'America/Menominee',
  },
  {
    countryCode: 'US',
    name: 'United States - America/Metlakatla',
    value: 'America/Metlakatla',
  },
  {
    countryCode: 'US',
    name: 'United States - America/New_York',
    value: 'America/New_York',
  },
  {
    countryCode: 'US',
    name: 'United States - America/Nome',
    value: 'America/Nome',
  },
  {
    countryCode: 'US',
    name: 'United States - America/North_Dakota/Beulah',
    value: 'America/North_Dakota/Beulah',
  },
  {
    countryCode: 'US',
    name: 'United States - America/North_Dakota/Center',
    value: 'America/North_Dakota/Center',
  },
  {
    countryCode: 'US',
    name: 'United States - America/North_Dakota/New_Salem',
    value: 'America/North_Dakota/New_Salem',
  },
  {
    countryCode: 'US',
    name: 'United States - America/Phoenix',
    value: 'America/Phoenix',
  },
  {
    countryCode: 'US',
    name: 'United States - America/Sitka',
    value: 'America/Sitka',
  },
  {
    countryCode: 'US',
    name: 'United States - America/Yakutat',
    value: 'America/Yakutat',
  },
  {
    countryCode: 'US',
    name: 'United States - Pacific/Honolulu',
    value: 'Pacific/Honolulu',
  },
  {
    countryCode: 'UY',
    name: 'Uruguay - America/Montevideo',
    value: 'America/Montevideo',
  },
  {
    countryCode: 'UZ',
    name: 'Uzbekistan - Asia/Samarkand',
    value: 'Asia/Samarkand',
  },
  {
    countryCode: 'UZ',
    name: 'Uzbekistan - Asia/Tashkent',
    value: 'Asia/Tashkent',
  },
  {
    name: 'Vatican - Europe/Vatican',
    value: 'Europe/Vatican',
  },
  {
    name: 'Saint Vincent and the Grenadines - America/St_Vincent',
    value: 'America/St_Vincent',
  },
  {
    countryCode: 'VE',
    name: 'Venezuela - America/Caracas',
    value: 'America/Caracas',
  },
  {
    name: 'British Virgin Islands - America/Tortola',
    value: 'America/Tortola',
  },
  {
    name: 'U.S. Virgin Islands - America/St_Thomas',
    value: 'America/St_Thomas',
  },
  {
    countryCode: 'VN',
    name: 'Vietnam - Asia/Ho_Chi_Minh',
    value: 'Asia/Ho_Chi_Minh',
  },
  {
    name: 'Vanuatu - Pacific/Efate',
    value: 'Pacific/Efate',
  },
  {
    name: 'Wallis and Futuna - Pacific/Wallis',
    value: 'Pacific/Wallis',
  },
  {
    name: 'Samoa - Pacific/Apia',
    value: 'Pacific/Apia',
  },
  {
    countryCode: 'YE',
    name: 'Yemen - Asia/Aden',
    value: 'Asia/Aden',
  },
  {
    name: 'Mayotte - Indian/Mayotte',
    value: 'Indian/Mayotte',
  },
  {
    countryCode: 'ZA',
    name: 'South Africa - Africa/Johannesburg',
    value: 'Africa/Johannesburg',
  },
  {
    name: 'Zambia - Africa/Lusaka',
    value: 'Africa/Lusaka',
  },
  {
    countryCode: 'ZW',
    name: 'Zimbabwe - Africa/Harare',
    value: 'Africa/Harare',
  },
];

export default Countries;
