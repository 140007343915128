import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Stack, Toolbar, Typography } from '@mui/material';
import Lottie from 'lottie-react';

import { getStripeState } from '../../store/Stripe';
import { useAppSelector } from '../../hooks';
import PaymentProcessingAnimation from '../../lottie/payment-processing.json';
import MonthlyPaymentHeader from '../../components/Header/MonthlyPaymentHeader';

const PaymentProcessingPage: React.FC = () => {
  const navigate = useNavigate();

  // Store
  const stripeState = useAppSelector(getStripeState);

  const { subscriptionData } = stripeState;

  useEffect(() => {
    if (subscriptionData) {
      navigate('/subscription/classes');
    }
  }, [subscriptionData]);

  return (
    <div>
      <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
        <MonthlyPaymentHeader />
        <Toolbar />
      </Box>
      <Stack
        direction="row"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        textAlign="center"
        style={{
          width: 'calc(100vw - 30px)',
          height: '70vh',
          padding: '0 15px',
        }}
      >
        <Lottie
          animationData={PaymentProcessingAnimation}
          loop
          style={{ width: '400px', maxWidth: '100%' }}
        />
        <div>
          <Typography variant="h6">Payment Processing</Typography>
        </div>
        <div>
          <Typography color="secondary">
            We&apos;re processing your credit card. This process may take a few
            <Box component="br" sx={{ display: { xs: 'none', sm: 'block' } }} />
            seconds, So please be patient.
          </Typography>
        </div>
      </Stack>
    </div>
  );
};

export default PaymentProcessingPage;
