import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '..';

import {
  applePayValidateSession,
  IApplePayValidation,
  payApplePayment,
} from './ApplePayApi';

interface IApplePayState {
  status: 'idle' | 'loading' | 'rejected';
  validation: IApplePayValidation | undefined;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  outcome: any;
}

const initialState: IApplePayState = {
  status: 'idle',
  validation: undefined,
  outcome: undefined,
};

export const ApplePaySlice = createSlice({
  name: 'applePay',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(applePayValidateSession.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(applePayValidateSession.fulfilled, (state, action) => {
        state.status = 'idle';
        state.validation = action.payload;
      })
      .addCase(applePayValidateSession.rejected, (state) => {
        state.status = 'rejected';
      })
      .addCase(payApplePayment.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(payApplePayment.fulfilled, (state, action) => {
        state.status = 'idle';
        state.outcome = action.payload;
      })
      .addCase(payApplePayment.rejected, (state) => {
        state.status = 'rejected';
      });
  },
});

export const getApplePayState = (state: RootState): RootState['applePay'] =>
  state.applePay;

export * from './ApplePayApi';
export default ApplePaySlice.reducer;
