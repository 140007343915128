import { createAsyncThunk } from '@reduxjs/toolkit';
import APIDataProvider from '../../dataProvider/APIDataProvider';
import ApiPaths from '../../dataProvider/APIPaths';
import { IUser } from '../AuthSlice';

const UserApiDataProvider = new APIDataProvider<IUser>();

export const getUserById = createAsyncThunk(
  'user/getById',
  async (id: string) => {
    const response = await UserApiDataProvider.getById(
      `${ApiPaths.user}/${id}`
    );

    return response.data;
  }
);

export default getUserById;
