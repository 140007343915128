import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
// ICONS
import CheckIcon from '@mui/icons-material/Check';
// CONSTANTS
import { TOTAL_SUBJECTS_COUNT } from '../tabs/WeeklyPlansTab';

interface SubjectsCountModalProps {
  open: boolean;
  onClose: () => void;
  selectedSubjectCount: number;
  onChange: (count: number) => void;
}

const SubjectsCountModal: React.FC<SubjectsCountModalProps> = ({
  open,
  onClose,
  selectedSubjectCount,
  onChange,
}) => {
  const options: React.ReactElement[] = [];
  for (let i = 1; i <= TOTAL_SUBJECTS_COUNT; i += 1) {
    const selected = selectedSubjectCount === i;
    options.push(
      <ListItem key={i}>
        <ListItemButton selected={selected} onClick={() => onChange(i)}>
          <ListItemText
            primary={`${i} Subject${i > 1 ? 's' : ''}`}
            style={{ fontWeight: 500, fontSize: '16px' }}
          />
          {selected && (
            <ListItemIcon>
              <CheckIcon />
            </ListItemIcon>
          )}
        </ListItemButton>
      </ListItem>
    );
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          minWidth: {
            xs: '95%',
            sm: '400px',
          },
          maxWidth: '400px',
        },
      }}
    >
      <DialogTitle>How Many Subjects?</DialogTitle>
      <DialogContent>
        <List>{options}</List>
      </DialogContent>
    </Dialog>
  );
};

export default SubjectsCountModal;
