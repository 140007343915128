export const SubjectColors: string[] = [
  'linear-gradient(264deg, #CD4CE5 0%, #7C3BC9 100%)',
  'linear-gradient(257deg, #3A7BD5 0%, #00D2FF 100%)',
  'linear-gradient(258deg, #FF9472 0%, #F2709C 100%)',
  'linear-gradient(99deg, #1CB07C 0%, #BEE87A 100%)',
  'linear-gradient(259deg, #EF4493 0%, #FF3967 100%)',
  'linear-gradient(281deg, #52D682 0%, #1CA2A5 100%)',
  'linear-gradient(259deg, #E9C938 0%, #FF7307 100%)',
];

export const TP_COLORS = {
  black: '#000000',
  white: '#fff',
  listItemGray: {
    light: '#F8F9FD',
    dark: '#36393e',
  },
  paperGray: {
    light: '#F9F9F9',
    dark: '#36393e',
  },
  tutopiyaGreenMain: {
    light: '#01B8AF',
    dark: '#0CD6CD',
  },
};

export default TP_COLORS;
