const basePath = '/api/v1';

export const ApiPaths = {
  invoice: `${basePath}/invoice`,
  checkout: `${basePath}/checkout`,
  hitpay: `${basePath}/hitpay`,
  applePay: `${basePath}/applePay`,
  promoCode: `${basePath}/promoCode`,
  package: `${basePath}/package`,
  user: `${basePath}/user`,
  payment: `${basePath}/payment`,
  stripe: `${basePath}/stripe`,
};

export default ApiPaths;
