import React from 'react';
import {
  Card,
  CardContent,
  Stack,
  Typography,
  Switch,
  Select,
  MenuItem,
  Collapse,
  SelectChangeEvent,
} from '@mui/material';
// HELPERS
import { isMobileScreen } from '../../../helper';
// CONSTANTS
import { TP_COLORS } from '../../../constants';

export interface IAutoTopUpData {
  isAutoTopUpEnabled: boolean;
  threshold: string;
}

interface AutoTopUpCardProps {
  autoTopUpData: IAutoTopUpData;
  updateAutoTopUpData: (autoTopUpData: IAutoTopUpData) => void;
}

// DATA
export const THRESHOLD_OPTIONS = [
  { label: '01 Hour', value: '60' },
  { label: '02 Hours', value: '120' },
  { label: '03 Hours', value: '180' },
  { label: '04 Hours', value: '240' },
  { label: '05 Hours', value: '300' },
];

const AutoTopUpCard: React.FC<AutoTopUpCardProps> = ({
  autoTopUpData,
  updateAutoTopUpData,
}) => {
  const isMobile = isMobileScreen();

  // METHODS
  const handleAutoTopUpSwitchChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    updateAutoTopUpData({
      ...autoTopUpData,
      isAutoTopUpEnabled: checked,
    });
  };

  const handleThresholdChange = (event: SelectChangeEvent) => {
    updateAutoTopUpData({
      ...autoTopUpData,
      threshold: event.target.value,
    });
  };

  // RENDER
  return (
    <Card
      elevation={0}
      sx={{
        border: isMobile ? 'none' : { xs: 'none', sm: '1px solid lightgray' },
        p: isMobile ? 0 : { xs: '0', sm: '0 15px' },
        height: { xs: 'unsest', lg: '100%' },
      }}
    >
      <CardContent sx={{ pl: { xs: '0' }, pr: { xs: '0' } }}>
        <div>
          <Stack
            direction="row"
            alignItems="center"
            style={{ marginBottom: '10px' }}
          >
            <div>
              <Typography variant="h6" fontWeight={600}>
                Auto Top-up
              </Typography>
            </div>
            <div>
              <Switch
                checked={autoTopUpData.isAutoTopUpEnabled}
                onChange={handleAutoTopUpSwitchChange}
              />
            </div>
          </Stack>
          <div>
            <Typography fontWeight={500} color="secondary">
              Save more time and money by enabling auto top-up feature.
            </Typography>
          </div>
          <Collapse in={autoTopUpData.isAutoTopUpEnabled}>
            <div style={{ marginTop: '10px' }}>
              <Card elevation={0}>
                <CardContent
                  style={{ backgroundColor: TP_COLORS.listItemGray.light }}
                >
                  <Stack
                    direction={{ xs: 'column', sm: 'column', md: 'row' }}
                    justifyContent={{
                      xs: 'unset',
                      sm: 'unset',
                      md: 'space-between',
                    }}
                    alignItems={{ xs: 'unset', sm: 'unset', md: 'center' }}
                  >
                    <div>
                      <Typography
                        fontWeight={600}
                        marginBottom={{ xs: '10px !important', sm: 'unset' }}
                      >
                        When Balance Falls Below:
                      </Typography>
                    </div>
                    <div>
                      <Select
                        fullWidth
                        size="small"
                        value={autoTopUpData.threshold}
                        style={{ backgroundColor: '#fff', minWidth: '220px' }}
                        onChange={handleThresholdChange}
                      >
                        {THRESHOLD_OPTIONS.map((thresholdOption, index) => (
                          <MenuItem key={index} value={thresholdOption.value}>
                            {thresholdOption.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </div>
                  </Stack>
                </CardContent>
              </Card>
            </div>
          </Collapse>
        </div>
      </CardContent>
    </Card>
  );
};

export default AutoTopUpCard;
