import { createSlice } from '@reduxjs/toolkit';
// STORE
import { RootState } from '..';
import { IUser } from '../AuthSlice';
// API
import { getInvoice, updateInvoice } from './InvoiceApi';

export interface IInvoiceSubject {
  curriculum: string;
  grade: string;
  lastClassEnd: number;
  lastClassStart: number;
  numberOfClasses: number;
  selectedClassDate: number;
  selectedNumberOfClass: number;
  subject: string;
  tutorId: string;
}

export interface IInvoice {
  id: string;
  createdAt: number;
  currency: string;
  description: string;
  invoiceNumber: number;
  isChangeSchedule?: boolean;
  qty: number;
  rate: number;
  status: 'Pending' | 'Paid';
  student: IUser;
  subjects: IInvoiceSubject[];
  userId: string;
  sourceId?: string;
}

export interface IInvoiceState {
  status: 'idle' | 'loading' | 'rejected';
  invoice: IInvoice | undefined;
  showPaymentCardModal: boolean;
}

const initialState: IInvoiceState = {
  status: 'idle',
  invoice: undefined,
  showPaymentCardModal: false,
};

export const InvoiceSlice = createSlice({
  name: 'invoice',
  initialState,
  reducers: {
    hidePaymentCardModal: (state) => {
      state.showPaymentCardModal = false;
    },
  },
  extraReducers: (builder) => {
    // list
    builder
      .addCase(getInvoice.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getInvoice.fulfilled, (state, action) => {
        state.status = 'idle';
        state.invoice = action.payload;
      })
      .addCase(getInvoice.rejected, (state) => {
        state.status = 'rejected';
      })
      // UPDATE
      .addCase(updateInvoice.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(updateInvoice.fulfilled, (state, action) => {
        state.status = 'idle';
        state.invoice = action.payload;
        if (action.payload.status === 'Pending') {
          state.showPaymentCardModal = true;
        }
      })
      .addCase(updateInvoice.rejected, (state) => {
        state.status = 'rejected';
      });
  },
});

export const getInvoiceState = (state: RootState): RootState['invoice'] =>
  state.invoice;

export const { hidePaymentCardModal } = InvoiceSlice.actions;
export * from './InvoiceApi';
export default InvoiceSlice.reducer;
