import { createAsyncThunk } from '@reduxjs/toolkit';
import { ICheckoutCard } from '.';
import { IFlexiblePlan } from '../../constants';
import APIDataProvider from '../../dataProvider/APIDataProvider';
import ApiPaths from '../../dataProvider/APIPaths';
import { IAutoTopUpData } from '../../pages/Payment/components/AutoTopUpCard';
import { IPromoCodeData } from '../PromoCodeSlice';

export interface ICheckoutPayment {
  invoiceId: string;
  token?: string;
  issuerCountryCode?: string;
  sourceId?: string;
  code?: string;
  saveCard?: boolean;
  applePaymentToken?: ApplePayJS.ApplePayPaymentToken;
  collectorId?: string;
}

export interface IGetCheckoutReceipt {
  sourceId: string;
}

export interface IAddCheckoutCard {
  isSaveCard: boolean;
  token: string;
  userId: string;
}

export interface IPaymentClass {
  tutorId: string;
  subject: string;
  curriculum: string;
  grade: string;
  selectedClassDate: number;
  selectedNumberOfClass: number;
  duration: number;
}

export interface ICheckoutMakePayment {
  userId: string;
  amount: string;
  currency: string;
  packageId: IFlexiblePlan['id'];
  sourceId?: string;
  token?: string;
  issuerCountryCode?: string;
  type: 'package';
  code?: string;
  discount?: string;
  promoCodeDetails?: IPromoCodeData;
  autoTopUpData: IAutoTopUpData;
  paymentClassData?: {
    isChangeSchedule?: boolean;
    paymentClasses?: IPaymentClass[];
  };
  subjectCount?: number;
  minutesPerWeek?: number;
  saveCard?: boolean;
  applePaymentToken?: ApplePayJS.ApplePayPaymentToken;
  isInstalmentPayment?: boolean;
  collectorId?: string;
}

const CheckoutPaymentApiDataProvider = new APIDataProvider<{
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  applePayOutcome: any;
  redirectUrl: string;
}>();

const CheckoutCardsApiDataProvider = new APIDataProvider<ICheckoutCard[]>();
const CheckoutAddCardApiDataProvider = new APIDataProvider<ICheckoutCard>();

export const checkoutPayment = createAsyncThunk(
  'checkout/get',
  async (data: ICheckoutPayment) => {
    const response = await CheckoutPaymentApiDataProvider.create(
      `${ApiPaths.checkout}/invoice_payment`,
      data
    );

    return response.data;
  }
);

export const getCheckoutCards = createAsyncThunk(
  'checkout/cards',
  async (userId: string) => {
    const response = await CheckoutCardsApiDataProvider.getById(
      `${ApiPaths.checkout}/cards/${userId}`
    );

    return response.data;
  }
);

export const addCheckoutCard = createAsyncThunk(
  'checkout/card/add',
  async (data: IAddCheckoutCard) => {
    const response = await CheckoutAddCardApiDataProvider.create(
      `${ApiPaths.checkout}/card`,
      data
    );
    return response.data;
  }
);

export const checkoutMakePayment = createAsyncThunk(
  'checkout/makePayment',
  async (data: ICheckoutMakePayment) => {
    const response = await CheckoutPaymentApiDataProvider.create(
      `${ApiPaths.checkout}/package_payment`,
      data
    );

    return response.data;
  }
);
