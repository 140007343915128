import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {
  Card,
  CardContent,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
// HOOKS
import { useAppSelector } from '../../../hooks';
// STORE
import {
  validatePromoCode,
  getPromoCodeState,
  resetPromoCodeState,
  IValidatePromoCode,
} from '../../../store/PromoCodeSlice';
// HELPERS
import { isMobileScreen } from '../../../helper';
// CONSTANTS
import { TP_COLORS } from '../../../constants';
import { IPackageData } from '../PaymentView';

interface PromoCardProps {
  packageData: IPackageData;
}

const PromoCard: React.FC<PromoCardProps> = ({ packageData }) => {
  // HOOKS
  const dispatch = useDispatch();
  const params = useParams();
  const promoCodeState = useAppSelector(getPromoCodeState);

  const promoCodeData = promoCodeState.promoCode;

  const isMobile = isMobileScreen();
  let isSubmitable = true;
  if (packageData.planType === 'weekly') {
    isSubmitable = false;
  }
  if (
    packageData.minutesPerWeek &&
    packageData.subjectCount &&
    packageData.planType === 'weekly'
  ) {
    isSubmitable = true;
  }

  // LOCAL STATE
  const [promoCode, setPromoCode] = useState('');

  // METHODS
  const handleAdd = () => {
    if (params && promoCode && params.userId && params.packageName) {
      const API_DATA: IValidatePromoCode = {
        userId: params.userId,
        promoCode,
        packageId: params.packageName,
      };
      if (packageData.planType === 'weekly') {
        API_DATA.subjectCount = packageData.subjectCount;
        API_DATA.minutesPerWeek = packageData.minutesPerWeek;
      }
      dispatch(validatePromoCode(API_DATA));
    }
  };

  const handleRemove = () => {
    dispatch(resetPromoCodeState());
    setPromoCode('');
  };

  // RENDER
  return (
    <Card
      elevation={0}
      sx={{
        border: isMobile ? 'none' : { xs: 'none', sm: '1px solid lightgray' },
        p: isMobile ? 0 : { xs: '0', sm: '15px' },
        mb: '15px',
        height: { xs: 'unsest', lg: '100%' },
      }}
    >
      <CardContent
        sx={{
          backgroundColor: TP_COLORS.listItemGray.light,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          height: { xs: 'unset', lg: 'calc(100% - 16px - 16px - 8px)' },
        }}
      >
        <div>
          <Typography variant="h6" fontWeight={600} sx={{ mb: '15px' }}>
            Promo Code
          </Typography>
          <TextField
            style={{ width: '100%' }}
            sx={{ backgroundColor: 'white' }}
            size="small"
            value={promoCode}
            placeholder="Enter Code"
            onChange={(event) => setPromoCode(event.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {promoCodeData && promoCodeData.price ? (
                    <LoadingButton
                      style={{
                        marginRight: '-12px',
                        backgroundColor: '#fedbda',
                      }}
                      variant="text"
                      color="error"
                      onClick={handleRemove}
                      loading={promoCodeState.status === 'loading'}
                    >
                      Remove
                    </LoadingButton>
                  ) : (
                    <LoadingButton
                      disabled={!promoCode || !isSubmitable}
                      style={{ marginRight: '-12px' }}
                      variant="contained"
                      color="secondary"
                      onClick={handleAdd}
                      loading={promoCodeState.status === 'loading'}
                    >
                      Add
                    </LoadingButton>
                  )}
                </InputAdornment>
              ),
            }}
          />
          {promoCodeData && !promoCodeData.valid && (
            <Typography variant="caption" mt="10px" color="error.main">
              {promoCodeData.message}
            </Typography>
          )}
          {promoCodeData && promoCodeData.valid && (
            <Typography variant="caption" mt="10px" color="success.main">
              {promoCodeData.message}
            </Typography>
          )}
        </div>
      </CardContent>
    </Card>
  );
};

export default PromoCard;
