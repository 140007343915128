import React from 'react';
import { Button, Container, Typography } from '@mui/material';

interface ErrorViewProps {
  redirectLink?: string;
}

const containerStyles: React.CSSProperties = {
  height: 'calc(100vh - 64px)',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center',
};

const ErrorView: React.FC<ErrorViewProps> = ({ redirectLink }) => {
  return (
    <Container maxWidth="xs" style={containerStyles}>
      <div style={{ marginBottom: '15px' }}>
        <img
          src="/images/objects/card-transaction-failed.png"
          alt="card-transaction-failed"
          width="100%"
          style={{ maxWidth: '300px' }}
        />
      </div>
      <div>
        <Typography variant="h5" fontWeight={600}>
          Transaction Failed
        </Typography>
      </div>
      <div style={{ margin: '15px 0 40px 0' }}>
        <Typography color="secondary" fontWeight={500}>
          Unfortunately, we couldn&apos;t collect payment on your purchase.
          Please take a moment to review your billing information
        </Typography>
      </div>
      <Button
        fullWidth
        size="large"
        variant="contained"
        color="primary"
        href={redirectLink}
      >
        Retry
      </Button>
    </Container>
  );
};

export default ErrorView;
