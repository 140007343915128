import { createSlice } from '@reduxjs/toolkit';
// STORE
import { RootState } from '..';
// API
import {
  checkoutPayment,
  getCheckoutCards,
  addCheckoutCard,
  checkoutMakePayment,
} from './CheckoutApi';

export interface ICheckoutCard {
  id: string;
  scheme: 'Visa' | 'Mastercard' | 'American Express';
  last4: string;
}

export interface ICheckoutState {
  status: 'idle' | 'loading' | 'rejected';
  redirectUrl: string | undefined;
  cards: ICheckoutCard[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  applePayOutcome?: any;
}

const initialState: ICheckoutState = {
  status: 'idle',
  redirectUrl: undefined,
  cards: [],
};

export const CheckoutSlice = createSlice({
  name: 'checkout',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // CHECKOUT PAYMENT
      .addCase(checkoutPayment.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(checkoutPayment.fulfilled, (state, action) => {
        state.status = 'idle';
        state.redirectUrl = action.payload.redirectUrl;

        if (action.payload.applePayOutcome) {
          state.applePayOutcome = action.payload.applePayOutcome;
        }
      })
      .addCase(checkoutPayment.rejected, (state) => {
        state.status = 'rejected';
      })
      // GET CHECKOUT CARDS
      .addCase(getCheckoutCards.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getCheckoutCards.fulfilled, (state, action) => {
        state.status = 'idle';
        state.cards = action.payload || [];
      })
      .addCase(getCheckoutCards.rejected, (state) => {
        state.status = 'rejected';
      })
      // ADD CHECKOUT CARD
      .addCase(addCheckoutCard.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(addCheckoutCard.fulfilled, (state, action) => {
        state.status = 'idle';
        state.cards = [...state.cards, action.payload];
      })
      .addCase(addCheckoutCard.rejected, (state) => {
        state.status = 'rejected';
      })
      // CHECKOUT MAKE PAYMENT
      .addCase(checkoutMakePayment.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(checkoutMakePayment.fulfilled, (state, action) => {
        state.status = 'idle';
        state.redirectUrl = action.payload.redirectUrl;
        window.location.href = action.payload.redirectUrl;

        if (action.payload.applePayOutcome) {
          state.applePayOutcome = action.payload.applePayOutcome;
        }
      })
      .addCase(checkoutMakePayment.rejected, (state) => {
        state.status = 'rejected';
      });
  },
});

export const getCheckoutState = (state: RootState): RootState['checkout'] =>
  state.checkout;

export * from './CheckoutApi';
export default CheckoutSlice.reducer;
