import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Drawer,
  FormControlLabel,
  Grid,
  Stack,
  Typography,
} from '@mui/material';
import moment, { Moment as IMoment } from 'moment';

import { getStripeState } from '../../../store/Stripe';
import { ICustomPaymentClass } from '../../../store/PaymentSlice';
import { useAppSelector } from '../../../hooks';
import { isMobileScreen } from '../../../helper';
import PaymentEventListItem from '../components/PaymentEventListItem';
import EditPaymentClassModal from './EditPaymentClassModal';

interface ScheduleConflictModalProps {
  open: boolean;
  onClose: () => void;
  updateClassData: (
    list: Array<{ id: string; duration: number; startDate: IMoment }>
  ) => void;
  removeClasses: (classIdList: string[]) => void;
  paymentType: 'monthly' | 'upfront' | 'flexible';
}

interface IEditClasModalData {
  open: boolean;
  item: ICustomPaymentClass;
}

const modalHeading = 'Schedule Conflict';

const ScheduleConflictModal: React.FC<ScheduleConflictModalProps> = ({
  open,
  onClose,
  updateClassData,
  removeClasses,
  paymentType,
}) => {
  // Store
  const stripeState = useAppSelector(getStripeState);

  // State
  const [check, setCheck] = useState(false);
  const [conflictClasses, setConflictClasses] = useState<
    Array<ICustomPaymentClass>
  >([]);

  const [editClassModalData, setEditClassModalData] = useState<
    IEditClasModalData | undefined
  >(undefined);

  const isMobile = isMobileScreen();

  useEffect(() => {
    if (
      stripeState.validatedPaymentClassessData &&
      stripeState.validatedPaymentClassessData.conflictPaymentClasses &&
      stripeState.validatedPaymentClassessData.conflictPaymentClasses.length
    ) {
      setConflictClasses(
        stripeState.validatedPaymentClassessData.conflictPaymentClasses || []
      );
    }
  }, []);

  const handleEdit = (classData: ICustomPaymentClass) => {
    setEditClassModalData({
      open: true,
      item: classData,
    });
  };

  const handleEditModalClose = () => {
    setEditClassModalData(undefined);
  };

  const handleUpdateClassData = (
    id: string,
    startDate: IMoment,
    duration: number
  ) => {
    const newConflictClasses: ICustomPaymentClass[] = [...conflictClasses];
    const _selectedClassIndex = newConflictClasses.findIndex(
      (item) => item.id === id
    );

    if (_selectedClassIndex > -1) {
      const _selectedClass = newConflictClasses[_selectedClassIndex]
        ? { ...newConflictClasses[_selectedClassIndex] }
        : undefined;

      if (_selectedClass) {
        _selectedClass.duration = duration;
        _selectedClass.classStart = startDate.toDate().getTime();
        _selectedClass.classEnd = moment(startDate)
          .add(duration)
          .toDate()
          .getTime();

        newConflictClasses[_selectedClassIndex] = {
          ..._selectedClass,
          error: undefined,
          classClashDate: undefined,
        };
      }
    }

    setConflictClasses(newConflictClasses);
  };

  const handleSubmit = () => {
    if (check) {
      removeClasses(conflictClasses.map((conflictClass) => conflictClass.id));
    } else {
      const classList = conflictClasses.map((conflictClass) => ({
        id: conflictClass.id,
        duration: conflictClass.duration,
        startDate: moment(conflictClass.classStart),
      }));

      updateClassData(classList);
    }

    onClose();
  };

  const toggleCheck = () => {
    setCheck(!check);
  };

  const renderCheckBox = (
    <FormControlLabel
      checked={check}
      value="start"
      control={<Checkbox />}
      label="Continue without these classes"
      onClick={toggleCheck}
    />
  );

  const renderContent = () => {
    return (
      <>
        <div>
          <Grid container spacing={2}>
            {conflictClasses && conflictClasses.length
              ? conflictClasses.map((conflictClass, index) => {
                  return (
                    <Grid key={index} item xs={12}>
                      <Typography variant="subtitle2" color="error.main">
                        {conflictClass.error}
                      </Typography>
                      <PaymentEventListItem
                        classData={conflictClass}
                        onEdit={() => handleEdit(conflictClass)}
                        paymentType={paymentType}
                      />
                    </Grid>
                  );
                })
              : null}
          </Grid>
          {/* Modals */}
          {editClassModalData && editClassModalData.open ? (
            <EditPaymentClassModal
              open={editClassModalData.open}
              onClose={handleEditModalClose}
              classData={editClassModalData.item}
              updateClassData={handleUpdateClassData}
              isFlexiblePlan={paymentType === 'flexible'}
            />
          ) : null}
        </div>
      </>
    );
  };

  if (isMobile) {
    return (
      <Drawer
        open={open}
        onClose={onClose}
        anchor="bottom"
        PaperProps={{
          style: {
            maxHeight: '90vh',
            borderTopLeftRadius: '12px',
            borderTopRightRadius: '12px',
          },
        }}
      >
        <Box role="presentation">
          <div style={{ margin: '30px 0 15px 0' }}>
            <Typography variant="h6" textAlign="center" fontWeight={600}>
              {modalHeading}
            </Typography>
          </div>
          <div
            style={{
              maxHeight: 'calc(90vh - 80px)',
              overflow: 'auto',
              padding: '0 15px',
            }}
          >
            {renderContent()}
            <div style={{ margin: '15px 0' }}>
              <Stack alignItems="center">
                {renderCheckBox}
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  onClick={handleSubmit}
                  style={{ marginTop: '15px' }}
                >
                  Update
                </Button>
              </Stack>
            </div>
          </div>
        </Box>
      </Drawer>
    );
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          maxWidth: { sm: '700px', md: '800px', lg: '800px', xl: '1000px' },
          minWidth: { xs: 'unset', sm: '650px', md: '700px' },
        },
      }}
    >
      <DialogTitle>{modalHeading}</DialogTitle>
      <DialogContent>{renderContent()}</DialogContent>
      <DialogActions
        style={{ padding: '15px 30px', justifyContent: 'space-between' }}
      >
        {renderCheckBox}
        <Stack direction="row" alignItems="center">
          <Button
            size="small"
            variant="contained"
            color="inherit"
            onClick={onClose}
            style={{ minWidth: '70px', marginRight: '10px' }}
          >
            Dismiss
          </Button>
          <Button
            size="small"
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            style={{ minWidth: '70px' }}
          >
            Update
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  );
};

export default ScheduleConflictModal;
