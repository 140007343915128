import React from 'react';
import { withStyles } from '@mui/styles';
import {
  Box,
  BoxProps,
  Button,
  Grid,
  GridProps,
  Tooltip,
  TooltipProps,
} from '@mui/material';

interface ICustomToolTipProps extends Omit<TooltipProps, 'children'> {
  children?: undefined;
}

export interface ITabOption {
  index: number;
  key: string;
  title: string;
  tooltipProps?: ICustomToolTipProps;
}

interface TabBarProps {
  tabOptions: ITabOption[];
  selectedOption: ITabOption;
  onChange: (tabOption: ITabOption) => void;
  containerProps?: BoxProps;
  optionGridProps?: GridProps;
}

// STYLES
const CustomToolTipDesktop = withStyles({
  tooltip: {
    backgroundColor: '#01B8AF',
    color: '#fff',
    marginBottom: '8px !important',
  },
})(Tooltip);

// DATA
export const TAB_COLORS = {
  light: {
    containerBackgroundColor: '#f9f9f9',
    textColor: '#000',
    buttonHighlightedBackgroundColor: '#fff',
  },
};

const TabBar: React.FC<TabBarProps> = ({
  tabOptions = [],
  selectedOption = undefined,
  onChange = undefined,
  containerProps = {},
  optionGridProps = {},
}) => {
  const tabTheme = TAB_COLORS.light;

  // STYLES
  const defaultContainerStyles: React.CSSProperties = {
    backgroundColor: tabTheme.containerBackgroundColor,
    padding: '5px',
    borderRadius: '4px',
  };

  const defaultOptionGridProps: GridProps = {
    xs: 4,
    sm: 3,
    md: 2,
    lg: 2,
    xl: 2,
  };

  return (
    <Box
      {...containerProps}
      style={{ ...defaultContainerStyles, ...(containerProps.style || {}) }}
    >
      <Grid container spacing={1} alignItems="center">
        {tabOptions.map((tabOption, index) => {
          const isSelected =
            selectedOption && selectedOption.key === tabOption.key;

          let tooltipProps: ICustomToolTipProps = {
            open: false,
            title: '',
          };

          if (tabOption.tooltipProps) {
            tooltipProps = tabOption.tooltipProps;
          }

          // STYLES
          const _defaultButtonStyles: React.CSSProperties = {
            color: tabTheme.textColor,
            fontSize: '14px',
            fontWeight: 600,
          };
          if (isSelected) {
            _defaultButtonStyles.backgroundColor =
              tabTheme.buttonHighlightedBackgroundColor;
          }

          return (
            <Grid
              key={index}
              item
              {...defaultOptionGridProps}
              {...optionGridProps}
            >
              <CustomToolTipDesktop {...tooltipProps}>
                <Button
                  fullWidth
                  variant="text"
                  color="inherit"
                  style={_defaultButtonStyles}
                  onClick={onChange ? () => onChange(tabOption) : undefined}
                >
                  {tabOption.title}
                </Button>
              </CustomToolTipDesktop>
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
};

export default TabBar;
