import React from 'react';
import { Button, Container, Typography } from '@mui/material';

const containerStyles: React.CSSProperties = {
  height: 'calc(100vh - 64px)',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center',
};

const SuccessView: React.FC = () => {
  return (
    <Container maxWidth="xs" style={containerStyles}>
      <div style={{ marginBottom: '15px' }}>
        <img
          src="/images/objects/Online calendar.png"
          width="100%"
          alt="online-calendar"
        />
      </div>
      <div>
        <Typography variant="h5" fontWeight={600}>
          You&apos;re all set!
        </Typography>
      </div>
      <div style={{ margin: '15px 0 40px 0' }}>
        <Typography color="secondary" fontWeight={500}>
          You can now close this browser tab and go back to your applciation or
          login
        </Typography>
      </div>
      <Button
        fullWidth
        size="large"
        variant="contained"
        color="primary"
        href={`${process.env.REACT_APP_TUTOPIYA_PLATFORM_BASEURL}/#/login`}
      >
        Login
      </Button>
    </Container>
  );
};

export default SuccessView;
