import React from 'react';
import { AppBar, SxProps, Theme, Toolbar, Box } from '@mui/material';

export const headerHeight = '64px';
const toolbarStyles: SxProps<Theme> = {
  backgroundColor: { xs: 'background.default', sm: 'primary.main' },
  justifyContent: { xs: 'center', sm: 'flex-start' },
};

const BillingHeader: React.FC = () => {
  return (
    <AppBar position="fixed">
      <Toolbar sx={toolbarStyles}>
        <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
          <img
            src="/images/logos/tp-logo-white.png"
            width="120px"
            alt="Tutopiya-Logo"
          />
        </Box>
        <Box sx={{ display: { xs: 'block', sm: 'none' } }}>
          <img
            src="/images/logos/tp-logo-green.png"
            width="120px"
            alt="Tutopiya-Logo"
          />
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default BillingHeader;
