import React from 'react';
import {
  Button,
  Card,
  CardContent,
  Checkbox,
  Grid,
  IconButton,
  Stack,
  Typography,
  styled,
} from '@mui/material';
import SquareIcon from '@mui/icons-material/Square';
import EditIcon from '@mui/icons-material/Edit';
import moment from 'moment';

import { SubjectColors, TP_COLORS } from '../../../constants';
import DetailViewCard, {
  IDetailViewCard,
} from '../../../components/Card/DetailViewCard';
import { getDurationTextFromMinutes, isMobileScreen } from '../../../helper';
import { ICustomPaymentClass } from '../../../store/PaymentSlice';

interface PaymentEventListItemProps {
  classData: ICustomPaymentClass;
  onChecked?: () => void;
  onEdit: () => void;
  paymentType: 'monthly' | 'upfront' | 'flexible';
}

const detailViewProps: IDetailViewCard = {
  containerProps: {
    sx: {
      mb: '0',
      backgroundColor: TP_COLORS.white,
    },
  },
  titleProps: {
    variant: 'caption',
    color: 'secondary',
    className: 'ellipsis-text',
  },
  descriptionProps: {
    className: 'ellipsis-text',
    variant: 'subtitle2',
    fontWeight: 600,
  },
};

const DesktopEditButton = styled(IconButton)({
  borderRadius: '4px',
  backgroundColor: TP_COLORS.white,
  '&:hover': {
    borderRadius: '4px',
    backgroundColor: TP_COLORS.white,
  },
  '&:active': {
    borderRadius: '4px',
    backgroundColor: TP_COLORS.white,
  },
  '&:focus': {
    borderRadius: '4px',
    backgroundColor: TP_COLORS.white,
  },
});

const emptyText = 'Not Specified';

export const renderDetailItem = ({
  isMobile = false,
  label = '',
  value = '',
}: {
  isMobile: boolean;
  label: string;
  value: string;
}): JSX.Element => {
  if (isMobile) {
    return (
      <Card
        elevation={0}
        style={{ backgroundColor: TP_COLORS.listItemGray.light }}
      >
        <CardContent style={{ padding: '10px' }}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="subtitle2" color="secondary" fontWeight={500}>
              {label}
            </Typography>
            <Typography
              variant="subtitle2"
              className="ellipsis-text"
              fontWeight={600}
              style={{ marginLeft: '10px' }}
            >
              {value}
            </Typography>
          </Stack>
        </CardContent>
      </Card>
    );
  }

  return (
    <DetailViewCard title={label} description={value} {...detailViewProps} />
  );
};

const PaymentEventListItem: React.FC<PaymentEventListItemProps> = ({
  classData,
  onChecked,
  onEdit,
  paymentType,
}) => {
  const isMobile = isMobileScreen();
  const {
    isChecked,
    subject,
    classStart,
    duration,
    numberOfClasses,
  } = classData;
  const durationText = getDurationTextFromMinutes(duration || 0);
  const recurrence = classStart
    ? `Weekly on ${moment(classStart).format('dddd')}`
    : emptyText;

  const startDate = classStart
    ? moment(classStart).format('DD MMM YYYY (ddd)')
    : 'Select Date';

  const startTime = classStart
    ? moment(classStart).format('hh:mm A')
    : 'Select Time';

  if (isMobile) {
    return (
      <Card elevation={2}>
        <CardContent style={{ padding: '10px' }}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
              >
                {onChecked ? (
                  <Checkbox
                    color="default"
                    style={{ padding: 0 }}
                    checked={classData.isChecked}
                    onClick={onChecked}
                  />
                ) : (
                  <div />
                )}
                <Button
                  disableElevation
                  variant="contained"
                  size="small"
                  color="inherit"
                  startIcon={<EditIcon />}
                  onClick={onEdit}
                  sx={{
                    background: TP_COLORS.paperGray.light,
                    color: 'secondary.main',
                  }}
                >
                  Edit Class
                </Button>
              </Stack>
            </Grid>
            <Grid item xs={12}>
              <Card elevation={0} style={{ background: SubjectColors[0] }}>
                <CardContent style={{ padding: '10px' }}>
                  <Typography
                    textAlign="center"
                    fontWeight={600}
                    color={TP_COLORS.white}
                    className="ellipsis-text"
                  >
                    {subject}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12}>
              {renderDetailItem({
                isMobile: true,
                label: 'Recurrence',
                value: recurrence,
              })}
            </Grid>
            <Grid item xs={12}>
              {renderDetailItem({
                isMobile: true,
                label: 'Start Date',
                value: startDate,
              })}
            </Grid>
            <Grid item xs={12}>
              {renderDetailItem({
                isMobile: true,
                label: 'Start Time',
                value: startTime,
              })}
            </Grid>
            <Grid item xs={12}>
              {renderDetailItem({
                isMobile: true,
                label: 'Duration',
                value: durationText,
              })}
            </Grid>
            {paymentType === 'flexible' && (
              <Grid item xs={12}>
                {renderDetailItem({
                  isMobile: true,
                  label: 'No of Classes',
                  value: (numberOfClasses && numberOfClasses.toString()) || '0',
                })}
              </Grid>
            )}
          </Grid>
        </CardContent>
      </Card>
    );
  }

  return (
    <Card elevation={0} style={{ backgroundColor: TP_COLORS.paperGray.light }}>
      <CardContent style={{ padding: '10px' }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Card elevation={0} style={{ background: SubjectColors[0] }}>
              <CardContent style={{ padding: '10px' }}>
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  {onChecked ? (
                    <Checkbox
                      checked={isChecked}
                      icon={<SquareIcon style={{ borderRadius: '12px' }} />}
                      onClick={onChecked}
                      style={{
                        padding: 0,
                        color: TP_COLORS.white,
                      }}
                    />
                  ) : (
                    <div />
                  )}
                  <Typography
                    variant="subtitle2"
                    color={TP_COLORS.white}
                    fontWeight={600}
                    className="ellipsis-text"
                  >
                    {subject}
                  </Typography>
                  <DesktopEditButton size="small" onClick={onEdit}>
                    <EditIcon />
                  </DesktopEditButton>
                </Stack>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={3}>
            {renderDetailItem({
              isMobile: false,
              label: 'Recurrence',
              value: recurrence,
            })}
          </Grid>
          <Grid item xs={12} sm={3}>
            {renderDetailItem({
              isMobile: false,
              label: 'Start Date',
              value: startDate,
            })}
          </Grid>
          <Grid item xs={12} sm={paymentType === 'flexible' ? 2 : 3}>
            {renderDetailItem({
              isMobile: false,
              label: 'Start Time',
              value: startTime,
            })}
          </Grid>
          <Grid item xs={12} sm={paymentType === 'flexible' ? 2 : 3}>
            {renderDetailItem({
              isMobile: false,
              label: 'Duration',
              value: durationText,
            })}
          </Grid>
          {paymentType === 'flexible' && (
            <Grid item xs={12} sm={paymentType === 'flexible' ? 2 : 3}>
              {renderDetailItem({
                isMobile: false,
                label: 'No of Classes',
                value: (numberOfClasses && numberOfClasses.toString()) || '0',
              })}
            </Grid>
          )}
        </Grid>
      </CardContent>
    </Card>
  );
};

export default PaymentEventListItem;
