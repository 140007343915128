import React from 'react';
import {
  Card,
  CardActionArea,
  CardContent,
  Grid,
  IconButton,
  Stack,
  SxProps,
  Theme,
  Typography,
  TypographyProps,
} from '@mui/material';
import moment from 'moment';
// ICONS
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
// COMPONENTS
import DetailViewCard from '../../../components/Card/DetailViewCard';
// HELPERS
import { getSubjectColor, isMobileScreen } from '../../../helper';
// CONSTANTS
import { ICustomInvoiceSubject } from '../BillingView';
import { TP_COLORS } from '../../../constants';

interface ListItemProps {
  invoiceSubject: ICustomInvoiceSubject;
  onClick?: () => void;
  isDisabled?: boolean;
  handleDelete?: () => void;
}

// STYLES
const defaultContainerStyles: SxProps<Theme> = {
  mb: '15px',
};

const defaultTitleProps: TypographyProps = {
  variant: 'caption',
  color: 'secondary',
  className: 'ellipsis-text',
};

const defaultDescriptionProps: TypographyProps = {
  variant: 'subtitle2',
  fontWeight: 600,
  className: 'ellipsis-text',
};

const defaultDetailViewCardContainerProps: React.CSSProperties = {
  backgroundColor: TP_COLORS.white,
  margin: 0,
};

const ListItem: React.FC<ListItemProps> = ({
  invoiceSubject,
  onClick,
  isDisabled,
  handleDelete,
}) => {
  // BOOLEANS
  const isMobile = isMobileScreen();

  const {
    subject,
    selectedClassDate,
    lastClassEnd,
    lastClassStart,
    numberOfClasses,
    duration,
  } = invoiceSubject;

  // START DATE
  const startDate = selectedClassDate
    ? moment(selectedClassDate).format('DD MMM YYYY (ddd)')
    : 'Select Date';

  // STATE TIME
  const startTime = selectedClassDate
    ? moment(selectedClassDate).format('hh:mm A')
    : 'Select Time';

  // RECURRENCE
  const recurrence = selectedClassDate
    ? `Weekly on ${moment(selectedClassDate).format('dddd')}`
    : 'Not Specified';

  // DURATION
  let _durationText = 'No Specified';

  if (lastClassEnd && lastClassStart) {
    const hours = Math.trunc(duration / 60);
    const mins = Math.round(duration % 60);

    const hourText =
      hours > 0
        ? `${hours.toString().padStart(2, '0')} Hour${hours > 1 ? 's' : ''}`
        : '';
    const minText =
      mins > 0
        ? `${mins.toString().padStart(2, '0')} Min${mins > 1 ? 's' : ''}`
        : '';

    _durationText = `${hourText} ${mins > 0 ? minText : ''}`;
  }

  // NUMBER OF CLASSES
  const _numberOfClasses =
    numberOfClasses || numberOfClasses === 0
      ? `${numberOfClasses.toString()} Class${numberOfClasses > 1 ? 'es' : ''}`
      : 'Not Specified';

  if (isMobile) {
    return (
      <Card sx={{ m: '0 -11px 16px -11px' }}>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <DetailViewCard
                description={subject || '-'}
                descriptionProps={{
                  variant: 'subtitle2',
                  fontWeight: 600,
                  color: TP_COLORS.white,
                  className: 'ellipsis-text',
                }}
                containerProps={{
                  style: {
                    background: getSubjectColor(subject),
                    margin: 0,
                  },
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <DetailViewCard
                descriptionComponent={
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Typography color="secondary" fontWeight={500}>
                      Start Date:
                    </Typography>
                    <Typography fontWeight={500}>{startDate}</Typography>
                  </Stack>
                }
                containerProps={{
                  style: {
                    backgroundColor: TP_COLORS.paperGray.light,
                    margin: 0,
                  },
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <DetailViewCard
                descriptionComponent={
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Typography color="secondary" fontWeight={500}>
                      Start Time:
                    </Typography>
                    <Typography fontWeight={500}>{startTime}</Typography>
                  </Stack>
                }
                containerProps={{
                  style: {
                    backgroundColor: TP_COLORS.paperGray.light,
                    margin: 0,
                  },
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <DetailViewCard
                descriptionComponent={
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Typography color="secondary" fontWeight={500}>
                      Recurrence:
                    </Typography>
                    <Typography fontWeight={500}>{recurrence}</Typography>
                  </Stack>
                }
                containerProps={{
                  style: {
                    backgroundColor: TP_COLORS.paperGray.light,
                    margin: 0,
                  },
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <DetailViewCard
                descriptionComponent={
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Typography color="secondary" fontWeight={500}>
                      Number of classes:
                    </Typography>
                    <Typography fontWeight={500}>{_numberOfClasses}</Typography>
                  </Stack>
                }
                containerProps={{
                  style: {
                    backgroundColor: TP_COLORS.paperGray.light,
                    margin: 0,
                  },
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="center"
                width="100%"
              >
                <IconButton onClick={onClick} disabled={isDisabled}>
                  <EditIcon
                    color="secondary"
                    style={{
                      padding: '8px',
                      borderRadius: '7px',
                    }}
                  />
                </IconButton>
                <IconButton
                  color="error"
                  onClick={handleDelete}
                  disabled={isDisabled}
                >
                  <DeleteIcon
                    sx={{
                      padding: '8px',
                      borderRadius: '7px',
                    }}
                  />
                </IconButton>
              </Stack>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    );
  }

  return (
    <Card elevation={0} sx={defaultContainerStyles}>
      <CardContent style={{ padding: '0' }}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} sm={10} lg={11}>
            <CardActionArea onClick={onClick} disabled={isDisabled}>
              <Grid
                container
                alignItems="center"
                spacing={2}
                style={{
                  padding: '16px',
                  backgroundColor: TP_COLORS.paperGray.light,
                }}
              >
                <Grid item xs={12}>
                  <DetailViewCard
                    title="Subject"
                    description={subject}
                    titleProps={{
                      ...defaultTitleProps,
                      color: TP_COLORS.white,
                    }}
                    descriptionProps={{
                      ...defaultDescriptionProps,
                      color: TP_COLORS.white,
                    }}
                    containerProps={{
                      style: {
                        background: getSubjectColor(subject),
                        margin: 0,
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={3}>
                  <DetailViewCard
                    title="Start Date"
                    description={startDate}
                    titleProps={defaultTitleProps}
                    descriptionProps={defaultDescriptionProps}
                    containerProps={{
                      style: defaultDetailViewCardContainerProps,
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={2}>
                  <DetailViewCard
                    title="Start Time"
                    description={startTime}
                    titleProps={defaultTitleProps}
                    descriptionProps={defaultDescriptionProps}
                    containerProps={{
                      style: defaultDetailViewCardContainerProps,
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={3}>
                  <DetailViewCard
                    title="Recurrence"
                    description={recurrence}
                    titleProps={defaultTitleProps}
                    descriptionProps={defaultDescriptionProps}
                    containerProps={{
                      style: defaultDetailViewCardContainerProps,
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={2}>
                  <DetailViewCard
                    title="Duration"
                    description={_durationText}
                    titleProps={defaultTitleProps}
                    descriptionProps={defaultDescriptionProps}
                    containerProps={{
                      style: defaultDetailViewCardContainerProps,
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={2}>
                  <DetailViewCard
                    title="Number of classes"
                    description={_numberOfClasses}
                    titleProps={defaultTitleProps}
                    descriptionProps={defaultDescriptionProps}
                    containerProps={{
                      style: defaultDetailViewCardContainerProps,
                    }}
                  />
                </Grid>
              </Grid>
            </CardActionArea>
          </Grid>
          <Grid item xs={12} sm={2} lg={1} display={{ xs: 'none', sm: 'flex' }}>
            <Stack>
              <IconButton onClick={onClick} disabled={isDisabled}>
                <EditIcon
                  color="secondary"
                  style={{
                    backgroundColor: TP_COLORS.paperGray.light,
                    padding: '8px',
                    borderRadius: '7px',
                  }}
                />
              </IconButton>
              <IconButton
                color="error"
                onClick={handleDelete}
                disabled={isDisabled}
              >
                <DeleteIcon
                  sx={{
                    backgroundColor: 'error.light',
                    padding: '8px',
                    borderRadius: '7px',
                  }}
                />
              </IconButton>
            </Stack>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default ListItem;
