import React from 'react';
import { CircularProgress, CircularProgressProps } from '@mui/material';

export interface FullScreenLoaderProps {
  containerProps?: React.HTMLAttributes<HTMLDivElement>;
  circularProgressProps?: CircularProgressProps;
}

const defaultContainerStyles: React.CSSProperties = {
  width: '100vw',
  height: '100vh',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

const FullScreenLoader: React.FC<FullScreenLoaderProps> = ({
  containerProps,
  circularProgressProps,
}) => {
  return (
    <div
      {...containerProps}
      style={
        containerProps && containerProps.style
          ? { ...defaultContainerStyles, ...containerProps.style }
          : defaultContainerStyles
      }
    >
      <CircularProgress
        {...circularProgressProps}
        color={
          circularProgressProps && circularProgressProps.color
            ? circularProgressProps.color
            : 'primary'
        }
      />
    </div>
  );
};

export default FullScreenLoader;
