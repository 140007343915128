import React from 'react';
import {
  Card,
  CardActionArea,
  CardContent,
  Stack,
  Typography,
} from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';

import { IStripeCard } from '../../../store/Stripe';
import { getCardImageData } from '../../../helper';

interface StripeCardListItemProps {
  checked?: boolean;
  card: IStripeCard;
  onClick: () => void;
}

const StripeCardListItem: React.FC<StripeCardListItemProps> = ({
  checked,
  card,
  onClick,
}) => {
  const cardData = card.card;
  const imageData = getCardImageData(cardData.brand);

  return (
    <Card elevation={0}>
      <CardActionArea onClick={onClick}>
        <CardContent style={{ paddingBottom: '16px' }}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Stack direction="row" alignItems="center">
              <img
                src={imageData.src}
                alt={imageData.alt}
                className="start-subscription-btn-card-logo"
              />
              <Typography
                fontWeight={600}
                variant="h6"
                style={{ margin: '0 15px' }}
              >
                &#8226;&#8226;&#8226;&#8226;
              </Typography>
              <Typography fontWeight={500}>{cardData.last4}</Typography>
            </Stack>
            {checked ? (
              <Stack direction="row">
                <CheckIcon color="secondary" />
              </Stack>
            ) : null}
          </Stack>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default StripeCardListItem;
