import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import {
  Dialog,
  Drawer,
  Box,
  Theme,
  SxProps,
  Typography,
  Grid,
  TypographyProps,
  Button,
  FormControl,
  Stack,
  FormControlLabel,
  Radio,
  Collapse,
  Card,
  CardActionArea,
  CardContent,
  Divider,
  Checkbox,
  DialogContent,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import {
  Frames,
  FramesProps,
  CardNumber,
  ExpiryDate,
  Cvv,
  FrameCardTokenizedEvent,
} from 'frames-react';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
// HOOKS
import { useAppSelector } from '../../../hooks';
// STORE
import {
  getInvoiceState,
  hidePaymentCardModal,
} from '../../../store/InvoiceSlice';
import {
  checkoutPayment,
  getCheckoutCards,
  getCheckoutState,
  ICheckoutCard,
  ICheckoutPayment,
} from '../../../store/CheckoutSlice';
import { getHitPayState } from '../../../store/HitPaySlice';
import { getPromoCodeState } from '../../../store/PromoCodeSlice';
// COMPONENTS
import BasicLoader from '../../../components/Loaders/BasicLoader';
// MODALS
import { getCardTypeImageData } from '../../Payment/modals/PaymentModal';
// HELPERS
import { isMobileScreen, SafeAreaGaps } from '../../../helper';
// CONSTANTS
import { Countries, TP_COLORS } from '../../../constants';
import { getUserState } from '../../../store/UserSlice';
import {
  applePayVersion,
  label,
  merchantCapabilities,
  supportedCountries,
  supportedNetworks,
} from '../../../constants/applePay';
import {
  applePayValidateSession,
  getApplePayState,
} from '../../../store/ApplePaySlice';

export interface PaymentDetailsModalProps {
  open: boolean;
  onClose: () => void;
  userId: string | undefined;
}

const otherPaymentOptions = [{ label: 'Pay Now', value: 'Pay Now' }];

// STYLES
const headerContainerStyles: SxProps<Theme> = {
  p: '15px',
  backgroundColor: 'primary.main',
  backgroundImage: "url('/images/objects/transparent-pot.png')",
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'bottom left',
  backgroundSize: '100px',
};
const bodyContainerStyles: SxProps<Theme> = {
  backgroundColor: 'background.paper',
  p: { xs: '30px 15px', sm: '30px' },
  overflowY: 'auto',
};
const labelStyles: TypographyProps = {
  variant: 'subtitle2',
  fontWeight: 600,
  mb: '3px',
};

const addCardFormContainerStyles: React.CSSProperties = {
  backgroundColor: TP_COLORS.white,
  borderRadius: '7px',
  marginBottom: '15px',
  border: '1px solid rgb(240, 243, 245)',
};

const radioOptionStyles: SxProps<Theme> = {
  fontWeight: 600,
  borderRadius: '7px',
  mb: isMobileScreen() ? '10px' : { xs: '10px', sm: '0' },
  backgroundColor: isMobileScreen()
    ? TP_COLORS.white
    : {
        xs: 'background.default',
      },
};

const cardNumberStyles: React.CSSProperties = {
  margin: '0',
  borderRadius: '4px',
  background: TP_COLORS.white,
  height: '35px',
  padding: '5px 10px',
  borderBottom: '1px solid #f0f3f5',
  borderTop: '1px solid #f0f3f5',
  borderLeft: '1px solid #f0f3f5',
  borderRight: '1px solid #f0f3f5',
};

const footerContainerStyles: SxProps<Theme> = {
  backgroundColor: TP_COLORS.listItemGray.light,
  p: { xs: '15px 15px', sm: '15px' },
  overflowY: 'auto',
  maxHeight: '78px',
};

const cardExpiryStyles: React.CSSProperties = {
  margin: '0',
  borderRadius: '4px',
  background: TP_COLORS.white,
  height: '35px',
  padding: '5px 10px',
  borderLeft: '1px solid #f0f3f5',
  borderRight: '1px solid #f0f3f5',
  borderBottom: '1px solid #f0f3f5',
};

const cardCvvStyles: React.CSSProperties = {
  margin: '0',
  borderRadius: '4px',
  background: TP_COLORS.white,
  height: '35px',
  padding: '5px 10px',
  borderRight: '1px solid #f0f3f5',
  borderBottom: '1px solid #f0f3f5',
};

const applePayButtonStyles: React.CSSProperties = {
  width: '100px',
  height: '30px',
  WebkitAppearance: '-apple-pay-button',
  cursor: 'pointer',
};

// DATA
const frameConfig: FramesProps['config'] = {
  debug: true,
  publicKey: process.env.REACT_APP_CHECKOUT_PUBLIC_KEY || '',
  localization: {
    cardNumberPlaceholder: 'Card number',
    expiryMonthPlaceholder: 'MM',
    expiryYearPlaceholder: 'YY',
    cvvPlaceholder: 'CVV',
  },
  style: {
    base: { fontSize: '17px' },
  },
};

const getCountryCode = (countryName) => {
  const countryCode = Countries.find((country) => country.value === countryName)
    ?.countryCode;

  return countryCode ?? 'SG';
};

const PaymentDetailsModal: React.FC<PaymentDetailsModalProps> = ({
  open,
  onClose,
  userId,
}) => {
  // HOOKS
  const dispatch = useDispatch();
  const params = useParams();
  const location = useLocation();

  // STORE
  const checkoutState = useAppSelector(getCheckoutState);
  const promoCodeState = useAppSelector(getPromoCodeState);
  const hitPayState = useAppSelector(getHitPayState);
  const invoiceState = useAppSelector(getInvoiceState);
  const userState = useAppSelector(getUserState);
  const applePayState = useAppSelector(getApplePayState);

  const promoCodeData = promoCodeState.promoCode;

  // STATES
  const [checkoutButtonLoading, setCheckoutButtonLoading] = useState(false);
  const [checkoutButtonEnabled, setCheckoutButtonEnabled] = useState(false);
  const [selectedOption, setSelectedOption] = useState(2);
  const [isCollapsable, setIsCollapsable] = useState(true);
  const [isSaveCard, setIsSaveCard] = useState(true);
  const [callCheckout, setCallCheckout] = useState(false);
  const [selectedCard, setSelectedCard] = useState<string | undefined>(
    undefined
  );

  const [checkoutEvent, setCheckoutEvent] = useState<
    FrameCardTokenizedEvent | undefined
  >(undefined);

  const [
    applePaySession,
    setApplePaySession,
  ] = useState<ApplePaySession | null>(null);

  const isMobile = isMobileScreen();
  const queryStringParams = new URLSearchParams(location.search);
  const checkoutCards = checkoutState.cards || [];
  const uniqSelectedCards: ICheckoutCard[] = [];
  const collectorId = queryStringParams.get('collectorId');

  if (checkoutCards && checkoutCards.length > 0) {
    checkoutCards.forEach((item) => {
      if (!uniqSelectedCards.some((c) => c.scheme === item.scheme)) {
        uniqSelectedCards.push(item);
      }
    });
  }

  // METHODS
  const handleClose = () => {
    dispatch(hidePaymentCardModal());
    onClose();
  };

  // USE EFFECT
  useEffect(() => {
    if (userId) {
      dispatch(getCheckoutCards(userId));
    }
  }, []);

  useEffect(() => {
    if (checkoutState.cards && checkoutState.cards.length > 0) {
      setSelectedOption(1);
    }
  }, [checkoutState.cards]);

  /* NOTE: This function is used to call the handleCardTokenized function by changing the 'callCheckout' local state value from the
  Frames component prop. Because 'Frames' RENDERS ONLY ONCE WHEN THE MAIN COMPONENT MOUNTS */
  useEffect(() => {
    if (callCheckout && checkoutEvent) {
      handleSubmit();
      setCallCheckout(false);
    }
  }, [callCheckout, checkoutEvent]);

  useEffect(() => {
    if (applePaySession) {
      handleSubmit();
    }
  }, [applePayState.validation]);

  const handleSubmit = () => {
    const isOtherPaymentOptionSelected = otherPaymentOptions.some(
      (opo) => opo.value === selectedCard
    );

    if (isOtherPaymentOptionSelected) {
      // INVOKE HITPAY PAYMENT API
      if (selectedCard === 'Pay Now' && params && params.id) {
        // NOTHING
      }
    } else if (
      // INVOKE CHECKOUT PAYMENT API
      !isOtherPaymentOptionSelected &&
      userId &&
      params &&
      params.id
    ) {
      const API_DATA: ICheckoutPayment = {
        invoiceId: params.id,
      };

      if (collectorId) {
        API_DATA.collectorId = collectorId;
      }

      if (selectedOption === 1) {
        API_DATA.sourceId = selectedCard;
      } else if (selectedOption === 2) {
        API_DATA.token = checkoutEvent?.token;
        API_DATA.saveCard = isSaveCard;
        API_DATA.issuerCountryCode = checkoutEvent?.issuer_country;
      } else if (selectedOption === 3 && applePaySession) {
        applePaySession.completeMerchantValidation(applePayState.validation);
      }

      if (
        promoCodeData &&
        promoCodeData.valid &&
        promoCodeData.promoCodeDetails &&
        promoCodeData.promoCodeDetails.promoCode
      ) {
        API_DATA.code = promoCodeData.promoCodeDetails.promoCode;
      }

      if (selectedOption === 3 && applePaySession) {
        applePaySession.onpaymentauthorized = (event) => {
          const applePaymentToken = event.payment.token;

          API_DATA.applePaymentToken = applePaymentToken;

          dispatch(checkoutPayment(API_DATA));
        };
      } else {
        dispatch(checkoutPayment(API_DATA));
      }
    }

    handleClose();
  };

  const clickApplePay = () => {
    const countryCode = getCountryCode(userState.user?.country);
    const currencyCode =
      invoiceState.invoice && invoiceState.invoice.currency
        ? invoiceState.invoice.currency.toUpperCase()
        : '';

    const session = new ApplePaySession(applePayVersion, {
      supportedNetworks,
      merchantCapabilities,
      countryCode,
      currencyCode,
      total: { label, amount: getPrice().toString() },
    });

    session.begin();
    session.onvalidatemerchant = (event) => {
      const validationUrl = event.validationURL;
      dispatch(applePayValidateSession(validationUrl));
    };

    setApplePaySession(session);
    setSelectedOption(3);
  };

  const setSelectedOptions = (option: number) => {
    setSelectedOption(option);
    setSelectedCard(undefined);
    setIsSaveCard(true);
    setCallCheckout(false);
    setIsCollapsable(true);
  };

  const getPrice = () => {
    if (
      promoCodeData &&
      promoCodeData.price > 0 &&
      promoCodeData.promoCodeDetails &&
      promoCodeData.promoCodeDetails.promotionType !== 'BONUS HOURS' &&
      promoCodeData &&
      promoCodeData.discount &&
      promoCodeData.price
    ) {
      return promoCodeData.price;
    }

    if (
      invoiceState.invoice &&
      invoiceState.invoice.qty &&
      invoiceState.invoice.rate &&
      invoiceState.invoice.qty * invoiceState.invoice.rate
    ) {
      return invoiceState.invoice.rate;
    }

    return 0;
  };

  const renderApplePayOption = () => {
    let applePayIsSupported = true;

    if (userState.user && userState.user.country) {
      applePayIsSupported = supportedCountries.includes(userState.user.country);
    }

    if (window.ApplePaySession && applePayIsSupported) {
      return (
        <>
          <Divider />
          <div style={{ padding: '15px' }}>
            <Stack direction="row" alignItems="center">
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <FormControlLabel
                      value="add"
                      control={<Radio />}
                      label="Apple Pay"
                      sx={radioOptionStyles}
                      checked={selectedOption === 3}
                      onChange={() => setSelectedOptions(3)}
                      onClick={() => setSelectedOptions(3)}
                    />
                    <div
                      aria-hidden="true"
                      style={{ ...applePayButtonStyles }}
                      onClick={() => clickApplePay()}
                      onKeyDown={(event) => event.preventDefault()}
                    />
                  </Stack>
                </Grid>
              </Grid>
            </Stack>
          </div>
        </>
      );
    }

    return null;
  };

  const RENDER_CONTENT = () => {
    return (
      <div role="presentation">
        {/* HEADER  */}
        <Box sx={headerContainerStyles}>
          <div style={{ textAlign: 'center' }}>
            <img
              src="/images/logos/tp-logo-white.png"
              alt="Tutopiya-Logo"
              width="120px"
            />
          </div>
          <div style={{ textAlign: 'center', marginTop: '10px' }}>
            <Typography variant="h5" color={TP_COLORS.white}>
              Total Amount
            </Typography>
            {promoCodeData &&
            promoCodeData.price > 0 &&
            promoCodeData.promoCodeDetails &&
            promoCodeData.promoCodeDetails.promotionType !== 'BONUS HOURS' ? (
              <Typography variant="h4" color={TP_COLORS.white} fontWeight={600}>
                {invoiceState.invoice && invoiceState.invoice.currency
                  ? invoiceState.invoice.currency.toUpperCase()
                  : ''}
                &nbsp;
                {promoCodeData &&
                  promoCodeData.discount &&
                  promoCodeData.price &&
                  promoCodeData.price}
              </Typography>
            ) : (
              <Typography variant="h4" color={TP_COLORS.white} fontWeight={600}>
                {invoiceState.invoice && invoiceState.invoice.currency
                  ? invoiceState.invoice.currency.toUpperCase()
                  : ''}
                &nbsp;
                {invoiceState.invoice &&
                  invoiceState.invoice.qty &&
                  invoiceState.invoice.rate &&
                  invoiceState.invoice.qty * invoiceState.invoice.rate}
              </Typography>
            )}
          </div>
        </Box>
        <Box sx={bodyContainerStyles}>
          <div style={addCardFormContainerStyles}>
            <div style={{ padding: '15px' }}>
              <div>
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <FormControlLabel
                    value="saved"
                    control={<Radio />}
                    label="Saved Cards"
                    sx={radioOptionStyles}
                    checked={selectedOption === 1}
                    onChange={() => setSelectedOptions(1)}
                  />
                  <div>
                    {uniqSelectedCards.map((checkoutCard, index) => {
                      // PAYMENT TYPE IMAGE
                      const imageProps = getCardTypeImageData(
                        checkoutCard.scheme
                      );

                      return (
                        <img key={index} {...imageProps} alt={imageProps.alt} />
                      );
                    })}
                  </div>
                </Stack>
              </div>
              <Collapse in={selectedOption === 1 && isCollapsable}>
                {selectedOption === 1 && (
                  <div style={{ marginBottom: '15px' }}>
                    {/* CARD LOADER  */}
                    {checkoutState.status === 'loading' && (
                      <BasicLoader
                        containerProps={{ style: { marginBottom: '15px' } }}
                      />
                    )}

                    {/* CARD LIST  */}
                    <Grid container spacing={2}>
                      {checkoutCards.map((checkoutCard, index) => {
                        // IS SELECTED
                        const _isSelected = selectedCard === checkoutCard.id;

                        // ICONS
                        let _icon = (
                          <RadioButtonUncheckedIcon color="secondary" />
                        );

                        // STYLES
                        const _cardStyles: SxProps<Theme> = {
                          border: '1px solid #dbdbdb',
                          backgroundColor: '@fff',
                        };

                        // WHEN SELECTED

                        if (_isSelected) {
                          _cardStyles.border = '1px solid #0cb9ab';
                          _cardStyles.backgroundColor = '#d9f4f3';
                          _icon = <RadioButtonCheckedIcon color="primary" />;
                        }

                        // PAYMENT TYPE IMAGE
                        const imageProps = getCardTypeImageData(
                          checkoutCard.scheme
                        );

                        return (
                          <Grid
                            key={index}
                            item
                            xs={12}
                            sm={12}
                            md={12}
                            lg={12}
                          >
                            <Card elevation={0} sx={_cardStyles}>
                              <CardActionArea
                                onClick={() => setSelectedCard(checkoutCard.id)}
                              >
                                <CardContent>
                                  <Stack
                                    direction="row"
                                    alignItems="center"
                                    justifyContent="space-between"
                                  >
                                    <Stack direction="row" alignItems="center">
                                      <img
                                        {...imageProps}
                                        alt={imageProps.alt}
                                      />
                                      <Typography variant="subtitle2">
                                        xxxx xxxx xxxx {checkoutCard.last4}
                                      </Typography>
                                    </Stack>
                                    {_icon}
                                  </Stack>
                                </CardContent>
                              </CardActionArea>
                            </Card>
                          </Grid>
                        );
                      })}
                    </Grid>
                  </div>
                )}
              </Collapse>
            </div>
            <Divider />
            <div style={{ padding: '15px' }}>
              {/* ADD CARD BUTTON  */}
              <div>
                <Stack direction="row" alignItems="center">
                  <FormControlLabel
                    value="add"
                    control={<Radio />}
                    label="New Credit/Debit Card"
                    sx={radioOptionStyles}
                    checked={selectedOption === 2}
                    onChange={() => setSelectedOptions(2)}
                    onClick={() => setSelectedOptions(2)}
                  />
                </Stack>
              </div>

              {/* ADD CARD FORM  */}
              <Collapse in={selectedOption === 2 && isCollapsable}>
                <div>
                  {selectedOption === 2 && (
                    <Frames
                      config={frameConfig}
                      cardSubmitted={() =>
                        setCheckoutButtonLoading(!checkoutButtonLoading)
                      }
                      cardTokenized={(event: FrameCardTokenizedEvent) => {
                        if (event && event.token) {
                          setCheckoutEvent(event);
                          setCallCheckout(true);
                        }
                      }}
                      cardValidationChanged={(e) =>
                        setCheckoutButtonEnabled(e.isValid)
                      }
                    >
                      <div>
                        <Grid container>
                          <Grid item xs={12}>
                            <FormControl fullWidth>
                              <Typography {...labelStyles}>
                                Card Information
                              </Typography>
                              <CardNumber
                                placeholder="0000 0000 0000 0000"
                                style={{
                                  ...cardNumberStyles,
                                }}
                              />
                            </FormControl>
                          </Grid>
                          <Grid item xs={12}>
                            <FormControl fullWidth>
                              <Stack direction="row">
                                <ExpiryDate
                                  placeholder="MM/YY"
                                  style={{
                                    ...cardExpiryStyles,
                                  }}
                                />
                                <Cvv
                                  placeholder="Enter here..."
                                  style={{
                                    ...cardCvvStyles,
                                  }}
                                />
                              </Stack>
                            </FormControl>
                          </Grid>
                        </Grid>
                        <Stack
                          direction="row"
                          justifyContent="flex-start"
                          alignItems="center"
                          style={{ marginTop: '15px' }}
                        >
                          <FormControlLabel
                            control={
                              <Checkbox
                                inputProps={{ 'aria-label': 'controlled' }}
                                checked={isSaveCard}
                                onChange={() => setIsSaveCard(!isSaveCard)}
                              />
                            }
                            componentsProps={{
                              typography: {
                                sx: {
                                  fontSize: '13px',
                                  fontWeight: 400,
                                },
                              },
                            }}
                            style={{ fontSize: '13px' }}
                            label="Save this card for future transactions"
                          />
                        </Stack>
                      </div>
                    </Frames>
                  )}
                </div>
              </Collapse>
            </div>
            {renderApplePayOption()}
          </div>
        </Box>
        <Box sx={footerContainerStyles}>
          <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            style={{ margin: '0px' }}
          >
            <Grid container spacing={{ xs: 1, sm: 5, md: 8 }}>
              <Grid item xs={9} sm={9} md={8}>
                <Stack direction="column" alignItems="start">
                  <Typography style={{ fontSize: '11.5px', fontWeight: '550' }}>
                    Your personal details will only be used for processing the
                    order by the payment gateway provider
                  </Typography>
                  <img
                    src="/images/logos/checkout-logo.png"
                    alt="Checkout-Logo"
                    width="140px"
                    style={{ marginTop: '3px' }}
                  />
                </Stack>
              </Grid>
              <Grid
                item
                xs={3}
                sm={3}
                md={4}
                style={{ display: 'flex', alignItems: 'center' }}
              >
                <FormControl fullWidth style={{ maxWidth: '300px' }}>
                  <LoadingButton
                    size="small"
                    loading={
                      checkoutState.status === 'loading' ||
                      hitPayState.status === 'loading' ||
                      checkoutButtonLoading
                    }
                    disabled={
                      (selectedOption === 1 && !selectedCard) ||
                      (selectedOption === 2 && !checkoutButtonEnabled)
                    }
                    sx={{ height: '45px', borderRadius: '9px' }}
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      if (selectedOption === 1) {
                        handleSubmit();
                      } else if (selectedOption === 2) {
                        Frames.submitCard();
                      }
                    }}
                  >
                    Pay
                  </LoadingButton>
                </FormControl>
              </Grid>
            </Grid>
          </Stack>
        </Box>
        {isMobile && <div style={{ minHeight: '65px' }} />}
        {isMobile && (
          <Box
            sx={{
              backgroundColor: 'background.paper',
              position: 'fixed',
              bottom: SafeAreaGaps.bottom,
              left: SafeAreaGaps.left,
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Grid container spacing={2} sx={{ maxWidth: '400px', p: '15px' }}>
              <Grid item xs={12}>
                <Button
                  fullWidth
                  variant="contained"
                  color="inherit"
                  sx={{ color: TP_COLORS.black }}
                  onClick={handleClose}
                >
                  Cancel
                </Button>
              </Grid>
            </Grid>
          </Box>
        )}
      </div>
    );
  };

  if (isMobile) {
    return (
      <Drawer
        open={open}
        onClose={handleClose}
        anchor="bottom"
        PaperProps={{
          sx: {
            maxHeight: '95vh',
            borderTopLeftRadius: '12px',
            borderTopRightRadius: '12px',
          },
        }}
      >
        {RENDER_CONTENT()}
      </Drawer>
    );
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      style={{ overflow: 'auto' }}
      TransitionProps={{ style: { height: 'unset' } }}
      PaperProps={{
        sx: {
          width: {
            xs: 'unset',
            sm: '700px',
          },
          maxWidth: { sm: '500px', md: '580px', lg: '580px' },
          borderRadius: '12px',
        },
      }}
    >
      <DialogContent
        style={{
          padding: `0 0 ${SafeAreaGaps.bottom} 0`,
          width: '100%',
        }}
      >
        {RENDER_CONTENT()}
      </DialogContent>
    </Dialog>
  );
};

export default PaymentDetailsModal;
