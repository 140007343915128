import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import {
  PaymentElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import { Stack } from '@mui/material';
import { LoadingButton } from '@mui/lab';

import { showErrorMessage } from '../../../store/ErrorSlice';

interface StripeFormProps {
  onCardAdded: () => void;
}

const StripeForm: React.FC<StripeFormProps> = ({ onCardAdded }) => {
  const dispatch = useDispatch();
  const stripe = useStripe();
  const elements = useElements();
  const params = useParams();
  const location = useLocation();

  // State
  const [loading, setloading] = useState(false);

  const queryStringParams = new URLSearchParams(location.search);
  const userId = params.userId ? params.userId.trim() : '';
  const planKey = params.packageName ? params.packageName.trim() : '';
  const queryStringParamSubjects = (
    queryStringParams.get('subjects') || ''
  ).trim();

  const queryStringParamMinutesPerWeek = (
    queryStringParams.get('mins') || ''
  ).trim();

  const queryStringParamPromoCode = (
    queryStringParams.get('promoCode') || ''
  ).trim();

  let redirectUrl = `${process.env.REACT_APP_TUTOPIYA_BASE_URL}/subscription/${planKey}/${userId}?subjects=${queryStringParamSubjects}&mins=${queryStringParamMinutesPerWeek}`;

  if (queryStringParamPromoCode) {
    redirectUrl += `&promoCode=${queryStringParamPromoCode}`;
  }

  const handleSubmit = async (event) => {
    event.preventDefault();

    setloading(true);

    if (!stripe || !elements) {
      return;
    }

    const data = await stripe.confirmSetup({
      elements,
      redirect: 'if_required',
      confirmParams: {
        return_url: redirectUrl,
      },
    });

    if (!data.error) {
      onCardAdded();
    } else if (data.error.message) {
      dispatch(
        showErrorMessage({
          message: data.error.message,
        })
      );
    }

    setloading(false);
  };

  return (
    <form onSubmit={handleSubmit}>
      <PaymentElement />
      <div style={{ margin: '15px 0' }}>
        <Stack alignItems="center">
          <LoadingButton
            loading={loading}
            disabled={!stripe}
            type="submit"
            fullWidth
            size="large"
            variant="contained"
            color="primary"
            style={{ maxWidth: '400px' }}
          >
            Save Card
          </LoadingButton>
        </Stack>
      </div>
    </form>
  );
};

export default StripeForm;
