import React from 'react';
import { Route, Routes } from 'react-router-dom';

import MonthlyPayment from './MonthlyPayment';
import PaymentSchedulePage from './PaymentSchedulePage';
import StripeWrapper from './components/StripeWrapper';
import PaymentSessionSchedulePage from './PaymentSessionSchedulePage';
import ValidateSession from '../Invoice/ValidateSession';
import InvoiceSchedulePage from '../Invoice/InvoiceSchedulePage';

const MonthlyPaymentPage: React.FC = (): JSX.Element => {
  return (
    <div>
      <Routes>
        <Route
          path="/:packageName/:userId"
          element={
            <StripeWrapper>
              <MonthlyPayment />
            </StripeWrapper>
          }
        />
        <Route
          path="/validate/:subscriptionId"
          element={<PaymentSchedulePage />}
        />
        <Route
          path="/validate/session/:sessionId"
          element={<PaymentSessionSchedulePage />}
        />
        <Route
          path="/invoice/validate/session/:sessionId"
          element={<ValidateSession />}
        />
        <Route
          path="/invoice/schedule/:sessionId"
          element={<InvoiceSchedulePage />}
        />
      </Routes>
    </div>
  );
};

export default MonthlyPaymentPage;
