import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { ThemeProvider, Typography } from '@mui/material';
import { FlagProvider, IConfig } from '@unleash/proxy-client-react';
import TagManager from 'react-gtm-module';

import { LightTheme, DarkTheme } from './theme';
import { getThemeMode } from './store/ConfigSlice';
import { getAuthState, getClientCredentialsToken } from './store/AuthSlice';
import { useAppSelector } from './hooks';
import ErrorAlert from './components/Alert/ErrorAlert';
import SuccessAlert from './components/Alert/SuccessAlert';
import FullScreenLoader from './components/Loaders/FullScreenloader';
import InvalidRoute from './pages/Validate/InvalidRoute';
import Billing from './pages/Billing';
import Payment from './pages/Payment';
import Receipt from './pages/Receipt';
import Packages from './pages/Packages';
import MonthlyPaymentPage from './pages/MonthlyPayment';
import FeatureFlagUpdater from './components/FeatureFlag/FeatureFlagUpdater';

const featureFlagConfig: IConfig = {
  url: `${process.env.REACT_APP_FEATURE_FLAG_URL}/proxy`, // Your front-end API URL or the Unleash proxy's URL (https://<proxy-url>/proxy)
  clientKey: process.env.REACT_APP_FEATURE_FLAG_CLIENT_KEY as string, // A client-side API token OR one of your proxy's designated client keys (previously known as proxy secrets)
  refreshInterval: 0, // How often (in seconds) the client should poll the proxy for updates
  appName: process.env.REACT_APP_FEATURE_FLAG_APP_NAME as string, // The name of your application. It's only used for identifying your application
};

const App: React.FC = () => {
  const dispatch = useDispatch();

  // Store
  const themeMode = useAppSelector(getThemeMode);
  const authState = useAppSelector(getAuthState);

  // State
  const [initLoading, setInitLoading] = useState(true);

  const theme = themeMode === 'dark' ? DarkTheme : LightTheme;

  useEffect(() => {
    dispatch(getClientCredentialsToken());
  }, []);

  useEffect(() => {
    TagManager.initialize({ gtmId: 'GTM-MKD33M3' });
  }, []);

  useEffect(() => {
    if (authState.accessToken && initLoading) {
      setInitLoading(false);
    }
  }, [authState.accessToken]);

  if (initLoading) {
    return <FullScreenLoader />;
  }

  if (authState.status === 'rejected') {
    return (
      <Typography variant="h5" sx={{ m: '15px' }}>
        Authorization Failed
      </Typography>
    );
  }

  return (
    <FlagProvider config={featureFlagConfig}>
      <FeatureFlagUpdater />
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Navigate to="/invoice" />} />
            <Route path="*" element={<InvalidRoute />} />
            <Route path="/invoice/*" element={<Billing />} />
            <Route path="/payment/*" element={<Payment />} />
            <Route path="/receipt" element={<Receipt />} />
            <Route path="/packages/:userId" element={<Packages />} />
            <Route path="/subscription/*" element={<MonthlyPaymentPage />} />
          </Routes>
        </BrowserRouter>
        <ErrorAlert />
        <SuccessAlert />
      </ThemeProvider>
    </FlagProvider>
  );
};

export default App;
