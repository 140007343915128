import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import moment, { Moment as IMoment } from 'moment';
import { nanoid } from 'nanoid';
import {
  Box,
  Collapse,
  Container,
  Grid,
  LinearProgress,
  Stack,
  SxProps,
  Theme,
  Toolbar,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import {
  createStripeInvoiceClasses,
  getStripeInvoiceData,
  getStripeState,
} from '../../store/Stripe';
import { useAppSelector } from '../../hooks';
import {
  ICustomPaymentClass,
  getPaymentState,
  updateValidatedPaymentClass,
  validatePaymentClasses,
} from '../../store/PaymentSlice';
import { TP_COLORS } from '../../constants';
import { SafeAreaGaps, getDurationTextFromMinutes } from '../../helper';
import PaymentProcessingPage from '../MonthlyPayment/PaymentProcessingPage';
import SuccessView from '../MonthlyPayment/SuccessView';
import ErrorView from '../MonthlyPayment/ErrorView';
import MonthlyPaymentHeader from '../../components/Header/MonthlyPaymentHeader';
import { IInvoiceSubject } from '../../store/InvoiceSlice';
import ListItem from '../Billing/components/ListItem';
import EditClassScheduleModal from '../Billing/modals/EditClassScheduleModal';
import ScheduleConflictModal from '../Payment/modals/ScheduleConflictModal';
import { getUserById } from '../../store/UserSlice';

interface ISubjectAlertData {
  id?: string;
  isDeleteSubjectConfirmationModal: boolean;
}

export interface ICustomInvoiceSubject extends IInvoiceSubject {
  id?: string | undefined;
  duration: number;
}

const headerStyles: SxProps<Theme> = {
  boxSizing: 'border-box',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  background: '#F1FBFA',
  textAlign: 'center',
  p: '30px 15px',
  mb: '15px',
  minHeight: {
    xs: '200px',
    sm: '130px',
  },
};

const buttonContainerStyles: SxProps<Theme> = {
  padding: '15px',
  boxShadow: '0px -2px 0px 0px #00000017',
  position: 'fixed',
  bottom: SafeAreaGaps.bottom,
  width: '100%',
  maxWidth: '900px',
  backgroundColor: 'background.default',
};

const supportLink = 'https://support.tutopiya.com/portal/';

const InvoiceSchedulePage: React.FC = () => {
  const dispatch = useDispatch();
  const params = useParams();

  // Store
  const stripeState = useAppSelector(getStripeState);
  const paymentState = useAppSelector(getPaymentState);

  const [subjectList, setSubjectList] = useState<ICustomInvoiceSubject[]>([]);
  const [isSuccessScreenEnabled, setSuccessScreenEnabled] = useState(false);
  const [isErrorScreenEnabled, setErrorScreenEnabled] = useState(false);
  const [selectedClass, setSelectedClass] = useState<string | undefined>(
    undefined
  ); // payment class temp id

  const [
    subjectDeleteAlertData,
    setSubjectDeleteAleartData,
  ] = useState<ISubjectAlertData>({
    id: undefined,
    isDeleteSubjectConfirmationModal: false,
  });

  const [isScheduleConflictModalOpen, setScheduleConflictModal] = useState(
    false
  );

  const stripeSessionId = params.sessionId || '';
  const { stripeInvoiceData } = stripeState;
  const { validatedPaymentClassessData } = paymentState;

  const stripeInvoiceSession = stripeInvoiceData?.session;
  const stripeInvoice = stripeInvoiceData?.invoice;

  let errorMessage = '';
  let packageMinutes = 0; // mins
  let usedClassDuration = 0;
  let isHoursExceeded = false;
  let isClassFieldsNotSpecified = false;

  if (stripeInvoice) {
    packageMinutes = stripeInvoice.qty * 60;
  }

  subjectList.forEach((s) => {
    if (s.duration) {
      usedClassDuration += s.duration * s.numberOfClasses;
    } else {
      isClassFieldsNotSpecified = true;
    }
  });

  if (packageMinutes < usedClassDuration) {
    isHoursExceeded = true;
    errorMessage = 'Total class hours exceeds the plan hours';

    if (stripeInvoice && stripeInvoice.qty) {
      errorMessage += ` (Plan hours: ${stripeInvoice.qty})`;
    }
  }

  if (!isHoursExceeded && isClassFieldsNotSpecified) {
    errorMessage = 'Please make required selection for the class.';
  }

  const isSubmitDisabled =
    !stripeInvoice ||
    (stripeInvoice && !stripeInvoice.qty) ||
    isHoursExceeded ||
    isClassFieldsNotSpecified;

  const isScheduleEmpty = subjectList.length < 1;
  const selectedClassData = subjectList.find((c) => c.id === selectedClass);
  const isEditScheduleModalOpen = Boolean(selectedClassData);
  const paymentClasses = subjectList.map((c) => ({
    id: c.id,
    subject: c.subject,
    curriculum: c.curriculum,
    grade: c.grade,
    tutorId: c.tutorId,
    classStart: c.selectedClassDate,
    duration: c.duration,
    lastClassStart: c.lastClassStart,
    numberOfClasses: c.numberOfClasses,
    classEnd: moment(c.selectedClassDate)
      .add(c.duration, 'minutes')
      .toDate()
      .getTime(),
  })) as ICustomPaymentClass[];

  useEffect(() => {
    dispatch(
      getStripeInvoiceData({
        stripeSessionId,
      })
    );
  }, []);

  useEffect(() => {
    if (
      stripeInvoice &&
      stripeInvoice.subjects &&
      stripeInvoice.subjects.length
    ) {
      if (stripeInvoice.isClassesScheduled) {
        setSuccessScreenEnabled(true);
      } else {
        const customInvoiceSubjectList: ICustomInvoiceSubject[] = [];

        stripeInvoice.subjects.forEach((invSubject) => {
          const newInvoiceSubject: ICustomInvoiceSubject = {
            id: nanoid(),
            duration: 0,
            ...invSubject,
          };

          const start = moment(invSubject.lastClassStart).add(7, 'days');
          const nextDayFromNow = moment().add(24, 'hours');

          while (start.isBefore(nextDayFromNow)) {
            start.add(7, 'days');
          }

          if (
            newInvoiceSubject.lastClassEnd &&
            newInvoiceSubject.lastClassStart
          ) {
            newInvoiceSubject.duration =
              (newInvoiceSubject.lastClassEnd -
                newInvoiceSubject.lastClassStart) /
              60000;
          }

          newInvoiceSubject.selectedClassDate = moment(start)
            .toDate()
            .getTime();
          newInvoiceSubject.selectedNumberOfClass = invSubject.numberOfClasses;

          customInvoiceSubjectList.push(newInvoiceSubject);
        });

        setSubjectList(customInvoiceSubjectList);
      }
    }
  }, [stripeInvoiceData]);

  useEffect(() => {
    if (
      validatedPaymentClassessData &&
      validatedPaymentClassessData.showConflictModal &&
      validatedPaymentClassessData.conflictPaymentClasses.length > 0 &&
      isScheduleConflictModalOpen === false
    ) {
      setScheduleConflictModal(true);
    } else if (
      paymentState.validatedPaymentClassessData &&
      paymentState.validatedPaymentClassessData.showPaymentModal
    ) {
      dispatch(
        createStripeInvoiceClasses({
          stripeSessionId,
          subjects: subjectList.map((item) => ({
            tutorId: item.tutorId,
            subject: item.subject,
            curriculum: item.curriculum,
            grade: item.grade,
            duration: item.duration,
            selectedClassDate: item.selectedClassDate,
            selectedNumberOfClass: item.selectedNumberOfClass,
            lastClassStart: item.lastClassStart,
          })),
        })
      );

      setSuccessScreenEnabled(true);
    }
  }, [paymentState.validatedPaymentClassessData]);

  const handleUpdateClassData = (
    id: string,
    startDate: IMoment,
    duration: number,
    numberOfClasses: number
  ) => {
    const newClasses: ICustomInvoiceSubject[] = [...subjectList];
    const _selectedClassIndex = newClasses.findIndex((item) => item.id === id);

    if (_selectedClassIndex > -1) {
      const _selectedClass = newClasses[_selectedClassIndex];

      _selectedClass.duration = duration;
      _selectedClass.selectedClassDate = startDate.toDate().getTime();
      _selectedClass.numberOfClasses = numberOfClasses;
      _selectedClass.selectedNumberOfClass = numberOfClasses;

      newClasses[_selectedClassIndex] = _selectedClass;

      dispatch(
        updateValidatedPaymentClass({
          id,
          classStart: startDate.toDate().getTime(),
          classEnd: startDate.add(duration, 'minutes').toDate().getTime(),
          duration,
          numberOfClasses,
        })
      );
    }

    setSubjectList(newClasses);
  };

  const handleBook = () => {
    if (stripeInvoice && subjectList.length) {
      const _paymentClasses: ICustomPaymentClass[] = [];

      subjectList.forEach((c) => {
        if (c.id) {
          _paymentClasses.push({
            id: c.id,
            subject: c.subject,
            curriculum: c.curriculum,
            grade: c.grade,
            tutorId: c.tutorId,
            classStart: c.selectedClassDate,
            duration: c.duration,
            classEnd: moment(c.selectedClassDate)
              .add(c.duration, 'minutes')
              .toDate()
              .getTime(),
            lastClassStart: c.lastClassStart,
            numberOfClasses: c.numberOfClasses,
            maxNumberOfClasses: 0,
          });
        }
      });

      dispatch(
        validatePaymentClasses({
          userId: stripeInvoice.userId,
          paymentClasses: _paymentClasses,
        })
      );

      dispatch(getUserById(stripeInvoice.userId));
    }
  };

  if (isErrorScreenEnabled) {
    let redirectLink: string | undefined;

    if (stripeInvoiceSession && stripeInvoiceSession.cancel_url) {
      redirectLink = stripeInvoiceSession.cancel_url;
    }

    return (
      <div>
        <MonthlyPaymentHeader />
        <Toolbar />
        <ErrorView redirectLink={redirectLink} />
      </div>
    );
  }

  if (
    isSuccessScreenEnabled ||
    (stripeInvoice && stripeInvoice.isClassesScheduled)
  ) {
    return (
      <div>
        <MonthlyPaymentHeader />
        <Toolbar />
        <SuccessView />
      </div>
    );
  }

  if (stripeState.status === 'loading' && !stripeInvoiceData) {
    return (
      <div>
        <MonthlyPaymentHeader />
        <Toolbar />
        <PaymentProcessingPage />
      </div>
    );
  }

  return (
    <div>
      <MonthlyPaymentHeader />
      <Toolbar />
      <Collapse
        in={stripeState.status === 'loading'}
        sx={{
          width: '100%',
          position: 'fixed',
          top: { xs: '56px', sm: '64px' },
        }}
      >
        <LinearProgress />
      </Collapse>
      <Collapse in={Boolean(stripeInvoice)}>
        <Box sx={headerStyles}>
          <div>
            <Typography
              variant="h6"
              fontWeight={600}
              style={{ marginBottom: '5px' }}
            >
              {`Successfully purchased invoice (INV: ${
                stripeInvoice && stripeInvoice.invoiceNumber
                  ? stripeInvoice.invoiceNumber.toString().padStart(4, '0')
                  : ''
              }) ${getDurationTextFromMinutes(
                stripeInvoice ? stripeInvoice.qty * 60 : 0
              )}`}
            </Typography>
            <Typography
              variant="subtitle2"
              color="secondary"
              fontWeight={500}
              style={{ wordBreak: 'break-word' }}
            >
              Reference: {stripeInvoice?.stripeSessionId}
            </Typography>
            {isScheduleEmpty ? (
              <Typography
                variant="subtitle2"
                color="secondary"
                fontWeight={500}
              >
                Please{' '}
                <a
                  href={supportLink}
                  target="_blank"
                  rel="noreferrer"
                  style={{ color: TP_COLORS.tutopiyaGreenMain.light }}
                >
                  contact our support team
                </a>{' '}
                to book your classes
                <br />
                Code 0124
              </Typography>
            ) : (
              <Typography
                variant="subtitle2"
                color="secondary"
                fontWeight={500}
              >
                You can update your schedule here. Please ensure that the total
                duration of your classes is{' '}
                {getDurationTextFromMinutes(packageMinutes)}
              </Typography>
            )}
          </div>
        </Box>
      </Collapse>
      <Container maxWidth="lg">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Collapse in={isHoursExceeded}>
              <Typography color="error.main">
                You have exceeded the package hours
              </Typography>
            </Collapse>
            <Collapse in={isClassFieldsNotSpecified}>
              <Typography color="error.main">
                Please make required selection for the class.
              </Typography>
            </Collapse>
          </Grid>
          {subjectList.map((invoiceSubject, index) => (
            <Grid key={index} item xs={12}>
              <ListItem
                invoiceSubject={invoiceSubject}
                onClick={() => setSelectedClass(invoiceSubject.id)}
                handleDelete={() =>
                  setSubjectDeleteAleartData({
                    id: invoiceSubject.id,
                    isDeleteSubjectConfirmationModal: true,
                  })
                }
                isDisabled={!stripeInvoice || !stripeInvoice?.qty}
              />
            </Grid>
          ))}
        </Grid>
      </Container>
      {!isScheduleEmpty ? (
        <>
          <div style={{ minHeight: '150px' }} />
          <Stack alignItems="center">
            <Box sx={buttonContainerStyles}>
              <Stack alignItems="center">
                <LoadingButton
                  fullWidth
                  variant="contained"
                  color="primary"
                  style={{ maxWidth: '300px', marginBottom: '10px' }}
                  disabled={isSubmitDisabled}
                  onClick={handleBook}
                  loading={stripeState.status === 'loading'}
                >
                  Book Now
                </LoadingButton>
                <div
                  style={{
                    display: 'inline',
                    textAlign: 'center',
                    maxWidth: '95%',
                  }}
                >
                  <Typography
                    variant="caption"
                    color="secondary"
                    textAlign="center"
                  >
                    Having trouble changing your class schedule? Please&nbsp;
                  </Typography>
                  <Typography
                    variant="caption"
                    component="a"
                    href={supportLink}
                    target="_blank"
                    rel="noreferrer"
                    style={{ color: TP_COLORS.tutopiyaGreenMain.light }}
                  >
                    contact our support team
                  </Typography>
                </div>
              </Stack>
            </Box>
          </Stack>
        </>
      ) : null}
      {/* Modals */}
      {isScheduleConflictModalOpen && (
        <ScheduleConflictModal
          open={isScheduleConflictModalOpen}
          onClose={() => setScheduleConflictModal(false)}
          paymentClasses={paymentClasses}
          disableContinue={isSubmitDisabled}
          errorMessage={errorMessage}
          actions={{
            setSelectedClass: (classId) => {
              setSelectedClass(classId);
            },
          }}
        />
      )}
      {isEditScheduleModalOpen &&
        selectedClass &&
        selectedClassData &&
        stripeInvoice && (
          <EditClassScheduleModal
            open={isEditScheduleModalOpen}
            onClose={() => setSelectedClass(undefined)}
            invoiceSubject={selectedClassData}
            updateClassData={handleUpdateClassData}
            invoice={stripeInvoice}
          />
        )}
      <Dialog
        open={subjectDeleteAlertData.isDeleteSubjectConfirmationModal}
        onClose={() =>
          setSubjectDeleteAleartData({
            isDeleteSubjectConfirmationModal: false,
          })
        }
      >
        <DialogTitle>Are you sure</DialogTitle>
        <DialogContent>Do you want to delete this class?</DialogContent>
        <DialogActions>
          <Button
            variant="text"
            color="info"
            onClick={() =>
              setSubjectDeleteAleartData({
                isDeleteSubjectConfirmationModal: false,
              })
            }
          >
            No
          </Button>
          <Button
            variant="text"
            color="info"
            onClick={() => {
              if (subjectDeleteAlertData.id) {
                setSubjectList((prevClasses) =>
                  prevClasses.filter((c) => c.id !== subjectDeleteAlertData.id)
                );
              }

              setSubjectDeleteAleartData({
                isDeleteSubjectConfirmationModal: false,
              });
            }}
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default InvoiceSchedulePage;
