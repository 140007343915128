import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Collapse,
  Container,
  Grid,
  LinearProgress,
  Stack,
  SxProps,
  Theme,
  Toolbar,
  Typography,
} from '@mui/material';
import { nanoid } from 'nanoid';
import moment, { Moment as IMoment } from 'moment';

import {
  IFlexiblePlan,
  IWeeklyPlan,
  TP_COLORS,
  flexiblePlans,
  subscriptionWeeks,
  weeklyPlans,
} from '../../constants';
import {
  getStripeState,
  getStripeSubscriptionById,
  validateStripePaymentClasses,
} from '../../store/Stripe';
import {
  ICustomPaymentClass,
  getPaymentClasses,
  getPaymentState,
} from '../../store/PaymentSlice';
import { useAppSelector } from '../../hooks';
import { SafeAreaGaps, getDurationTextFromMinutes } from '../../helper';
import EditPaymentClassModal from './modals/EditPaymentClassModal';
import ScheduleConflictModal from './modals/ScheduleConflictModal';
import MonthlyPaymentHeader from '../../components/Header/MonthlyPaymentHeader';
import PaymentEventListItem from './components/PaymentEventListItem';
import PaymentEventListItemSummary from './components/PaymentEventListItemSummary';
import SuccessView from './SuccessView';
import ErrorView from './ErrorView';
import PaymentProcessingPage from './PaymentProcessingPage';
import BasicLoader from '../../components/Loaders/BasicLoader';

interface IEditClasModalData {
  open: boolean;
  item: ICustomPaymentClass;
}

const headerStyles: SxProps<Theme> = {
  boxSizing: 'border-box',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  background: '#F1FBFA',
  textAlign: 'center',
  p: '30px 15px',
  mb: '15px',
  minHeight: {
    xs: '200px',
    sm: '130px',
  },
};

const buttonContainerStyles: SxProps<Theme> = {
  padding: '15px',
  boxShadow: '0px -2px 0px 0px #00000017',
  position: 'fixed',
  bottom: SafeAreaGaps.bottom,
  width: '100%',
  maxWidth: '900px',
  backgroundColor: 'background.default',
};

const supportLink = 'https://support.tutopiya.com/portal/';

const PaymmentSchedulePage: React.FC = () => {
  const dispatch = useDispatch();
  const params = useParams();

  // Store
  const stripeState = useAppSelector(getStripeState);
  const paymentState = useAppSelector(getPaymentState);

  // State
  const [classes, setClasses] = useState<ICustomPaymentClass[]>([]);
  const [isSuccessScreenEnabled, setSuccessScreenEnabled] = useState(false);
  const [isErrorScreenEnabled, setErrorScreenEnabled] = useState(false);
  const [editClassModalData, setEditClassModalData] = useState<
    IEditClasModalData | undefined
  >(undefined);

  const [isScheduleConflictModalOpen, setScheduleConflictModalOpen] = useState(
    false
  );

  const { subscriptionData } = stripeState;
  const paymentData =
    subscriptionData && subscriptionData.paymentData
      ? subscriptionData.paymentData
      : undefined;

  const selectedClasses = classes.filter((classData) => classData.isChecked);

  let usedDuration = 0; // mins
  let packagehours = 0; // mins
  let selectedPlan: IWeeklyPlan | undefined;
  let selectedFlexiblePlan: IFlexiblePlan | undefined;
  let subscriptionHourText = '';
  let hasInvalidClasses = false;

  if (paymentData && paymentData.subscriptionType === 'flexible') {
    selectedFlexiblePlan = flexiblePlans.find(
      (plan) => plan.id === paymentData.packageId
    );
    packagehours = selectedFlexiblePlan?.hours || 0;
    subscriptionHourText = getDurationTextFromMinutes(packagehours * 60);
  } else if (paymentData) {
    packagehours = paymentData.minutesPerWeek;

    selectedPlan = weeklyPlans.find(
      (plan) => plan.id === paymentData.packageId
    );

    subscriptionHourText = getDurationTextFromMinutes(
      paymentData.minutesPerWeek
    );
  }

  selectedClasses.forEach((classData) => {
    if (classData.duration && paymentData?.subscriptionType !== 'flexible') {
      usedDuration += classData.duration;
    } else if (
      classData.duration &&
      paymentData?.subscriptionType === 'flexible'
    ) {
      usedDuration +=
        (classData.duration / 60) * (classData.numberOfClasses || 0);
    }

    if (!classData.duration || !classData.classStart) {
      hasInvalidClasses = true;
    }
  });

  const isScheduleEmpty = paymentState.status !== 'loading' && !classes?.length;
  const isPackageHoursExceeded = usedDuration > packagehours;
  const isBookingDisabled =
    isPackageHoursExceeded ||
    (selectedClasses && selectedClasses.length === 0) ||
    hasInvalidClasses;

  useEffect(() => {
    dispatch(getStripeSubscriptionById(params.subscriptionId || ''));
  }, []);

  useEffect(() => {
    if (
      subscriptionData &&
      subscriptionData.userId &&
      subscriptionData.subscription &&
      subscriptionData.subscription.status === 'active'
    ) {
      dispatch(
        getPaymentClasses({
          userId: subscriptionData.userId,
        })
      );
    } else if (
      subscriptionData &&
      subscriptionData.subscription &&
      subscriptionData.subscription.status !== 'active'
    ) {
      setErrorScreenEnabled(true);
    }
  }, [subscriptionData]);

  useEffect(() => {
    if (paymentState.classes && paymentState.classes.length) {
      const newClasses: ICustomPaymentClass[] = [];
      let packageQuantityHours = 0;

      paymentState.classes.forEach((paymentClass) => {
        const {
          lastClassEnd,
          lastClassStart,
          ...otherPaymentClassProps
        } = paymentClass;

        const newClass: ICustomPaymentClass = {
          id: nanoid(),
          duration: 60,
          isChecked: true,
          numberOfClasses: 0,
          maxNumberOfClasses: 0,
          ...otherPaymentClassProps,
        };

        if (lastClassStart && lastClassEnd) {
          newClass.duration = (lastClassEnd - lastClassStart) / 60000; // minutes
        }

        if (paymentData && paymentData.subscriptionType === 'monthly') {
          newClass.numberOfClasses = subscriptionWeeks;
        } else if (
          paymentData &&
          paymentData.subscriptionType === 'upfront' &&
          selectedPlan
        ) {
          newClass.numberOfClasses = selectedPlan.weeks;
        }

        if (lastClassStart && lastClassEnd) {
          const _nextDayFromNow = moment().add(1, 'day').toDate().getTime();
          const _time = moment(lastClassStart).format('hh:mm A');
          const _day = moment(lastClassStart).format('dddd');
          const _nextDayDate = moment(
            `${moment().day(_day).format('YYYY-MM-DD')} ${_time}`,
            'YYYY-MM-DD hh:mm A'
          );

          let _start = moment(lastClassStart).add(1, 'week');

          if (lastClassStart < _nextDayFromNow) {
            if (_nextDayFromNow < _nextDayDate.toDate().getTime()) {
              _start = _nextDayDate;
            } else {
              _start = moment(_nextDayDate).add(1, 'week');
            }
          }

          const _end = moment(_start).add(newClass.duration, 'minutes');

          newClass.lastClassStart = lastClassStart;
          newClass.classStart = _start.toDate().getTime();
          newClass.classEnd = _end.toDate().getTime();
        }

        if (selectedFlexiblePlan) {
          packageQuantityHours = Number(selectedFlexiblePlan.hours) * 60;

          newClass.maxNumberOfClasses = Math.floor(
            packageQuantityHours / newClass.duration
          );
        }

        newClasses.push(newClass);
      });

      if (newClasses.length && selectedFlexiblePlan) {
        let classIndex = 0;
        let availableMins = packageQuantityHours;
        let isProceed = true;

        while (isProceed) {
          const currentClass = newClasses[classIndex];
          const currentClassDuration = currentClass.duration;

          if (availableMins >= currentClassDuration) {
            if (currentClass.numberOfClasses) {
              currentClass.numberOfClasses += 1;
            } else {
              currentClass.numberOfClasses = 1;
            }

            availableMins -= currentClassDuration;

            classIndex += 1;

            if (newClasses.length === classIndex) {
              classIndex = 0;
            }
          } else {
            isProceed = false;
          }
        }
      }
      setClasses(newClasses);
    }
  }, [paymentState.classes]);

  useEffect(() => {
    if (
      stripeState.validatedPaymentClassessData &&
      stripeState.validatedPaymentClassessData.showConflictModal &&
      stripeState.validatedPaymentClassessData.conflictPaymentClasses.length &&
      isScheduleConflictModalOpen === false
    ) {
      toggleScheduleConflictModal();
    } else if (
      stripeState.validatedPaymentClassessData &&
      stripeState.validatedPaymentClassessData.showSuccessScreen &&
      isSuccessScreenEnabled === false
    ) {
      setSuccessScreenEnabled(true);
    }
  }, [stripeState.validatedPaymentClassessData]);

  const toggleScheduleConflictModal = () => {
    setScheduleConflictModalOpen(!isScheduleConflictModalOpen);
  };

  const handleCheck = (classId: string) => {
    const newClasses = [...classes];

    const index = newClasses.findIndex((classData) => classData.id === classId);

    if (index > -1) {
      const selectedClassData = newClasses[index];
      newClasses[index] = {
        ...selectedClassData,
        isChecked: !selectedClassData.isChecked,
      };
    }

    setClasses(newClasses);
  };

  const handleEdit = (classData: ICustomPaymentClass) => {
    setEditClassModalData({
      open: true,
      item: classData,
    });
  };

  const handleEditModalClose = () => {
    setEditClassModalData(undefined);
  };

  const handleUpdateClassData = (
    list: Array<{
      id: string;
      startDate: IMoment;
      duration: number;
      numberOfClasses?: number;
    }>
  ) => {
    const newClasses: ICustomPaymentClass[] = [...classes];

    if (list && list.length) {
      list.forEach((data) => {
        const _selectedClassIndex = newClasses.findIndex(
          (item) => item.id === data.id
        );

        if (_selectedClassIndex > -1) {
          const _selectedClass = newClasses[_selectedClassIndex]
            ? { ...newClasses[_selectedClassIndex] }
            : undefined;

          if (_selectedClass) {
            _selectedClass.duration = data.duration;
            _selectedClass.classStart = data.startDate.toDate().getTime();
            _selectedClass.classEnd = moment(data.startDate)
              .add(data.duration, 'minutes')
              .toDate()
              .getTime();
            if (data.numberOfClasses) {
              _selectedClass.numberOfClasses = data.numberOfClasses;
              usedDuration += data.numberOfClasses * data.duration;
            }

            newClasses[_selectedClassIndex] = _selectedClass;
          }
        }
      });
    }

    setClasses(newClasses);
  };

  const handleRemoveClasses = (classIdList: Array<string>) => {
    const newClasses = [...classes].filter(
      (classData) => !classIdList.includes(classData.id)
    );

    setClasses(newClasses);
  };

  const handleBook = () => {
    const paymentClasses: ICustomPaymentClass[] = classes
      .filter((paymentClass) => paymentClass.isChecked)
      .map((paymentClass) => {
        const { isChecked, ...otherPaymentClassProps } = paymentClass;

        return otherPaymentClassProps;
      });

    dispatch(
      validateStripePaymentClasses({
        paymentClasses,
        sessionId: params.subscriptionId || '',
        userId: subscriptionData?.userId || '',
      })
    );
  };

  if (isErrorScreenEnabled) {
    let redirectLink: string | undefined;

    if (
      paymentData &&
      selectedPlan &&
      paymentData.userId &&
      paymentData.subjectCount &&
      paymentData.minutesPerWeek
    ) {
      if (paymentData.subscriptionType === 'flexible' && selectedFlexiblePlan) {
        redirectLink = `${process.env.REACT_APP_TUTOPIYA_BASE_URL}/subscription/${selectedFlexiblePlan.key}/${paymentData.userId}?subjects=${paymentData.subjectCount}&mins=${paymentData.minutesPerWeek}`;
      } else {
        redirectLink = `${process.env.REACT_APP_TUTOPIYA_BASE_URL}/subscription/${selectedPlan.key}/${paymentData.userId}?subjects=${paymentData.subjectCount}&mins=${paymentData.minutesPerWeek}`;
      }

      if (
        paymentData.promoCodeData &&
        paymentData.promoCodeData.promoCodeDetails &&
        paymentData.promoCodeData.promoCodeDetails.promoCode
      ) {
        redirectLink += `&promoCode=${paymentData.promoCodeData.promoCodeDetails.promoCode}`;
      }
    }

    return (
      <div>
        <MonthlyPaymentHeader />
        <Toolbar />
        <ErrorView redirectLink={redirectLink} />
      </div>
    );
  }

  if (isSuccessScreenEnabled) {
    return (
      <div>
        <MonthlyPaymentHeader />
        <Toolbar />
        <SuccessView />
      </div>
    );
  }

  if (stripeState.status === 'loading' && !subscriptionData) {
    return (
      <div>
        <MonthlyPaymentHeader />
        <Toolbar />
        <PaymentProcessingPage />
      </div>
    );
  }

  return (
    <div>
      <MonthlyPaymentHeader />
      <Toolbar />
      <Collapse
        in={
          stripeState.status === 'loading' || paymentState.status === 'loading'
        }
        sx={{
          width: '100%',
          position: 'fixed',
          top: { xs: '56px', sm: '64px' },
        }}
      >
        <LinearProgress />
      </Collapse>
      <Collapse in={Boolean(paymentData)}>
        <Box sx={headerStyles}>
          <div>
            {paymentData?.subscriptionType !== 'flexible' && (
              <Typography
                variant="h6"
                fontWeight={600}
                style={{ marginBottom: '5px' }}
              >
                {`Successfully subscribed for ${subscriptionHourText} per week for ${
                  selectedPlan?.weeks || 0
                } weeks`}
              </Typography>
            )}
            {paymentData?.subscriptionType === 'flexible' && (
              <Typography
                variant="h6"
                fontWeight={600}
                style={{ marginBottom: '5px' }}
              >
                {`Successfully subscribed for ${subscriptionHourText} with ${selectedFlexiblePlan?.title} Plan`}
              </Typography>
            )}
            {!isScheduleEmpty &&
            classes.length &&
            paymentData?.subscriptionType !== 'flexible' ? (
              <Typography
                variant="subtitle2"
                color="secondary"
                fontWeight={500}
              >
                You can update your weekly schedule here. Please ensure that the
                total duration of your classes is {subscriptionHourText} per
                week
              </Typography>
            ) : null}
            {!isScheduleEmpty &&
            classes.length &&
            paymentData?.subscriptionType === 'flexible' ? (
              <Typography
                variant="subtitle2"
                color="secondary"
                fontWeight={500}
              >
                You can update your weekly schedule here.
              </Typography>
            ) : null}
            {isScheduleEmpty ? (
              <Typography
                variant="subtitle2"
                color="secondary"
                fontWeight={500}
              >
                Please{' '}
                <a
                  href={supportLink}
                  target="_blank"
                  rel="noreferrer"
                  style={{ color: TP_COLORS.tutopiyaGreenMain.light }}
                >
                  contact our support team
                </a>{' '}
                to book your classes
                <br />
                Code 0124
              </Typography>
            ) : null}
          </div>
        </Box>
      </Collapse>
      <Container maxWidth="lg">
        <Box sx={{ mb: '15px' }}>
          {!classes?.length && paymentState.status === 'loading' ? (
            <Stack alignItems="center" style={{ width: '100%' }}>
              <BasicLoader />
            </Stack>
          ) : null}
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Collapse in={isPackageHoursExceeded}>
                <Typography color="error.main">
                  You have exceeded the package hours
                </Typography>
              </Collapse>
              <Collapse in={hasInvalidClasses}>
                <Typography color="error.main">
                  Please make required selection for the class.
                </Typography>
              </Collapse>
            </Grid>
            {classes && classes.length ? (
              <>
                {classes.map((classData, index) => (
                  <Grid key={index} item xs={12}>
                    <PaymentEventListItem
                      classData={classData}
                      onChecked={() => handleCheck(classData.id)}
                      onEdit={() => handleEdit(classData)}
                      paymentType={paymentData?.subscriptionType || 'monthly'}
                    />
                  </Grid>
                ))}
                {paymentData?.subscriptionType !== 'flexible' && (
                  <Grid item xs={12}>
                    <div style={{ marginTop: '15px' }}>
                      <PaymentEventListItemSummary
                        usedDuration={usedDuration}
                      />
                    </div>
                  </Grid>
                )}
              </>
            ) : null}
          </Grid>
          {isScheduleEmpty ? (
            <Stack alignItems="center" style={{ padding: '30px 0 15px 0' }}>
              <img
                src="/images/objects/billing-empty-schedule.png"
                alt="empty-schedule"
                width="90%"
                style={{ maxWidth: '400px' }}
              />
            </Stack>
          ) : null}
        </Box>
      </Container>
      {!isScheduleEmpty ? (
        <>
          <div style={{ minHeight: '150px' }} />
          <Stack alignItems="center">
            <Box sx={buttonContainerStyles}>
              <Stack alignItems="center">
                <LoadingButton
                  fullWidth
                  variant="contained"
                  color="primary"
                  style={{ maxWidth: '300px', marginBottom: '10px' }}
                  disabled={isBookingDisabled}
                  onClick={handleBook}
                  loading={stripeState.status === 'loading'}
                >
                  Book Now
                </LoadingButton>
                <div
                  style={{
                    display: 'inline',
                    textAlign: 'center',
                    maxWidth: '95%',
                  }}
                >
                  <Typography
                    variant="caption"
                    color="secondary"
                    textAlign="center"
                  >
                    Having trouble changing your class schedule? Please&nbsp;
                  </Typography>
                  <Typography
                    variant="caption"
                    component="a"
                    href={supportLink}
                    target="_blank"
                    rel="noreferrer"
                    style={{ color: TP_COLORS.tutopiyaGreenMain.light }}
                  >
                    contact our support team
                  </Typography>
                </div>
                <Typography
                  variant="caption"
                  component="a"
                  target="_blank"
                  rel="noreferrer"
                  style={{ color: TP_COLORS.tutopiyaGreenMain.light }}
                  href={
                    subscriptionData &&
                    subscriptionData.subscription &&
                    subscriptionData.subscription.latest_invoice
                      ? subscriptionData.subscription.latest_invoice
                          .hosted_invoice_url || ''
                      : ''
                  }
                >
                  View payment receipt
                </Typography>
              </Stack>
            </Box>
          </Stack>
        </>
      ) : null}
      {/* Modals */}
      {editClassModalData && editClassModalData.open ? (
        <EditPaymentClassModal
          open={editClassModalData.open}
          onClose={handleEditModalClose}
          classData={editClassModalData.item}
          isFlexiblePlan={
            paymentData && paymentData.subscriptionType === 'flexible'
          }
          updateClassData={(
            id: string,
            classStart: IMoment,
            duration: number,
            numberOfClasses?: number
          ) =>
            handleUpdateClassData([
              {
                id,
                duration,
                startDate: classStart,
                numberOfClasses,
              },
            ])
          }
        />
      ) : null}
      {isScheduleConflictModalOpen ? (
        <ScheduleConflictModal
          open={isScheduleConflictModalOpen}
          onClose={toggleScheduleConflictModal}
          updateClassData={handleUpdateClassData}
          removeClasses={handleRemoveClasses}
          paymentType={paymentData?.subscriptionType || 'monthly'}
        />
      ) : null}
    </div>
  );
};

export default PaymmentSchedulePage;
