import React, { useEffect } from 'react';
import { useUnleashContext } from '@unleash/proxy-client-react';
import { useAppSelector } from '../../hooks';
import { getUserState } from '../../store/UserSlice';

const FeatureFlagUpdater = () => {
  const updateContext = useUnleashContext();

  const userState = useAppSelector(getUserState);

  const { user } = userState;

  useEffect(() => {
    if (user && user.id) {
      updateContext({ userId: user.id }).then();
    }
  }, [user]);

  return <></>;
};

export default FeatureFlagUpdater;
