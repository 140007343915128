import { createAsyncThunk } from '@reduxjs/toolkit';
import { IHitPayPaymentData } from '.';
import APIDataProvider from '../../dataProvider/APIDataProvider';
import ApiPaths from '../../dataProvider/APIPaths';
import { IPaymentClass } from '../CheckoutSlice';
import { IPromoCodeData } from '../PromoCodeSlice';

export interface IHitPayMakePayment {
  userId: string;
  packageId: string;
  amount: string;
  code?: string;
  discount?: string;
  promoCodeDetails?: IPromoCodeData;
  paymentClassData?: {
    isChangeSchedule?: boolean;
    paymentClasses?: IPaymentClass[];
  };
  subjectCount?: number;
  minutesPerWeek?: number;
  isInstalmentPayment?: boolean;
}

const HitPayPaymentApiDataProvider = new APIDataProvider<IHitPayPaymentData>();

export const hitPayMakePayment = createAsyncThunk(
  'hitpay/makePayment',
  async (data: IHitPayMakePayment) => {
    const response = await HitPayPaymentApiDataProvider.create(
      `${ApiPaths.hitpay}/create`,
      data
    );

    return response.data;
  }
);
