import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '..';
import AuthServiceHelper from './AuthServiceHelper';
import {
  getAuthTokenFromLocalStorage,
  removeAuthTokenToLocalStorage,
  setAuthTokenToLocalStorage,
} from '../../helper';

export interface IUser {
  id: string;
  firstName: string;
  userName: string;
  email: string;
  lastName?: string;
  country: string;
  avatar?: string;
  curriculum?: string;
  grade?: string;
  remainingTime?: number; // in minutes
  createdAt: number;
  allowFlexiblePlanTab?: boolean;
  allowMonthlyPlanTab?: boolean;
  autoTopUpData?: {
    cardId: string;
    isAutoTopUpEnabled: boolean;
    packageId: string;
    threshold: number;
  };
}

export interface IAuthState {
  status: 'idle' | 'loading' | 'rejected';
  accessToken: string | undefined;
}

const initialState: IAuthState = {
  status: 'idle',
  accessToken: getAuthTokenFromLocalStorage(),
};

// eslint-disable-next-line
export const getClientCredentialsToken: any = createAsyncThunk(
  'auth/client_credentials',
  async () => {
    const res = await AuthServiceHelper.getClientCredentialsToken();
    return res;
  }
);

export const AuthSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    signOut: (state) => {
      removeAuthTokenToLocalStorage();
      state.status = 'idle';
      state.accessToken = undefined;
    },
  },
  extraReducers: {
    [getClientCredentialsToken.pending]: (state) => {
      state.status = 'loading';
    },
    [getClientCredentialsToken.fulfilled]: (state, action) => {
      state.status = 'idle';
      setAuthTokenToLocalStorage(action.payload.access_token);
      state.accessToken = action.payload.access_token;
    },
    [getClientCredentialsToken.rejected]: (state, action) => {
      state.status = 'rejected';
      removeAuthTokenToLocalStorage();
      state.accessToken = undefined;
    },
  },
});

export const { signOut } = AuthSlice.actions;

export const getAuthState = (state: RootState): RootState['auth'] => state.auth;

export default AuthSlice.reducer;
