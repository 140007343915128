import React from 'react';
import {
  Grid,
  SxProps,
  Theme,
  TypographyProps,
  Card,
  CardContent,
  CardActionArea,
  Stack,
  Typography,
  IconButton,
} from '@mui/material';
import moment from 'moment';
// ICONS
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
// STORE
import { ICustomPaymentClass } from '../../../store/PaymentSlice';
// COMPONENTS
import DetailViewCard from '../../../components/Card/DetailViewCard';
// HELPERS
import { getSubjectColor, isMobileScreen } from '../../../helper';
// CONSTANTS
import { TP_COLORS } from '../../../constants';

export interface ListItemProps {
  paymentClass: ICustomPaymentClass;
  onClick?: () => void;
  isDisabled?: boolean;
  handleDelete?: () => void;
}

// STYLES
const defaultContainerStyles: SxProps<Theme> = {
  mb: '15px',
};

const defaultTitleProps: TypographyProps = {
  variant: 'caption',
  color: 'secondary',
  className: 'ellipsis-text',
};

const defaultDescriptionProps: TypographyProps = {
  variant: 'subtitle2',
  fontWeight: 600,
  className: 'ellipsis-text',
};

const defaultDetailViewCardContainerProps: React.CSSProperties = {
  backgroundColor: TP_COLORS.white,
  margin: 0,
  // minHeight: '65px',
};

// DATA
const EMPTY_TEXT = 'Not Specified';

const ListItem: React.FC<ListItemProps> = ({
  paymentClass,
  onClick,
  isDisabled,
  handleDelete,
}) => {
  const isMobile = isMobileScreen();

  const { subject, classStart, duration, numberOfClasses } = paymentClass || {};

  // START DATE
  const startDate = classStart
    ? moment(classStart).format('DD MMM YYYY (ddd)')
    : 'Select Date';

  // STATE TIME
  const startTime = classStart
    ? moment(classStart).format('hh:mm A')
    : 'Select Time';

  // RECURRENCE
  const recurrence = classStart
    ? `Weekly on ${moment(classStart).format('dddd')}`
    : EMPTY_TEXT;

  // DURATION
  let _duration = EMPTY_TEXT;
  if (duration) {
    const hours = Math.trunc(duration / 60);
    const mins = Math.round(duration % 60);
    const hourText = `${hours.toString().padStart(2, '0')} Hour${
      hours > 1 ? 's' : ''
    }`;
    const minText = `${mins.toString().padStart(2, '0')} Min${
      mins > 1 ? 's' : ''
    }`;
    _duration = `${hourText} ${mins > 0 ? minText : ''}`;
  }

  // NUMBER OF CLASSES
  const _numberOfClasses =
    numberOfClasses || numberOfClasses === 0
      ? `${numberOfClasses.toString()} Class${numberOfClasses > 1 ? 'es' : ''}`
      : EMPTY_TEXT;

  // RENDER
  if (isMobile) {
    return (
      <Card sx={{ m: '0 -11px 16px -11px' }}>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <DetailViewCard
                description={subject || '-'}
                descriptionProps={{
                  variant: 'subtitle2',
                  fontWeight: 600,
                  color: TP_COLORS.white,
                  className: 'ellipsis-text',
                }}
                containerProps={{
                  style: {
                    background: getSubjectColor(subject),
                    margin: 0,
                  },
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <DetailViewCard
                descriptionComponent={
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Typography color="secondary" fontWeight={500}>
                      Start Date:
                    </Typography>
                    <Typography fontWeight={500}>{startDate}</Typography>
                  </Stack>
                }
                containerProps={{
                  style: {
                    backgroundColor: TP_COLORS.paperGray.light,
                    margin: 0,
                  },
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <DetailViewCard
                descriptionComponent={
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Typography color="secondary" fontWeight={500}>
                      Start Time:
                    </Typography>
                    <Typography fontWeight={500}>{startTime}</Typography>
                  </Stack>
                }
                containerProps={{
                  style: {
                    backgroundColor: TP_COLORS.paperGray.light,
                    margin: 0,
                  },
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <DetailViewCard
                descriptionComponent={
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Typography color="secondary" fontWeight={500}>
                      Recurrence:
                    </Typography>
                    <Typography fontWeight={500}>{recurrence}</Typography>
                  </Stack>
                }
                containerProps={{
                  style: {
                    backgroundColor: TP_COLORS.paperGray.light,
                    margin: 0,
                  },
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <DetailViewCard
                descriptionComponent={
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Typography color="secondary" fontWeight={500}>
                      Duration:
                    </Typography>
                    <Typography fontWeight={500}>{_duration}</Typography>
                  </Stack>
                }
                containerProps={{
                  style: {
                    backgroundColor: TP_COLORS.paperGray.light,
                    margin: 0,
                  },
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <DetailViewCard
                descriptionComponent={
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Typography color="secondary" fontWeight={500}>
                      Number of classes:
                    </Typography>
                    <Typography fontWeight={500}>{_numberOfClasses}</Typography>
                  </Stack>
                }
                containerProps={{
                  style: {
                    backgroundColor: TP_COLORS.paperGray.light,
                    margin: 0,
                  },
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="center"
                width="100%"
              >
                <IconButton onClick={onClick} disabled={isDisabled}>
                  <EditIcon
                    color="secondary"
                    style={{
                      padding: '8px',
                      borderRadius: '7px',
                    }}
                  />
                </IconButton>
                {handleDelete && (
                  <IconButton
                    color="error"
                    onClick={handleDelete}
                    disabled={isDisabled}
                  >
                    <DeleteIcon
                      sx={{
                        padding: '8px',
                        borderRadius: '7px',
                      }}
                    />
                  </IconButton>
                )}
              </Stack>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    );
  }

  return (
    <Card elevation={0} sx={defaultContainerStyles}>
      <CardContent style={{ padding: '0' }}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} sm={10} lg={11}>
            <CardActionArea onClick={onClick} disabled={isDisabled}>
              <Grid
                container
                alignItems="center"
                spacing={2}
                style={{
                  padding: '16px',
                  backgroundColor: TP_COLORS.paperGray.light,
                }}
              >
                <Grid item xs={12}>
                  <DetailViewCard
                    title="Subject"
                    description={subject}
                    titleProps={{
                      ...defaultTitleProps,
                      color: TP_COLORS.white,
                    }}
                    descriptionProps={{
                      ...defaultDescriptionProps,
                      color: TP_COLORS.white,
                    }}
                    containerProps={{
                      style: {
                        background: getSubjectColor(subject),
                        margin: 0,
                        // minHeight: '65px',
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={3}>
                  <DetailViewCard
                    title="Start Date"
                    description={startDate}
                    titleProps={defaultTitleProps}
                    descriptionProps={defaultDescriptionProps}
                    containerProps={{
                      style: defaultDetailViewCardContainerProps,
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={2}>
                  <DetailViewCard
                    title="Start Time"
                    description={startTime}
                    titleProps={defaultTitleProps}
                    descriptionProps={defaultDescriptionProps}
                    containerProps={{
                      style: defaultDetailViewCardContainerProps,
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={3}>
                  <DetailViewCard
                    title="Recurrence"
                    description={recurrence}
                    titleProps={defaultTitleProps}
                    descriptionProps={defaultDescriptionProps}
                    containerProps={{
                      style: defaultDetailViewCardContainerProps,
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={2}>
                  <DetailViewCard
                    title="Duration"
                    description={_duration}
                    titleProps={defaultTitleProps}
                    descriptionProps={defaultDescriptionProps}
                    containerProps={{
                      style: defaultDetailViewCardContainerProps,
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={2}>
                  <DetailViewCard
                    title="Number of classes"
                    description={_numberOfClasses}
                    titleProps={defaultTitleProps}
                    descriptionProps={defaultDescriptionProps}
                    containerProps={{
                      style: defaultDetailViewCardContainerProps,
                    }}
                  />
                </Grid>
              </Grid>
            </CardActionArea>
          </Grid>
          <Grid item xs={12} sm={2} lg={1} display={{ xs: 'none', sm: 'flex' }}>
            <Stack>
              <IconButton onClick={onClick} disabled={isDisabled}>
                <EditIcon
                  color="secondary"
                  style={{
                    backgroundColor: TP_COLORS.paperGray.light,
                    padding: '8px',
                    borderRadius: '7px',
                  }}
                />
              </IconButton>
              {handleDelete && (
                <IconButton
                  color="error"
                  onClick={handleDelete}
                  disabled={isDisabled}
                >
                  <DeleteIcon
                    sx={{
                      backgroundColor: 'error.light',
                      padding: '8px',
                      borderRadius: '7px',
                    }}
                  />
                </IconButton>
              )}
            </Stack>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default ListItem;
