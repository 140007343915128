import { useStripe } from '@stripe/react-stripe-js';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import { CircularProgress } from '@mui/material';
import {
  IStripeCreateSession,
  createStripeSession,
  getStripeState,
} from '../../store/Stripe';
import {
  getFlexiblePlanPricesForUser,
  getPackageState,
  getWeeklyPlanPricesForUser,
} from '../../store/PackageSlice';
import { IPromoCodeData } from '../../store/PromoCodeSlice';
import { useAppSelector } from '../../hooks';
import {
  IFlexiblePlan,
  IWeeklyPlan,
  flexiblePlans,
  weeklyPlans,
  weeklyPlanTotalSubjectsCount,
  weeklyPlanHourList,
  subscriptionWeeks,
  TPlanKey,
} from '../../constants';
import { getUserById } from '../../store/UserSlice';
import { TSubscriptionType } from '../Packages/tabs/WeeklyPlansTab';
import { getPlanTypeByKey } from '../../helper';

export interface IPackageData {
  key: IFlexiblePlan['key'] | IWeeklyPlan['key'];
  id: IFlexiblePlan['id'] | IWeeklyPlan['id'];
  title: IFlexiblePlan['title'] | IWeeklyPlan['title'];
  currency: string;
  rate: number;
  price: number;
  amount: number;
  promoCode?: string;
  discount?: number;
  promoCodeDetails?: IPromoCodeData;
  hours?: IFlexiblePlan['hours'];
  weeks?: IWeeklyPlan['weeks'];
  subjectCount?: number;
  minutesPerWeek?: number;
  subscriptionType: 'monthly' | 'upfront' | 'flexible';
}

interface IState {
  isPromoModalOpen: boolean;
  isAddCardModalOpen: boolean;
  packageData: IPackageData | undefined;
  isProcessing: boolean;
}

const initialState: IState = {
  isPromoModalOpen: false,
  isAddCardModalOpen: false,
  packageData: undefined,
  isProcessing: false,
};

const CheckoutSessionTab: React.FC = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const stripe = useStripe();
  const location = useLocation();

  const stripeState = useAppSelector(getStripeState);
  const packageState = useAppSelector(getPackageState);

  const userId = params.userId ? params.userId.trim() : '';
  const planKey = params.packageName
    ? (params.packageName.trim() as TPlanKey | undefined)
    : '';
  const queryStringParams = new URLSearchParams(location.search);
  const collectorId = queryStringParams.get('collectorId');

  // State
  const [state, setState] = useState(initialState);

  const validateSubscriptionType = (
    queryStringParamSubscriptionType: string | null
  ) => {
    let subscriptionType: TSubscriptionType = 'upfront';

    switch (queryStringParamSubscriptionType) {
      case 'monthly':
        subscriptionType = 'monthly';
        break;

      default:
        subscriptionType = 'upfront';
        break;
    }

    return subscriptionType;
  };

  const subscriptionType = validateSubscriptionType(
    queryStringParams.get('subscriptionType')
  );

  useEffect(() => {
    let packageData: IPackageData;

    dispatch(getUserById(userId));

    const selectedFlexiblePlan = flexiblePlans.find(
      (flexiblePlan) => flexiblePlan.key === planKey
    );

    const selectedWeeklyPlan = weeklyPlans.find(
      (weeklyPlan) => weeklyPlan.key === planKey
    );

    if (
      planKey &&
      getPlanTypeByKey(planKey) === 'flexible' &&
      selectedFlexiblePlan
    ) {
      dispatch(
        getFlexiblePlanPricesForUser({
          userId,
        })
      );
      packageData = {
        key: selectedFlexiblePlan.key,
        id: selectedFlexiblePlan.id,
        title: selectedFlexiblePlan.title,
        currency: 'SGD',
        rate: 0,
        price: 0,
        amount: 0,
        hours: selectedFlexiblePlan.hours,
        subscriptionType: 'flexible',
      };
    } else if (
      planKey &&
      getPlanTypeByKey(planKey) === 'weekly' &&
      selectedWeeklyPlan
    ) {
      dispatch(
        getWeeklyPlanPricesForUser({
          userId,
        })
      );
      packageData = {
        key: selectedWeeklyPlan.key,
        id: selectedWeeklyPlan.id,
        title: selectedWeeklyPlan.title,
        currency: 'SGD',
        rate: 0,
        price: 0,
        amount: 0,
        weeks: selectedWeeklyPlan.weeks,
        subjectCount: 1,
        minutesPerWeek: 60,
        subscriptionType,
      };

      const queryStringParamSubjects = queryStringParams.get('subjects');
      const queryStringParamMinutesPerWeek = queryStringParams.get('mins');

      if (
        queryStringParamSubjects &&
        parseFloat(queryStringParamSubjects) &&
        Math.floor(parseFloat(queryStringParamSubjects)) > 1 &&
        Math.floor(parseFloat(queryStringParamSubjects)) <
          weeklyPlanTotalSubjectsCount
      ) {
        packageData.subjectCount = Math.floor(
          parseFloat(queryStringParamSubjects)
        );
      }

      const weeklyPlanHourOptions = weeklyPlanHourList.map(
        (option) => option * (packageData?.subjectCount || 0)
      );

      // eslint-disable-next-line prefer-destructuring
      packageData.minutesPerWeek = weeklyPlanHourOptions[0];

      if (
        queryStringParamMinutesPerWeek &&
        parseFloat(queryStringParamMinutesPerWeek) &&
        weeklyPlanHourOptions.includes(
          parseFloat(queryStringParamMinutesPerWeek)
        )
      ) {
        packageData.minutesPerWeek = parseFloat(queryStringParamMinutesPerWeek);
      }
    }

    setState((prevState) => ({
      ...prevState,
      packageData,
    }));
  }, []);

  useEffect(() => {
    if (
      packageState.packages &&
      Object.keys(packageState.packages).length > 0 &&
      state.packageData
    ) {
      const selectedPlanPrice = packageState.packages[state.packageData.key];

      const newPackageData: IPackageData = { ...state.packageData };

      if (selectedPlanPrice) {
        newPackageData.currency = selectedPlanPrice.currency || 'SGD';
        newPackageData.rate = selectedPlanPrice.price
          ? parseFloat(selectedPlanPrice.price.toString())
          : 0;

        const amount = parseFloat(Number(selectedPlanPrice.price).toString());

        newPackageData.amount = amount;
        newPackageData.price = amount;

        setState((prevState) => ({
          ...prevState,
          packageData: newPackageData,
        }));
      }
      const data: IStripeCreateSession = {
        userId: params?.userId || '',
        amount: newPackageData?.amount || 0,
        currency: newPackageData?.currency || '',
        packageId: newPackageData?.id || '',
        subscriptionType: newPackageData.subscriptionType,
      };

      if (collectorId) {
        data.collectorId = collectorId;
      }

      dispatch(createStripeSession(data));
    }
  }, [packageState.packages]);

  useEffect(() => {
    if (
      packageState.weeklyPlanPrices &&
      Object.keys(packageState.weeklyPlanPrices).length > 0 &&
      state.packageData
    ) {
      const selectedPlanPrice =
        packageState.weeklyPlanPrices[state.packageData.key];

      const newPackageData: IPackageData = { ...state.packageData };

      if (selectedPlanPrice) {
        newPackageData.currency = selectedPlanPrice.currency || 'SGD';
        newPackageData.rate = selectedPlanPrice.price
          ? parseFloat(selectedPlanPrice.price.toString())
          : 0;

        const amount = parseFloat(
          (
            Number(selectedPlanPrice.price) *
            ((newPackageData.minutesPerWeek || 0) / 60) *
            (newPackageData.weeks || 0)
          ).toString()
        );

        newPackageData.amount = amount;
        newPackageData.price = amount;

        if (subscriptionType === 'monthly') {
          const updatedAmount = parseFloat(
            (
              (amount / (newPackageData.weeks || 0)) *
              subscriptionWeeks
            ).toFixed(2)
          );

          newPackageData.amount = updatedAmount;
          newPackageData.price = updatedAmount;
        }

        newPackageData.price = newPackageData.amount;

        setState((prevState) => ({
          ...prevState,
          packageData: newPackageData,
        }));
      }
      const data: IStripeCreateSession = {
        userId: params?.userId || '',
        amount: newPackageData?.amount || 0,
        currency: newPackageData?.currency || '',
        packageId: newPackageData?.id || '',
        subscriptionType: newPackageData.subscriptionType,
        minutesPerWeek: newPackageData.minutesPerWeek,
        subjectCount: newPackageData.subjectCount,
      };

      if (collectorId) {
        data.collectorId = collectorId;
      }

      dispatch(createStripeSession(data));
    }
  }, [packageState.weeklyPlanPrices]);

  useEffect(() => {
    if (stripeState.CheckoutSession.sessionId) {
      stripe?.redirectToCheckout({
        sessionId: stripeState.CheckoutSession.sessionId,
      });
    }
  }, [stripeState.CheckoutSession.sessionId]);
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: 'calc(100vh - 64px)',
      }}
    >
      <CircularProgress />
    </div>
  );
};

export default CheckoutSessionTab;
