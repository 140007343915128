import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Stack, Typography } from '@mui/material';

import {
  getStripeState,
  validateStripeInvoiceSession,
} from '../../store/Stripe';
import { useAppSelector } from '../../hooks';
import FullScreenLoader from '../../components/Loaders/FullScreenloader';

const ValidateSession: React.FC = () => {
  const dispatch = useDispatch();
  const params = useParams();

  const sessionId = params.sessionId || '';
  const stripeState = useAppSelector(getStripeState);

  useEffect(() => {
    if (sessionId) {
      dispatch(
        validateStripeInvoiceSession({
          sessionId,
        })
      );
    }
  }, []);

  if (
    stripeState.invoiceValidationData &&
    stripeState.invoiceValidationData.status !== 'success'
  ) {
    return (
      <Stack textAlign="center" padding="30px 15px">
        <Typography variant="h4">Payment failed</Typography>
        {stripeState.invoiceValidationData.message ? (
          <Typography variant="h6">
            {stripeState.invoiceValidationData.message}
          </Typography>
        ) : null}
      </Stack>
    );
  }

  if (
    stripeState.invoiceValidationData &&
    stripeState.invoiceValidationData.status === 'success' &&
    stripeState.invoiceValidationData.url
  ) {
    window.location.replace(stripeState.invoiceValidationData.url);
  }

  return (
    <div>
      <FullScreenLoader
        containerProps={{
          style: {
            width: '100%',
            height: 'calc(100vh - 64px)',
          },
        }}
      />
    </div>
  );
};

export default ValidateSession;
