import { createSlice } from '@reduxjs/toolkit';
// STORE
import { RootState } from '..';
// API
import {
  getFlexiblePlanPricesForUser,
  getWeeklyPlanPricesForUser,
} from './PackageApi';

export interface IFlexiblePackageItem {
  price: string;
  currency: string;
}

export interface IWeeklyPlanPriceData {
  price: number;
  currency: string;
}

export interface IPackages {
  // eslint-disable-next-line camelcase
  starter_plan: IFlexiblePackageItem;
  // eslint-disable-next-line camelcase
  standard_plan: IFlexiblePackageItem;
  // eslint-disable-next-line camelcase
  regular_plan: IFlexiblePackageItem;
  // eslint-disable-next-line camelcase
  premium_plan: IFlexiblePackageItem;
  // eslint-disable-next-line camelcase
  platinum_plan: IFlexiblePackageItem;
}

export interface IWeeklyPlanPrices {
  bronzePlan: IWeeklyPlanPriceData;
  silverPlan: IWeeklyPlanPriceData;
  goldPlan: IWeeklyPlanPriceData;
}

export interface IPackageState {
  status: 'idle' | 'loading' | 'rejected';
  packages: IPackages | undefined;
  weeklyPlanPrices: IWeeklyPlanPrices | undefined;
}

const initialState: IPackageState = {
  status: 'idle',
  packages: undefined,
  weeklyPlanPrices: undefined,
};

export const PackageSlice = createSlice({
  name: 'package',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getFlexiblePlanPricesForUser.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getFlexiblePlanPricesForUser.fulfilled, (state, action) => {
        state.status = 'idle';
        state.packages = action.payload;
      })
      .addCase(getFlexiblePlanPricesForUser.rejected, (state) => {
        state.status = 'rejected';
      })
      // WEEKLY PLANS
      .addCase(getWeeklyPlanPricesForUser.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getWeeklyPlanPricesForUser.fulfilled, (state, action) => {
        state.status = 'idle';
        state.weeklyPlanPrices = action.payload;
      })
      .addCase(getWeeklyPlanPricesForUser.rejected, (state) => {
        state.status = 'rejected';
      });
  },
});

export const getPackageState = (state: RootState): RootState['package'] =>
  state.package;

export * from './PackageApi';
export default PackageSlice.reducer;
