import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Fade,
  FormControlLabel,
  Grid,
  Radio,
  Stack,
  SxProps,
  Theme,
  Typography,
} from '@mui/material';
import moment, { Moment as IMoment } from 'moment';
import { nanoid } from 'nanoid';
// HOOKS
import { useAppSelector } from '../../hooks';
// STORE
import {
  getFlexiblePlanPricesForUser,
  getPackageState,
  getWeeklyPlanPricesForUser,
} from '../../store/PackageSlice';
import {
  getPromoCodeState,
  IPromoCodeData,
  resetPromoCodeState,
} from '../../store/PromoCodeSlice';
import { getAuthState } from '../../store/AuthSlice';
import { getUserById, getUserState } from '../../store/UserSlice';
import {
  getPaymentClasses,
  getPaymentState,
  ICustomPaymentClass,
  updateValidatedPaymentClass,
  validatePaymentClasses,
} from '../../store/PaymentSlice';
import { getCheckoutState } from '../../store/CheckoutSlice';
import { getHitPayState } from '../../store/HitPaySlice';
// COMPONENTS
import FullScreenLoader from '../../components/Loaders/FullScreenloader';
import { headerHeight } from '../../components/Header/BillingHeader';
import AutoTopUpCard, {
  IAutoTopUpData,
  THRESHOLD_OPTIONS,
} from './components/AutoTopUpCard';
import BalanceCard from './components/BalanceCard';
import ListItem from './components/ListItem';
import PromoCard from './components/PromoCard';
import TotalCard from './components/TotalCard';
// MODALS
import ClassScheduleModal from './modals/ClassScheduleModal';
import PaymentModal from './modals/PaymentModal';
import ScheduleConflictModal from './modals/ScheduleConflictModal';
// CONSTANTS
import {
  flexiblePlans,
  IFlexiblePlan,
  IWeeklyPlan,
  TFlexiblePlanKey,
  TP_COLORS,
  TPlanKey,
  TPlanType,
  TWeeklyPlanKey,
  weeklyPlans,
  instalmentWeeksCount,
} from '../../constants';
// HELPERS
import { getPlanTypeByKey, isMobileScreen } from '../../helper';

interface IDeleteClassAlertData {
  id?: string;
  isConfirmationModalOpen: boolean;
}

export interface IPackageData {
  key: IFlexiblePlan['key'] | IWeeklyPlan['key'];
  id: IFlexiblePlan['id'] | IWeeklyPlan['id'];
  title: IFlexiblePlan['title'] | IWeeklyPlan['title'];
  planType: TPlanType;
  currency: string;
  rate: number;
  price: number;
  amount: number;
  promoCode?: string;
  discount?: number;
  promoCodeDetails?: IPromoCodeData;
  // FLEXIBLE
  hours?: number;
  // WEEKLY
  weeks?: IWeeklyPlan['weeks'];
  subjectCount?: number;
  minutesPerWeek?: number;
}

interface IState {
  packageData: IPackageData | undefined;
  autoTopUpData: IAutoTopUpData;
  selectedOption: 1 | 2;
  isClassScheduleModalOpen: boolean;
  isPaymentModalOpen: boolean;
  isScheduleConflictModalOpen: boolean;
}

// STYLES
const scheduleContainerStyles: SxProps<Theme> = {
  border: isMobileScreen() ? 'none' : { xs: 'none', sm: '1px solid lightgray' },
  mt: { xs: '0', sm: '16px' },
};

const radioOptionStyles: SxProps<Theme> = {
  borderRadius: '7px',
  mb: isMobileScreen() ? '10px' : { xs: '10px', sm: '0' },
  backgroundColor: isMobileScreen()
    ? TP_COLORS.listItemGray.light
    : {
        xs: TP_COLORS.listItemGray.light,
        sm: 'background.default',
      },
};

// METHODS
const RENDER_EMPTY_VIEW = (title: string) => (
  <div style={{ margin: '30px 0 15px 0' }}>
    <Typography variant="h4" textAlign="center">
      {title}
    </Typography>
  </div>
);

// DATA
export const TOTAL_SUBJECTS_COUNT = 7;

export const HOUR_LIST = [60, 90, 120, 180, 240, 300, 360]; // in mins

const initialState: IState = {
  packageData: undefined,
  autoTopUpData: {
    isAutoTopUpEnabled: false,
    threshold: THRESHOLD_OPTIONS[1].value,
  },
  selectedOption: 1,
  isClassScheduleModalOpen: false,
  isPaymentModalOpen: false,
  isScheduleConflictModalOpen: false,
};

const PaymentView: React.FC = () => {
  // HOOKS
  const dispatch = useDispatch();
  const params = useParams();
  const location = useLocation();

  // STORE
  const authState = useAppSelector(getAuthState);
  const userState = useAppSelector(getUserState);
  const packageState = useAppSelector(getPackageState);
  const paymentState = useAppSelector(getPaymentState);
  const promoCodeState = useAppSelector(getPromoCodeState);
  const checkoutState = useAppSelector(getCheckoutState);
  const hitPayState = useAppSelector(getHitPayState);

  // STATES
  const [state, setState] = useState(initialState);
  const [classes, setClasses] = useState<ICustomPaymentClass[]>([]);
  const [selectedClass, setSelectedClass] = useState<string | undefined>(
    undefined
  ); // payment class temp id
  const [
    deleteClassAlertData,
    setDeleteClassAleartData,
  ] = useState<IDeleteClassAlertData>({
    isConfirmationModalOpen: false,
  });

  const urlParams = new URLSearchParams(location.search);
  const planKey = params.packageName as TPlanKey | undefined;
  const _selectedClassData = classes.find((c) => c.id === selectedClass);

  // BOOLEANS
  const isInstalmentPayment =
    urlParams.get('payment') === 'instalment' &&
    state.packageData?.planType === 'weekly';

  const isPlanValid =
    planKey === 'regular_plan' ||
    planKey === 'premium_plan' ||
    planKey === 'platinum_plan' ||
    planKey === 'bronzePlan' ||
    planKey === 'silverPlan' ||
    planKey === 'goldPlan';

  const isClassScheduleModalOpen = Boolean(_selectedClassData);

  let isSubmitDisabled =
    !state.packageData || (state.packageData && state.packageData.amount <= 0);

  let isHoursExceeded = false;
  let errorMessage = '';

  if (classes.length > 0 && state.packageData && state.selectedOption === 1) {
    let totalHours = 0;
    let _packageHours = 0;

    if (state.packageData.planType === 'flexible' && state.packageData.hours) {
      _packageHours = state.packageData.hours;
    } else if (
      state.packageData.planType === 'weekly' &&
      state.packageData.weeks &&
      state.packageData.minutesPerWeek
    ) {
      _packageHours =
        (state.packageData.weeks * state.packageData.minutesPerWeek) / 60;

      if (isInstalmentPayment) {
        _packageHours =
          (_packageHours / state.packageData.weeks) * instalmentWeeksCount;
      }
    }

    classes.forEach((item) => {
      if (item.duration && item.numberOfClasses) {
        const _hours = item.duration / 60;
        totalHours += _hours * item.numberOfClasses;
      }
    });

    isHoursExceeded = totalHours > _packageHours;

    if (isHoursExceeded) {
      isSubmitDisabled = true;
      errorMessage = 'Total class hours exceeds the plan hours';

      if (state.packageData && state.packageData.planType === 'flexible') {
        errorMessage += ` (Plan hours: ${state.packageData.hours})`;
      }
    }

    if (classes.some((c) => !c.classStart)) {
      isSubmitDisabled = true;
      errorMessage = 'Please make required selection for the class.';
    }
  }

  // USE EFFECT
  // DID MOUNT
  useEffect(() => {
    // eslint-disable-next-line no-undef-init
    let _packageData: IState['packageData'] = undefined;
    let _subjectCount = 1;
    const planType = getPlanTypeByKey(planKey);

    if (params && params.userId && isPlanValid && planType) {
      dispatch(getUserById(params.userId));
      dispatch(
        getPaymentClasses({
          userId: params.userId,
          eventId: urlParams.get('eventId') || undefined,
        })
      );

      if (planType === 'flexible') {
        dispatch(
          getFlexiblePlanPricesForUser({
            userId: params.userId,
          })
        );

        const _flexiblePlan = flexiblePlans.find(
          (flexiblePlan) => flexiblePlan.key === planKey
        );

        if (_flexiblePlan) {
          _packageData = {
            key: _flexiblePlan.key,
            id: _flexiblePlan.id,
            title: _flexiblePlan.title,
            currency: 'SGD',
            rate: 0,
            price: 0,
            amount: 0,
            hours: _flexiblePlan.hours,
            planType: 'flexible',
          };
        }
      } else if (planType === 'weekly') {
        dispatch(
          getWeeklyPlanPricesForUser({
            userId: params.userId || '',
          })
        );

        const _weeklyPlan = weeklyPlans.find(
          (weeklyPlan) => weeklyPlan.key === planKey
        );

        if (_weeklyPlan) {
          _packageData = {
            key: _weeklyPlan.key,
            id: _weeklyPlan.id,
            title: _weeklyPlan.title,
            currency: 'SGD',
            rate: 0,
            amount: 0,
            price: 0,
            weeks: _weeklyPlan.weeks,
            planType: 'weekly',
          };
          if (urlParams.get('subjects')) {
            const _count = Math.floor(Number(urlParams.get('subjects')));
            if (_count > 1 && _count <= TOTAL_SUBJECTS_COUNT) {
              _subjectCount = _count;
            }
          }

          const _options = HOUR_LIST.map((option) => option * _subjectCount);
          let _mins = _options[0];

          if (urlParams.get('mins')) {
            const _count = Math.floor(Number(urlParams.get('mins')));
            if (_options.includes(_count)) {
              _mins = _count;
            }
          }

          _packageData.minutesPerWeek = _mins;
          _packageData.subjectCount = _subjectCount;
        }
      }
    }

    setState((prevState) => ({
      ...prevState,
      packageData: _packageData,
    }));

    // COMPONENT WILL UNMOUNT
    return () => {
      dispatch(resetPromoCodeState());
    };
  }, []);

  // DID UPDATE FLEXIBLE PLAN PRICES
  useEffect(() => {
    if (
      packageState.packages &&
      Object.keys(packageState.packages).length > 0 &&
      state.packageData &&
      state.packageData.planType === 'flexible'
    ) {
      const _selectedPlanPrice =
        packageState.packages[state.packageData.key as TFlexiblePlanKey];
      const _newPackageData = { ...state.packageData };

      if (_selectedPlanPrice && _newPackageData && _newPackageData.hours) {
        _newPackageData.currency = _selectedPlanPrice.currency;
        _newPackageData.price = Number(_selectedPlanPrice.price);
        _newPackageData.amount = Number(_selectedPlanPrice.price);
        _newPackageData.rate = parseFloat(
          (Number(_selectedPlanPrice.price) / _newPackageData.hours).toFixed(2)
        );

        setState((prevState) => ({
          ...prevState,
          packageData: _newPackageData,
        }));
      }
    }
  }, [packageState.packages]);

  // DID UPDATE WEEKLY PLAN PRICES
  useEffect(() => {
    if (
      packageState.weeklyPlanPrices &&
      Object.keys(packageState.weeklyPlanPrices).length > 0 &&
      state.packageData &&
      state.packageData.planType === 'weekly'
    ) {
      const _selectedPlanPrice =
        packageState.weeklyPlanPrices[state.packageData.key as TWeeklyPlanKey];
      const _newPackageData = { ...state.packageData };

      if (
        _selectedPlanPrice &&
        _newPackageData &&
        _newPackageData.weeks &&
        _newPackageData.minutesPerWeek &&
        _newPackageData.subjectCount
      ) {
        _newPackageData.currency = _selectedPlanPrice.currency;
        _newPackageData.rate = Number(_selectedPlanPrice.price);
        _newPackageData.price = parseFloat(
          (
            Number(_selectedPlanPrice.price) *
            (_newPackageData.minutesPerWeek / 60) *
            _newPackageData.weeks
          ).toString()
        );
        _newPackageData.amount = parseFloat(
          (
            Number(_selectedPlanPrice.price) *
            (_newPackageData.minutesPerWeek / 60) *
            _newPackageData.weeks
          ).toString()
        );

        setState((prevState) => ({
          ...prevState,
          packageData: _newPackageData,
        }));
      }
    }
  }, [packageState.weeklyPlanPrices]);

  // DID UPDATE PAYMENT CLASSES
  useEffect(() => {
    if (paymentState.classes && paymentState.classes.length > 0) {
      const newClasses: ICustomPaymentClass[] = [];

      let packageQuantityHours = 0;

      // CONSTRUCT CLASS DATA
      paymentState.classes.forEach((paymentClass) => {
        const {
          lastClassEnd,
          lastClassStart,
          ...otherPaymentClassProps
        } = paymentClass;

        const newClass: ICustomPaymentClass = {
          id: nanoid(),
          duration: 60,
          maxNumberOfClasses: 0,
          ...otherPaymentClassProps,
        };

        const _nextDayFromNow = moment().add(1, 'day').toDate().getTime();

        if (lastClassStart && lastClassEnd) {
          const _time = moment(lastClassStart).format('hh:mm A');
          const _day = moment(lastClassStart).format('dddd');
          const _nextDayDate = moment(
            `${moment().day(_day).format('YYYY-MM-DD')} ${_time}`,
            'YYYY-MM-DD hh:mm A'
          );
          const _duration = (lastClassEnd - lastClassStart) / 60000; // minutes

          let _start = moment(lastClassStart).add(1, 'week');

          if (lastClassStart < _nextDayFromNow) {
            if (_nextDayFromNow < _nextDayDate.toDate().getTime()) {
              _start = _nextDayDate;
            } else {
              _start = moment(_nextDayDate).add(1, 'week');
            }
          }

          const _end = moment(_start).add(_duration, 'minutes');

          newClass.lastClassStart = lastClassStart;
          newClass.classStart = _start.toDate().getTime();
          newClass.classEnd = _end.toDate().getTime();
          newClass.duration = _duration;

          if (state.packageData) {
            if (state.packageData.planType === 'flexible') {
              packageQuantityHours = Number(state.packageData.hours) * 60;
            } else if (
              state.packageData.planType === 'weekly' &&
              state.packageData.minutesPerWeek &&
              state.packageData.weeks
            ) {
              packageQuantityHours =
                state.packageData.weeks * state.packageData.minutesPerWeek;

              if (isInstalmentPayment) {
                packageQuantityHours =
                  (packageQuantityHours / state.packageData.weeks) *
                  instalmentWeeksCount;
              }
            }

            newClass.maxNumberOfClasses = Math.floor(
              packageQuantityHours / _duration
            );

            newClass.numberOfClasses = 0;
          }
        }

        newClasses.push(newClass);
      });

      if (newClasses.length) {
        let classIndex = 0;
        let availableMins = packageQuantityHours;
        let isProceed = true;

        while (isProceed) {
          const currentClass = newClasses[classIndex];
          const currentClassDuration = currentClass.duration || 60;

          if (availableMins >= currentClassDuration) {
            if (currentClass.numberOfClasses) {
              currentClass.numberOfClasses += 1;
            } else {
              currentClass.numberOfClasses = 1;
            }

            availableMins -= currentClassDuration;

            classIndex += 1;

            if (newClasses.length === classIndex) {
              classIndex = 0;
            }
          } else {
            isProceed = false;
          }
        }
      }

      setClasses(newClasses);
    }
  }, [paymentState.classes]);

  // DID UPDATE PROMOCODE DATA
  useEffect(() => {
    if (state.packageData) {
      const _promoCodeData = promoCodeState.promoCode;
      const _newPackageData: IPackageData = {
        ...state.packageData,
        promoCode: undefined,
        discount: undefined,
        promoCodeDetails: undefined,
        amount: state.packageData.price,
      };

      if (
        packageState.packages &&
        state.packageData.planType === 'flexible' &&
        Object.keys(packageState.packages).length > 0
      ) {
        const _selectedPackagePrice =
          state.packageData[state.packageData.key as TFlexiblePlanKey];
        if (_selectedPackagePrice) {
          _newPackageData.amount = _selectedPackagePrice.price;
        }
      } else if (
        packageState.weeklyPlanPrices &&
        state.packageData.planType === 'weekly' &&
        Object.keys(packageState.weeklyPlanPrices).length > 0
      ) {
        const _selectedPackagePrice =
          state.packageData[state.packageData.key as TWeeklyPlanKey];
        if (_selectedPackagePrice) {
          _newPackageData.amount = _selectedPackagePrice.price;
        }
      }

      if (
        _promoCodeData &&
        _promoCodeData.discount &&
        _promoCodeData.promoCodeDetails &&
        _promoCodeData.promoCodeDetails.promotionType
      ) {
        _newPackageData.discount = _promoCodeData.discount;
        _newPackageData.promoCodeDetails = _promoCodeData.promoCodeDetails;

        if (
          _promoCodeData.promoCodeDetails &&
          _promoCodeData.promoCodeDetails.promotionType !== 'BONUS HOURS'
        ) {
          _newPackageData.amount = _promoCodeData.price;
        }

        if (
          _promoCodeData.promoCodeDetails &&
          _promoCodeData.promoCodeDetails.promoCode
        ) {
          _newPackageData.promoCode = _promoCodeData.promoCodeDetails.promoCode;
        }
      }

      setState((prevState) => ({
        ...prevState,
        packageData: _newPackageData,
      }));
    }
  }, [promoCodeState.promoCode]);

  useEffect(() => {
    if (
      paymentState.validatedPaymentClassessData &&
      paymentState.validatedPaymentClassessData.showConflictModal &&
      paymentState.validatedPaymentClassessData.conflictPaymentClasses.length >
        0 &&
      state.isScheduleConflictModalOpen === false
    ) {
      toggleScheduleConflictModal();
    } else if (
      paymentState.validatedPaymentClassessData &&
      paymentState.validatedPaymentClassessData.showPaymentModal &&
      state.isPaymentModalOpen === false
    ) {
      togglePaymentModal();
    }
  }, [paymentState.validatedPaymentClassessData]);

  useEffect(() => {
    if (userState.user && userState.user.autoTopUpData) {
      const _autoTopUpData = userState.user.autoTopUpData;

      setState((prevState) => ({
        ...prevState,
        autoTopUpData: {
          isAutoTopUpEnabled:
            _autoTopUpData && _autoTopUpData.isAutoTopUpEnabled === true,
          threshold:
            _autoTopUpData && _autoTopUpData.threshold
              ? _autoTopUpData.threshold.toString()
              : initialState.autoTopUpData.threshold.toString(),
        },
      }));
    }
  }, [userState.user]);

  // METHODS
  const setSelectedOption = (option: IState['selectedOption']) => {
    setState((prevState) => ({
      ...prevState,
      selectedOption: option,
    }));
  };

  const togglePaymentModal = () => {
    setState((prevState) => ({
      ...prevState,
      isPaymentModalOpen: !prevState.isPaymentModalOpen,
    }));
  };

  const toggleScheduleConflictModal = () => {
    setState((prevState) => ({
      ...prevState,
      isScheduleConflictModalOpen: !prevState.isScheduleConflictModalOpen,
    }));
  };

  const onContinue = () => {
    if (state.selectedOption === 1 && classes.length > 0 && userState.user) {
      dispatch(
        validatePaymentClasses({
          userId: userState.user.id,
          paymentClasses: classes,
        })
      );
    } else {
      togglePaymentModal();
    }
  };

  const handleUpdateClassData = (
    id: string,
    startDate: IMoment,
    duration: number,
    numberOfClasses: number
  ) => {
    const newClasses: ICustomPaymentClass[] = [...classes];
    const _selectedClassIndex = newClasses.findIndex((item) => item.id === id);

    if (state.packageData && _selectedClassIndex > -1) {
      let _packageQuantityHours = 0;
      const _selectedClass = newClasses[_selectedClassIndex];

      _selectedClass.duration = duration;
      _selectedClass.classStart = startDate.toDate().getTime();
      _selectedClass.classEnd = moment(startDate)
        .add(duration)
        .toDate()
        .getTime();

      if (state.packageData.planType === 'flexible') {
        _packageQuantityHours = Number(state.packageData.hours) * 60;
      } else if (
        state.packageData.planType === 'weekly' &&
        state.packageData.minutesPerWeek &&
        state.packageData.weeks
      ) {
        _packageQuantityHours =
          state.packageData.weeks * state.packageData.minutesPerWeek;

        if (isInstalmentPayment) {
          _packageQuantityHours =
            (_packageQuantityHours / state.packageData.weeks) *
            instalmentWeeksCount;
        }
      }

      _selectedClass.maxNumberOfClasses = Math.floor(
        _packageQuantityHours / duration
      );
      _selectedClass.numberOfClasses = numberOfClasses;

      newClasses[_selectedClassIndex] = _selectedClass;
      dispatch(
        updateValidatedPaymentClass({
          id,
          classStart: _selectedClass.classStart,
          classEnd: _selectedClass.classEnd,
          duration,
          numberOfClasses,
        })
      );
    }

    setClasses(newClasses);
  };

  const RENDER_OPTION_CONTENT = () => {
    if (state.selectedOption === 1) {
      return (
        <Fade in={state.selectedOption === 1}>
          <div>
            {errorMessage && (
              <div style={{ marginBottom: '10px' }}>
                <Typography color="error.main">{errorMessage}</Typography>
              </div>
            )}
            {classes.map((paymentClass, index) => {
              return (
                <ListItem
                  key={index}
                  paymentClass={paymentClass}
                  onClick={() => setSelectedClass(paymentClass.id)}
                  isDisabled={
                    !state.packageData ||
                    (state.packageData && state.packageData.amount <= 0)
                  }
                  handleDelete={() => {
                    setDeleteClassAleartData({
                      id: paymentClass.id,
                      isConfirmationModalOpen: true,
                    });
                  }}
                />
              );
            })}
          </div>
        </Fade>
      );
    }

    return (
      <Fade in={state.selectedOption === 2}>
        <div style={{ marginBottom: '15px' }}>
          <Typography color="secondary" fontWeight={500}>
            After payment completion, you will be contacted by our support team.
          </Typography>
        </div>
      </Fade>
    );
  };

  // RENDER
  if (
    authState.status === 'loading' ||
    userState.status === 'loading' ||
    packageState.status === 'loading'
  ) {
    return (
      <FullScreenLoader
        containerProps={{
          style: { height: `calc(100vh - ${headerHeight})` },
        }}
      />
    );
  }

  if (!isPlanValid || !state.packageData) {
    return RENDER_EMPTY_VIEW('Invalid Plan');
  }

  if (!userState.user) {
    return RENDER_EMPTY_VIEW('Invalid User');
  }

  return (
    <div style={{ paddingTop: '30px' }}>
      <Container maxWidth="xl">
        <div style={{ marginBottom: '30px' }}>
          <Grid container spacing={2}>
            {!isInstalmentPayment ? (
              <Grid item xs={12} sm={12} md={12} lg={6}>
                <AutoTopUpCard
                  autoTopUpData={state.autoTopUpData}
                  updateAutoTopUpData={(atd) => {
                    setState((prevState) => ({
                      ...prevState,
                      autoTopUpData: atd,
                    }));
                  }}
                />
              </Grid>
            ) : null}
            <Grid item xs={12} sm={12} md={12} lg={6}>
              <BalanceCard
                minutes={
                  userState.user && userState.user.remainingTime
                    ? userState.user.remainingTime
                    : 0
                }
              />
            </Grid>
            {classes && classes.length > 0 && (
              <Grid item xs={12}>
                <div style={{ marginBottom: '15px' }}>
                  <Card elevation={0} sx={scheduleContainerStyles}>
                    <CardContent style={{ paddingBottom: '0' }}>
                      <div style={{ marginBottom: '15px' }}>
                        <Typography variant="h6" fontWeight={600}>
                          Class Schedule
                        </Typography>
                      </div>
                      <Stack sx={{ mb: '30px' }}>
                        <FormControlLabel
                          value="start"
                          control={<Radio />}
                          label={
                            <Typography style={{ fontWeight: '600' }}>
                              Continue with my previous schedule
                            </Typography>
                          }
                          sx={radioOptionStyles}
                          checked={state.selectedOption === 1}
                          onChange={() => setSelectedOption(1)}
                        />
                        <FormControlLabel
                          value="start"
                          control={<Radio />}
                          label={
                            <Typography style={{ fontWeight: '600' }}>
                              Change my class schedule. Please contact me
                            </Typography>
                          }
                          sx={radioOptionStyles}
                          checked={state.selectedOption === 2}
                          onChange={() => setSelectedOption(2)}
                        />
                      </Stack>
                      <div>{RENDER_OPTION_CONTENT()}</div>
                    </CardContent>
                  </Card>
                </div>
              </Grid>
            )}
            {state.packageData && (
              <>
                <Grid item xs={12} sm={12} md={12} lg={6}>
                  <PromoCard packageData={state.packageData} />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={6}>
                  <TotalCard packageData={state.packageData} />
                </Grid>
              </>
            )}
          </Grid>
        </div>
        <Box sx={{ m: { xs: '30px 0', lg: '80px 0 30px 0' } }}>
          <Stack direction="row" justifyContent="center">
            <LoadingButton
              fullWidth
              variant="contained"
              color="primary"
              style={{ maxWidth: '300px' }}
              onClick={onContinue}
              disabled={isSubmitDisabled}
              loading={
                checkoutState.status === 'loading' ||
                hitPayState.status === 'loading' ||
                paymentState.status === 'loading'
              }
            >
              Continue
            </LoadingButton>
          </Stack>
        </Box>
      </Container>

      {/* MODALS */}
      {isClassScheduleModalOpen &&
        selectedClass &&
        _selectedClassData &&
        state.packageData && (
          <ClassScheduleModal
            open={isClassScheduleModalOpen}
            onClose={() => setSelectedClass(undefined)}
            paymentClass={_selectedClassData}
            updateClassData={handleUpdateClassData}
            packageData={state.packageData}
          />
        )}

      {state.isPaymentModalOpen && state.packageData && (
        <PaymentModal
          open={state.isPaymentModalOpen}
          onClose={togglePaymentModal}
          packageData={state.packageData}
          autoTopUpData={state.autoTopUpData}
          paymentClasses={classes}
          selectedOption={state.selectedOption}
        />
      )}
      {state.isScheduleConflictModalOpen && (
        <ScheduleConflictModal
          open={state.isScheduleConflictModalOpen}
          onClose={toggleScheduleConflictModal}
          paymentClasses={classes}
          disableContinue={isSubmitDisabled}
          errorMessage={errorMessage}
          actions={{
            onContinueWithoutClasses: (classesNeedstoBeFilterOut) => {
              setClasses((prevClasses) => {
                const newClasses: ICustomPaymentClass[] = [];
                prevClasses.forEach((prevClass) => {
                  const hasConflict = classesNeedstoBeFilterOut.some(
                    (c) => c.id === prevClass.id
                  );
                  if (!hasConflict) {
                    newClasses.push(prevClass);
                  }
                });

                return newClasses;
              });
              setState((prevState) => ({
                ...prevState,
                isPaymentModalOpen: true,
                isScheduleConflictModalOpen: false,
              }));
            },
            setSelectedClass: (classId) => {
              setSelectedClass(classId);
            },
          }}
        />
      )}
      <Dialog
        open={deleteClassAlertData.isConfirmationModalOpen}
        onClose={() =>
          setDeleteClassAleartData({
            isConfirmationModalOpen: false,
          })
        }
      >
        <DialogTitle>Are you sure</DialogTitle>
        <DialogContent>Do you want to delete this class?</DialogContent>
        <DialogActions>
          <Button
            variant="text"
            color="info"
            onClick={() =>
              setDeleteClassAleartData({ isConfirmationModalOpen: false })
            }
          >
            No
          </Button>
          <Button
            variant="text"
            color="info"
            onClick={() => {
              if (deleteClassAlertData.id) {
                setClasses((prevClasses) =>
                  prevClasses.filter((c) => c.id !== deleteClassAlertData.id)
                );
              }
              setDeleteClassAleartData({ isConfirmationModalOpen: false });
            }}
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default PaymentView;
