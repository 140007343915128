import { createSlice } from '@reduxjs/toolkit';
// STORE
import { RootState } from '..';
import { IUser } from '../AuthSlice';
// API
import { getUserById } from './UserApi';

export interface IUserState {
  status: 'idle' | 'loading' | 'rejected';
  user: IUser | undefined;
}

const initialState: IUserState = {
  status: 'idle',
  user: undefined,
};

export const UserSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    resetUserState: (state) => {
      state.user = undefined;
    },
  },
  extraReducers: (builder) => {
    builder
      // GET USER
      .addCase(getUserById.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getUserById.fulfilled, (state, action) => {
        state.status = 'idle';
        state.user = action.payload;
      })
      .addCase(getUserById.rejected, (state) => {
        state.status = 'rejected';
      });
  },
});

export const getUserState = (state: RootState): RootState['user'] => state.user;

export const { resetUserState } = UserSlice.actions;
export * from './UserApi';
export default UserSlice.reducer;
