import { createSlice } from '@reduxjs/toolkit';
// STORE
import { RootState } from '..';
import { IFlexiblePlan } from '../../constants';
// API
import { hitPayMakePayment } from './HitPayApi';

export interface IHitPayPaymentData {
  id: string;
  amount: string;
  currency: 'SGD';
  email: string;
  name: 'Arshad';
  purpose: IFlexiblePlan['id'];
  // eslint-disable-next-line camelcase
  url: string;
}

export interface IHitPayState {
  status: 'idle' | 'loading' | 'rejected';
  redirectUrl: string | undefined;
}

const initialState: IHitPayState = {
  status: 'idle',
  redirectUrl: undefined,
};

export const HitPaySlice = createSlice({
  name: 'hitpay',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // HITPAY PAYMENT
      .addCase(hitPayMakePayment.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(hitPayMakePayment.fulfilled, (state, action) => {
        state.status = 'idle';
        state.redirectUrl = action.payload.url;
        window.location.href = action.payload.url;
      })
      .addCase(hitPayMakePayment.rejected, (state) => {
        state.status = 'rejected';
      });
  },
});

export const getHitPayState = (state: RootState): RootState['hitPay'] =>
  state.hitPay;

export * from './HitPayApi';
export default HitPaySlice.reducer;
