import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useStripe } from '@stripe/react-stripe-js';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  Card,
  CardActionArea,
  CardContent,
  Collapse,
  Container,
  Grid,
  LinearProgress,
  Stack,
  SxProps,
  Theme,
  Toolbar,
  Typography,
} from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import CircleIcon from '@mui/icons-material/Circle';

import {
  IWeeklyPlan,
  TP_COLORS,
  subscriptionWeeks,
  weeklyPlanHourList,
  weeklyPlanTotalSubjectsCount,
  weeklyPlans,
} from '../../constants';
import { SafeAreaGaps, getDurationTextFromMinutes } from '../../helper';
import { getThemeMode } from '../../store/ConfigSlice';
import { getUserById } from '../../store/UserSlice';
import { IPromoCodeData, getPromoCodeState } from '../../store/PromoCodeSlice';
import { showSuccessMessage } from '../../store/SuccessSlice';
import {
  getWeeklyPlanPricesForUser,
  getPackageState,
} from '../../store/PackageSlice';
import {
  IStripeCard,
  IStripeSubscribe,
  getStripeCards,
  getStripeState,
  stripeSubscribe,
} from '../../store/Stripe';
import { useAppSelector } from '../../hooks';
import { TSubscriptionType } from '../Packages/tabs/WeeklyPlansTab';
import PromoCodeModal from './modals/PromoCodeModal';
import AddCardModal from './modals/AddCardModal';
import MonthlyPaymentHeader from '../../components/Header/MonthlyPaymentHeader';
import CardListItem from './components/StripeCardListItem';
import PaymentProcessingPage from './PaymentProcessingPage';

export interface IPackageData {
  key: IWeeklyPlan['key'];
  id: IWeeklyPlan['id'];
  title: IWeeklyPlan['title'];
  currency: string;
  rate: number;
  price: number;
  amount: number;
  promoCode?: string;
  discount?: number;
  promoCodeDetails?: IPromoCodeData;
  weeks: IWeeklyPlan['weeks'];
  subjectCount: number;
  minutesPerWeek: number;
}

const buttonContainerStyles: SxProps<Theme> = {
  padding: '15px',
  boxShadow: '0px -2px 0px 0px #00000017',
  position: 'fixed',
  bottom: SafeAreaGaps.bottom,
  width: '100%',
  maxWidth: '600px',
  backgroundColor: 'background.default',
};

const termsButtonStyles: React.CSSProperties = {
  textDecoration: 'underline',
  color: TP_COLORS.tutopiyaGreenMain.light,
};

interface IState {
  isPromoModalOpen: boolean;
  isAddCardModalOpen: boolean;
  selectedCard: IStripeCard | undefined;
  packageData: IPackageData | undefined;
  isProcessing: boolean;
}

const initialState: IState = {
  isPromoModalOpen: false,
  isAddCardModalOpen: false,
  selectedCard: undefined,
  packageData: undefined,
  isProcessing: false,
};

const validateSubscriptionType = (
  queryStringParamSubscriptionType: string | null
) => {
  let subscriptionType: TSubscriptionType = 'upfront';

  switch (queryStringParamSubscriptionType) {
    case 'monthly':
      subscriptionType = 'monthly';
      break;

    default:
      subscriptionType = 'upfront';
      break;
  }

  return subscriptionType;
};

const MonthlyPayment: React.FC = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const stripe = useStripe();
  const location = useLocation();

  // Store
  const themeMode = useAppSelector(getThemeMode);
  const stripeState = useAppSelector(getStripeState);
  const packageState = useAppSelector(getPackageState);
  const promoCodeState = useAppSelector(getPromoCodeState);

  // State
  const [state, setState] = useState(initialState);

  const queryStringParams = new URLSearchParams(location.search);
  const promoCodeData = promoCodeState.promoCode;
  const userId = params.userId ? params.userId.trim() : '';
  const planKey = params.packageName ? params.packageName.trim() : '';
  const subscriptionType = validateSubscriptionType(
    queryStringParams.get('subscriptionType')
  );

  const isSubmitDisabled =
    !state.packageData ||
    (state.packageData && !state.packageData.amount) ||
    !state.selectedCard;

  const paymentCardStyles: React.CSSProperties = {
    borderRadius: '7px',
    background:
      themeMode === 'light'
        ? TP_COLORS.listItemGray.light
        : TP_COLORS.listItemGray.dark,
  };

  useEffect(() => {
    let packageData: IPackageData;

    dispatch(getUserById(userId));
    dispatch(
      getStripeCards({
        userId,
      })
    );

    dispatch(
      getWeeklyPlanPricesForUser({
        userId,
      })
    );

    const selectedWeeklyPlan = weeklyPlans.find(
      (weeklyPlan) => weeklyPlan.key === planKey
    );

    if (selectedWeeklyPlan) {
      packageData = {
        key: selectedWeeklyPlan.key,
        id: selectedWeeklyPlan.id,
        title: selectedWeeklyPlan.title,
        currency: 'SGD',
        rate: 0,
        price: 0,
        amount: 0,
        weeks: selectedWeeklyPlan.weeks,
        subjectCount: 1,
        minutesPerWeek: 60,
      };

      const queryStringParamSubjects = queryStringParams.get('subjects');
      const queryStringParamMinutesPerWeek = queryStringParams.get('mins');

      if (
        queryStringParamSubjects &&
        parseFloat(queryStringParamSubjects) &&
        Math.floor(parseFloat(queryStringParamSubjects)) > 1 &&
        Math.floor(parseFloat(queryStringParamSubjects)) <
          weeklyPlanTotalSubjectsCount
      ) {
        packageData.subjectCount = Math.floor(
          parseFloat(queryStringParamSubjects)
        );
      }

      const weeklyPlanHourOptions = weeklyPlanHourList.map(
        (option) => option * packageData.subjectCount
      );

      // eslint-disable-next-line prefer-destructuring
      packageData.minutesPerWeek = weeklyPlanHourOptions[0];

      if (
        queryStringParamMinutesPerWeek &&
        parseFloat(queryStringParamMinutesPerWeek) &&
        weeklyPlanHourOptions.includes(
          parseFloat(queryStringParamMinutesPerWeek)
        )
      ) {
        packageData.minutesPerWeek = parseFloat(queryStringParamMinutesPerWeek);
      }

      setState((prevState) => ({
        ...prevState,
        packageData,
      }));
    }
  }, []);

  useEffect(() => {
    if (stripeState && stripeState.cards.length) {
      setState((prevState) => ({
        ...prevState,
        selectedCard: stripeState.cards[0],
      }));
    }
  }, [stripeState.cards]);

  useEffect(() => {
    if (
      packageState.weeklyPlanPrices &&
      Object.keys(packageState.weeklyPlanPrices).length > 0 &&
      state.packageData
    ) {
      const selectedPlanPrice =
        packageState.weeklyPlanPrices[state.packageData.key];

      const newPackageData: IPackageData = { ...state.packageData };

      if (selectedPlanPrice) {
        newPackageData.currency = selectedPlanPrice.currency || 'SGD';
        newPackageData.rate = selectedPlanPrice.price
          ? parseFloat(selectedPlanPrice.price.toString())
          : 0;

        const amount = parseFloat(
          (
            Number(selectedPlanPrice.price) *
            (newPackageData.minutesPerWeek / 60) *
            newPackageData.weeks
          ).toString()
        );

        newPackageData.amount = amount;
        newPackageData.price = amount;

        if (subscriptionType === 'monthly') {
          const updatedAmount = parseFloat(
            ((amount / newPackageData.weeks) * subscriptionWeeks).toFixed(2)
          );

          newPackageData.amount = updatedAmount;
          newPackageData.price = updatedAmount;
        }

        newPackageData.price = newPackageData.amount;

        setState((prevState) => ({
          ...prevState,
          packageData: newPackageData,
        }));
      }
    }
  }, [packageState.weeklyPlanPrices]);

  useEffect(() => {
    setState((prevState) => {
      const newPackageData = prevState.packageData;
      let newIsPromoModalOpen = prevState.isPromoModalOpen;

      if (newPackageData && promoCodeData) {
        if (
          promoCodeData &&
          promoCodeData.promoCodeDetails &&
          promoCodeData.promoCodeDetails.promotionType !== 'BONUS HOURS'
        ) {
          newPackageData.amount = promoCodeData.price;

          if (subscriptionType === 'monthly') {
            newPackageData.amount = parseFloat(
              (
                (newPackageData.amount / newPackageData.weeks) *
                subscriptionWeeks
              ).toFixed(2)
            );
          }
        }

        if (promoCodeData.valid) {
          newIsPromoModalOpen = false;
          dispatch(
            showSuccessMessage({
              message: 'Promo code applied!',
            })
          );
        }
      } else if (newPackageData && !promoCodeData) {
        newPackageData.amount = newPackageData.price;
      }

      return {
        ...prevState,
        packageData: newPackageData,
        isPromoModalOpen: newIsPromoModalOpen,
      };
    });
  }, [promoCodeData]);

  useEffect(() => {
    let redirectUrl;

    if (stripe && stripeState.stripeSubscription) {
      redirectUrl = `${process.env.REACT_APP_TUTOPIYA_BASE_URL}/subscription/validate/${stripeState.stripeSubscription.id}`;

      if (
        stripeState.stripeSubscription.status === 'incomplete' &&
        stripeState.stripeSubscription.latest_invoice &&
        stripeState.stripeSubscription.latest_invoice.payment_intent &&
        stripeState.stripeSubscription.latest_invoice.payment_intent
          .client_secret
      ) {
        stripe
          .confirmCardPayment(
            stripeState.stripeSubscription.latest_invoice.payment_intent
              .client_secret,
            {
              return_url: redirectUrl,
            }
          )
          .then(() => {
            window.location.href = redirectUrl;
          })
          // eslint-disable-next-line no-console
          .catch((err) => console.error('err', err));
      } else {
        window.location.href = redirectUrl;
      }
    }
  }, [stripeState.stripeSubscription]);

  const togglePromoModal = () => {
    setState((prevState) => ({
      ...prevState,
      isPromoModalOpen: !prevState.isPromoModalOpen,
    }));
  };

  const toggleAddCardModal = () => {
    setState((prevState) => ({
      ...prevState,
      isAddCardModalOpen: !prevState.isAddCardModalOpen,
    }));
  };

  const handleCardChange = (card: IStripeCard) => {
    setState((prevState) => ({
      ...prevState,
      selectedCard: card,
    }));
  };

  const startSubscription = () => {
    setState((prevState) => ({
      ...prevState,
      isProcessing: true,
    }));

    const { packageData } = state;

    if (packageData && state.selectedCard) {
      const apiData: IStripeSubscribe = {
        userId,
        subscriptionType,
        amount: packageData.amount,
        currency: packageData.currency,
        packageId: packageData.id,
        subjectCount: packageData.subjectCount,
        minutesPerWeek: packageData.minutesPerWeek,
        sourceId: state.selectedCard.id,
      };

      if (
        promoCodeState.promoCode &&
        promoCodeState.promoCode.valid &&
        promoCodeState.promoCode.promoCodeDetails &&
        promoCodeState.promoCode.promoCodeDetails.promoCode
      ) {
        apiData.promoCode = promoCodeState.promoCode.promoCodeDetails.promoCode;
      }

      dispatch(stripeSubscribe(apiData));
    }
  };

  if (state.isProcessing) {
    return (
      <div>
        <MonthlyPaymentHeader />
        <Toolbar />
        <PaymentProcessingPage />
      </div>
    );
  }

  return (
    <div>
      <MonthlyPaymentHeader />
      <Toolbar />
      <Collapse
        in={
          stripeState.status === 'loading' ||
          packageState.status === 'loading' ||
          promoCodeState.status === 'loading'
        }
        sx={{
          width: '100%',
          position: 'fixed',
          top: { xs: '56px', sm: '64px' },
        }}
      >
        <LinearProgress />
      </Collapse>
      {/* Price header */}
      <Collapse in={Boolean(state.packageData && state.packageData.amount)}>
        <Box
          sx={{
            minHeight: '180px',
            background: {
              xs: `url('/images/objects/gadgets-right-aligned.png') right center/120px no-repeat, #F1FBFA`,
              sm: `url('/images/objects/gadgets-right-aligned.png') right center/220px no-repeat, #F1FBFA`,
              md: `url('/images/objects/gadgets-left-aligned.png') left center/180px no-repeat, url('/images/objects/gadgets-right-aligned.png') right center/180px no-repeat, #F1FBFA`,
            },
          }}
        >
          <div style={{ padding: '40px 15px' }}>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent={{ xs: 'flex-start', sm: 'center' }}
            >
              <Typography
                variant="h4"
                color="#949494"
                fontWeight={600}
                sx={{ fontSize: { xs: '20px', sm: '40px', md: '30px' } }}
              >
                {state.packageData?.currency || ''}
              </Typography>
              &nbsp;&nbsp;
              <Typography
                variant="h2"
                color="#494C68"
                fontWeight={600}
                sx={{ fontSize: { xs: '30px', sm: '55px', md: '40px' } }}
              >
                {state.packageData?.amount || 0}
              </Typography>
              {subscriptionType === 'monthly' ? (
                <>
                  &nbsp;&nbsp;
                  <Typography
                    variant="h4"
                    fontWeight={600}
                    color="#494C68"
                    sx={{ fontSize: { xs: '20px', sm: '40px', md: '30px' } }}
                  >
                    per month
                  </Typography>
                </>
              ) : null}
            </Stack>
            {promoCodeData &&
            promoCodeData.discount &&
            promoCodeData.promoCodeDetails &&
            promoCodeData.promoCodeDetails.promotionType !== 'BONUS HOURS' ? (
              <Stack
                direction="row"
                alignItems="center"
                justifyContent={{ xs: 'flex-start', sm: 'center' }}
                style={{ marginBottom: '15px' }}
              >
                <Typography
                  variant="h5"
                  color="#949494"
                  sx={{ fontSize: { xs: '18px', sm: '25px' } }}
                >
                  Was{' '}
                  <span style={{ textDecoration: 'line-through' }}>
                    {state.packageData?.currency || ''}{' '}
                    {state.packageData?.price
                      ? state.packageData?.price?.toFixed(2)
                      : ''}
                  </span>
                </Typography>
              </Stack>
            ) : null}
            {promoCodeData &&
            promoCodeData.discount &&
            promoCodeData.promoCodeDetails &&
            promoCodeData.promoCodeDetails.promotionType === 'BONUS HOURS' ? (
              <Stack
                direction="row"
                alignItems="center"
                justifyContent={{ xs: 'flex-start', sm: 'center' }}
                style={{ marginBottom: '15px' }}
              >
                <Typography
                  variant="h5"
                  color="#949494"
                  sx={{ fontSize: { xs: '18px', sm: '25px' } }}
                >
                  + Bonus Hours {promoCodeData.promoCodeDetails.amount}
                </Typography>
              </Stack>
            ) : null}
            {state.packageData ? (
              <Stack
                direction="row"
                alignItems="center"
                justifyContent={{ xs: 'flex-start', sm: 'center' }}
              >
                <CircleIcon fontSize="small" style={{ color: '#7C3BC9' }} />
                &nbsp;
                <Typography
                  variant="h6"
                  sx={{ fontSize: { xs: '18px', sm: '22px' } }}
                >
                  {getDurationTextFromMinutes(
                    state.packageData.minutesPerWeek || 0
                  )}{' '}
                  per week for {state.packageData.weeks || 0} weeks
                </Typography>
              </Stack>
            ) : null}
          </div>
        </Box>
      </Collapse>
      {/* Main Content */}
      <Container maxWidth="xs">
        {state.packageData ? (
          <ul style={{ margin: '20px', padding: '0' }}>
            {subscriptionType === 'monthly' ? (
              <Typography
                component="li"
                color="secondary"
                style={{ marginBottom: '10px' }}
              >
                You will be billed every {subscriptionWeeks} weeks for this
                minimum {state.packageData.weeks} week commitment.
              </Typography>
            ) : null}
            {subscriptionType === 'upfront' ? (
              <Typography
                component="li"
                color="secondary"
                style={{ marginBottom: '10px' }}
              >
                This plan will auto-renew at the end of the{' '}
                {state.packageData.weeks} week period unless cancelled prior to
                this date.
              </Typography>
            ) : null}
          </ul>
        ) : null}
        <div style={{ marginBottom: '15px' }}>
          <Button
            variant="text"
            color="info"
            onClick={togglePromoModal}
            style={{ textDecoration: 'underline', fontWeight: 600 }}
          >
            Have a promo code ?
          </Button>
        </div>
        <div>
          <div style={{ marginBottom: '10px' }}>
            <Typography variant="h6">Payment Methods</Typography>
          </div>
          <div style={{ marginBottom: '15px' }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                {stripeState.cards && stripeState.cards.length
                  ? stripeState.cards.map((stripeCard, index) => {
                      const isSelected =
                        state.selectedCard?.id === stripeCard.id;

                      return (
                        <CardListItem
                          key={index}
                          card={stripeCard}
                          checked={isSelected}
                          onClick={() => handleCardChange(stripeCard)}
                        />
                      );
                    })
                  : null}
              </Grid>
            </Grid>
          </div>
          <div>
            <Card elevation={0} style={paymentCardStyles}>
              <CardActionArea onClick={toggleAddCardModal}>
                <CardContent style={{ paddingBottom: '16px' }}>
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Stack direction="row" alignItems="center">
                      <Typography
                        fontWeight={600}
                        fontSize={{ xs: '12px', sm: '1rem' }}
                        sx={{ marginRight: '10px' }}
                      >
                        Credit or Debit card
                      </Typography>
                      <img
                        src="/images/logos/visa-card.png"
                        alt="visa-card-logo"
                        className="start-subscription-btn-card-logo"
                      />
                      <img
                        src="/images/logos/master-card.png"
                        alt="master-card-logo"
                        className="start-subscription-btn-card-logo"
                        style={{ margin: '0 5px' }}
                      />
                      <img
                        src="/images/logos/american-express-card.png"
                        alt="american-express-card-logo"
                        className="start-subscription-btn-card-logo"
                      />
                    </Stack>
                    <Stack direction="row" alignItems="center">
                      <ChevronRightIcon />
                    </Stack>
                  </Stack>
                </CardContent>
              </CardActionArea>
            </Card>
          </div>
        </div>
      </Container>
      <div style={{ minHeight: '150px' }} />
      <Stack alignItems="center">
        <Box sx={buttonContainerStyles}>
          <Stack alignItems="center">
            <LoadingButton
              fullWidth
              color="primary"
              variant="contained"
              disabled={isSubmitDisabled}
              onClick={startSubscription}
              style={{ maxWidth: '300px', marginBottom: '10px' }}
              loading={
                stripeState.status === 'loading' ||
                packageState.status === 'loading' ||
                promoCodeState.status === 'loading'
              }
            >
              Start Subscription
            </LoadingButton>
            <Typography variant="caption" color="secondary" textAlign="center">
              By using Tutopiya’s platform you agree to our{' '}
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.tutopiya.com/policy-handbook/"
                style={termsButtonStyles}
              >
                Terms & Conditions
              </a>
              .
            </Typography>
          </Stack>
        </Box>
      </Stack>

      {/* Modals */}
      <PromoCodeModal
        open={state.isPromoModalOpen}
        onClose={togglePromoModal}
        packageData={state.packageData}
      />
      <AddCardModal
        open={state.isAddCardModalOpen}
        onClose={toggleAddCardModal}
      />
    </div>
  );
};

export default MonthlyPayment;
