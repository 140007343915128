/* eslint-disable */
import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
// MAIN SLICES
import AuthReducer from './AuthSlice';
import InvoiceReducer from './InvoiceSlice';
import CheckoutReducer from './CheckoutSlice';
import PromoCodeReducer from './PromoCodeSlice';
import PackageReducer from './PackageSlice';
import UserReducer from './UserSlice';
import HitPayReducer from './HitPaySlice';
import ApplePayReducer from './ApplePaySlice';
import PaymentReducer from './PaymentSlice';
import StripeReducer from './Stripe';
// OTHERS SLICE
import ConfigReducer from './ConfigSlice';
import ErrorReducer from './ErrorSlice';
import SuccessReducer from './SuccessSlice';

export const store = configureStore({
  reducer: {
    auth: AuthReducer,
    invoice: InvoiceReducer,
    checkout: CheckoutReducer,
    promoCode: PromoCodeReducer,
    package: PackageReducer,
    user: UserReducer,
    hitPay: HitPayReducer,
    applePay: ApplePayReducer,
    payment: PaymentReducer,
    stripe: StripeReducer,
    // OTHER SLICE
    config: ConfigReducer,
    error: ErrorReducer,
    success: SuccessReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
export default store;
