import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '..';
import {
  getPaymentClasses,
  getPaymentClassFreeSlots,
  getReciept,
  validatePaymentClasses,
  getFreeSlotsForTheWeekInBackground,
} from './PaymentApi';
import { IFlexiblePlan } from '../../constants';
import { IPromoCodeData } from '../PromoCodeSlice';

export interface IPaymentReceipt {
  discount: number;
  promoCurrency?: string;
  amount: number;
  currency: string;
  curriculum: string;
  updatedHours: number;
  packageId: 'CUSTOM INVOICE' | IFlexiblePlan['id'];
  ratePerHour: number;
  transactionDate: string;
  transactionId: string;
  userName: string;
  promoCodeDetails: IPromoCodeData;
  packageHours: 15 | 30 | 60;
  packageRate: number;
  originalRate: number;
  subjectCount?: number;
  minutesPerWeek?: number;
  isInstalmentPayment?: boolean;
  instalmentCount?: number;
}

export interface IPaymentLastClass {
  subject: string;
  curriculum: string;
  grade: string;
  tutor: {
    id: string;
    firstName: string;
    lastName?: string;
  };
  tutorId: string;
  lastClassEnd: number;
  lastClassStart: number;
}

export interface ICustomPaymentClass
  extends Omit<IPaymentLastClass, 'lastClassEnd' | 'lastClassStart' | 'tutor'> {
  id: string;
  lastClassStart?: number;
  classStart?: number;
  classEnd?: number;
  duration: number; // minutes
  numberOfClasses?: number;
  maxNumberOfClasses: number;
  tutor?: {
    id: string;
    firstName: string;
    lastName?: string;
  };
  error?: string;
  classClashDate?: string;
  isChecked?: boolean;
}

export interface IPaymentFreeSlot {
  booked: boolean;
  classTitle: string;
  consultantId: string;
  end: string;
  platform: 'ZOOM';
  start: string;
  status: 'active';
  title: string;
  type: 'free';
}

export interface IValidatedPaymentClassesData {
  showPaymentModal: boolean;
  showConflictModal: boolean;
  conflictPaymentClasses: ICustomPaymentClass[];
}

export interface IPaymentState {
  status: 'idle' | 'loading' | 'rejected';
  classes: IPaymentLastClass[];
  freeslots: IPaymentFreeSlot[];
  freeSlotsForTheWeek: Array<{ start: string }>;
  receipt: IPaymentReceipt | undefined;
  validatedPaymentClassessData: IValidatedPaymentClassesData | undefined;
}

const initialState: IPaymentState = {
  status: 'idle',
  classes: [],
  freeslots: [],
  freeSlotsForTheWeek: [],
  receipt: undefined,
  validatedPaymentClassessData: undefined,
};

export const PaymentSlice = createSlice({
  name: 'payment',
  initialState,
  reducers: {
    resetPaymentReceipt: (state) => {
      state.receipt = undefined;
    },
    updateValidatedPaymentClass: (
      state,
      action: PayloadAction<{
        id: string;
        classStart: number;
        classEnd: number;
        duration: number;
        numberOfClasses: number;
      }>
    ) => {
      if (
        state.validatedPaymentClassessData &&
        state.validatedPaymentClassessData.conflictPaymentClasses &&
        state.validatedPaymentClassessData.conflictPaymentClasses.length > 0 &&
        action.payload &&
        action.payload.id
      ) {
        const newConflictPaymentClassess = [
          ...state.validatedPaymentClassessData.conflictPaymentClasses,
        ];
        const index = newConflictPaymentClassess.findIndex(
          (c) => c.id === action.payload.id
        );
        if (index > -1) {
          const _item = newConflictPaymentClassess[index];
          newConflictPaymentClassess[index] = {
            ..._item,
            ...action.payload,
            error: undefined,
            classClashDate: undefined,
          };
        }
        const validatedPaymentClassessData: IValidatedPaymentClassesData = {
          ...state.validatedPaymentClassessData,
          conflictPaymentClasses: newConflictPaymentClassess,
          showConflictModal: false,
        };
        state.validatedPaymentClassessData = validatedPaymentClassessData;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      // GET USER
      .addCase(getPaymentClasses.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getPaymentClasses.fulfilled, (state, action) => {
        state.status = 'idle';
        state.classes = action.payload;
      })
      .addCase(getPaymentClasses.rejected, (state) => {
        state.status = 'rejected';
      })
      // GET PAYMENT CLASS FREESLOTS
      .addCase(getPaymentClassFreeSlots.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getPaymentClassFreeSlots.fulfilled, (state, action) => {
        state.status = 'idle';
        state.freeslots = action.payload;
      })
      .addCase(getPaymentClassFreeSlots.rejected, (state) => {
        state.status = 'rejected';
      })
      // GET PAYMENT RECEIPT
      .addCase(getReciept.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getReciept.fulfilled, (state, action) => {
        state.status = 'idle';
        state.receipt = action.payload;
      })
      .addCase(getReciept.rejected, (state) => {
        state.status = 'rejected';
      })
      // VALIDATE PAYMENT CLASSES
      .addCase(validatePaymentClasses.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(validatePaymentClasses.fulfilled, (state, action) => {
        state.status = 'idle';
        const validatedPaymentClassessData: IValidatedPaymentClassesData = {
          showPaymentModal: true,
          showConflictModal: false,
          conflictPaymentClasses: [],
        };
        if (action && action.payload && action.payload.length > 0) {
          const _conflictClasses = action.payload.filter((c) => c.error);
          if (_conflictClasses && _conflictClasses.length > 0) {
            validatedPaymentClassessData.showConflictModal = true;
            validatedPaymentClassessData.showPaymentModal = false;
            validatedPaymentClassessData.conflictPaymentClasses = _conflictClasses;
          }
        }
        state.validatedPaymentClassessData = validatedPaymentClassessData;
      })
      .addCase(validatePaymentClasses.rejected, (state) => {
        state.status = 'rejected';
      })
      // GET FREE SLOTS FOR THE WEEK
      .addCase(
        getFreeSlotsForTheWeekInBackground.fulfilled,
        (state, action) => {
          state.freeSlotsForTheWeek = action.payload;
        }
      );
  },
});

export const getPaymentState = (state: RootState): RootState['payment'] =>
  state.payment;

export const {
  resetPaymentReceipt,
  updateValidatedPaymentClass,
} = PaymentSlice.actions;
export * from './PaymentApi';
export default PaymentSlice.reducer;
