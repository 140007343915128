import { createSlice } from '@reduxjs/toolkit';

import { RootState } from '..';
import {
  getStripeCards,
  getStripeSecretKey,
  getStripeSubscriptionById,
  stripeSubscribe,
  validateStripePaymentClasses,
  createStripeSession,
  getStripeSession,
  validateStripeInvoiceSession,
  getStripeInvoiceData,
} from './StripeApi';
import { IFlexiblePlan, IWeeklyPlan } from '../../constants';
import { IPromoCode } from '../PromoCodeSlice';
import { ICustomPaymentClass } from '../PaymentSlice';
import { IInvoiceSubject } from '../InvoiceSlice';

export type TStripeSession = {
  id: string;
  // eslint-disable-next-line camelcase
  cancel_url: string;
};

export type TStripeInvoice = {
  isClassesScheduled?: boolean;
  qty: number;
  rate: number;
  userId: string;
  invoiceNumber: number;
  stripeSessionId: string;
  subjects?: IInvoiceSubject[];
};

export type TStripeInvoiceData = {
  status: 'success' | 'fail';
  session: TStripeSession;
  invoice: TStripeInvoice;
};

export interface IStripeCard {
  id: string;
  card: {
    brand: 'visa';
    country: string;
    fingerprint: string;
    funding: 'credit';
    last4: string;
  };
}

export interface IStripeSubscription {
  id: string;
  status: 'active' | 'incomplete';
  // eslint-disable-next-line camelcase
  latest_invoice: {
    // eslint-disable-next-line camelcase
    hosted_invoice_url: string;
    // eslint-disable-next-line camelcase
    payment_intent: {
      // eslint-disable-next-line camelcase
      client_secret: string;
    };
  };
}

export interface IStripeCheckoutSessionValidation {
  id: string;
  userId: string;
  session: any;
  packageId: string;
  curency: string;
  subscriptionType: 'monthly' | 'upfront' | 'flexible' | '';
  isWebhookCalled: boolean;
  minutesPerWeek: number;
  subjectCount: number;
  invoice: string;
}

export interface IStripePaymentData {
  userId: string;
  amount: number;
  currency: string;
  minutesPerWeek: number;
  packageId: IWeeklyPlan['id'] | IFlexiblePlan['id'];
  sourceId: string;
  subjectCount: number;
  subscriptionType: 'monthly' | 'upfront' | 'flexible';
  promoCodeData?: IPromoCode;
}

export interface ISubscriptionData {
  id: string;
  userId: string;
  subscription: IStripeSubscription;
  paymentData: IStripePaymentData;
}

export interface IStripeValidatedPaymentClassesData {
  showSuccessScreen: boolean;
  showConflictModal: boolean;
  conflictPaymentClasses: ICustomPaymentClass[];
}

export type TInvoiceValidationData = {
  status: 'success' | 'fail' | 'expired';
  url?: string;
  message?: string;
};

export interface IStripeState {
  status: 'idle' | 'loading' | 'rejected';
  secretData?: {
    clientSecret: string;
  };
  cards: IStripeCard[];
  stripeSubscription: IStripeSubscription | undefined;
  subscriptionData: ISubscriptionData | undefined;
  validatedPaymentClassessData: IStripeValidatedPaymentClassesData | undefined;
  CheckoutSession: {
    redirectUrl: string;
    sessionId: string;
  };
  session: IStripeCheckoutSessionValidation;
  invoiceValidationData: TInvoiceValidationData | undefined;
  stripeInvoiceData: TStripeInvoiceData | undefined;
}

const initialState: IStripeState = {
  status: 'idle',
  secretData: undefined,
  cards: [],
  stripeSubscription: undefined,
  subscriptionData: undefined,
  validatedPaymentClassessData: undefined,
  CheckoutSession: {
    redirectUrl: '',
    sessionId: '',
  },
  session: {
    id: '',
    userId: '',
    session: undefined,
    packageId: 'Bronze Plan',
    curency: '',
    isWebhookCalled: false,
    subscriptionType: '',
    minutesPerWeek: 0,
    subjectCount: 0,
    invoice: '',
  },
  invoiceValidationData: undefined,
  stripeInvoiceData: undefined,
};

export const StripeSlice = createSlice({
  name: 'checkout',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Get stripe secret key
      .addCase(getStripeSecretKey.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getStripeSecretKey.fulfilled, (state, action) => {
        state.status = 'idle';
        state.secretData = action.payload;
      })
      .addCase(getStripeSecretKey.rejected, (state) => {
        state.status = 'rejected';
        state.secretData = undefined;
      })
      // Get stripe cards
      .addCase(getStripeCards.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getStripeCards.fulfilled, (state, action) => {
        state.status = 'idle';
        state.cards = action.payload.cards;
      })
      .addCase(getStripeCards.rejected, (state) => {
        state.status = 'rejected';
        state.cards = [];
      })
      // Stripe subscribe
      .addCase(stripeSubscribe.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(stripeSubscribe.fulfilled, (state, action) => {
        state.status = 'idle';

        const subscriptionData = action.payload.subscription;
        state.stripeSubscription = subscriptionData;
      })
      .addCase(stripeSubscribe.rejected, (state) => {
        state.status = 'rejected';
      })
      // Get stripe subscription data from DB
      .addCase(getStripeSubscriptionById.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getStripeSubscriptionById.fulfilled, (state, action) => {
        state.status = 'idle';
        state.subscriptionData = action.payload;
      })
      .addCase(getStripeSubscriptionById.rejected, (state) => {
        state.status = 'rejected';
      })
      // Stripe validate schedule
      .addCase(validateStripePaymentClasses.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(validateStripePaymentClasses.fulfilled, (state, action) => {
        state.status = 'idle';

        const paymentClasses = action.payload || [];
        const newValidatedPaymentClassessData: IStripeValidatedPaymentClassesData = {
          showSuccessScreen: true,
          showConflictModal: false,
          conflictPaymentClasses: [],
        };

        const conflictPaymentClasses = paymentClasses.filter(
          (paymentClass) => paymentClass.error
        );

        if (conflictPaymentClasses.length) {
          newValidatedPaymentClassessData.showSuccessScreen = false;
          newValidatedPaymentClassessData.showConflictModal = true;
          newValidatedPaymentClassessData.conflictPaymentClasses = conflictPaymentClasses;
        }

        state.validatedPaymentClassessData = newValidatedPaymentClassessData;
      })
      .addCase(validateStripePaymentClasses.rejected, (state) => {
        state.status = 'rejected';
      })
      .addCase(createStripeSession.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(createStripeSession.fulfilled, (state, action) => {
        state.status = 'idle';
        state.CheckoutSession = action.payload;
      })
      .addCase(createStripeSession.rejected, (state) => {
        state.status = 'rejected';
        state.secretData = undefined;
      })
      .addCase(getStripeSession.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getStripeSession.fulfilled, (state, action) => {
        state.status = 'idle';
        state.session = action.payload;
      })
      .addCase(getStripeSession.rejected, (state) => {
        state.status = 'rejected';
        state.session = {
          id: '',
          userId: '',
          session: undefined,
          packageId: 'Bronze Plan',
          curency: '',
          isWebhookCalled: false,
          subscriptionType: '',
          minutesPerWeek: 0,
          subjectCount: 0,
          invoice: '',
        };
      })
      // Stripe invoice validation
      .addCase(validateStripeInvoiceSession.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(validateStripeInvoiceSession.fulfilled, (state, action) => {
        state.status = 'idle';
        state.invoiceValidationData = action.payload;
      })
      .addCase(validateStripeInvoiceSession.rejected, (state, action) => {
        state.status = 'rejected';
      })
      // Get stripe invoice data
      .addCase(getStripeInvoiceData.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getStripeInvoiceData.fulfilled, (state, action) => {
        state.status = 'idle';
        state.stripeInvoiceData = action.payload;
      })
      .addCase(getStripeInvoiceData.rejected, (state) => {
        state.status = 'rejected';
      });
  },
});

export const getStripeState = (state: RootState): RootState['stripe'] =>
  state.stripe;

export * from './StripeApi';
export default StripeSlice.reducer;
