import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  Card,
  CardContent,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Drawer,
  FormControl,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material';

import { useAppSelector } from '../../../hooks';
import {
  IValidatePromoCode,
  getPromoCodeState,
  resetPromoCodeState,
  validatePromoCode,
} from '../../../store/PromoCodeSlice';
import { isMobileScreen } from '../../../helper';
import { IPackageData } from '../MonthlyPayment';
import { IPackageData as IFlexiblePacakgeData } from '../FlexiblePayment';

interface PromoCodeModalProps {
  open: boolean;
  onClose: () => void;
  packageData: IPackageData | IFlexiblePacakgeData | undefined;
}

interface IState {
  promoCodeText: string;
  initialPromoCodeSubmitted: boolean;
}

const initialState: IState = {
  promoCodeText: '',
  initialPromoCodeSubmitted: false,
};

const modalHeading = 'Add Promo code';

const PromoCodeModal: React.FC<PromoCodeModalProps> = ({
  open,
  onClose,
  packageData,
}) => {
  const dispatch = useDispatch();
  const params = useParams();
  const location = useLocation();

  // Store
  const promoCodeState = useAppSelector(getPromoCodeState);

  // State
  const [state, setState] = useState(initialState);

  const isMobile = isMobileScreen();
  const queryStringParams = new URLSearchParams(location.search);
  const userId = params.userId ? params.userId.trim() : '';
  const planKey = params.packageName ? params.packageName.trim() : '';
  const promoCodeData = promoCodeState.promoCode;
  const isSubmitDisabled = state.promoCodeText.trim().length === 0;

  useEffect(() => {
    if (!state.initialPromoCodeSubmitted && packageData) {
      const urlPromoCode = (queryStringParams.get('promoCode') || '').trim();

      if (urlPromoCode) {
        onSubmit(urlPromoCode);

        setState((prevState) => ({
          ...prevState,
          promoCodeText: urlPromoCode,
          initialPromoCodeSubmitted: true,
        }));
      }
    }
  }, [packageData]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const onSubmit = (newPromoCode = '') => {
    let usablePromoCode = state.promoCodeText.trim();

    if (newPromoCode.trim()) {
      usablePromoCode = newPromoCode.trim();
    }

    dispatch(resetPromoCodeState());

    const apiData: IValidatePromoCode = {
      userId,
      promoCode: usablePromoCode,
      packageId: planKey,
    };

    if (packageData && packageData.subjectCount) {
      apiData.subjectCount = packageData.subjectCount;
    }

    if (packageData && packageData.minutesPerWeek) {
      apiData.minutesPerWeek = packageData.minutesPerWeek;
    }

    dispatch(validatePromoCode(apiData));
  };

  const handleRemove = () => {
    dispatch(resetPromoCodeState());

    setState((prevState) => ({
      ...prevState,
      promoCodeText: '',
    }));
  };

  const renderContent = () => {
    return (
      <>
        <div style={{ marginBottom: '20px' }}>
          <Typography color="secondary">
            Don’t miss out on exclusive savings! Use our promo code at checkout
            to get a great deal on your purchase.
          </Typography>
        </div>
        <div style={{ marginBottom: '15px' }}>
          <FormControl fullWidth>
            <TextField
              size="small"
              name="promoCodeText"
              value={state.promoCodeText}
              placeholder="Enter here..."
              onChange={handleInputChange}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {promoCodeData && promoCodeData.price ? (
                      <Button
                        variant="contained"
                        color="secondary"
                        size="small"
                        style={{ width: '100%', marginRight: '-15px' }}
                        onClick={handleRemove}
                      >
                        Remove
                      </Button>
                    ) : null}
                  </InputAdornment>
                ),
              }}
            />
          </FormControl>
        </div>
        <Collapse in={promoCodeData?.valid === false}>
          <Card elevation={0} sx={{ backgroundColor: 'error.light' }}>
            <CardContent style={{ padding: '10px' }}>
              <Typography variant="subtitle2" color="error">
                {promoCodeData?.message || 'Promocode not applied'}
              </Typography>
            </CardContent>
          </Card>
        </Collapse>
        <Collapse in={promoCodeData?.valid}>
          <Card elevation={0} sx={{ backgroundColor: 'success.light' }}>
            <CardContent style={{ padding: '10px' }}>
              <Typography variant="subtitle2" color="success.main">
                {promoCodeData?.message || 'Promo code applied!'}
              </Typography>
            </CardContent>
          </Card>
        </Collapse>
      </>
    );
  };

  if (isMobile) {
    return (
      <Drawer
        open={open}
        onClose={onClose}
        anchor="bottom"
        PaperProps={{
          style: {
            maxHeight: '80vh',
            borderTopLeftRadius: '12px',
            borderTopRightRadius: '12px',
          },
        }}
      >
        <Box role="presentation" style={{ padding: '30px 15px' }}>
          <div style={{ marginBottom: '15px' }}>
            <Typography variant="h6" textAlign="center" fontWeight={600}>
              {modalHeading}
            </Typography>
          </div>
          {renderContent()}
          <div>
            <LoadingButton
              fullWidth
              variant="contained"
              color="primary"
              onClick={() => onSubmit()}
              disabled={isSubmitDisabled}
              loading={promoCodeState.status === 'loading'}
              style={{ marginTop: '15px' }}
            >
              Apply
            </LoadingButton>
          </div>
        </Box>
      </Drawer>
    );
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{modalHeading}</DialogTitle>
      <DialogContent>{renderContent()}</DialogContent>
      <DialogActions style={{ padding: '15px 30px' }}>
        <Button
          size="small"
          variant="contained"
          color="inherit"
          onClick={onClose}
          style={{ minWidth: '70px' }}
        >
          Dismiss
        </Button>
        <LoadingButton
          size="small"
          variant="contained"
          color="primary"
          onClick={() => onSubmit()}
          disabled={isSubmitDisabled}
          style={{ minWidth: '70px' }}
          loading={promoCodeState.status === 'loading'}
        >
          Apply
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default PromoCodeModal;
