import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  Button,
  Card,
  CardContent,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material';
// HOOKS
import { useAppSelector } from '../../../hooks';
// STORE
import {
  validatePromoCode,
  getPromoCodeState,
  resetPromoCodeState,
} from '../../../store/PromoCodeSlice';
// HELPERS
import { isMobileScreen } from '../../../helper';
// CONSTANTS
import { TP_COLORS } from '../../../constants';
import { getInvoiceState } from '../../../store/InvoiceSlice';

const PromoCard: React.FC = () => {
  // HOOKS
  const dispatch = useDispatch();

  // STORE
  const promoCodeState = useAppSelector(getPromoCodeState);
  const invoiceState = useAppSelector(getInvoiceState);

  const invoiceData = invoiceState.invoice;
  const promoCodeData = promoCodeState.promoCode;

  const isMobile = isMobileScreen();

  // LOCAL STATE
  const [promoCode, setPromoCode] = useState('');

  const handleAdd = () => {
    if (invoiceData && promoCode) {
      dispatch(
        validatePromoCode({
          userId: invoiceData.userId,
          promoCode,
          invoiceId: invoiceData.id,
        })
      );
    }
  };
  const handleRemove = () => {
    dispatch(resetPromoCodeState());
  };

  return (
    <Card
      elevation={0}
      sx={{
        border: isMobile ? 'none' : { xs: 'none', sm: '1px solid lightgray' },
        p: isMobile ? 0 : { xs: '0', sm: '15px' },
        mb: '15px',
      }}
    >
      <CardContent sx={{ backgroundColor: TP_COLORS.listItemGray.light }}>
        <div>
          <Typography variant="h6" fontWeight={600} sx={{ mb: '15px' }}>
            Promo Code
          </Typography>
          <TextField
            style={{ width: '100%' }}
            sx={{ backgroundColor: 'white' }}
            size="small"
            value={promoCode}
            placeholder="Enter Code"
            onChange={(event) => setPromoCode(event.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {promoCodeData && promoCodeData.price ? (
                    <Button
                      style={{
                        marginRight: '-12px',
                        backgroundColor: '#fedbda',
                      }}
                      variant="text"
                      color="error"
                      onClick={handleRemove}
                    >
                      Remove
                    </Button>
                  ) : (
                    <Button
                      disabled={!promoCode}
                      style={{ marginRight: '-12px' }}
                      variant="contained"
                      color="secondary"
                      onClick={handleAdd}
                    >
                      Add
                    </Button>
                  )}
                </InputAdornment>
              ),
            }}
          />
          {promoCodeData && !promoCodeData.valid && (
            <Typography variant="caption" mt="10px" color="error.main">
              {promoCodeData.message}
            </Typography>
          )}
          {promoCodeData && promoCodeData.valid && (
            <Typography variant="caption" mt="10px" color="success.main">
              {promoCodeData.message}
            </Typography>
          )}
        </div>
      </CardContent>
    </Card>
  );
};

export default PromoCard;
