import { createSlice } from '@reduxjs/toolkit';
// STORE
import { RootState } from '..';
// API
import { validatePromoCode } from './PromoCodeApi';

export interface IPromoCodeData {
  adminNote: string;
  amount: number;
  country: string;
  createdAt: number;
  curriculum: string;
  expiration: number;
  id: string;
  promoCode: string;
  promotionDescription: string;
  promotionType: string;
  startDate: number;
  subject: string;
  usage: string;
  maximumAmount?: number;
  minimumPurchasePrice?: number;
  usageAmount?: number;
  flexibly?: string[];
  monthly?: string[];
  status: 'inactive' | 'active';
}

export interface IPromoCode {
  valid: boolean;
  message: string;
  promoCodeDetails: IPromoCodeData;
  price: number;
  discount: number;
}

export interface IPromoCodeState {
  status: 'idle' | 'loading' | 'rejected';
  promoCode: IPromoCode | undefined;
}

const initialState: IPromoCodeState = {
  status: 'idle',
  promoCode: undefined,
};

export const PromoCodeSlice = createSlice({
  name: 'promo',
  initialState,
  reducers: {
    resetPromoCodeState: (state) => {
      state.promoCode = undefined;
      state.status = 'idle';
    },
  },
  extraReducers: (builder) => {
    // list
    builder
      .addCase(validatePromoCode.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(validatePromoCode.fulfilled, (state, action) => {
        state.status = 'idle';
        state.promoCode = action.payload;
      })
      .addCase(validatePromoCode.rejected, (state) => {
        state.status = 'rejected';
      });
  },
});

export const getPromoCodeState = (state: RootState): RootState['promoCode'] =>
  state.promoCode;
export const { resetPromoCodeState } = PromoCodeSlice.actions;

export * from './PromoCodeApi';
export default PromoCodeSlice.reducer;
