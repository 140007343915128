import React from 'react';
import { Divider, Grid, Typography } from '@mui/material';

interface TextWithDividerProps {
  label: string;
  value: string;
}

const TextWithDivider: React.FC<TextWithDividerProps> = ({ label, value }) => {
  return (
    <Grid
      container
      alignItems="center"
      justifyContent="space-between"
      spacing={2}
      sx={{ mb: '10px' }}
    >
      <Grid item>
        <Typography color="secondary" fontWeight={600}>
          {label}
        </Typography>
      </Grid>
      <Grid item flexGrow={1}>
        <Divider />
      </Grid>
      <Grid item>
        <Typography color="secondary" fontWeight={600}>
          {value}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default TextWithDivider;
