/* eslint-disable class-methods-use-this */
import http from './AuthBaseRequest';

class AuthServiceHelper {
  getClientCredentialsToken() {
    const params = new URLSearchParams();
    params.append('grant_type', 'client_credentials');
    params.append(
      'client_id',
      process.env.REACT_APP_TUTOPIYA_CLIENT_ID as string
    );
    params.append(
      'client_secret',
      process.env.REACT_APP_TUTOPIYA_CLIENT_SECRET as string
    );
    return http.post('/oauth/token', params).then((res) => res.data);
  }
}

export default new AuthServiceHelper();
