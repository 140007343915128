import { createAsyncThunk } from '@reduxjs/toolkit';
import APIDataProvider from '../../dataProvider/APIDataProvider';

import ApiPaths from '../../dataProvider/APIPaths';

export interface IApplePayValidation {
  displayName: string;
  domainName: string;
  epochTimestamp: number;
  expiresAt: number;
  merchantIdentifier: string;
  merchantSessionIdentifier: string;
  nonce: string;
  operationalAnalyticsIdentifier: string;
  pspId: string;
  retries: number;
  signature: string;
}

const ApplePayApiValidationProvider = new APIDataProvider<IApplePayValidation>();
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const ApplePayApiPaymentProvider = new APIDataProvider<any>();

export const applePayValidateSession = createAsyncThunk(
  'applePay/validateSession',
  async (validationUrl: string) => {
    const response = await ApplePayApiValidationProvider.post(
      `${ApiPaths.applePay}/session/validate`,
      { validationUrl }
    );

    return response.data;
  }
);

export const payApplePayment = createAsyncThunk(
  'applePay/pay',
  async (applePaymentToken: ApplePayJS.ApplePayPaymentToken) => {
    const response = await ApplePayApiPaymentProvider.post(
      `${ApiPaths.applePay}/pay`,
      { token: applePaymentToken }
    );

    return response.data;
  }
);
