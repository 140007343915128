import { SubjectColors } from '../constants/Color';

export const getNumberHashFromString = (text: string): number => {
  const _stringToBeHashed: string = text ? text.trim() : 'no-subject';
  let hash = 0;
  if (_stringToBeHashed.length === 0) return hash;
  for (let i = 0; i < _stringToBeHashed.length; i += 1) {
    const charCode = _stringToBeHashed.charCodeAt(i);
    // eslint-disable-next-line no-bitwise
    hash = (hash << 7) - hash + charCode;
    hash = hash && hash;
  }
  return hash;
};

export const getSubjectColor = (subject: string): string => {
  const numberHash: number = getNumberHashFromString(subject);
  const absoluteNumberHash = Math.abs(numberHash);
  const index = absoluteNumberHash % SubjectColors.length;
  const color: string = SubjectColors[index];
  return color;
};
