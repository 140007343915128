export interface ICardImageData {
  src: string;
  alt: string;
}

export const getCardImageData = (brand: 'visa'): ICardImageData => {
  const result = {
    src: '',
    alt: '',
  };

  if (brand === 'visa') {
    result.src = '/images/logos/visa-card.png';
    result.alt = brand;
  }

  return result;
};

export default getCardImageData;
