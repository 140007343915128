import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import {
  SxProps,
  Theme,
  Box,
  Container,
  Grid,
  TypographyProps,
  Stack,
  Typography,
} from '@mui/material';
import moment from 'moment';
// HOOKS
import { useAppSelector } from '../../hooks';
// STORE
import {
  getFlexiblePlanPricesForUser,
  getPackageState,
  getWeeklyPlanPricesForUser,
} from '../../store/PackageSlice';
// TABS
import FlexiblePlansTab from './tabs/FlexiblePlansTab';
import WeeklyPlansTab from './tabs/WeeklyPlansTab';
// STORE
import { getUserById, getUserState } from '../../store/UserSlice';
// COMPONENTS
import BillingHeader, {
  headerHeight,
} from '../../components/Header/BillingHeader';
import DetailViewCard from '../../components/Card/DetailViewCard';
import TabBar, { ITabOption } from '../../components/TabBar';
import FullScreenLoader from '../../components/Loaders/FullScreenloader';

// STYLES
const containerStyles: SxProps<Theme> = {
  backgroundColor: 'background.default',
  color: 'text.primary',
};

const titleProps: TypographyProps = {
  variant: 'caption',
  className: 'ellipsis-text',
  sx: {
    color: 'text.primary',
  },
};

const descriptionProps: TypographyProps = {
  className: 'ellipsis-text',
  variant: 'subtitle2',
  fontWeight: 600,
  sx: {
    color: 'text.primary',
  },
};

// DATA
const tabs: ITabOption[] = [
  {
    index: 0,
    key: 'flexiblePlans',
    title: 'Flexible Plans',
  },
  {
    index: 1,
    key: 'weeklyPlans',
    title: 'Monthly Plans',
    tooltipProps: {
      open: true,
      title: 'Save 10%',
      placement: 'top',
      PopperProps: { style: { zIndex: 1 } },
    },
  },
];

const Packages: React.FC = () => {
  const params = useParams();
  const location = useLocation();
  const dispatch = useDispatch();

  // Store
  const userState = useAppSelector(getUserState);
  const packageState = useAppSelector(getPackageState);

  const { user } = userState;
  const queryStringParams = new URLSearchParams(location.search);

  let hideFlexiblePlanTab =
    user &&
    user.createdAt &&
    process.env.REACT_APP_HIDE_FLEXIBLE_PLANS_DATE &&
    moment(process.env.REACT_APP_HIDE_FLEXIBLE_PLANS_DATE).toDate().getTime() <=
      new Date(user.createdAt).getTime();

  if (user?.allowFlexiblePlanTab === true) {
    hideFlexiblePlanTab = false;
  }

  if (user?.allowFlexiblePlanTab === false) {
    hideFlexiblePlanTab = true;
  }

  if (queryStringParams.get('payment') === 'instalment') {
    hideFlexiblePlanTab = true;
  }

  let hideMonthlyPlanTab =
    user &&
    user.createdAt &&
    process.env.REACT_APP_HIDE_WEEKLY_PLANS_DATE &&
    moment(process.env.REACT_APP_HIDE_WEEKLY_PLANS_DATE).toDate().getTime() <=
      new Date(user.createdAt).getTime();

  if (user?.allowMonthlyPlanTab === true) {
    hideMonthlyPlanTab = false;
  }

  if (user?.allowMonthlyPlanTab === false) {
    hideMonthlyPlanTab = true;
  }

  if (queryStringParams.get('payment') === 'instalment') {
    hideMonthlyPlanTab = true;
  }

  // State
  const [state, setState] = useState({
    tabs: [...tabs],
    selectedTab: tabs ? tabs[0] : undefined,
  });

  useEffect(() => {
    if (params && params.userId) {
      dispatch(getUserById(params.userId));
      dispatch(
        getFlexiblePlanPricesForUser({
          userId: params.userId,
        })
      );

      dispatch(
        getWeeklyPlanPricesForUser({
          userId: params.userId,
        })
      );
    }
  }, []);

  useEffect(() => {
    setState((prevState) => {
      const newTabs = [...prevState.tabs]
        .filter(filterOutUnwantedTabs)
        .map((tabItem) => {
          if (tabItem.key === 'weeklyPlans' && hideFlexiblePlanTab) {
            return {
              ...tabItem,
              title: 'Plans',
            };
          }
          if (tabItem.key === 'flexiblePlans' && hideMonthlyPlanTab) {
            return {
              ...tabItem,
              title: 'Plans',
            };
          }

          return tabItem;
        });

      return {
        ...prevState,
        tabs: newTabs,
        selectedTab: newTabs[0],
      };
    });
  }, [user]);

  const handleTabChange = (tabOption: ITabOption) => {
    setState((prevState) => ({
      ...prevState,
      selectedTab: tabOption,
    }));
  };

  const filterOutUnwantedTabs = (tabOption) => {
    if (tabOption.key === 'flexiblePlans') {
      if (hideFlexiblePlanTab) {
        return false;
      }
    }
    if (tabOption.key === 'weeklyPlans') {
      if (hideMonthlyPlanTab) {
        return false;
      }
    }

    return true;
  };

  const renderTabContent = () => {
    if (state.selectedTab && state.selectedTab.key === 'flexiblePlans') {
      return <FlexiblePlansTab />;
    }

    if (state.selectedTab && state.selectedTab.key === 'weeklyPlans') {
      return <WeeklyPlansTab />;
    }

    if (!state.selectedTab) {
      return (
        <Stack alignItems="center" style={{ padding: '30px 0 15px 0' }}>
          <img
            src="/images/objects/Online calendar.png"
            alt="empty-schedule"
            width="90%"
            style={{ maxWidth: '400px' }}
          />
          <Typography variant="h5" sx={{ mt: '15px' }}>
            No Plans Available
          </Typography>
        </Stack>
      );
    }

    return null;
  };

  if (packageState.status === 'loading' || userState.status === 'loading') {
    return (
      <FullScreenLoader
        containerProps={{
          style: { height: `calc(100vh - ${headerHeight})` },
        }}
      />
    );
  }

  return (
    <Box sx={containerStyles}>
      {/* Billing Header */}
      <BillingHeader />

      {/* MAIN */}
      <Box
        component="main"
        sx={{
          minHeight: `calc(100vh - ${headerHeight})`,
          pt: headerHeight,
        }}
      >
        <Container maxWidth="xl">
          <div style={{ margin: '30px 0' }}>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={12} sm={4} md={4} lg={3}>
                <DetailViewCard
                  title="Student's Name"
                  description={
                    user && user.firstName
                      ? `${user.firstName}${
                          user.lastName ? ` ${user.lastName}` : ''
                        }`
                      : '-'
                  }
                  titleProps={titleProps}
                  descriptionProps={descriptionProps}
                  avatarProps={{
                    src: user && user.avatar,
                    alt:
                      user && user.firstName
                        ? user.firstName.substring(0, 1)
                        : '',
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={4} md={4} lg={3}>
                <DetailViewCard
                  title="Curriculum"
                  description={user && user.curriculum ? user.curriculum : '-'}
                  titleProps={titleProps}
                  descriptionProps={descriptionProps}
                />
              </Grid>
              <Grid item xs={12} sm={4} md={4} lg={3}>
                <DetailViewCard
                  title="Grade"
                  description={user && user.grade ? user.grade : '-'}
                  titleProps={titleProps}
                  descriptionProps={descriptionProps}
                />
              </Grid>
            </Grid>
          </div>
          <div style={{ marginBottom: '30px' }}>
            {state.selectedTab && (
              <TabBar
                selectedOption={state.selectedTab}
                tabOptions={state.tabs}
                onChange={handleTabChange}
              />
            )}
          </div>
          <div style={{ marginBottom: '30px' }}>
            <div>{renderTabContent()}</div>
          </div>
        </Container>
      </Box>
    </Box>
  );
};

export default Packages;
