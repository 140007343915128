import React from 'react';
import {
  Card,
  CardContent,
  Stack,
  Switch,
  SwitchProps,
  Typography,
  styled,
} from '@mui/material';

import { TP_COLORS } from '../../../constants';

interface MonthlyPlanSubscriptionSwitcherProps {
  isChecked: boolean;
  toggle: () => void;
}

const CustomSwitch = styled((props: SwitchProps) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: TP_COLORS.tutopiyaGreenMain.light,
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color:
        theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 22,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: '#C9D4DC',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));

const MonthlyPlanSubscriptionSwitcher: React.FC<MonthlyPlanSubscriptionSwitcherProps> = ({
  isChecked,
  toggle,
}) => {
  return (
    <Card
      elevation={0}
      style={{
        background:
          'transparent linear-gradient(266deg, #01B8AF 0%, #015C58 100%) 0% 0% no-repeat padding-box',
      }}
    >
      <CardContent style={{ paddingBottom: '16px' }}>
        <Stack direction="row" justifyContent="center" alignItems="center">
          <Typography
            variant="h6"
            color={TP_COLORS.white}
            fontWeight={600}
            sx={{
              fontSize: {
                xs: '14px',
                sm: '1rem',
              },
            }}
          >
            Pay Upfront
          </Typography>
          <div style={{ margin: '0 20px' }}>
            <CustomSwitch checked={isChecked} onChange={toggle} />
          </div>
          <Typography
            variant="h6"
            color={TP_COLORS.white}
            fontWeight={600}
            sx={{
              fontSize: {
                xs: '14px',
                sm: '1rem',
              },
            }}
          >
            Pay Monthly
          </Typography>
        </Stack>
      </CardContent>
    </Card>
  );
};

export default MonthlyPlanSubscriptionSwitcher;
