import React from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  Card,
  CardProps,
  Theme,
  CardContent,
  Box,
  Typography,
  Stack,
  Button,
  SxProps,
} from '@mui/material';
import DoneIcon from '@mui/icons-material/Done';

import {
  instalmentWeeksCount,
  IWeeklyPlan,
  subscriptionWeeks,
  SubjectColors,
} from '../../../constants';
import { TSubscriptionType } from '../tabs/WeeklyPlansTab';

interface WeeklyPlanCardProps {
  cardStyles?: CardProps['sx'];
  plan: IWeeklyPlan;
  selectedHourCount: number;
  selectedSubjectsCount: number;
  subscriptionType: TSubscriptionType;
}

// STYLES
const defaltHeaderContainerStyles: SxProps<Theme> = {
  borderRadius: '7px',
  color: '#fff',
  padding: '10px 15px',
};

const cardHeaderTitleBottomLineStyles: React.CSSProperties = {
  backgroundColor: '#fff',
  borderRadius: '4px',
  minHeight: '3px',
  minWidth: '130px',
  maxWidth: '130px',
};

const badgeContainerStyles: SxProps<Theme> = {
  marginTop: '-18px',
  marginRight: '-22px',
};

const defaultCardHeaderContainerBackground =
  "url('/img/biling/planCard/half-circle-dock-top-right.png') no-repeat top right/150px, url('/img/biling/planCard/half-circle-dock-bottom-left.png') no-repeat bottom left/100px";

// DATA
const getCardColor = (planId: IWeeklyPlan['id']) => {
  let color = SubjectColors[0];

  switch (planId) {
    case 'Bronze Plan':
      // eslint-disable-next-line prefer-destructuring
      color = SubjectColors[0];
      break;

    case 'Silver Plan':
      // eslint-disable-next-line prefer-destructuring
      color = SubjectColors[1];
      break;

    case 'Gold Plan':
      // eslint-disable-next-line prefer-destructuring
      color = SubjectColors[4];
      break;

    default:
      // eslint-disable-next-line prefer-destructuring
      color = SubjectColors[0];
      break;
  }

  return color;
};

const checkFeatureHighlight = (planId: IWeeklyPlan['id'], index: number) => {
  let isHighLighted = false;

  // const bronzePlanHighLightFeatureIndexes = [];
  const silverPlanHighLightFeatureIndexes = [] as number[];
  const goldPlanHighLightFeatureIndexes = [] as number[];

  switch (planId) {
    case 'Silver Plan':
      isHighLighted = silverPlanHighLightFeatureIndexes.includes(index);
      break;

    case 'Gold Plan':
      isHighLighted = goldPlanHighLightFeatureIndexes.includes(index);
      break;

    default:
      isHighLighted = false;
      break;
  }

  return isHighLighted;
};

const getCardBadgeUrl = (planId: IWeeklyPlan['id']) => {
  let badge = '/images/shapes/billing/bronze-weekly-plan-badge.png';

  switch (planId) {
    case 'Silver Plan':
      badge = '/images/shapes/billing/silver-weekly-plan-badge.png';
      break;

    case 'Gold Plan':
      badge = '/images/shapes/billing/gold-weekly-plan-badge.png';
      break;

    default:
      badge = '/images/shapes/billing/bronze-weekly-plan-badge.png';
      break;
  }

  return badge;
};

const WeeklyPlanCard: React.FC<WeeklyPlanCardProps> = ({
  cardStyles = {},
  plan,
  selectedHourCount,
  selectedSubjectsCount,
  subscriptionType = 'upfront',
}) => {
  const navigate = useNavigate();
  const params = useParams();
  const location = useLocation();

  const _numberRate = Number(plan.rate);
  const _numberWeek = Number(plan.weeks);
  const _hours = selectedHourCount / 60;

  let _price = plan.rate
    ? parseFloat((_numberRate * _hours * _numberWeek).toString()).toString()
    : '0';

  if (subscriptionType === 'instalment') {
    _price = parseFloat(
      ((Number(_price) / _numberWeek) * instalmentWeeksCount).toString()
    ).toString();
  } else if (subscriptionType === 'monthly') {
    _price = parseFloat(
      ((Number(_price) / _numberWeek) * subscriptionWeeks).toString()
    ).toString();
  }

  const _subjectColor = getCardColor(plan.id);
  const _badgeUrl = getCardBadgeUrl(plan.id);

  const _headerContainerStyes: SxProps<Theme> = {
    ...defaltHeaderContainerStyles,
    background: `${defaultCardHeaderContainerBackground}, ${_subjectColor}`,
  };

  const extraOptions = [
    `${selectedSubjectsCount} Subject${selectedSubjectsCount > 1 ? 's' : ''}`,
    `${parseFloat((selectedHourCount / 60).toFixed(2)).toString()} Hour${
      selectedHourCount >= 120 ? 's' : ''
    } per Week`,
  ];

  if (plan.id === 'Silver Plan') {
    extraOptions.push('1 Pause for Holidays');
  }

  if (plan.id === 'Gold Plan') {
    extraOptions.push('2 Pauses for Holidays');
  }

  const onPurchase = () => {
    if (subscriptionType === 'instalment') {
      navigate(
        `/payment/instalment/${plan.key}/${
          params.userId
        }?subjects=${selectedSubjectsCount}&mins=${selectedHourCount}${
          location.search ? `&${location.search.substring(1)}` : ''
        }`
      );
    } else {
      navigate(
        `/payment/session/${plan.key}/${
          params.userId
        }?subjects=${selectedSubjectsCount}&mins=${selectedHourCount}&subscriptionType=${subscriptionType}${
          location.search ? `&${location.search.substring(1)}` : ''
        }`
      );
    }
  };

  return (
    <Card
      elevation={3}
      sx={{
        borderRadius: '7px',
        height: '100%',
        minHeight: '700px',
        ...cardStyles,
      }}
    >
      <CardContent style={{ padding: '8px' }}>
        {/* CARD HEADER */}
        <Box sx={_headerContainerStyes}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <div>
              <div style={{ marginBottom: '8px' }}>
                <Typography className="ellipsis-text" fontWeight={500}>
                  {plan.title} - {plan.durationTitle}
                </Typography>
              </div>
              <div style={cardHeaderTitleBottomLineStyles} />
            </div>
            <Box sx={badgeContainerStyles}>
              <img
                src={_badgeUrl}
                alt={`${plan.id}-badge`}
                style={{ width: '65px' }}
              />
            </Box>
          </Stack>
          <div style={{ margin: '40px 0 20px 0' }}>
            <Typography textAlign="center" className="ellipsis-text">
              <span style={{ fontWeight: 500, fontSize: '20px' }}>
                {plan.currency || 'SGD'}
              </span>
              &nbsp;&nbsp;
              <span style={{ fontWeight: 600, fontSize: '40px' }}>
                {plan.rate || '0'}
              </span>
              &nbsp;
              <span style={{ fontWeight: 400, fontSize: '14px' }}>
                / per hour
              </span>
            </Typography>
          </div>
          <div style={{ minHeight: '92px', padding: '0 15px' }}>
            {extraOptions.map((option, index) => (
              <div
                key={index}
                style={{ marginBottom: '10px', minHeight: '20px' }}
              >
                <Stack direction="row" alignItems="center">
                  <div style={{ marginRight: '10px' }}>
                    <DoneIcon fontSize="small" style={{ color: '#fff' }} />
                  </div>
                  <div>
                    <Typography
                      className="ellipsis-text"
                      variant="subtitle2"
                      fontWeight={500}
                    >
                      {option}
                    </Typography>
                  </div>
                </Stack>
              </div>
            ))}
          </div>
          <div style={{ marginTop: '20px' }}>
            <Button
              fullWidth
              disabled={Number(plan.rate) <= 0}
              variant="contained"
              color="inherit"
              onClick={onPurchase}
              style={{
                backgroundColor: '#fff',
                color: '#000',
                fontWeight: 600,
              }}
            >
              Buy now for {plan.currency || 'SGD'} {_price}
              {subscriptionType === 'instalment' ||
              subscriptionType === 'monthly' ? (
                <>
                  &nbsp;
                  <Typography
                    component="span"
                    variant="caption"
                    fontWeight={600}
                  >
                    (Monthly)
                  </Typography>
                </>
              ) : null}
            </Button>
          </div>
        </Box>

        <div style={{ marginTop: '30px' }}>
          {plan &&
            plan.features &&
            plan.features.map((item, index) => {
              const isHighlighted = checkFeatureHighlight(plan.id, index);

              return (
                <div key={index} style={{ marginBottom: '20px' }}>
                  <Stack direction="row" alignItems="center">
                    <div style={{ marginRight: '10px' }}>
                      <DoneIcon
                        fontSize="small"
                        color={isHighlighted ? undefined : 'secondary'}
                      />
                    </div>
                    <div>
                      <Typography
                        className="ellipsis-text"
                        fontWeight={isHighlighted ? 600 : 500}
                        color={isHighlighted ? undefined : 'secondary'}
                      >
                        {item}
                      </Typography>
                    </div>
                  </Stack>
                </div>
              );
            })}
        </div>
      </CardContent>
    </Card>
  );
};

export default WeeklyPlanCard;
