import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  ICustomPaymentClass,
  IPaymentFreeSlot,
  IPaymentLastClass,
  IPaymentReceipt,
} from '.';
import APIDataProvider from '../../dataProvider/APIDataProvider';
import ApiPaths from '../../dataProvider/APIPaths';

export interface IGetPaymentReceipt {
  sourceId: string;
}

export interface IGetPaymentClasses {
  userId: string;
  eventId?: string;
}

export interface IGetPaymentClassFreeSlots {
  date: string;
  tutorId: string;
  duration: number;
}

export interface IGetPaymentClassFreeSlotsForTheWeek {
  date: number;
  tutorId: string;
}

export interface IValidatePaymentClassess {
  userId: string;
  paymentClasses: ICustomPaymentClass[];
}

const PaymentReceiptApiDataProvider = new APIDataProvider<IPaymentReceipt>();
const PaymentApiDataProvider = new APIDataProvider<Array<IPaymentLastClass>>();
const FreeSlotApiDataProvider = new APIDataProvider<Array<IPaymentFreeSlot>>();
const FreeSlotsForTheWeekApiDataProvider = new APIDataProvider<
  Array<{ start: string }>
>();
const ValidatePaymentClassesApiDataProvider = new APIDataProvider<
  ICustomPaymentClass[]
>();

export const getPaymentClasses = createAsyncThunk(
  'payment/classes',
  async (data: IGetPaymentClasses) => {
    const response = await PaymentApiDataProvider.create(
      `${ApiPaths.payment}/classes`,
      data
    );

    return response.data;
  }
);

export const getPaymentClassFreeSlots = createAsyncThunk(
  'payment/freeslots',
  async (data: IGetPaymentClassFreeSlots) => {
    const response = await FreeSlotApiDataProvider.create(
      `${ApiPaths.payment}/freeslots`,
      data
    );

    return response.data;
  }
);

export const getFreeSlotsForTheWeekInBackground = createAsyncThunk(
  'payment/freeslotsForTheWeek',
  async (data: IGetPaymentClassFreeSlotsForTheWeek) => {
    const response = await FreeSlotsForTheWeekApiDataProvider.create(
      `${ApiPaths.payment}/freeslotsForTheWeek`,
      data
    );

    return response.data;
  }
);

export const getReciept = createAsyncThunk(
  'payment/reciept',
  async (data: IGetPaymentReceipt) => {
    const response = await PaymentReceiptApiDataProvider.getById(
      `${ApiPaths.payment}/receipt?sourceId=${data.sourceId}`
    );

    return response.data;
  }
);

export const validatePaymentClasses = createAsyncThunk(
  'payment/validate-payment-classess',
  async (data: IValidatePaymentClassess) => {
    const response = await ValidatePaymentClassesApiDataProvider.create(
      `${ApiPaths.payment}/validate_auto_booking`,
      data
    );

    return response.data;
  }
);

export default getPaymentClasses;
