import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { Box, SxProps, Theme } from '@mui/material';
// COMPONENTS
import BillingHeader, {
  headerHeight,
} from '../../components/Header/BillingHeader';
// PAGES
import InvalidRoute from '../Validate/InvalidRoute';
import FlexiblePaymentsTab from '../MonthlyPayment/FlexiblePayment';
import StripeWrapper from '../MonthlyPayment/components/StripeWrapper';
import PaymentView from './PaymentView';
import CheckoutSessionTab from '../MonthlyPayment/CheckoutSessionTab';

// STYLES
const containerStyles: SxProps<Theme> = {
  backgroundColor: 'background.default',
  color: 'text.primary',
};

const Payment: React.FC = () => {
  return (
    <Box data-testid="main-app-container" className="App" sx={containerStyles}>
      {/* Billing Header */}
      <BillingHeader />
      {/* MAIN */}
      <Box
        component="main"
        sx={{
          minHeight: `calc(100vh - ${headerHeight})`,
          pt: headerHeight,
        }}
      >
        {/* HEADER GAP */}
        <Routes>
          <Route
            path="/instalment/:packageName/:userId"
            element={<PaymentView />}
          />
          <Route
            path="/:packageName/:userId"
            element={
              <StripeWrapper>
                <FlexiblePaymentsTab />
              </StripeWrapper>
            }
          />
          <Route
            path="/session/:packageName/:userId"
            element={
              <StripeWrapper>
                <CheckoutSessionTab />
              </StripeWrapper>
            }
          />
          <Route path="*" element={<InvalidRoute />} />
        </Routes>
      </Box>
    </Box>
  );
};

export default Payment;
