import { createAsyncThunk } from '@reduxjs/toolkit';
import APIDataProvider from '../../dataProvider/APIDataProvider';
import ApiPaths from '../../dataProvider/APIPaths';
import { IPromoCode } from '.';

export interface IValidatePromoCode {
  promoCode: string;
  userId: string;
  invoiceId?: string;
  packageId?: string;
  subjectCount?: number;
  minutesPerWeek?: number;
}

const InvoiceApiDataProvider = new APIDataProvider<IPromoCode>();

export const validatePromoCode = createAsyncThunk(
  'promoCode/post',
  async (data: IValidatePromoCode) => {
    const response = await InvoiceApiDataProvider.create(
      ApiPaths.promoCode,
      data
    );

    return response.data;
  }
);
