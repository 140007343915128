export const getDurationTextFromMinutes = (minutes: number): string => {
  let durationText = '0 Min';

  if (minutes) {
    const hours = Math.trunc(minutes / 60);
    const mins = Math.round(minutes % 60);
    const hourText = `${hours.toString().padStart(2, '0')} Hour${
      hours > 1 ? 's' : ''
    }`;

    const minText = `${mins.toString().padStart(2, '0')} Min${
      mins > 1 ? 's' : ''
    }`;

    durationText = `${hours > 0 ? hourText : ''} ${
      mins > 0 ? minText : ''
    }`.trim();
  }

  return durationText;
};

export default getDurationTextFromMinutes;
