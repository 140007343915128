import React from 'react';
import {
  Box,
  BoxProps,
  Grid,
  Typography,
  TypographyProps,
  Avatar,
  GridSize,
  AvatarProps,
  Stack,
} from '@mui/material';
// HOOKS
import { useAppSelector } from '../../hooks';
// STORE
import { getThemeMode } from '../../store/ConfigSlice';
// CONSTANTS
import { TP_COLORS } from '../../constants';

export interface IDetailViewCard {
  title?: string;
  description?: string;
  containerProps?: BoxProps;
  titleProps?: TypographyProps;
  descriptionProps?: TypographyProps;
  avatarProps?: AvatarProps;
  descriptionComponent?: React.ReactElement;
}

const DetailViewCard: React.FC<IDetailViewCard> = ({
  title,
  description,
  containerProps,
  titleProps,
  descriptionProps,
  avatarProps,
  descriptionComponent,
}) => {
  // HOOKS
  const themeMode = useAppSelector(getThemeMode);

  const gridSize: GridSize = avatarProps ? 10 : 12;

  // STYLES
  const defaultContainerStyles: BoxProps['sx'] = {
    p: '12px',
    mb: '10px',
    borderRadius: '7px',
    background:
      themeMode === 'light'
        ? TP_COLORS.listItemGray.light
        : TP_COLORS.listItemGray.dark,
  };

  // RENDER
  return (
    <Box
      {...containerProps}
      sx={
        containerProps
          ? { ...defaultContainerStyles, ...containerProps.sx }
          : defaultContainerStyles
      }
    >
      <Grid container alignItems="center">
        <Grid
          item
          xs={gridSize}
          sx={{ pr: avatarProps ? '5px' : undefined, overflow: 'hidden' }}
        >
          {title && <Typography {...titleProps}>{title}</Typography>}
          {description && (
            <Typography {...descriptionProps}>{description}</Typography>
          )}
          {descriptionComponent && <div>{descriptionComponent}</div>}
        </Grid>
        {avatarProps && (
          <Grid item xs={2}>
            <Stack alignItems="flex-end">
              <Avatar {...avatarProps} />
            </Stack>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default DetailViewCard;
