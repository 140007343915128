import { IFlexiblePlan, TPlanId, TPlanKey, TPlanType } from '../constants';

export const getPlanTypeByKey = (
  planKey: TPlanKey | undefined
): TPlanType | undefined => {
  // eslint-disable-next-line no-undef-init
  let _planType: TPlanType | undefined = undefined;

  if (planKey) {
    switch (planKey) {
      case 'regular_plan':
      case 'premium_plan':
      case 'platinum_plan':
        _planType = 'flexible';
        break;
      case 'bronzePlan':
      case 'silverPlan':
      case 'goldPlan':
        _planType = 'weekly';
        break;
      default:
        _planType = undefined;
        break;
    }
  }

  return _planType;
};

export const getPlanTypeById = (
  planId: TPlanId | undefined
): TPlanType | undefined => {
  // eslint-disable-next-line no-undef-init
  let _planType: TPlanType | undefined = undefined;

  if (planId) {
    switch (planId) {
      case 'Regular Plan':
      case 'Premium Plan':
      case 'Platinum Plan':
        _planType = 'flexible';
        break;
      case 'Bronze Plan':
      case 'Silver Plan':
      case 'Gold Plan':
        _planType = 'weekly';
        break;
      default:
        _planType = undefined;
        break;
    }
  }

  return _planType;
};

export const getFlexiblePlanIdByOldPackageId = (
  oldPackageId: IFlexiblePlan['id']
): string => {
  let newFlexiblePlanId = '';

  switch (oldPackageId) {
    case 'Regular Plan':
      newFlexiblePlanId = 'Improve Plan';
      break;
    case 'Premium Plan':
      newFlexiblePlanId = 'Succeed Plan';
      break;
    case 'Platinum Plan':
      newFlexiblePlanId = 'Excel Plan';
      break;
    default:
      newFlexiblePlanId = '';
      break;
  }

  return newFlexiblePlanId;
};

export default getPlanTypeByKey;
