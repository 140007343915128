import { createAsyncThunk } from '@reduxjs/toolkit';
import APIDataProvider from '../../dataProvider/APIDataProvider';
import ApiPaths from '../../dataProvider/APIPaths';
import { IInvoice } from '.';

export interface IUpdateInvoiceSubject {
  tutorId: string;
  subject: string;
  curriculum: string;
  grade: string;
  selectedClassDate: number;
  selectedNumberOfClass: number;
  lastClassStart: number;
}
export interface IPromoCodeDetails {
  promoCodeId: string;
  promoCode: string;
  promotionType: string;
  discount: number;
  price: number;
}
export interface IGetInvoice {
  id: string;
}
export interface IUpdateInvoice {
  id: string;
  subjects?: IUpdateInvoiceSubject[];
  isChangeSchedule?: boolean;
  promoCode?: IPromoCodeDetails;
}

const InvoiceApiDataProvider = new APIDataProvider<IInvoice>();

export const getInvoice = createAsyncThunk(
  'invoice/get',
  async (data: IGetInvoice) => {
    const response = await InvoiceApiDataProvider.getById(
      `${ApiPaths.invoice}/${data.id}`
    );

    return response.data;
  }
);

export const updateInvoice = createAsyncThunk(
  'invoice/update',
  async (data: IUpdateInvoice) => {
    const response = await InvoiceApiDataProvider.create(
      ApiPaths.invoice,
      data
    );

    return response.data;
  }
);
