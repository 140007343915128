import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { RootState } from '..';

export interface IinitialState {
  visible: boolean;
  message: string;
}

const initialState: IinitialState = {
  visible: false,
  message: '',
};

export const SuccessSlice = createSlice({
  name: 'success',
  initialState,
  reducers: {
    resetMessage: (state) => {
      state.visible = false;
      state.message = '';
    },
    showSuccessMessage: (
      state,
      action: PayloadAction<{
        message: string;
      }>
    ) => {
      state.visible = true;
      state.message = action.payload.message;
    },
  },
});

export const { resetMessage, showSuccessMessage } = SuccessSlice.actions;

export const getSuccessState = (state: RootState): RootState['success'] =>
  state.success;

export default SuccessSlice.reducer;
