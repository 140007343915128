import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Elements } from '@stripe/react-stripe-js';
import { StripeElementsOptions, loadStripe } from '@stripe/stripe-js';
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Drawer,
  Grid,
  Typography,
} from '@mui/material';

import {
  getStripeCards,
  getStripeSecretKey,
  getStripeState,
} from '../../../store/Stripe';
import { showSuccessMessage } from '../../../store/SuccessSlice';
import { useAppSelector } from '../../../hooks';
import { isMobileScreen } from '../../../helper';
import BasicLoader from '../../../components/Loaders/BasicLoader';
import StripeForm from '../components/StripeForm';

interface IState {
  stripeSecretKey: string;
}

interface AddCardModalProps {
  open: boolean;
  onClose: () => void;
}

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY || '');
const stripeSloganBackgroundColor = '#7cbae714';
const modalHeading = 'Save Time and Effort';
const stripeSlogan =
  "The World Leader in Safe Payments. So secure, even we don't see your details";

const initialState: IState = {
  stripeSecretKey: '',
};

const AddCardModal: React.FC<AddCardModalProps> = ({ open, onClose }) => {
  const dispatch = useDispatch();
  const params = useParams();

  // State
  const [state, setState] = useState(initialState);

  // Store
  const stripeState = useAppSelector(getStripeState);

  const userId = params.userId || '';
  const isMobile = isMobileScreen();

  useEffect(() => {
    if (open) {
      dispatch(
        getStripeSecretKey({
          userId,
        })
      );
    }
  }, [open]);

  useEffect(() => {
    if (stripeState.secretData && stripeState.secretData.clientSecret) {
      setState((prevState) => ({
        ...prevState,
        stripeSecretKey: stripeState.secretData?.clientSecret || '',
      }));
    }
  }, [stripeState.secretData]);

  const onCardAdded = () => {
    dispatch(
      showSuccessMessage({
        message: 'Card added!',
      })
    );

    dispatch(
      getStripeCards({
        userId,
      })
    );

    onClose();
  };

  const stripeOptions: StripeElementsOptions = {
    clientSecret: state.stripeSecretKey,
  };

  const renderContent = () => {
    return (
      <>
        <div style={{ marginBottom: '15px' }}>
          <Typography variant="subtitle2" color="secondary" textAlign="center">
            Add a card and experience the ease of seamless payments.
          </Typography>
        </div>
        {!state.stripeSecretKey && stripeState.status === 'loading' ? (
          <div style={{ padding: '15px 0' }}>
            <BasicLoader />
          </div>
        ) : null}
        {state.stripeSecretKey && (
          <Elements stripe={stripePromise} options={stripeOptions}>
            <StripeForm onCardAdded={onCardAdded} />
          </Elements>
        )}
      </>
    );
  };

  if (isMobile) {
    return (
      <Drawer
        open={open}
        onClose={onClose}
        anchor="bottom"
        PaperProps={{
          style: { borderTopLeftRadius: '12px', borderTopRightRadius: '12px' },
        }}
      >
        <Box role="presentation">
          <div style={{ margin: '30px 0 15px 0' }}>
            <Typography variant="h6" textAlign="center" fontWeight={600}>
              {modalHeading}
            </Typography>
          </div>
          <div
            style={{
              maxHeight: 'calc(80vh - 160px - 32px)',
              overflow: 'auto',
              padding: '0 15px',
            }}
          >
            {renderContent()}
          </div>
          <div
            style={{
              padding: '15px',
              backgroundColor: stripeSloganBackgroundColor,
            }}
          >
            <Grid container spacing={3} alignItems="center">
              <Grid item xs={12} sm>
                <img
                  src="/images/logos/stripe-with-card-logos.png"
                  alt="stripe-with-card-logos"
                  style={{ width: '163px', height: '60px' }}
                />
              </Grid>
              <Grid item xs={12} sm={8}>
                <Typography variant="subtitle2">{stripeSlogan}</Typography>
              </Grid>
            </Grid>
          </div>
        </Box>
      </Drawer>
    );
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{ style: { maxWidth: '600px' } }}
    >
      <DialogTitle style={{ textAlign: 'center' }}>{modalHeading}</DialogTitle>
      <DialogContent>{renderContent()}</DialogContent>
      <DialogActions
        style={{
          justifyContent: 'flex-start',
          backgroundColor: stripeSloganBackgroundColor,
          padding: '15px',
        }}
      >
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={12} sm>
            <img
              src="/images/logos/stripe-with-card-logos.png"
              alt="stripe-with-card-logos"
              style={{ width: '163px', height: '60px' }}
            />
          </Grid>
          <Grid item xs={12} sm={8}>
            <Typography variant="subtitle2">{stripeSlogan}</Typography>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export default AddCardModal;
