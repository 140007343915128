import { PayloadAction, createSlice, isRejected } from '@reduxjs/toolkit';
import { RootState } from '..';
import { removeAuthTokenToLocalStorage } from '../../helper';

export interface IinitialState {
  visible: boolean;
  message: string;
}

const initialState: IinitialState = {
  visible: false,
  message: '',
};

export const ErrorSlice = createSlice({
  name: 'error',
  initialState,
  reducers: {
    resetMessage: (state) => {
      state.visible = false;
      state.message = '';
    },
    showErrorMessage: (
      state,
      action: PayloadAction<{
        message: string;
      }>
    ) => {
      state.visible = true;
      state.message = action.payload.message;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(isRejected, (state, action) => {
      state.visible = true;
      const err = JSON.parse(action.error.message as string);

      let msg = 'Something went wrong';

      if (
        err.data &&
        err.data.error_description &&
        err.data.error_description
      ) {
        msg = err.data.error_description;
      } else if (err.data && err.data.message) {
        msg = err.data.message;
      }

      state.message = msg;

      if (err.status === 401) {
        removeAuthTokenToLocalStorage();
        window.location.reload();
      }
    });
  },
});

export const { resetMessage, showErrorMessage } = ErrorSlice.actions;

export const getErrorState = (state: RootState): RootState['error'] =>
  state.error;

export default ErrorSlice.reducer;
