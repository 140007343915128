import React from 'react';
import { Card, CardContent, Grid, Typography } from '@mui/material';

import { TP_COLORS } from '../../../constants';
import { getDurationTextFromMinutes, isMobileScreen } from '../../../helper';
import { renderDetailItem } from './PaymentEventListItem';

interface PaymentEventListItemSummaryProps {
  usedDuration: number;
}

const PaymentEventListItemSummary: React.FC<PaymentEventListItemSummaryProps> = ({
  usedDuration,
}) => {
  const isMobile = isMobileScreen();

  const durationText = getDurationTextFromMinutes(usedDuration);

  if (isMobile) {
    return (
      <Card elevation={2}>
        <CardContent style={{ padding: '10px' }}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              {renderDetailItem({
                isMobile: true,
                label: 'Total Duration (per week)',
                value: durationText,
              })}
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    );
  }

  return (
    <Card elevation={0} style={{ backgroundColor: TP_COLORS.paperGray.light }}>
      <CardContent style={{ padding: '10px' }}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={9}>
            <Card elevation={0} style={{ background: 'none' }}>
              <CardContent style={{ padding: '10px' }}>
                <Typography variant="subtitle2" fontWeight={600}>
                  Total duration (per week)
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={3}>
            {renderDetailItem({
              isMobile: false,
              label: 'Duration',
              value: durationText,
            })}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default PaymentEventListItemSummary;
