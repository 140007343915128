import React from 'react';
import { Card, CardContent, Typography, Grid } from '@mui/material';
// HELPERS
import { isMobileScreen } from '../../../helper';
// CONSTANTS
import { TP_COLORS } from '../../../constants';
import { useAppSelector } from '../../../hooks';
import { getPromoCodeState } from '../../../store/PromoCodeSlice';

interface BalanceCardProps {
  minutes?: number;
}

const BalanceCard: React.FC<BalanceCardProps> = ({ minutes = 0 }) => {
  // STORE
  const promoCodeState = useAppSelector(getPromoCodeState);

  const promoCodeData = promoCodeState.promoCode;

  const isMobile = isMobileScreen();

  // LOCAL STATE
  let hours = Math.trunc(minutes / 60);
  if (
    promoCodeData &&
    promoCodeData.discount &&
    promoCodeData.promoCodeDetails &&
    promoCodeData.promoCodeDetails.promotionType === 'BONUS HOURS'
  ) {
    hours += promoCodeData.discount;
  }
  const mins = Math.round(minutes % 60);

  return (
    <Card
      elevation={0}
      sx={{
        border: isMobile ? 'none' : { xs: 'none', sm: '1px solid lightgray' },
        p: '0',
        mt: { xs: '0', sm: '16px', md: '0' },
        height: { xs: 'unsest', lg: '100%' },
      }}
    >
      <CardContent
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          p: { xs: '0', sm: '16px' },
        }}
        style={{ height: 'calc(100% - 42px)' }}
      >
        <div
          style={{
            backgroundColor: TP_COLORS.listItemGray.light,
            padding: '15px',
          }}
        >
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} sm={6}>
              <div>
                <div style={{ marginBottom: '10px' }}>
                  <Typography fontWeight={600}>Balance Remaining</Typography>
                </div>
                <div>
                  <Typography fontWeight={500}>
                    Remaining lesson time
                  </Typography>
                </div>
              </div>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Grid container spacing={2} alignItems="center">
                <Grid item xs={6}>
                  <Card elevation={0}>
                    <CardContent style={{ textAlign: 'center' }}>
                      <Typography variant="h4" fontWeight={600}>
                        {hours}
                      </Typography>
                      <Typography fontWeight={500}>Hours</Typography>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={6}>
                  <Card elevation={0}>
                    <CardContent style={{ textAlign: 'center' }}>
                      <Typography variant="h4" fontWeight={600}>
                        {mins}
                      </Typography>
                      <Typography fontWeight={500}>Mins</Typography>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </CardContent>
    </Card>
  );
};

export default React.memo(BalanceCard);
