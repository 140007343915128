import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { Box, SxProps, Theme } from '@mui/material';
// COMPONENTS
import BillingHeader, {
  headerHeight,
} from '../../components/Header/BillingHeader';
// PAGES
import InvalidRoute from '../Validate/InvalidRoute';
import BillingView from './BillingView';

// STYLES
const containerStyles: SxProps<Theme> = {
  backgroundColor: 'background.default',
  color: 'text.primary',
};

const Billing: React.FC = () => {
  return (
    <Box data-testid="main-app-container" className="App" sx={containerStyles}>
      {/* Billing Header */}
      <BillingHeader />
      {/* MAIN */}
      <Box
        component="main"
        sx={{
          minHeight: `calc(100vh - ${headerHeight})`,
          pt: headerHeight,
        }}
      >
        {/* HEADER GAP */}
        <Routes>
          <Route path="/:id" element={<BillingView />} />
          <Route path="*" element={<InvalidRoute />} />
        </Routes>
      </Box>
    </Box>
  );
};

export default Billing;
