import React from 'react';
import { AppBar, Box, Toolbar } from '@mui/material';

const MonthlyPaymentHeader: React.FC = () => {
  return (
    <Box data-testid="monthly-payment-header" sx={{ flexGrow: 1 }}>
      <AppBar
        position="fixed"
        color="inherit"
        sx={{
          backgroundColor: { xs: '#F1FBFA', sm: 'background.default' },
          boxShadow: {
            xs: 'none',
            sm:
              '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)',
          },
        }}
      >
        <Toolbar sx={{ justifyContent: { xs: 'center', sm: 'flex-start' } }}>
          <img
            src="/images/logos/tp-logo-green.png"
            alt="quiz-topiya-logo"
            style={{ width: '120px' }}
          />
        </Toolbar>
      </AppBar>
    </Box>
  );
};

export default MonthlyPaymentHeader;
