import React from 'react';
import { Card, CardContent, Typography, Grid, Stack } from '@mui/material';
// HELPERS
import { isMobileScreen } from '../../../helper';
// CONSTANTS
import { TP_COLORS } from '../../../constants';
import { useAppSelector } from '../../../hooks';
import { getPromoCodeState } from '../../../store/PromoCodeSlice';

interface BalanceCardProps {
  minutes?: number;
}

const BalanceCard: React.FC<BalanceCardProps> = ({ minutes = 0 }) => {
  // STORE
  const promoCodeState = useAppSelector(getPromoCodeState);

  const promoCodeData = promoCodeState.promoCode;

  const isMobile = isMobileScreen();

  // LOCAL STATE
  let hours = Math.trunc(minutes / 60);
  if (
    promoCodeData &&
    promoCodeData.discount &&
    promoCodeData.promoCodeDetails &&
    promoCodeData.promoCodeDetails.promotionType === 'BONUS HOURS'
  ) {
    hours += promoCodeData.discount;
  }
  const mins = Math.round(minutes % 60);
  let imageWidth = 277;
  let imageMargin = '20px';
  if (typeof promoCodeData === 'string') {
    imageWidth = 314;
  }
  if (promoCodeData && promoCodeData.discount > 0) {
    imageWidth = 314;
    imageMargin = '54px';
  }

  // METHODS
  const RENDER_CONTENT = () => {
    if (isMobile) {
      return (
        <div>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography fontWeight={600}>
              Balance
              <br />
              Remaining
            </Typography>
            <Grid container spacing={2} alignItems="center" maxWidth={170}>
              <Grid item xs={6}>
                <Card elevation={0}>
                  <CardContent style={{ textAlign: 'center', padding: '5px' }}>
                    <Typography variant="h6" fontWeight={600}>
                      {hours}
                    </Typography>
                    <Typography variant="subtitle2" fontWeight={600}>
                      Hours
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={6}>
                <Card elevation={0}>
                  <CardContent style={{ textAlign: 'center', padding: '5px' }}>
                    <Typography variant="h6" fontWeight={600}>
                      {mins}
                    </Typography>
                    <Typography variant="subtitle2" fontWeight={600}>
                      Mins
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Stack>
        </div>
      );
    }
    return (
      <div>
        <Typography
          fontWeight={600}
          textAlign="center"
          sx={{ mb: { xs: 0, sm: '10px' } }}
        >
          Balance Remaining
        </Typography>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Grid container spacing={2} alignItems="center" maxWidth={250}>
            <Grid item xs={6}>
              <Card elevation={0}>
                <CardContent style={{ textAlign: 'center' }}>
                  <Typography variant="h4" fontWeight={600}>
                    {hours}
                  </Typography>
                  <Typography fontWeight={500}>Hours</Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={6}>
              <Card elevation={0}>
                <CardContent style={{ textAlign: 'center' }}>
                  <Typography variant="h4" fontWeight={600}>
                    {mins}
                  </Typography>
                  <Typography fontWeight={500}>Mins</Typography>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'end',
            marginTop: imageMargin,
          }}
        >
          <img
            src="/images/objects/time-remaining-calendar.png"
            width={imageWidth}
            alt="Tutopiya-Logo"
          />
        </div>
      </div>
    );
  };

  return (
    <Card
      elevation={0}
      sx={{
        border: isMobile ? 'none' : { xs: 'none', sm: '1px solid lightgray' },
        p: isMobile ? 0 : { xs: '0', sm: '15px' },
        height: isMobile ? undefined : { xs: 'unset', sm: 'calc(100% - 32px)' },
      }}
    >
      <CardContent
        sx={{
          paddingBottom: { xs: '16px', sm: '0' },
          backgroundColor: TP_COLORS.listItemGray.light,
        }}
      >
        {RENDER_CONTENT()}
      </CardContent>
    </Card>
  );
};

export default React.memo(BalanceCard);
