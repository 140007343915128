import { createAsyncThunk } from '@reduxjs/toolkit';
import { IPackageState, IWeeklyPlanPrices } from '.';
import APIDataProvider from '../../dataProvider/APIDataProvider';
import ApiPaths from '../../dataProvider/APIPaths';

export interface IGetFlexiblePlanPricesForUser {
  userId: string;
}

export interface IGetWeeklyPlanPricesForUser {
  userId: string;
}

const PackageApiDataProvider = new APIDataProvider<IPackageState['packages']>();
const WeeklyPlanApiDataProvider = new APIDataProvider<IWeeklyPlanPrices>();

export const getFlexiblePlanPricesForUser = createAsyncThunk(
  'package/flexible/get',
  async (data: IGetFlexiblePlanPricesForUser) => {
    const response = await PackageApiDataProvider.getById(
      `${ApiPaths.package}/flexible/${data.userId}`
    );

    return response.data;
  }
);

export const getWeeklyPlanPricesForUser = createAsyncThunk(
  'package/weekly/get',
  async (data: IGetWeeklyPlanPricesForUser) => {
    const response = await WeeklyPlanApiDataProvider.getById(
      `${ApiPaths.package}/weekly/${data.userId}`
    );

    return response.data;
  }
);
