export interface ISafeAreaGaps {
  top: string;
  left: string;
  right: string;
  bottom: string;
}

export const SafeAreaGaps: ISafeAreaGaps = {
  top: 'env(safe-area-inset-top)',
  left: 'env(safe-area-inset-left)',
  right: 'env(safe-area-inset-right)',
  bottom: 'env(safe-area-inset-bottom)',
};

export const isMobileScreen = (): boolean => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const _screen: any = window.screen;
  const orientation =
    (_screen.orientation || {}).type ||
    _screen.mozOrientation ||
    _screen.msOrientation;

  if (orientation === 'landscape-primary') {
    return _screen.height <= 576;
  }

  if (orientation === 'landscape-secondary') {
    return _screen.height <= 576;
  }

  if (
    orientation === 'portrait-secondary' ||
    orientation === 'portrait-primary'
  ) {
    return _screen.width <= 576;
  }

  if (orientation === undefined) {
    return _screen.width <= 576 || _screen.height <= 576;
  }

  return false;
};
