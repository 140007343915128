import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
  Grid,
  Select,
  MenuItem,
  FormControl,
  TypographyProps,
  DialogActions,
  Button,
  Card,
  CardContent,
  CardActionArea,
  Drawer,
  TextField,
  SxProps,
  Theme,
  SelectChangeEvent,
  Chip,
  Autocomplete,
} from '@mui/material';
import { LocalizationProvider, MobileDatePicker, PickersDay } from '@mui/lab';
import AdapterMoment from '@mui/lab/AdapterMoment';
import moment, { Moment as IMoment } from 'moment';
import { withStyles } from '@mui/styles';
// HOOKS
import { useAppSelector } from '../../../hooks';
// STORE
import {
  getFreeSlotsForTheWeekInBackground,
  getPaymentClassFreeSlots,
  getPaymentState,
  ICustomPaymentClass,
  IGetPaymentClassFreeSlots,
  IPaymentFreeSlot,
} from '../../../store/PaymentSlice';
// COMPONENTS
import { IPackageData } from '../PaymentView';
import BasicLoader from '../../../components/Loaders/BasicLoader';
// HELPERS
import { isMobileScreen, SafeAreaGaps } from '../../../helper';
// CONSTANTS
import { instalmentWeeksCount, TP_COLORS } from '../../../constants';

interface ClassScheduleModalProps {
  open: boolean;
  onClose: () => void;
  paymentClass: ICustomPaymentClass;
  packageData: IPackageData;
  updateClassData: (
    id: string,
    startDate: IMoment,
    duration: number,
    numberOfClasses: number
  ) => void;
}

// STYLES
const labelStyles: TypographyProps = {
  fontWeight: 600,
  style: { marginBottom: '5px' },
};

const slotItemStyles: React.CSSProperties = {
  paddingBottom: '16px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  minHeight: 'calc(76px - 30px)',
};

// DATA
const MODAL_HEADING = 'Class Schedule';

const DURATION_OPTIONS = [60, 120, 180].map((item) => {
  const _hours = Math.floor(item / 60);
  return {
    label: `${_hours.toString().padStart(2, '0')} Hour${_hours > 1 ? 's' : ''}`,
    value: item,
  };
});

const defaultStartDate = moment().add(1, 'week');

const defaultNumberOfClassesOption = {
  label: '00 Class',
  value: 0,
};

const getNumberOfClassesOptions = (numberOfClasses: number) => {
  const options = [defaultNumberOfClassesOption];

  if (numberOfClasses && numberOfClasses > 0) {
    for (let i = 1; i <= numberOfClasses; i += 1) {
      options.push({
        label: `${i.toString().padStart(2, '0')} Class${i > 1 ? 'es' : ''}`,
        value: i,
      });
    }
  }

  return options;
};

// VIEWS
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const CustomPickersDay: any = withStyles({
  root: {
    '&.Mui-disabled': {
      pointerEvents: 'auto',
    },
  },
})(PickersDay);

const ClassScheduleModal: React.FC<ClassScheduleModalProps> = ({
  open,
  onClose,
  paymentClass,
  updateClassData,
  packageData,
}) => {
  // HOOKS
  const dispatch = useDispatch();
  const location = useLocation();

  // STORE
  const paymentState = useAppSelector(getPaymentState);

  const isMobile = isMobileScreen();
  const urlParams = new URLSearchParams(location.search);
  const isInstalmentPayment =
    urlParams.get('payment') === 'instalment' &&
    packageData?.planType === 'weekly';

  // LOCAL STATES
  const [startDate, setStartDate] = useState<IMoment>(defaultStartDate);
  const [duration, setDuration] = useState(60);
  const [numberOfClasses, setNumberOfClasses] = useState<number>(0);
  const [selectedFreeSlot, setSelectedFreeSlot] = useState<
    IPaymentFreeSlot | undefined
  >(undefined);
  const [numberOfClassesOptions, setNumberOfClassesOptions] = useState([
    defaultNumberOfClassesOption,
  ]);

  // USE EFFECT
  useEffect(() => {
    if (paymentClass) {
      let _start = defaultStartDate;
      const FREE_SLOT_API_DATA: IGetPaymentClassFreeSlots = {
        date: _start.format('YYYY-MM-DD'),
        tutorId: paymentClass.tutorId,
        duration: 60,
      };
      if (paymentClass.classStart) {
        _start = moment(paymentClass.classStart);
        setStartDate(_start);
        FREE_SLOT_API_DATA.date = _start.format('YYYY-MM-DD');
      }

      if (
        paymentClass.duration &&
        DURATION_OPTIONS.find((d) => d.value === paymentClass.duration)
      ) {
        setDuration(paymentClass.duration);
        FREE_SLOT_API_DATA.duration = paymentClass.duration;
      }

      if (paymentClass.numberOfClasses) {
        setNumberOfClasses(paymentClass.numberOfClasses);
      }

      setNumberOfClassesOptions(() => {
        if (packageData.planType === 'flexible') {
          const _packageQuantityHours = Number(packageData.hours);
          const _maxNumberOfClasses = Math.floor(
            (_packageQuantityHours * 60) / duration
          );

          const _options = getNumberOfClassesOptions(_maxNumberOfClasses);

          return _options;
        }

        if (
          packageData.planType === 'weekly' &&
          packageData.minutesPerWeek &&
          packageData.weeks
        ) {
          let _packageQuantityHours =
            packageData.weeks * packageData.minutesPerWeek;

          if (isInstalmentPayment) {
            _packageQuantityHours =
              (_packageQuantityHours / packageData.weeks) *
              instalmentWeeksCount;
          }

          const _maxNumberOfClasses = Math.floor(
            _packageQuantityHours / duration
          );

          const _options = getNumberOfClassesOptions(_maxNumberOfClasses);

          return _options;
        }

        return getNumberOfClassesOptions(0);
      });

      dispatch(getPaymentClassFreeSlots(FREE_SLOT_API_DATA));
      getFreeSlotsForTheWeek(_start);
    }
  }, []);

  useEffect(() => {
    setNumberOfClassesOptions(() => {
      if (packageData.planType === 'flexible') {
        const _packageQuantityHours = Number(packageData.hours);
        const _maxNumberOfClasses = Math.floor(
          (_packageQuantityHours * 60) / duration
        );

        const _options = getNumberOfClassesOptions(_maxNumberOfClasses);

        return _options;
      }

      if (
        packageData.planType === 'weekly' &&
        packageData.minutesPerWeek &&
        packageData.weeks
      ) {
        let _packageQuantityHours =
          packageData.weeks * packageData.minutesPerWeek;

        if (isInstalmentPayment) {
          _packageQuantityHours =
            (_packageQuantityHours / packageData.weeks) * instalmentWeeksCount;
        }

        const _maxNumberOfClasses = Math.floor(
          _packageQuantityHours / duration
        );

        const _options = getNumberOfClassesOptions(_maxNumberOfClasses);

        return _options;
      }

      return getNumberOfClassesOptions(0);
    });
  }, [duration]);

  // METHODS
  const handleStartDateChange = (date: IMoment | null) => {
    if (date && paymentClass) {
      setStartDate(date);
      const FREE_SLOT_API_DATA: IGetPaymentClassFreeSlots = {
        date: date.format('YYYY-MM-DD'),
        tutorId: paymentClass.tutorId,
        duration,
      };
      dispatch(getPaymentClassFreeSlots(FREE_SLOT_API_DATA));
    }
    setSelectedFreeSlot(undefined);
  };

  const handleDurationChange = (event: SelectChangeEvent<number>) => {
    setDuration(event.target.value as number);
    const FREE_SLOT_API_DATA: IGetPaymentClassFreeSlots = {
      date: startDate.format('YYYY-MM-DD'),
      tutorId: paymentClass.tutorId,
      duration: event.target.value as number,
    };
    dispatch(getPaymentClassFreeSlots(FREE_SLOT_API_DATA));
    setSelectedFreeSlot(undefined);
  };

  const filterFreeSlots = (item: IPaymentFreeSlot) => {
    return (
      moment(item.start).format('YYYY-MM-DD') ===
        startDate.format('YYYY-MM-DD') &&
      moment().add(24, 'hours').toDate().getTime() <
        moment(item.start).toDate().getTime()
    );
  };

  const sortFreeSlots = (a: IPaymentFreeSlot, b: IPaymentFreeSlot) => {
    const startA = moment(a.start).toDate().getTime();
    const startB = moment(b.start).toDate().getTime();

    if (startA < startB) {
      return -1;
    }

    if (startA > startB) {
      return 1;
    }

    return 0;
  };

  const handleFreeSlotClick = (item: IPaymentFreeSlot) => {
    setSelectedFreeSlot((prevState) => {
      if (prevState === item) {
        return undefined;
      }

      return item;
    });
  };

  const getFreeSlotsForTheWeek = (date: IMoment) => {
    dispatch(
      getFreeSlotsForTheWeekInBackground({
        date: date.clone().startOf('month').toDate().getTime(),
        tutorId: paymentClass.tutorId,
      })
    );
  };

  const handleSubmit = () => {
    if (paymentClass) {
      let classStart = moment(startDate);

      if (selectedFreeSlot) {
        classStart = moment(
          `${startDate.format('YYYY-MM-DD')} ${moment(
            selectedFreeSlot.start
          ).format('hh:mm A')}`,
          'YYYY-MM-DD hh:mm A'
        );
      }

      updateClassData(paymentClass.id, classStart, duration, numberOfClasses);
    }
    onClose();
  };

  const freeSlots = paymentState.freeslots;
  const freeSlotsFormated = freeSlots
    .filter(filterFreeSlots)
    .sort(sortFreeSlots);
  const isNumberClassesMatchingOptionValue = numberOfClassesOptions.find(
    (item) => item.value === numberOfClasses
  );
  const isSubmitDisabled =
    !isNumberClassesMatchingOptionValue ||
    (!paymentClass.classStart && !selectedFreeSlot) ||
    (moment(paymentClass.classStart).format('YYYY-MM-DD') !==
      startDate.format('YYYY-MM-DD') &&
      !selectedFreeSlot);
  const freeSlotsAvailabilityDays: string[] = [];

  if (
    paymentState.freeSlotsForTheWeek &&
    paymentState.freeSlotsForTheWeek.length > 0
  ) {
    paymentState.freeSlotsForTheWeek.forEach((item) => {
      const _day = moment(item.start).format('dddd');
      if (!freeSlotsAvailabilityDays.includes(_day)) {
        freeSlotsAvailabilityDays.push(_day);
      }
    });
  }

  // RENDER
  const RENDER_TIME_CHIP = () => {
    if (paymentClass.classStart) {
      let _date = paymentClass.classStart;
      if (selectedFreeSlot) {
        _date = moment(selectedFreeSlot.start).toDate().getTime();
      }
      const _time = moment(_date).format('hh:mm A');
      return (
        <div style={{ marginBottom: '30px' }}>
          <Chip label={`${_time}`} />
        </div>
      );
    }

    return null;
  };

  const RENDER_CONTENT = () => {
    return (
      <div role="presentation">
        <div style={{ marginBottom: '30px' }}>
          <Typography color="secondary">
            To schedule your classes with a tutor, please select below
          </Typography>
        </div>
        <div style={{ marginBottom: '30px' }}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} sm={4}>
              <Typography {...labelStyles}>Start Date</Typography>
              <FormControl fullWidth>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <MobileDatePicker
                    showToolbar={false}
                    value={startDate}
                    onChange={handleStartDateChange}
                    disableCloseOnSelect={false}
                    inputFormat="YYYY-MM-DD"
                    minDate={moment()}
                    renderDay={(day, selectedDates, pickersDayProps) => {
                      if (
                        pickersDayProps.disabled &&
                        day.toDate().getTime() > new Date().getTime()
                      ) {
                        return (
                          <span key={pickersDayProps.key} title="No Free Slots">
                            <CustomPickersDay
                              {...pickersDayProps}
                              style={{
                                ...pickersDayProps.style,
                                backgroundColor: 'rgba(0, 0, 0, 0.04)',
                              }}
                            />
                          </span>
                        );
                      }

                      return <PickersDay {...pickersDayProps} />;
                    }}
                    shouldDisableDate={(day) => {
                      const _day = day.format('dddd');
                      if (!freeSlotsAvailabilityDays.includes(_day)) {
                        return true;
                      }
                      return false;
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        className="mui-text-field-label-hidden"
                        placeholder="Select Start Date & Time"
                      />
                    )}
                  />
                </LocalizationProvider>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography {...labelStyles}>Duration</Typography>
              <FormControl fullWidth>
                <Select
                  size="small"
                  value={duration}
                  onChange={handleDurationChange}
                >
                  {DURATION_OPTIONS.map((option, index) => {
                    return (
                      <MenuItem key={index} value={option.value}>
                        {option.label}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography {...labelStyles}>No of Classes</Typography>
              <FormControl fullWidth>
                <Autocomplete
                  size="small"
                  disableClearable
                  value={numberOfClassesOptions.find(
                    (item) => item.value === numberOfClasses
                  )}
                  onChange={(event, value) =>
                    setNumberOfClasses(value.value as number)
                  }
                  options={numberOfClassesOptions}
                  renderInput={(params) => (
                    <TextField {...params} placeholder="Select..." />
                  )}
                />
              </FormControl>
            </Grid>
          </Grid>
        </div>

        {RENDER_TIME_CHIP()}

        {paymentState.status === 'loading' ? (
          <div style={{ marginBottom: '15px' }}>
            <BasicLoader />
          </div>
        ) : (
          <div>
            <Grid container spacing={2}>
              {freeSlotsFormated.length > 0 &&
                freeSlotsFormated.map((item, index) => {
                  let isSelected = item === selectedFreeSlot;
                  if (!selectedFreeSlot && !isSelected) {
                    isSelected =
                      paymentClass.classStart ===
                      moment(item.start).toDate().getTime();
                  }
                  const time = moment(item.start).format('hh:mm A');
                  // STYLES
                  const cardStyles: SxProps<Theme> = {
                    backgroundColor: TP_COLORS.paperGray.light,
                  };
                  let textColor: TypographyProps['color'];
                  if (isSelected) {
                    cardStyles.backgroundColor = 'primary.main';
                    cardStyles.color = TP_COLORS.white;
                    textColor = TP_COLORS.white;
                  }
                  return (
                    <Grid key={index} item xs={6} sm={4} md={4} lg={3}>
                      <Card elevation={0} sx={cardStyles}>
                        <CardActionArea
                          disabled={item.booked}
                          onClick={() => handleFreeSlotClick(item)}
                        >
                          <CardContent style={slotItemStyles}>
                            <Typography
                              variant="subtitle2"
                              fontWeight={600}
                              color={textColor || 'secondary'}
                            >
                              {time}
                            </Typography>
                          </CardContent>
                        </CardActionArea>
                      </Card>
                    </Grid>
                  );
                })}
            </Grid>
          </div>
        )}

        {paymentState.status !== 'loading' && freeSlotsFormated.length === 0 && (
          <div style={{ marginBottom: '15px' }}>
            <Typography variant="h6">No Freeslots are available</Typography>
          </div>
        )}
      </div>
    );
  };

  if (isMobile) {
    return (
      <Drawer
        open={open}
        onClose={onClose}
        anchor="bottom"
        PaperProps={{
          sx: {
            maxHeight: '95vh',
            borderTopLeftRadius: '12px',
            borderTopRightRadius: '12px',
          },
        }}
      >
        <div
          style={{
            margin: `30px 0 calc(${SafeAreaGaps.bottom}) 0`,
            overflow: 'auto',
            padding: '0 15px',
          }}
        >
          <div style={{ marginBottom: '15px' }}>
            <Typography variant="h6" fontWeight={600}>
              {MODAL_HEADING}
            </Typography>
          </div>
          {RENDER_CONTENT()}
          <div style={{ margin: '30px 0' }}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Button
                  fullWidth
                  variant="contained"
                  color="inherit"
                  style={{ color: '#000' }}
                  onClick={onClose}
                >
                  Dismiss
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  onClick={handleSubmit}
                  disabled={isSubmitDisabled}
                >
                  Update
                </Button>
              </Grid>
            </Grid>
          </div>
        </div>
      </Drawer>
    );
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          maxWidth: { sm: '700px', md: '800px', lg: '800px', xl: '1000px' },
          minWidth: { xs: 'unset', sm: '650px', md: '700px' },
        },
      }}
    >
      <DialogTitle style={{ fontWeight: 600 }}>{MODAL_HEADING}</DialogTitle>
      <DialogContent>{RENDER_CONTENT()}</DialogContent>
      <DialogActions style={{ padding: '15px' }}>
        <Button
          variant="contained"
          color="inherit"
          style={{ color: '#000' }}
          onClick={onClose}
        >
          Dismiss
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          disabled={isSubmitDisabled}
        >
          Update
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ClassScheduleModal;
