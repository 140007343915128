import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Container,
  Grid,
  Stack,
  Typography,
  Card,
  SxProps,
  Theme,
  FormControlLabel,
  Radio,
  CardContent,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import moment, { Moment as IMoment } from 'moment';
import { nanoid } from 'nanoid';
// HOOKS
import { useAppSelector } from '../../hooks';
// STORE
import {
  getInvoice,
  getInvoiceState,
  IInvoiceSubject,
  IUpdateInvoice,
  IUpdateInvoiceSubject,
  updateInvoice,
} from '../../store/InvoiceSlice';
import { getPromoCodeState } from '../../store/PromoCodeSlice';
import { getUserById } from '../../store/UserSlice';
import {
  getPaymentState,
  ICustomPaymentClass,
  updateValidatedPaymentClass,
  validatePaymentClasses,
} from '../../store/PaymentSlice';
import { getCheckoutState } from '../../store/CheckoutSlice';
// COMPONENTS
import PromoCard from './components/PromoCard';
import TotalCard from './components/TotalCard';
import BalanceCard from './components/BalanceCard';
import ListItem from './components/ListItem';
import { headerHeight } from '../../components/Header/BillingHeader';
import FullScreenLoader from '../../components/Loaders/FullScreenloader';
// MODALS
import PaymentDetailsModal from './modals/PaymentDetailsModal';
import EditClassScheduleModal from './modals/EditClassScheduleModal';
import ScheduleConflictModal from '../Payment/modals/ScheduleConflictModal';
// HELPERS
import { isMobileScreen, SafeAreaGaps } from '../../helper';
// CONSTANTS
import { TP_COLORS } from '../../constants';

interface ISubjectAlertData {
  id?: string;
  isDeleteSubjectConfirmationModal: boolean;
}
export interface ICustomInvoiceSubject extends IInvoiceSubject {
  id?: string | undefined;
  duration: number;
}

// STYLES
const scheduleContainerStyles: SxProps<Theme> = {
  border: isMobileScreen() ? 'none' : { xs: 'none', sm: '1px solid lightgray' },
  p: isMobileScreen() ? 0 : { xs: '0', sm: '15px' },
};

const radioOptionStyles: SxProps<Theme> = {
  borderRadius: '7px',
  mb: isMobileScreen() ? '10px' : { xs: '10px', sm: '0' },
  backgroundColor: isMobileScreen()
    ? TP_COLORS.listItemGray.light
    : {
        xs: TP_COLORS.listItemGray.light,
        sm: 'background.default',
      },
};

const buttonContainerStyles: SxProps<Theme> = {
  backgroundColor: 'background.paper',
  position: 'fixed',
  left: SafeAreaGaps.left,
  bottom: SafeAreaGaps.bottom,
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  p: '15px 0',
};

const BillingView: React.FC = () => {
  // HOOKS
  const dispatch = useDispatch();
  const params = useParams();
  const navigate = useNavigate();

  // STORE
  const invoiceState = useAppSelector(getInvoiceState);
  const promoCodeState = useAppSelector(getPromoCodeState);
  const paymentState = useAppSelector(getPaymentState);
  const checkoutState = useAppSelector(getCheckoutState);

  const invoiceData = invoiceState.invoice;
  const promoCodeData = promoCodeState.promoCode;

  // STATES
  const [initLoading, setInitLoading] = useState(true);
  const [selectedOption, setSelectedOption] = useState<1 | 2>(1);
  const [ispaymentDetailsModalOpen, setPaymentDetailsModalOpen] = useState(
    false
  );
  const [subjectList, setSubjectList] = useState<ICustomInvoiceSubject[]>([]);
  const [
    subjectDeleteAlertData,
    setSubjectDeleteAleartData,
  ] = useState<ISubjectAlertData>({
    id: undefined,
    isDeleteSubjectConfirmationModal: false,
  });
  const [selectedClass, setSelectedClass] = useState<string | undefined>(
    undefined
  ); // payment class temp id
  const [isScheduleConflictModalOpen, setScheduleConflictModal] = useState(
    false
  );

  const totalQtyMins =
    invoiceData && invoiceData.qty ? invoiceData.qty * 60 : 0;
  let errorMessage = '';

  // CHECK HOURS EXCEEDED
  let isHoursExceeded = false;
  let isClassFieldsNotSpecified = false;

  if (selectedOption === 1) {
    let totalDurationMins = 0;
    subjectList.forEach((s) => {
      if (s.lastClassEnd && s.lastClassStart) {
        const _durationMins =
          ((s.lastClassEnd - s.lastClassStart) / 60000) * s.numberOfClasses;
        totalDurationMins += _durationMins;
      } else if (
        !s.lastClassEnd &&
        !s.lastClassStart &&
        !isClassFieldsNotSpecified
      ) {
        isClassFieldsNotSpecified = true;
      }
    });

    if (totalQtyMins < totalDurationMins) {
      isHoursExceeded = true;
      errorMessage = 'Total class hours exceeds the plan hours';

      if (invoiceData && invoiceData.qty) {
        errorMessage += ` (Plan hours: ${invoiceData.qty})`;
      }
    }

    if (!isHoursExceeded && isClassFieldsNotSpecified) {
      errorMessage = 'Please make required selection for the class.';
    }
  }

  const isSubmitDisabled =
    !invoiceData ||
    (invoiceData && !invoiceData.qty) ||
    isHoursExceeded ||
    isClassFieldsNotSpecified;
  const _selectedClassData = subjectList.find((c) => c.id === selectedClass);
  const isEditScheduleModalOpen = Boolean(_selectedClassData);
  const paymentClasses = subjectList.map((c) => ({
    id: c.id,
    subject: c.subject,
    curriculum: c.curriculum,
    grade: c.grade,
    tutorId: c.tutorId,
    classStart: c.selectedClassDate,
    duration: c.duration,
    classEnd: moment(c.selectedClassDate)
      .add(c.duration, 'minutes')
      .toDate()
      .getTime(),
    lastClassStart: c.lastClassStart,
    numberOfClasses: c.numberOfClasses,
  })) as ICustomPaymentClass[];

  // USE EFFECTS
  useEffect(() => {
    if (params && params.id) {
      dispatch(getInvoice({ id: params.id }));
    }
  }, []);

  useEffect(() => {
    if (initLoading && invoiceState.invoice) {
      setInitLoading(false);
    }
  }, [invoiceState.invoice]);

  useEffect(() => {
    if (invoiceState.showPaymentCardModal) {
      setPaymentDetailsModalOpen(true);
    }
  }, [invoiceState.showPaymentCardModal]);

  useEffect(() => {
    if (invoiceState.invoice && invoiceState.invoice.subjects) {
      const customInvoiceSubjectList: ICustomInvoiceSubject[] = [];

      invoiceState.invoice.subjects.forEach((invSubject) => {
        const newInvoiceSubject: ICustomInvoiceSubject = {
          id: nanoid(),
          duration: 0,
          ...invSubject,
        };

        const start = moment(invSubject.lastClassStart).add(7, 'days');
        const nextDayFromNow = moment().add(24, 'hours');

        while (start.isBefore(nextDayFromNow)) {
          start.add(7, 'days');
        }

        if (
          newInvoiceSubject.lastClassEnd &&
          newInvoiceSubject.lastClassStart
        ) {
          newInvoiceSubject.duration =
            (newInvoiceSubject.lastClassEnd -
              newInvoiceSubject.lastClassStart) /
            60000;
        }

        newInvoiceSubject.selectedClassDate = moment(start).toDate().getTime();
        newInvoiceSubject.selectedNumberOfClass = invSubject.numberOfClasses;

        customInvoiceSubjectList.push(newInvoiceSubject);
      });

      setSubjectList(customInvoiceSubjectList);
    }
  }, [invoiceState.invoice]);

  useEffect(() => {
    if (
      paymentState.validatedPaymentClassessData &&
      paymentState.validatedPaymentClassessData.showConflictModal &&
      paymentState.validatedPaymentClassessData.conflictPaymentClasses.length >
        0 &&
      isScheduleConflictModalOpen === false
    ) {
      setScheduleConflictModal(true);
    } else if (
      paymentState.validatedPaymentClassessData &&
      paymentState.validatedPaymentClassessData.showPaymentModal &&
      ispaymentDetailsModalOpen === false
    ) {
      handleSubmit();
    }
  }, [paymentState.validatedPaymentClassessData]);

  useEffect(() => {
    if (checkoutState.redirectUrl) {
      window.location.replace(checkoutState.redirectUrl);
    }
  }, [checkoutState.redirectUrl]);

  // METHODS
  const onContinue = () => {
    if (
      selectedOption === 1 &&
      subjectList.length > 0 &&
      invoiceData &&
      invoiceData.student &&
      invoiceData.student.id
    ) {
      const _paymentClasses: ICustomPaymentClass[] = [];
      subjectList.forEach((c) => {
        if (c.id) {
          _paymentClasses.push({
            id: c.id,
            subject: c.subject,
            curriculum: c.curriculum,
            grade: c.grade,
            tutorId: c.tutorId,
            classStart: c.selectedClassDate,
            duration: c.duration,
            classEnd: moment(c.selectedClassDate)
              .add(c.duration, 'minutes')
              .toDate()
              .getTime(),
            lastClassStart: c.lastClassStart,
            numberOfClasses: c.numberOfClasses,
            maxNumberOfClasses: 0,
          });
        }
      });
      dispatch(
        validatePaymentClasses({
          userId: invoiceData.student.id,
          paymentClasses: _paymentClasses,
        })
      );
      dispatch(getUserById(invoiceData.student.id));
    } else {
      handleSubmit();
    }
  };

  const handleUpdateClassData = (
    id: string,
    startDate: IMoment,
    duration: number,
    numberOfClasses: number
  ) => {
    const newClasses: ICustomInvoiceSubject[] = [...subjectList];
    const _selectedClassIndex = newClasses.findIndex((item) => item.id === id);

    if (_selectedClassIndex > -1) {
      const _selectedClass = newClasses[_selectedClassIndex];

      _selectedClass.duration = duration;
      _selectedClass.selectedClassDate = startDate.toDate().getTime();
      _selectedClass.numberOfClasses = numberOfClasses;
      _selectedClass.selectedNumberOfClass = numberOfClasses;

      newClasses[_selectedClassIndex] = _selectedClass;
      dispatch(
        updateValidatedPaymentClass({
          id,
          classStart: startDate.toDate().getTime(),
          classEnd: startDate.add(duration, 'minutes').toDate().getTime(),
          duration,
          numberOfClasses,
        })
      );
    }

    setSubjectList(newClasses);
  };

  const handleSubmit = () => {
    if (params && params.id) {
      const API_DATA: IUpdateInvoice = { id: params.id };

      if (
        promoCodeData &&
        promoCodeData.promoCodeDetails &&
        invoiceData &&
        invoiceData.rate &&
        invoiceData?.qty
      ) {
        API_DATA.promoCode = {
          promoCode: promoCodeData.promoCodeDetails.promoCode,
          promoCodeId: promoCodeData.promoCodeDetails.id,
          discount: parseFloat(promoCodeData.discount.toFixed(2)),
          price:
            promoCodeData.promoCodeDetails.promotionType === 'BONUS HOURS'
              ? parseFloat((invoiceData?.qty * invoiceData?.rate).toFixed(2))
              : parseFloat(promoCodeData.price.toFixed(2)),
          promotionType: promoCodeData.promoCodeDetails.promotionType,
        };
      }

      if (selectedOption === 1) {
        API_DATA.subjects = subjectList.map((invoiceSubject) => {
          const newSubject: IUpdateInvoiceSubject = {
            tutorId: invoiceSubject.tutorId,
            subject: invoiceSubject.subject,
            curriculum: invoiceSubject.curriculum,
            grade: invoiceSubject.grade,
            selectedClassDate: invoiceSubject.selectedClassDate,
            selectedNumberOfClass: invoiceSubject.selectedNumberOfClass,
            lastClassStart: invoiceSubject.lastClassStart,
          };
          return newSubject;
        });
      } else if (selectedOption === 2) {
        API_DATA.isChangeSchedule = true;
      }

      dispatch(updateInvoice(API_DATA));
    }
  };

  // RENDER
  const RENDER_OPTION_CONTENT = () => {
    if (
      invoiceState.invoice &&
      invoiceState.invoice.status !== 'Pending' &&
      invoiceState.invoice.sourceId
    ) {
      navigate(`/receipt/${invoiceState.invoice.sourceId}`);
    }

    if (selectedOption === 1) {
      return (
        <div>
          {errorMessage && (
            <div style={{ marginBottom: '10px' }}>
              <Typography color="error.main">{errorMessage}</Typography>
            </div>
          )}
          {subjectList.map((invoiceSubject, index) => {
            return (
              <ListItem
                key={index}
                invoiceSubject={invoiceSubject}
                onClick={() => setSelectedClass(invoiceSubject.id)}
                handleDelete={() =>
                  setSubjectDeleteAleartData({
                    id: invoiceSubject.id,
                    isDeleteSubjectConfirmationModal: true,
                  })
                }
                isDisabled={!invoiceState.invoice || !invoiceState.invoice?.qty}
              />
            );
          })}
        </div>
      );
    }
    return (
      <Typography color="secondary" fontWeight={500}>
        After payment completion, you will be contacted by our support team.
      </Typography>
    );
  };

  if (initLoading) {
    return (
      <FullScreenLoader
        containerProps={{
          style: { height: `calc(100vh - ${headerHeight})` },
        }}
      />
    );
  }

  return (
    <div style={{ paddingTop: '30px' }}>
      <Container maxWidth="xl">
        <Grid container spacing={2} sx={{ mb: '30px' }}>
          <Grid item xs={12} sm={12} md={8} lg={6} xl={5}>
            <PromoCard />
            <TotalCard />
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4} xl={3}>
            <div style={{ height: '100%' }}>
              <BalanceCard
                minutes={
                  invoiceState.invoice &&
                  invoiceState.invoice.student &&
                  invoiceState.invoice.student.remainingTime
                    ? invoiceState.invoice.student.remainingTime
                    : 0
                }
              />
            </div>
          </Grid>
        </Grid>
        {subjectList.length > 0 && (
          <>
            <div style={{ marginBottom: '30px' }}>
              <Typography variant="h6" fontWeight={600}>
                Class Schedule
              </Typography>
            </div>
            <div style={{ marginBottom: '30px' }}>
              <Card elevation={0} sx={scheduleContainerStyles}>
                <CardContent>
                  <Stack sx={{ mb: '30px' }}>
                    <FormControlLabel
                      value="start"
                      control={<Radio />}
                      label={
                        <Typography style={{ fontWeight: '600' }}>
                          Continue with my previous schedule
                        </Typography>
                      }
                      sx={radioOptionStyles}
                      checked={selectedOption === 1}
                      onChange={() => setSelectedOption(1)}
                    />
                    <FormControlLabel
                      value="start"
                      control={<Radio />}
                      label={
                        <Typography style={{ fontWeight: '600' }}>
                          Change my class schedule. Please contact me
                        </Typography>
                      }
                      sx={radioOptionStyles}
                      checked={selectedOption === 2}
                      onChange={() => setSelectedOption(2)}
                    />
                  </Stack>
                  <div>{RENDER_OPTION_CONTENT()}</div>
                </CardContent>
              </Card>
            </div>
          </>
        )}
      </Container>

      {/* BUTTON CONTAINER GAP */}
      <div style={{ minHeight: 'calc(66.5px + 15px)' }} />

      {/* BUTTON CONTAINER */}
      <Box sx={buttonContainerStyles}>
        <Grid container spacing={2} sx={{ maxWidth: '300px' }}>
          <Grid item xs={12}>
            <LoadingButton
              fullWidth
              disabled={isSubmitDisabled}
              loading={
                invoiceState.status === 'loading' ||
                paymentState.status === 'loading' ||
                checkoutState.status === 'loading'
              }
              variant="contained"
              color="primary"
              onClick={onContinue}
            >
              {invoiceState.status === 'loading' ? 'Loading...' : 'Continue'}
            </LoadingButton>
          </Grid>
        </Grid>
      </Box>

      {/* MODALS */}
      {isEditScheduleModalOpen &&
        selectedClass &&
        _selectedClassData &&
        invoiceState.invoice && (
          <EditClassScheduleModal
            open={isEditScheduleModalOpen}
            onClose={() => setSelectedClass(undefined)}
            invoiceSubject={_selectedClassData}
            updateClassData={handleUpdateClassData}
            invoice={invoiceState.invoice}
          />
        )}

      {ispaymentDetailsModalOpen && (
        <PaymentDetailsModal
          open={ispaymentDetailsModalOpen}
          userId={invoiceData && invoiceData.userId}
          onClose={() => setPaymentDetailsModalOpen(false)}
        />
      )}

      {isScheduleConflictModalOpen && (
        <ScheduleConflictModal
          open={isScheduleConflictModalOpen}
          onClose={() => setScheduleConflictModal(false)}
          paymentClasses={paymentClasses}
          disableContinue={isSubmitDisabled}
          errorMessage={errorMessage}
          actions={{
            onContinueWithoutClasses: (classesNeedstoBeFilterOut) => {
              setSubjectList((prevClasses) => {
                const newClasses: ICustomInvoiceSubject[] = [];
                prevClasses.forEach((prevClass) => {
                  const hasConflict = classesNeedstoBeFilterOut.some(
                    (c) => c.id === prevClass.id
                  );
                  if (!hasConflict) {
                    newClasses.push(prevClass);
                  }
                });

                return newClasses;
              });
              setPaymentDetailsModalOpen(true);
              setScheduleConflictModal(false);
            },
            setSelectedClass: (classId) => {
              setSelectedClass(classId);
            },
          }}
        />
      )}

      <Dialog
        open={subjectDeleteAlertData.isDeleteSubjectConfirmationModal}
        onClose={() =>
          setSubjectDeleteAleartData({
            isDeleteSubjectConfirmationModal: false,
          })
        }
      >
        <DialogTitle>Are you sure</DialogTitle>
        <DialogContent>Do you want to delete this class?</DialogContent>
        <DialogActions>
          <Button
            variant="text"
            color="info"
            onClick={() =>
              setSubjectDeleteAleartData({
                isDeleteSubjectConfirmationModal: false,
              })
            }
          >
            No
          </Button>
          <Button
            variant="text"
            color="info"
            onClick={() => {
              if (subjectDeleteAlertData.id) {
                setSubjectList((prevClasses) =>
                  prevClasses.filter((c) => c.id !== subjectDeleteAlertData.id)
                );
              }
              setSubjectDeleteAleartData({
                isDeleteSubjectConfirmationModal: false,
              });
            }}
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default BillingView;
