import { createAsyncThunk } from '@reduxjs/toolkit';

import {
  IStripeCard,
  IStripeCheckoutSessionValidation,
  IStripeSubscription,
  ISubscriptionData,
  TInvoiceValidationData,
  TStripeInvoiceData,
} from '.';
import { TSubscriptionType } from '../../pages/Packages/tabs/WeeklyPlansTab';
import APIDataProvider from '../../dataProvider/APIDataProvider';
import ApiPaths from '../../dataProvider/APIPaths';
import { ICustomPaymentClass } from '../PaymentSlice';
import { IUpdateInvoiceSubject } from '../InvoiceSlice';

export interface IStripeSubscribe {
  userId: string;
  amount: number;
  currency: string;
  packageId: string;
  subscriptionType: TSubscriptionType;
  sourceId: string;
  promoCode?: string;
  subjectCount?: number;
  minutesPerWeek?: number;
}

export interface IStripeSessionValidation {
  sessionId: string;
}

export interface IStripeCreateSession {
  userId: string;
  amount: number;
  currency: string;
  packageId: string;
  subscriptionType: TSubscriptionType;
  subjectCount?: number;
  minutesPerWeek?: number;
  collectorId?: string;
}

export interface IStripeValidatePaymentClassess {
  userId: string;
  paymentClasses: ICustomPaymentClass[];
  sessionId: string;
}

export type TCreateStripeInvoiceClasses = {
  stripeSessionId: string;
  subjects: Array<IUpdateInvoiceSubject>;
};

const StripeSecretkeyApiDataProvider = new APIDataProvider<{
  clientSecret: string;
}>();

const StripeCardsApiDataProvider = new APIDataProvider<{
  cards: Array<IStripeCard>;
}>();

const StripeSubscribeApiDataProvider = new APIDataProvider<{
  subscription: IStripeSubscription;
}>();
const StripeSessionValidationApiDataProvider = new APIDataProvider<IStripeCheckoutSessionValidation>();

const SubscribeApiDataProvider = new APIDataProvider<ISubscriptionData>();
const ValidateStripePaymentClassesApiDataProvider = new APIDataProvider<
  ICustomPaymentClass[]
>();

const StripeInvoiceValidattionDataprovider = new APIDataProvider<TInvoiceValidationData>();

const StripeInvoiceApiDataProvider = new APIDataProvider<TStripeInvoiceData>();

const StripeSessionApiDataProvider = new APIDataProvider<{
  redirectUrl: string;
  sessionId: string;
}>();

const StripeCreateInvoiceClassesApiDataProvider = new APIDataProvider<{
  status: 'success';
}>();

export const getStripeSecretKey = createAsyncThunk(
  'stripe/secretKey',
  async (data: { userId: string }) => {
    const response = await StripeSecretkeyApiDataProvider.create(
      `${ApiPaths.stripe}/secret`,
      data
    );

    return response.data;
  }
);

export const getStripeCards = createAsyncThunk(
  'stripe/cards',
  async (data: { userId: string }) => {
    const response = await StripeCardsApiDataProvider.post(
      `${ApiPaths.stripe}/cards`,
      data
    );

    return response.data;
  }
);

export const stripeSubscribe = createAsyncThunk(
  'stripe/subscribe',
  async (data: IStripeSubscribe) => {
    const response = await StripeSubscribeApiDataProvider.post(
      `${ApiPaths.stripe}/subscribe`,
      data
    );

    return response.data;
  }
);

export const getStripeSubscriptionById = createAsyncThunk(
  'stripe/subscription/id',
  async (subscriptionId: string) => {
    const response = await SubscribeApiDataProvider.getById(
      `${ApiPaths.stripe}/subscription/${subscriptionId}`
    );

    return response.data;
  }
);

export const validateStripePaymentClasses = createAsyncThunk(
  'stripe/subscription_booking',
  async (data: IStripeValidatePaymentClassess) => {
    const response = await ValidateStripePaymentClassesApiDataProvider.create(
      `${ApiPaths.stripe}/subscription_booking`,
      data
    );

    return response.data;
  }
);

export const createStripeSession = createAsyncThunk(
  'stripe/create_stripe_session',
  async (data: IStripeCreateSession) => {
    const response = await StripeSessionApiDataProvider.post(
      `${ApiPaths.stripe}/create_stripe_session`,
      data
    );

    return response.data;
  }
);
export const getStripeSession = createAsyncThunk(
  'stripe/validate_stripe_payment',
  async (data: IStripeSessionValidation) => {
    const response = await StripeSessionValidationApiDataProvider.post(
      `${ApiPaths.stripe}/validate_stripe_payment`,
      data
    );

    return response.data;
  }
);

export const validateStripeInvoiceSession = createAsyncThunk(
  'stripe/invoice/validate-session',
  async (data: { sessionId: string }) => {
    const response = await StripeInvoiceValidattionDataprovider.post(
      `${ApiPaths.stripe}/invoice/validate-session`,
      data
    );

    return response.data;
  }
);

export const getStripeInvoiceData = createAsyncThunk(
  'stripe/invoice/get',
  async (data: { stripeSessionId: string }) => {
    const response = await StripeInvoiceApiDataProvider.post(
      `${ApiPaths.stripe}/invoice/get`,
      data
    );

    return response.data;
  }
);

export const createStripeInvoiceClasses = createAsyncThunk(
  'stripe/invoice/schedule-invoice-classes',
  async (data: TCreateStripeInvoiceClasses) => {
    const response = await StripeCreateInvoiceClassesApiDataProvider.post(
      `${ApiPaths.stripe}/invoice/schedule-invoice-classes`,
      data
    );

    return response.data;
  }
);

export default getStripeSecretKey;
