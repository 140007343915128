import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
// ICONS
import CheckIcon from '@mui/icons-material/Check';
// CONSTANTS
import { HOUR_LIST } from '../tabs/WeeklyPlansTab';

interface HoursCountModalProps {
  open: boolean;
  onClose: () => void;
  selectedHourCount: number;
  selectedSubjectsCount: number;
  onChange: (count: number) => void;
}

const HoursCountModal: React.FC<HoursCountModalProps> = ({
  open,
  onClose,
  selectedHourCount,
  onChange,
  selectedSubjectsCount,
}) => {
  const options: React.ReactElement[] = [];
  for (let i = 0; i < HOUR_LIST.length; i += 1) {
    const selected = selectedHourCount === HOUR_LIST[i] * selectedSubjectsCount;
    const _hours = Math.floor((HOUR_LIST[i] * selectedSubjectsCount) / 60);
    const _mins = Math.floor((HOUR_LIST[i] * selectedSubjectsCount) % 60);

    options.push(
      <ListItem key={i}>
        <ListItemButton
          selected={selected}
          onClick={() => onChange(HOUR_LIST[i] * selectedSubjectsCount)}
        >
          <ListItemText
            primary={`${_hours.toString()} Hour${_hours > 1 ? 's' : ''}${
              _mins > 0 ? ` ${_mins} Min${_mins > 1 ? 's' : ''} ` : ''
            }`}
            style={{ fontWeight: 500, fontSize: '16px' }}
          />
          {selected && (
            <ListItemIcon>
              <CheckIcon />
            </ListItemIcon>
          )}
        </ListItemButton>
      </ListItem>
    );
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          minWidth: {
            xs: '95%',
            sm: '400px',
          },
          maxWidth: '400px',
        },
      }}
    >
      <DialogTitle>How Many Hours Per Week?</DialogTitle>
      <DialogContent>
        <List>{options}</List>
      </DialogContent>
    </Dialog>
  );
};

export default HoursCountModal;
