export const label = 'Tutopiya';
export const applePayVersion = 14;
export const supportedNetworks = ['visa', 'masterCard', 'amex', 'discover'];
export const merchantCapabilities: ApplePayJS.ApplePayMerchantCapability[] = [
  'supports3DS',
];

export const supportedCountries = [
  'Africa/South_Africa',
  'Asia/Australia',
  'Asia/China',
  'Asia/Hong_Kong',
  'Asia/Tokyo',
  'Asia/Macao',
  'Asia/Malaysia',
  'Pacific/Auckland',
  'Pacific/Chatham',
  'Asia/Taipei',
  'Asia/Singapore',
  'Asia/Yerevan',
  'Europe/Vienna',
  'Asia/Baku',
  'Europe/Minsk',
  'Europe/Brussels',
  'Europe/Sofia',
  'Europe/Zagreb',
  'Asia/Famagusta',
  'Europe/Prague',
  'Europe/Copenhagen',
  'Europe/Tallinn',
  'Atlantic/Faroe',
  'Europe/Helsinki',
  'Europe/Paris',
  'Asia/Tbilisi',
  'Atlantic/South_Georgia',
  'Europe/Berlin',
  'Europe/Busingen',
  'Europe/Athens',
  'America/Danmarkshavn',
  'America/Godthab',
  'America/Scoresbysund',
  'America/Thule',
  'Europe/Guernsey',
  'Europe/Budapest',
  'Atlantic/Reykjavik',
  'Europe/Dublin',
  'Europe/Isle_of_Man',
  'Europe/Rome',
  'Asia/Almaty',
  'Asia/Aqtau',
  'Asia/Aqtobe',
  'Asia/Atyrau',
  'Asia/Oral',
  'Asia/Qostanay',
  'Asia/Qyzylorda',
  'Europe/Jersey',
  'Europe/Riga',
  'Europe/Vaduz',
  'Europe/Vilnius',
  'Europe/Luxembourg',
  'Europe/Malta',
  'Europe/Chisinau',
  'Europe/Monaco',
  'Europe/Podgorica',
  'Europe/Amsterdam',
  'Europe/Oslo',
  'Europe/Warsaw',
  'Atlantic/Azores',
  'Atlantic/Madeira',
  'Europe/Lisbon',
  'Europe/Bucharest',
  'Europe/San_Marino',
  'Europe/Belgrade',
  'Europe/Bratislava',
  'Europe/Ljubljana',
  'Africa/Ceuta',
  'Atlantic/Canary',
  'Europe/Madrid',
  'Europe/Stockholm',
  'Europe/Zurich',
  'Europe/Kiev',
  'Europe/Simferopol',
  'Europe/Uzhgorod',
  'Europe/Zaporozhye',
  'Europe/London',
  'Europe/Vatican',
  'America/Argentina/Buenos_Aires',
  'America/Argentina/Catamarca',
  'America/Argentina/Cordoba',
  'America/Argentina/Jujuy',
  'America/Argentina/La_Rioja',
  'America/Argentina/Mendoza',
  'America/Argentina/Rio_Gallegos',
  'America/Argentina/Salta',
  'America/Argentina/San_Juan',
  'America/Argentina/San_Luis',
  'America/Argentina/Tucuman',
  'America/Argentina/Ushuaia',
  'America/Bogota',
  'America/Costa_Rica',
  'America/Araguaina',
  'America/Bahia_Banderas',
  'America/Cancun',
  'America/Chihuahua',
  'America/Hermosillo',
  'America/Matamoros',
  'America/Mazatlan',
  'America/Merida',
  'America/Mexico_City',
  'America/Monterrey',
  'America/Ojinaga',
  'America/Tijuana',
  'America/Lima',
  'Asia/Bahrain',
  'Asia/Jerusalem',
  'Asia/Amman',
  'Asia/Kuwait',
  'Asia/Qatar',
  'Asia/Riyadh',
  'Asia/Dubai',
  'Asia/Riyadh',
  'America/Atikokan',
  'America/Blanc-Sablon',
  'America/Cambridge_Bay',
  'America/Creston',
  'America/Dawson',
  'America/Dawson_Creek',
  'America/Edmonton',
  'America/Fort_Nelson',
  'America/Glace_Bay',
  'America/Goose_Bay',
  'America/Halifax',
  'America/Inuvik',
  'America/Iqaluit',
  'America/Moncton',
  'America/Nipigon',
  'America/Pangnirtung',
  'America/Rainy_River',
  'America/Rankin_Inlet',
  'America/Regina',
  'America/Resolute',
  'America/St_Johns',
  'America/Swift_Current',
  'America/Thunder_Bay',
  'America/Toronto',
  'America/Vancouver',
  'America/Whitehorse',
  'America/Winnipeg',
  'America/Yellowknife',
  'Pacific/Midway',
  'Pacific/Wake',
  'America/Adak',
  'America/Anchorage',
  'America/Boise',
  'America/Chicago',
  'America/Denver',
  'America/Detroit',
  'America/Indiana/Indianapolis',
  'America/Indiana/Knox',
  'America/Indiana/Marengo',
  'America/Indiana/Petersburg',
  'America/Indiana/Tell_City',
  'America/Indiana/Vevay',
  'America/Indiana/Vincennes',
  'America/Indiana/Winamac',
  'America/Juneau',
  'America/Kentucky/Louisville',
  'America/Kentucky/Monticello',
  'America/Los_Angeles',
  'America/Menominee',
  'America/Metlakatla',
  'America/New_York',
  'America/Nome',
  'America/North_Dakota/Beulah',
  'America/North_Dakota/Center',
  'America/North_Dakota/New_Salem',
  'America/Phoenix',
  'America/Sitka',
  'America/Yakutat',
  'Pacific/Honolulu',
];
