import React from 'react';
import { useLocation } from 'react-router-dom';
import { Card, CardContent, Typography } from '@mui/material';

import { instalmentWeeksCount, TP_COLORS } from '../../../constants';
import { IPackageData } from '../PaymentView';
import TextWithDivider from '../../Billing/components/TextWithDivider';
import { isMobileScreen } from '../../../helper';

interface TotalCardProps {
  packageData: IPackageData;
}

const TotalCard: React.FC<TotalCardProps> = ({ packageData }) => {
  const location = useLocation();

  const isMobile = isMobileScreen();
  const urlParams = new URLSearchParams(location.search);
  const isInstalmentPayment =
    urlParams.get('payment') === 'instalment' &&
    packageData?.planType === 'weekly';

  let total = packageData.amount || 0;

  if (isInstalmentPayment && packageData.planType === 'weekly') {
    total = parseFloat(
      ((total / (packageData.weeks || 0)) * instalmentWeeksCount).toString()
    );
  }

  const totalAmount = packageData
    ? `${packageData.currency || ''} ${total.toString()}`
    : '';

  return (
    <Card
      elevation={0}
      sx={{
        border: isMobile ? 'none' : { xs: 'none', sm: '1px solid lightgray' },
        p: isMobile ? 0 : { xs: '0', sm: '15px' },
        height: { xs: 'unsest', lg: '100%' },
      }}
    >
      <CardContent
        sx={{
          backgroundColor: TP_COLORS.listItemGray.light,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          height: { xs: 'unset', lg: 'calc(100% - 16px - 16px - 8px)' },
        }}
      >
        <div>
          <Typography variant="h6" fontWeight={600} sx={{ mb: '15px' }}>
            {packageData.planType === 'flexible'
              ? 'On-Demand Plan'
              : 'Weekly Plan'}{' '}
            ({packageData.title})
          </Typography>

          {/* QUANTITY */}
          {packageData.planType === 'flexible' && (
            <TextWithDivider
              label="Quantity (Hours)"
              value={
                packageData && packageData.hours
                  ? packageData.hours.toString()
                  : '-'
              }
            />
          )}

          {packageData.planType === 'weekly' && (
            <TextWithDivider
              label="Quantity (Weeks)"
              value={
                packageData && packageData.weeks
                  ? packageData.weeks.toString()
                  : '-'
              }
            />
          )}

          {packageData.planType === 'weekly' && (
            <>
              <TextWithDivider
                label="Subject Count"
                value={
                  packageData.subjectCount
                    ? packageData.subjectCount.toString()
                    : '-'
                }
              />

              <TextWithDivider
                label="Hours per Week"
                value={
                  packageData.minutesPerWeek
                    ? parseFloat(
                        (packageData.minutesPerWeek / 60).toFixed(2)
                      ).toString()
                    : '-'
                }
              />
            </>
          )}

          <TextWithDivider
            label="Rate"
            value={`${
              packageData && packageData.currency ? packageData.currency : ''
            } ${
              packageData && packageData.rate
                ? packageData.rate.toString()
                : '0'
            }`}
          />

          {packageData &&
            packageData.discount &&
            packageData.promoCodeDetails &&
            packageData.promoCodeDetails.promotionType === 'BONUS HOURS' && (
              <TextWithDivider
                label="Bonus Hours"
                value={`+ ${parseFloat(packageData.discount.toFixed(2))}`}
              />
            )}

          {packageData &&
            packageData.discount &&
            packageData.promoCodeDetails &&
            packageData.promoCodeDetails.promotionType !== 'BONUS HOURS' && (
              <>
                <Typography
                  textAlign="right"
                  fontWeight={600}
                  sx={{ mb: '15px' }}
                >
                  Base Plan Total:&nbsp;
                  {packageData
                    ? `${packageData.currency || ''} ${
                        packageData.price ? packageData.price.toString() : ''
                      }`
                    : '-'}
                </Typography>
                <TextWithDivider
                  label="Discount"
                  value={`- ${packageData.currency} ${parseFloat(
                    packageData.discount.toFixed(2)
                  )}`}
                />
              </>
            )}

          <Typography textAlign="right" fontWeight={600}>
            Total Amount{isInstalmentPayment === true ? ` (Monthly)` : ''}
            :&nbsp;
            {totalAmount || '-'}
            {}
          </Typography>
        </div>
      </CardContent>
    </Card>
  );
};

export default TotalCard;
