import React from 'react';
import { Card, CardContent, Typography } from '@mui/material';
// COMPONENTS
import TextWithDivider from './TextWithDivider';
// HOOKS
import { useAppSelector } from '../../../hooks';
// STORE
import { getInvoiceState } from '../../../store/InvoiceSlice';
// HELPERS
import { isMobileScreen } from '../../../helper';
// CONSTANTS
import { TP_COLORS } from '../../../constants';
import { getPromoCodeState } from '../../../store/PromoCodeSlice';

const TotalCard: React.FC = () => {
  // STORE
  const invoiceState = useAppSelector(getInvoiceState);
  const promoCodeState = useAppSelector(getPromoCodeState);

  const invoiceData = invoiceState.invoice;
  const promoCodeData = promoCodeState.promoCode;

  const isMobile = isMobileScreen();

  // STUDENT NAME
  let qty = '00';
  let studentName = '';
  let rate = '00.00';
  let total = '00.00';
  if (invoiceData) {
    if (invoiceData.student) {
      const studentData = invoiceData.student;
      studentName = studentData.firstName || '';
      if (studentData.lastName) {
        studentName += ` ${studentData.lastName}`;
      }
    }
    qty = invoiceData.qty ? invoiceData.qty.toString().padStart(2, '0') : '00';
    rate = invoiceData.rate ? invoiceData.rate.toFixed(2) : '00.00';
    if (invoiceData.qty && invoiceData.rate) {
      total = (invoiceData.qty * invoiceData.rate).toFixed(2);
    }
  }

  return (
    <Card
      elevation={0}
      sx={{
        border: isMobile ? 'none' : { xs: 'none', sm: '1px solid lightgray' },
        p: isMobile ? 0 : { xs: '0', sm: '15px' },
      }}
    >
      <CardContent sx={{ backgroundColor: TP_COLORS.listItemGray.light }}>
        <div>
          <Typography variant="h6" fontWeight={600} sx={{ mb: '15px' }}>
            {studentName}
          </Typography>
          <TextWithDivider label="Quantity (Hours)" value={qty} />
          <TextWithDivider
            label="Rate"
            value={`${invoiceData?.currency} ${rate}`}
          />
          {promoCodeData &&
            promoCodeData.discount &&
            promoCodeData.promoCodeDetails &&
            promoCodeData.promoCodeDetails.promotionType !== 'BONUS HOURS' && (
              <TextWithDivider
                label="Discount"
                value={`- ${
                  invoiceData?.currency
                } ${promoCodeData.discount.toFixed(2)}`}
              />
            )}
          {promoCodeData &&
            promoCodeData.discount &&
            promoCodeData.promoCodeDetails &&
            promoCodeData.promoCodeDetails.promotionType === 'BONUS HOURS' && (
              <TextWithDivider
                label="Bonus Hours"
                value={`+ ${promoCodeData.discount.toFixed(2)} Hours`}
              />
            )}
          {promoCodeData &&
          promoCodeData.discount &&
          promoCodeData.promoCodeDetails &&
          promoCodeData.promoCodeDetails.promotionType !== 'BONUS HOURS' ? (
            <Typography textAlign="right" fontWeight={600}>
              Total Amount: {invoiceData?.currency}{' '}
              {promoCodeData.price.toFixed(2)}
            </Typography>
          ) : (
            <Typography textAlign="right" fontWeight={600}>
              Total Amount: {invoiceData?.currency} {total}
            </Typography>
          )}
        </div>
      </CardContent>
    </Card>
  );
};

export default TotalCard;
