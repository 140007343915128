import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import {
  Box,
  SxProps,
  Theme,
  Typography,
  Grid,
  Stack,
  IconButton,
  Button,
} from '@mui/material';
// ICONS
import PrintIcon from '@mui/icons-material/Print';
import ArrowCircleLeftOutlinedIcon from '@mui/icons-material/ArrowCircleLeftOutlined';
// HOOKS
import { useAppSelector } from '../../hooks';
// STORE
import {
  getPaymentState,
  getReciept,
  IPaymentReceipt,
  resetPaymentReceipt,
} from '../../store/PaymentSlice';
// COMPONENTS
import BasicLoader from '../../components/Loaders/BasicLoader';
// HELPERS
import {
  getFlexiblePlanIdByOldPackageId,
  getPlanTypeById,
  SafeAreaGaps,
} from '../../helper';
// CONSTANTS
import {
  flexiblePlans,
  IFlexiblePlan,
  IWeeklyPlan,
  TPlanType,
  TP_COLORS,
  weeklyPlans,
} from '../../constants';

// STYLES
const containerStyles: SxProps<Theme> = {
  minHeight: '100vh',
  background: `url('/images/background-image-texts/tutopiya-text.png') no-repeat bottom center/100% , linear-gradient(180deg, #01B8AF ,#005753)`,
};
const alertStyles: SxProps<Theme> = {
  backgroundColor: 'success.main',
  textAlign: 'center',
  p: '16px',
  position: 'fixed',
  top: SafeAreaGaps.top,
  left: SafeAreaGaps.left,
  width: '100%',
  zIndex: 1,
};
const contentCardContainer: SxProps<Theme> = {
  backgroundColor: 'background.paper',
  borderRadius: '12px',
  m: '30px 0 30px 0',
  width: { xs: '85%', sm: '90%', md: '70%', lg: '60%', xl: '40%' },
  maxWidth: '800px',
  p: { xs: '30px 15px' },
};

// METHODS
const getFormatedPackageName = (planId: IPaymentReceipt['packageId']) => {
  const flexiblePlan = flexiblePlans.find((itm) => itm.id === planId);

  if (flexiblePlan && flexiblePlan.id) {
    return getFlexiblePlanIdByOldPackageId(flexiblePlan.id);
  }

  return planId || '';
};

// RENDER
const CustomAlert = (
  <Box sx={alertStyles}>
    <Typography color={TP_COLORS.white}>
      Package was Purchased Successfully!
    </Typography>
  </Box>
);

const Receipt: React.FC = () => {
  // HOOKS
  const dispatch = useDispatch();
  const location = useLocation();
  const paymentState = useAppSelector(getPaymentState);

  // LOCAL STATES
  const [isLoading, setLoading] = useState(true);
  // eslint-disable-next-line
  const [intervalId, setIntervalId] = useState<any>(undefined);

  // URL PARAMS
  const _urlParams = new URLSearchParams(location.search);
  const referenceId = _urlParams.get('reference') || undefined;
  const status = _urlParams.get('status') || undefined;

  // USE EFFECT
  useEffect(() => {
    if (status !== 'canceled' && status !== 'avoid') {
      getReceiptData();
    }
    // returned function will be called on component unmount
    return () => {
      clearInterval(intervalId);
      dispatch(resetPaymentReceipt());
    };
  }, []);

  useEffect(() => {
    if (paymentState.receipt) {
      setLoading(false);
      clearInterval(intervalId);
    } else {
      const interval = setInterval(() => {
        getReceiptData();
      }, 5000);
      setIntervalId(interval);
    }
  }, [paymentState.receipt]);

  // METHODS
  const getReceiptData = () => {
    if (referenceId) {
      dispatch(
        getReciept({
          sourceId: referenceId,
        })
      );
    }
  };

  // RENDER
  const RENDER_CONTENT = () => {
    if (status === 'canceled') {
      return (
        <Box sx={contentCardContainer}>
          <Stack direction="column" alignItems="center">
            <Typography variant="h5">Payment Canceled</Typography>
          </Stack>
        </Box>
      );
    }

    if (status === 'avoid') {
      return (
        <Box sx={contentCardContainer}>
          <Stack direction="column" alignItems="center">
            <Typography variant="h5">Payment Avoided</Typography>
          </Stack>
        </Box>
      );
    }

    if (isLoading) {
      return (
        <Box sx={contentCardContainer}>
          <BasicLoader />
        </Box>
      );
    }

    if (!isLoading && paymentState.receipt) {
      const _receipt = paymentState.receipt;
      let planType: TPlanType | undefined;
      let plan: IFlexiblePlan | IWeeklyPlan | undefined;
      let qty = 0;

      if (_receipt.packageId !== 'CUSTOM INVOICE') {
        planType = getPlanTypeById(_receipt.packageId);
        if (planType === 'weekly') {
          plan = weeklyPlans.find(
            (p) => p.id === (_receipt.packageId as IWeeklyPlan['id'])
          ) as IWeeklyPlan;
          if (plan) {
            qty = plan.weeks;
          }
        } else if (planType === 'flexible') {
          plan = flexiblePlans.find(
            (p) => p.id === (_receipt.packageId as IFlexiblePlan['id'])
          ) as IFlexiblePlan;
          if (plan) {
            qty = plan.hours;
          }
        }
      } else {
        qty = _receipt.packageHours;
      }

      return (
        <Box sx={contentCardContainer}>
          {/* LOGO */}
          <Box
            sx={{
              textAlign: { xs: 'center', sm: 'left' },
              mb: '15px',
            }}
          >
            <img
              src="/images/logos/tp-logo-green.png"
              alt="Tutopiya-Logo"
              width="140px"
            />
          </Box>

          {/* STUDENT NAME & INVOICE NO CONTAINER */}
          <Grid
            container
            spacing={2}
            alignItems="center"
            justifyContent="space-between"
            sx={{ mb: '30px' }}
          >
            {/* NAME */}
            <Grid item xs={12} sm={4}>
              <Typography
                variant="h6"
                fontWeight={600}
                sx={{
                  backgroundColor: TP_COLORS.listItemGray.light,
                  width: 'fit-content',
                  borderRadius: '6px',
                  p: '3px 5px',
                }}
              >
                {_receipt.userName}
              </Typography>
            </Grid>

            {/* INVOICE DATE & NO */}
            <Grid item xs={12} sm={8}>
              <div>
                <Typography
                  variant="subtitle2"
                  color="secondary"
                  fontWeight={600}
                  sx={{ textAlign: { xs: 'left', sm: 'right' } }}
                >
                  Invoice #: {_receipt.transactionId}
                </Typography>
              </div>
              <div>
                <Typography
                  variant="subtitle2"
                  color="secondary"
                  fontWeight={600}
                  sx={{ textAlign: { xs: 'left', sm: 'right' } }}
                >
                  Created: {_receipt.transactionDate}
                </Typography>
              </div>
              {_receipt &&
                _receipt.promoCodeDetails &&
                _receipt.promoCodeDetails.promoCode && (
                  <div>
                    <Typography
                      variant="subtitle2"
                      color="secondary"
                      fontWeight={600}
                      sx={{ textAlign: { xs: 'left', sm: 'right' } }}
                    >
                      Promo code: {_receipt.promoCodeDetails.promoCode}
                    </Typography>
                  </div>
                )}
              {_receipt && _receipt.isInstalmentPayment && (
                <Typography
                  variant="subtitle2"
                  color="secondary"
                  fontWeight={600}
                  sx={{ textAlign: { xs: 'left', sm: 'right' } }}
                >
                  Instalment Payment
                  {_receipt.instalmentCount
                    ? ` (${_receipt.instalmentCount})`
                    : ''}
                </Typography>
              )}
            </Grid>
          </Grid>

          {/* ITEM */}
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{
              backgroundColor: TP_COLORS.listItemGray.light,
              borderRadius: '7px',
              p: '5px 10px',
              mb: '15px',
            }}
          >
            <Typography variant="h6" fontWeight={600}>
              Item
            </Typography>
            <Typography variant="h6" fontWeight={600}>
              {_receipt && _receipt.packageId
                ? getFormatedPackageName(_receipt.packageId)
                : ''}
            </Typography>
          </Stack>

          {/* QUANTITY */}
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{
              backgroundColor: TP_COLORS.paperGray.light,
              borderRadius: '7px',
              p: '5px 10px',
              mb: '15px',
            }}
          >
            <Typography variant="h6" fontWeight={500}>
              Quantity ({planType === 'weekly' ? 'Weeks' : 'Hours'})
            </Typography>
            <Typography variant="h6" fontWeight={500}>
              {qty.toString()}
            </Typography>
          </Stack>

          {planType === 'weekly' && (
            <>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                sx={{
                  backgroundColor: TP_COLORS.paperGray.light,
                  borderRadius: '7px',
                  p: '5px 10px',
                  mb: '15px',
                }}
              >
                <Typography variant="h6" fontWeight={500}>
                  Subject Count
                </Typography>
                <Typography variant="h6" fontWeight={500}>
                  {_receipt.subjectCount
                    ? _receipt.subjectCount.toString()
                    : ''}
                </Typography>
              </Stack>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                sx={{
                  backgroundColor: TP_COLORS.paperGray.light,
                  borderRadius: '7px',
                  p: '5px 10px',
                  mb: '15px',
                }}
              >
                <Typography variant="h6" fontWeight={500}>
                  Hours per Week
                </Typography>
                <Typography variant="h6" fontWeight={500}>
                  {_receipt.minutesPerWeek
                    ? parseFloat(
                        (_receipt.minutesPerWeek / 60).toFixed(2)
                      ).toString()
                    : ''}
                </Typography>
              </Stack>
            </>
          )}

          {/* RATE */}
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{
              backgroundColor: TP_COLORS.paperGray.light,
              borderRadius: '7px',
              p: '5px 10px',
              mb: '15px',
            }}
          >
            <Typography variant="h6" fontWeight={500}>
              Rate
            </Typography>
            <Typography variant="h6" fontWeight={500}>
              {_receipt.currency}{' '}
              {_receipt && _receipt.packageRate
                ? _receipt.packageRate.toString()
                : ''}
            </Typography>
          </Stack>

          {/* DISCOUNT - BONUS HOURS */}
          {_receipt.discount &&
            _receipt.promoCodeDetails &&
            _receipt.promoCodeDetails.promotionType === 'BONUS HOURS' && (
              <>
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  sx={{
                    backgroundColor: TP_COLORS.paperGray.light,
                    borderRadius: '7px',
                    p: '5px 10px',
                    mb: '15px',
                  }}
                >
                  <Typography variant="h6" fontWeight={500}>
                    Bonus Hours
                  </Typography>
                  <Typography variant="h6" fontWeight={500}>
                    + {_receipt.discount} Hours
                  </Typography>
                </Stack>
              </>
            )}

          {_receipt.discount &&
            _receipt.promoCodeDetails &&
            _receipt.promoCodeDetails.promotionType !== 'BONUS HOURS' && (
              <>
                {/* SUBTOTAL */}
                <Stack
                  direction="row"
                  justifyContent="flex-end"
                  sx={{
                    backgroundColor: TP_COLORS.listItemGray.light,
                    borderRadius: '7px',
                    p: '5px 10px',
                    mb: '15px',
                  }}
                >
                  <Typography variant="h6" fontWeight={600}>
                    Base Plan Total: {_receipt.currency}{' '}
                    {_receipt.originalRate.toFixed(2)}
                  </Typography>
                </Stack>

                {/* DISCOUNT */}
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  sx={{
                    backgroundColor: TP_COLORS.paperGray.light,
                    borderRadius: '7px',
                    p: '5px 10px',
                    mb: '15px',
                  }}
                >
                  <Typography variant="h6" fontWeight={500}>
                    Discount
                  </Typography>
                  <Typography variant="h6" fontWeight={500}>
                    - {_receipt.currency} {Number(_receipt.discount).toFixed(2)}
                  </Typography>
                </Stack>
              </>
            )}

          {/* TOTAL */}
          <Stack
            direction="row"
            justifyContent="flex-end"
            sx={{
              backgroundColor: TP_COLORS.listItemGray.light,
              borderRadius: '7px',
              p: '5px 10px',
            }}
          >
            <Typography variant="h6" fontWeight={600}>
              Total Amount{_receipt.isInstalmentPayment ? ` (Monthly)` : ''}:{' '}
              {_receipt.currency} {Number(_receipt.amount).toFixed(2)}
            </Typography>
          </Stack>

          {/* TERMS */}
          <div style={{ paddingTop: '30px', marginBottom: '15px' }}>
            <Typography variant="h6" fontWeight={600}>
              Terms
            </Typography>
            <Typography color="secondary" fontWeight={500}>
              Lessons may be postponed or cancelled prior to the scheduled
              lesson start time without charge subject to the minimum notice
              periods below.
            </Typography>
            <ul>
              <Typography component="li" color="secondary" fontWeight={500}>
                Lessons may be cancelled or rescheduled without charge subject
                to a minimum notice period of 2 hours prior to the scheduled
                lesson start time.
              </Typography>
              <Typography component="li" color="secondary" fontWeight={500}>
                Lessons are transferrable to siblings only.
              </Typography>
            </ul>
          </div>

          {/* PRINT BUTTON */}
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <IconButton size="large" onClick={() => window.print()}>
              <PrintIcon
                sx={{ width: '40px', height: '40px', color: 'primary.main' }}
              />
            </IconButton>
          </div>
        </Box>
      );
    }

    return null;
  };

  let isAlertVisible = true;

  if (status === 'canceled' || status === 'avoid' || !paymentState.receipt) {
    isAlertVisible = false;
  }

  return (
    <Box sx={containerStyles}>
      {isAlertVisible && CustomAlert}

      <div style={{ margin: '0 30px 15px 30px' }}>
        <div style={{ minHeight: '64px' }} />
        {!isLoading && (
          <Button
            variant="contained"
            color="inherit"
            startIcon={<ArrowCircleLeftOutlinedIcon />}
            href={`${process.env.REACT_APP_TUTOPIYA_PLATFORM_BASEURL}/#/dashboard/billing`}
            style={{
              color: TP_COLORS.black,
              backgroundColor: 'rgba(255, 2555, 255, 0.7)',
            }}
          >
            Back to Billing
          </Button>
        )}
      </div>

      <div style={{ display: 'flex', justifyContent: 'center' }}>
        {RENDER_CONTENT()}
      </div>
    </Box>
  );
};

export default Receipt;
