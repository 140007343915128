import { TTheme } from '../store/ConfigSlice';

// GET FROM LOCAL STORAGE
export const getAppThemeModeFromLocalStorage = (): TTheme | undefined => {
  const data = localStorage.getItem('TUTOPIYA_BILLING_THEME_MODE');
  const mode: TTheme = (data as TTheme) || 'light';
  return mode.trim() as TTheme;
};

export const getAuthTokenFromLocalStorage = (): string | undefined => {
  const data = localStorage.getItem('TUTOPIYA_BILLING_AUTH_TOKEN');
  if (data) {
    return data;
  }
  return undefined;
};

// SET TO LOCAL STORAGE
export const setAppThemeModeToLocalStorage = (mode: TTheme): void => {
  localStorage.setItem('TUTOPIYA_BILLING_THEME_MODE', mode as string);
};

export const setAuthTokenToLocalStorage = (token: string): void => {
  localStorage.setItem('TUTOPIYA_BILLING_AUTH_TOKEN', token);
};

// REMOVE FROM LOCAL STORAGE
export const removeAuthTokenToLocalStorage = (): void => {
  localStorage.removeItem('TUTOPIYA_BILLING_AUTH_TOKEN');
};
