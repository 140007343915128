import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { LoadingButton } from '@mui/lab';
import {
  Dialog,
  DialogContent,
  Typography,
  Box,
  SxProps,
  Theme,
  DialogActions,
  FormControlLabel,
  Checkbox,
  Stack,
  Button,
  Drawer,
  Grid,
} from '@mui/material';
import MomentTimeZone from 'moment-timezone';
// HOOKS
import { useAppSelector } from '../../../hooks';
// STORE
import {
  getPaymentState,
  ICustomPaymentClass,
  validatePaymentClasses,
} from '../../../store/PaymentSlice';
// HELPERS
import { isMobileScreen, SafeAreaGaps } from '../../../helper';
// CONSTANTS
import ListItem from '../components/ListItem';
import { getUserState } from '../../../store/UserSlice';

interface ScheduleConflictModalProps {
  open: boolean;
  onClose: () => void;
  paymentClasses: ICustomPaymentClass[];
  disableContinue: boolean;
  errorMessage: string;
  actions: {
    onContinueWithoutClasses?: (
      classesNeedstoBeFilterOut: ICustomPaymentClass[]
    ) => void;
    setSelectedClass: (classTempId: string | undefined) => void;
  };
}

interface IState {
  continueWithoutClasses: boolean;
}

// STYLES
const bodyContainerStyles: SxProps<Theme> = {
  backgroundColor: 'background.paper',
  p: { xs: '30px 15px', sm: '30px' },
  overflowY: 'auto',
};

// DATA
const initialState: IState = {
  continueWithoutClasses: false,
};

const ScheduleConflictModal: React.FC<ScheduleConflictModalProps> = ({
  open,
  onClose,
  paymentClasses,
  actions,
  disableContinue,
  errorMessage,
}) => {
  // HOOKS
  const dispatch = useDispatch();

  // STORE
  const paymentState = useAppSelector(getPaymentState);
  const userState = useAppSelector(getUserState);

  const conflictClasses =
    paymentState.validatedPaymentClassessData &&
    paymentState.validatedPaymentClassessData.conflictPaymentClasses
      ? paymentState.validatedPaymentClassessData.conflictPaymentClasses
      : [];
  const userTimezone =
    userState.user && userState.user.country
      ? userState.user.country
      : 'Asia/Singapore';

  // STATE
  const [state, setState] = useState<IState>(initialState);

  // BOOLEANS
  const isMobile = isMobileScreen();

  // VIEWS
  const RENDER_CHECKBOX = (
    <FormControlLabel
      checked={state.continueWithoutClasses}
      value="start"
      control={<Checkbox />}
      label="Continue without these classes"
      onChange={(event, checked) =>
        setState((prevState) => ({
          ...prevState,
          continueWithoutClasses: checked,
        }))
      }
    />
  );

  // METHODS
  const handleContinue = () => {
    if (state.continueWithoutClasses && actions.onContinueWithoutClasses) {
      actions.onContinueWithoutClasses(conflictClasses);
    } else if (!state.continueWithoutClasses && userState.user) {
      dispatch(
        validatePaymentClasses({
          userId: userState.user.id,
          paymentClasses,
        })
      );
      onClose();
    }
  };

  const RENDER_CONTENT = () => {
    return (
      <div role="presentation">
        <Box sx={bodyContainerStyles}>
          <div style={{ marginBottom: '15px' }}>
            <Typography variant="h6" fontWeight={600}>
              Schedule Conflict
            </Typography>
          </div>

          {errorMessage && (
            <div style={{ marginBottom: '15px' }}>
              <Typography color="error">{errorMessage}</Typography>
            </div>
          )}

          <div>
            {paymentState.validatedPaymentClassessData &&
              paymentState.validatedPaymentClassessData.conflictPaymentClasses.map(
                (paymentClass, index) => {
                  const _classClassMoment = MomentTimeZone(
                    paymentClass.classClashDate
                  ).tz(userTimezone);
                  const _classClassDate = _classClassMoment.format(
                    'DD MMM YYYY (ddd)'
                  );
                  const _classClassTime = _classClassMoment.format('hh:mm A');
                  const _errorLabel = (paymentClass.error || '')
                    .replace('DD MMM YYYY (ddd)', _classClassDate)
                    .replace('hh:mm A', _classClassTime);
                  return (
                    <div key={index}>
                      <div style={{ marginBottom: '10px' }}>
                        <Typography color="error">{_errorLabel}</Typography>
                      </div>
                      <Box sx={{ p: { xs: '0 15px', sm: '0' } }}>
                        <ListItem
                          paymentClass={paymentClass}
                          onClick={() =>
                            actions.setSelectedClass(paymentClass.id)
                          }
                        />
                      </Box>
                    </div>
                  );
                }
              )}
          </div>
        </Box>
      </div>
    );
  };

  if (isMobile) {
    return (
      <Drawer
        open={open}
        onClose={onClose}
        anchor="bottom"
        PaperProps={{
          sx: {
            maxHeight: '95vh',
            borderTopLeftRadius: '12px',
            borderTopRightRadius: '12px',
          },
        }}
      >
        {RENDER_CONTENT()}
        <div style={{ padding: '15px' }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              {actions.onContinueWithoutClasses ? RENDER_CHECKBOX : <div />}
            </Grid>
            <Grid item xs={12}>
              <LoadingButton
                fullWidth
                variant="contained"
                color="primary"
                onClick={handleContinue}
                disabled={disableContinue}
              >
                Continue
              </LoadingButton>
            </Grid>
          </Grid>
        </div>
      </Drawer>
    );
  }

  // RENDER
  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          width: {
            xs: 'unset',
            sm: '700px',
            lg: '800px',
          },
          maxWidth: { sm: '700px', md: '800px', lg: '1200px' },
          borderRadius: '12px',
        },
      }}
    >
      <DialogContent
        style={{ padding: `0 0 ${SafeAreaGaps.bottom} 0`, width: '100%' }}
      >
        {RENDER_CONTENT()}
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'space-between', p: '15px 30px' }}>
        {actions.onContinueWithoutClasses ? RENDER_CHECKBOX : <div />}
        <Stack direction="row" alignItems="center">
          <Button
            variant="contained"
            color="inherit"
            style={{ marginRight: '10px' }}
            onClick={onClose}
          >
            Dismiss
          </Button>
          <LoadingButton
            variant="contained"
            color="primary"
            onClick={handleContinue}
            disabled={disableContinue}
          >
            Continue
          </LoadingButton>
        </Stack>
      </DialogActions>
    </Dialog>
  );
};

export default ScheduleConflictModal;
