import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { LocalizationProvider, MobileDatePicker, PickersDay } from '@mui/lab';
import { withStyles } from '@mui/styles';
import {
  Box,
  Button,
  Card,
  CardActionArea,
  CardContent,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Drawer,
  FormControl,
  Grid,
  MenuItem,
  Select,
  SxProps,
  TextField,
  Typography,
  TypographyProps,
  Theme,
  SelectChangeEvent,
} from '@mui/material';
import AdapterMoment from '@mui/lab/AdapterMoment';
import moment, { Moment as IMoment } from 'moment';

import { TP_COLORS } from '../../../constants';
import {
  ICustomPaymentClass,
  IGetPaymentClassFreeSlots,
  IPaymentFreeSlot,
  getFreeSlotsForTheWeekInBackground,
  getPaymentClassFreeSlots,
  getPaymentState,
} from '../../../store/PaymentSlice';
import { useAppSelector } from '../../../hooks';
import { isMobileScreen } from '../../../helper';
import BasicLoader from '../../../components/Loaders/BasicLoader';

interface EditPaymentClassModalProps {
  open: boolean;
  onClose: () => void;
  classData: ICustomPaymentClass;
  isFlexiblePlan?: boolean;
  updateClassData: (
    id: string,
    startDate: IMoment,
    duration: number,
    numberOfClasses?: number
  ) => void;
}

interface IState {
  startDate: IMoment;
  duration: number;
  selectedFreeSlot: IPaymentFreeSlot | undefined;
}

const modalHeading = 'Class Schedule';
const defaultStartDate = moment().add(1, 'week');
const defaultNumberOfClassesOption = {
  label: '00 Class',
  value: 0,
};
const initialState: IState = {
  startDate: defaultStartDate,
  duration: 60,
  selectedFreeSlot: undefined,
};

const durationOptions = [60, 120, 180].map((item) => {
  const _hours = Math.floor(item / 60);
  return {
    label: `${_hours.toString().padStart(2, '0')} Hour${_hours > 1 ? 's' : ''}`,
    value: item,
  };
});

const labelStyles: TypographyProps = {
  fontWeight: 600,
  style: { marginBottom: '5px' },
};

const slotItemStyles: React.CSSProperties = {
  paddingBottom: '16px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  minHeight: 'calc(76px - 30px)',
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const CustomPickersDay: any = withStyles({
  root: {
    '&.Mui-disabled': {
      pointerEvents: 'auto',
    },
  },
})(PickersDay);

const getNumberOfClassesOptions = (numberOfClasses: number) => {
  const options = [defaultNumberOfClassesOption];

  if (numberOfClasses && numberOfClasses > 0) {
    for (let i = 1; i <= numberOfClasses; i += 1) {
      options.push({
        label: `${i.toString().padStart(2, '0')} Class${i > 1 ? 'es' : ''}`,
        value: i,
      });
    }
  }

  return options;
};

const EditPaymentClassModal: React.FC<EditPaymentClassModalProps> = ({
  open,
  onClose,
  classData,
  updateClassData,
  isFlexiblePlan = false,
}) => {
  const dispatch = useDispatch();

  // Store
  const paymentState = useAppSelector(getPaymentState);

  // State
  const [state, setState] = useState<IState>(initialState);
  const [numberOfClasses, setNumberOfClasses] = useState<number>(
    classData.numberOfClasses || 0
  );
  const [numberOfClassesOptions, setNumberOfClassesOptions] = useState([
    defaultNumberOfClassesOption,
  ]);

  const isMobile = isMobileScreen();
  const freeSlots = paymentState.freeslots || [];
  const freeSlotsAvailabilityDays: string[] = [];

  const filterFreeSlots = (item: IPaymentFreeSlot) => {
    return (
      moment(item.start).format('YYYY-MM-DD') ===
        state.startDate.format('YYYY-MM-DD') &&
      moment().add(24, 'hours').toDate().getTime() <
        moment(item.start).toDate().getTime()
    );
  };

  const sortFreeSlots = (a: IPaymentFreeSlot, b: IPaymentFreeSlot) => {
    const startA = moment(a.start).toDate().getTime();
    const startB = moment(b.start).toDate().getTime();

    if (startA < startB) {
      return -1;
    }

    if (startA > startB) {
      return 1;
    }

    return 0;
  };

  const freeSlotsFormated = freeSlots
    .filter(filterFreeSlots)
    .sort(sortFreeSlots);

  const isNumberClassesMatchingOptionValue = numberOfClassesOptions.find(
    (item) => item.value === numberOfClasses
  );

  const isSubmitDisabled =
    (isFlexiblePlan && !isNumberClassesMatchingOptionValue) ||
    paymentState.status === 'loading' ||
    (!classData.classStart && !state.selectedFreeSlot) ||
    (moment(classData.classStart).format('YYYY-MM-DD') !==
      state.startDate.format('YYYY-MM-DD') &&
      !state.selectedFreeSlot);

  if (
    paymentState.freeSlotsForTheWeek &&
    paymentState.freeSlotsForTheWeek.length > 0
  ) {
    paymentState.freeSlotsForTheWeek.forEach((item) => {
      const _day = moment(item.start).format('dddd');
      if (!freeSlotsAvailabilityDays.includes(_day)) {
        freeSlotsAvailabilityDays.push(_day);
      }
    });
  }

  useEffect(() => {
    const newState = { ...state };

    if (classData) {
      let classStart = defaultStartDate;

      const freeSlotApiData: IGetPaymentClassFreeSlots = {
        date: classStart.format('YYYY-MM-DD'),
        tutorId: classData.tutorId,
        duration: 60,
      };

      if (classData.classStart) {
        classStart = moment(classData.classStart);

        newState.startDate = classStart;

        freeSlotApiData.date = classStart.format('YYYY-MM-DD');
      }

      if (
        classData.duration &&
        durationOptions.find((d) => d.value === classData.duration)
      ) {
        newState.duration = classData.duration;

        freeSlotApiData.duration = classData.duration;
      }

      dispatch(getPaymentClassFreeSlots(freeSlotApiData));
      getFreeSlotsForTheWeek(classStart);

      setState(newState);
    }
  }, []);

  useEffect(() => {
    if (isFlexiblePlan) {
      let _numberOfClasses = 0;

      if (classData) {
        const maximumNumberOfClasses = classData.maxNumberOfClasses || 0;
        const totalPlanMins = maximumNumberOfClasses * classData.duration;

        _numberOfClasses = Math.floor(totalPlanMins / state.duration);
      }

      const _numberOfClassesOptions = getNumberOfClassesOptions(
        _numberOfClasses
      );
      setNumberOfClassesOptions(_numberOfClassesOptions);
    }
  }, [state.duration]);

  const getFreeSlotsForTheWeek = (date: IMoment) => {
    dispatch(
      getFreeSlotsForTheWeekInBackground({
        date: date.clone().startOf('month').toDate().getTime(),
        tutorId: classData.tutorId,
      })
    );
  };

  const handleStartDateChange = (date: IMoment | null) => {
    const newState: IState = { ...state, selectedFreeSlot: undefined };

    if (date && classData) {
      newState.startDate = date;

      const freeSlotApiData: IGetPaymentClassFreeSlots = {
        date: date.format('YYYY-MM-DD'),
        tutorId: classData.tutorId,
        duration: state.duration,
      };

      dispatch(getPaymentClassFreeSlots(freeSlotApiData));
    }

    setState(newState);
  };

  const handleDurationChange = (event: SelectChangeEvent<number>) => {
    const newState: IState = { ...state, selectedFreeSlot: undefined };

    newState.duration = event.target.value as number;

    const freeSlotApiData: IGetPaymentClassFreeSlots = {
      date: state.startDate.format('YYYY-MM-DD'),
      tutorId: classData.tutorId,
      duration: newState.duration,
    };

    dispatch(getPaymentClassFreeSlots(freeSlotApiData));
    setState(newState);
  };

  const handleFreeSlotClick = (item: IPaymentFreeSlot) => {
    const newState: IState = { ...state };

    if (newState.selectedFreeSlot === item) {
      newState.selectedFreeSlot = undefined;
    } else {
      newState.selectedFreeSlot = item;
    }

    setState(newState);
  };

  const renderTimeChip = () => {
    if (classData.classStart) {
      let date = classData.classStart;

      if (state.selectedFreeSlot) {
        date = moment(state.selectedFreeSlot.start).toDate().getTime();
      }

      const _time = moment(date).format('hh:mm A');

      return (
        <div style={{ marginBottom: '30px' }}>
          <Chip label={`${_time}`} />
        </div>
      );
    }

    return null;
  };

  const handleSubmit = () => {
    if (classData) {
      let classStart = moment(state.startDate);

      if (state.selectedFreeSlot) {
        classStart = moment(
          `${state.startDate.format('YYYY-MM-DD')} ${moment(
            state.selectedFreeSlot.start
          ).format('hh:mm A')}`,
          'YYYY-MM-DD hh:mm A'
        );
      }

      updateClassData(
        classData.id,
        classStart,
        state.duration,
        numberOfClasses
      );
    }
    onClose();
  };

  const renderContent = () => {
    return (
      <>
        <div style={{ marginBottom: '30px' }}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} sm={4}>
              <Typography {...labelStyles}>Start Date</Typography>
              <FormControl fullWidth>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <MobileDatePicker
                    showToolbar={false}
                    value={state.startDate}
                    onChange={handleStartDateChange}
                    disableCloseOnSelect={false}
                    inputFormat="YYYY-MM-DD"
                    minDate={moment()}
                    renderDay={(day, selectedDates, pickersDayProps) => {
                      if (
                        pickersDayProps.disabled &&
                        day.toDate().getTime() > new Date().getTime()
                      ) {
                        return (
                          <span key={pickersDayProps.key} title="No Free Slots">
                            <CustomPickersDay
                              {...pickersDayProps}
                              style={{
                                ...pickersDayProps.style,
                                backgroundColor: 'rgba(0, 0, 0, 0.04)',
                              }}
                            />
                          </span>
                        );
                      }

                      return <PickersDay {...pickersDayProps} />;
                    }}
                    shouldDisableDate={(day) => {
                      const _day = day.format('dddd');
                      if (!freeSlotsAvailabilityDays.includes(_day)) {
                        return true;
                      }
                      return false;
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        className="mui-text-field-label-hidden"
                        placeholder="Select Start Date & Time"
                      />
                    )}
                  />
                </LocalizationProvider>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography {...labelStyles}>Duration</Typography>
              <FormControl fullWidth>
                <Select
                  size="small"
                  value={state.duration}
                  onChange={handleDurationChange}
                >
                  {durationOptions.map((option, index) => {
                    return (
                      <MenuItem key={index} value={option.value}>
                        {option.label}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
            {isFlexiblePlan ? (
              <Grid item xs={12} sm={4}>
                <Typography {...labelStyles}>No of classes</Typography>
                <FormControl fullWidth>
                  <Select
                    size="small"
                    value={numberOfClasses}
                    onChange={(event) =>
                      setNumberOfClasses(event.target.value as number)
                    }
                  >
                    {numberOfClassesOptions.map((option, index) => {
                      return (
                        <MenuItem key={index} value={option.value}>
                          {option.label}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>
            ) : null}
          </Grid>
        </div>

        {renderTimeChip()}

        {paymentState.status === 'loading' ? (
          <div style={{ marginBottom: '15px' }}>
            <BasicLoader />
          </div>
        ) : (
          <div>
            <Grid container spacing={2}>
              {freeSlotsFormated.length > 0 &&
                freeSlotsFormated.map((item, index) => {
                  let isSelected = item === state.selectedFreeSlot;
                  let textColor: TypographyProps['color'];

                  if (!state.selectedFreeSlot && !isSelected) {
                    isSelected =
                      classData.classStart ===
                      moment(item.start).toDate().getTime();
                  }

                  const time = moment(item.start).format('hh:mm A');
                  const cardStyles: SxProps<Theme> = {
                    backgroundColor: TP_COLORS.paperGray.light,
                  };

                  if (isSelected) {
                    cardStyles.backgroundColor = 'primary.main';
                    cardStyles.color = TP_COLORS.white;
                    textColor = TP_COLORS.white;
                  }

                  return (
                    <Grid key={index} item xs={6} sm={4} md={4} lg={3}>
                      <Card elevation={0} sx={cardStyles}>
                        <CardActionArea
                          disabled={item.booked}
                          onClick={() => handleFreeSlotClick(item)}
                        >
                          <CardContent style={slotItemStyles}>
                            <Typography
                              variant="subtitle2"
                              fontWeight={600}
                              color={textColor || 'secondary'}
                            >
                              {time}
                            </Typography>
                          </CardContent>
                        </CardActionArea>
                      </Card>
                    </Grid>
                  );
                })}
            </Grid>
          </div>
        )}
      </>
    );
  };

  if (isMobile) {
    return (
      <Drawer
        open={open}
        onClose={onClose}
        anchor="bottom"
        PaperProps={{
          style: {
            maxHeight: '90vh',
            borderTopLeftRadius: '12px',
            borderTopRightRadius: '12px',
          },
        }}
      >
        <Box role="presentation">
          <div style={{ margin: '30px 0 15px 0' }}>
            <Typography variant="h6" textAlign="center" fontWeight={600}>
              {modalHeading}
            </Typography>
          </div>
          <div
            style={{
              maxHeight: 'calc(90vh - 80px)',
              overflow: 'auto',
              padding: '0 15px',
            }}
          >
            {renderContent()}
            <div style={{ marginBottom: '15px' }}>
              <Button
                fullWidth
                variant="contained"
                color="primary"
                onClick={handleSubmit}
                disabled={isSubmitDisabled}
                style={{ marginTop: '15px' }}
              >
                Update
              </Button>
            </div>
          </div>
        </Box>
      </Drawer>
    );
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          maxWidth: { sm: '700px', md: '800px', lg: '800px', xl: '1000px' },
          minWidth: { xs: 'unset', sm: '650px', md: '700px' },
        },
      }}
    >
      <DialogTitle>{modalHeading}</DialogTitle>
      <DialogContent>{renderContent()}</DialogContent>
      <DialogActions style={{ padding: '15px 30px' }}>
        <Button
          size="small"
          variant="contained"
          color="inherit"
          onClick={onClose}
          style={{ minWidth: '70px' }}
        >
          Dismiss
        </Button>
        <Button
          size="small"
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          disabled={isSubmitDisabled}
          style={{ minWidth: '70px' }}
        >
          Update
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditPaymentClassModal;
